import {
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  Collapse,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect } from "react";
import parse from "html-react-parser";
import { copyMixedHtml, formatDate } from "../common/Utils";
import PreviousNextPicker from "../common/PreviousNextPicker";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  CancelConfirmButtons,
  StyledTextfield,
  scrollbarStyleThin,
} from "../common/StyledComponents";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { useForm } from "react-hook-form";
import axios from "axios";
import { rule5properties } from "../properties";
import OpenResearchButton from "../common/OpenResearchButton";
import { useOpportunityCompanies } from "../api/opportunities";
import FeedbackForm from "../components/FeedbackForm";

export default function ViewAgentResult(props) {
  const {
    agentResults,
    sendFeedback,
    selectedId,
    setSelectedAgentResult,
    setFeedbackById,
  } = props;
  // Find the index of the selected result.
  const displayedIndex = agentResults.findIndex(
    (result) => result.agentResultId === selectedId
  );
  const currentResult = agentResults[displayedIndex];
  const [localFeedbackInfo, setLocalFeedbackInfo] = React.useState(
    agentResults[displayedIndex].feedbackInfo
  );
  const [copyStatus, setCopyStatus] = React.useState("Copy summary");
  const [feedbackMode, setFeedbackMode] = React.useState(sendFeedback);

  const { data: opportunityCompanies, isFetching } = useOpportunityCompanies();

  function copySummary() {
    copyMixedHtml(currentResult.summary);
    setCopyStatus("Copied!");
    setTimeout(() => {
      setCopyStatus("Copy summary");
    }, 2000);
  }

  function toggleFeedbackMode() {
    setFeedbackMode(!feedbackMode);
  }

  const companyResearch = opportunityCompanies?.find(
    (company) => company.companyId === currentResult.companyId
  );

  return (
    <Stack
      sx={{ height: "80vh", overflow: "hidden" }}
      flexDirection="column"
      alignItems="center"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography variant="modalHeader1">{currentResult.title}</Typography>
        <PreviousNextPicker
          mini
          previousTooltip="Previous"
          nextTooltip="Next"
          previousDisabled={displayedIndex === 0}
          nextDisabled={displayedIndex === agentResults.length - 1}
          previousClick={() => {
            const newIndex = displayedIndex - 1;
            setSelectedAgentResult(
              agentResults[newIndex].agentResultId,
              newIndex
            );
          }}
          nextClick={() => {
            const newIndex = displayedIndex + 1;
            setSelectedAgentResult(
              agentResults[newIndex].agentResultId,
              newIndex
            );
          }}
          sx={{ mb: 1, ml: 1 }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mt: 0.5, width: "100%" }}
      >
        <Typography variant="modalHeader2">
          {currentResult.companyName} - {currentResult.signal}
        </Typography>
        <Typography variant="modalHeader2">
          {formatDate(new Date(currentResult.publishedDate))}
        </Typography>
      </Stack>
      <Box
        style={{
          width: "100%",
          backgroundColor: "rgba(0,0,0,.03)",
          margin: "16px 0px 8px 0px",
          padding: "16px 24px",
          borderRadius: "8px",
          overflow: "auto",
        }}
        sx={scrollbarStyleThin}
      >
        {currentResult.summary && parse(currentResult.summary)}
      </Box>
      {!feedbackMode && (
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ width: "100%", height: "40px" }}
        >
          <Box>
            <Button
              endIcon={<ContentCopyIcon />}
              onClick={copySummary}
              sx={{ width: "140px", mr: 1 }}
            >
              {copyStatus}
            </Button>
            <Button
              endIcon={<RateReviewOutlinedIcon />}
              onClick={toggleFeedbackMode}
            >
              Feedback
            </Button>
          </Box>
          <Stack direction="row" spacing={1}>
            <OpenResearchButton
              agentResult={currentResult}
              userOpportunities={companyResearch?.oppties}
              isFetching={isFetching}
            />
            <Button
              disableElevation
              sx={{ width: "fit-content", borderRadius: "8px" }}
              variant="contained"
              endIcon={<OpenInNewIcon />}
              onClick={() => {
                window.open(currentResult.link, "_blank");
              }}
            >
              View full article
            </Button>
          </Stack>
        </Stack>
      )}
      {feedbackMode && (
        <FeedbackForm
          defaultFeedbackInfo={localFeedbackInfo}
          setLocalFeedbackInfo={setLocalFeedbackInfo}
          toggleFeedbackMode={toggleFeedbackMode}
          selectedId={selectedId}
          setFeedbackById={setFeedbackById}
          resultsUrl={rule5properties.agentResults}
        />
      )}
    </Stack>
  );
}
