import axios from "axios";
import { useQuery } from "react-query";
import { rule5properties } from "../properties";

const fetchProspects = async (campaignId) => {
  const response = await axios.get(
    `${rule5properties.campaignProspects}?campaignId=${campaignId}`
  );
  return response.data;
};

export const useProspects = (campaignId) => {
  return useQuery(["prospects", campaignId], () => fetchProspects(campaignId), {
    staleTime: 60000 * 5, // Consider data fresh for 5 minutes
    // placeholderData: [],
  });
};
