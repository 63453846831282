import Joi from "joi";
import _ from "lodash";

const customFieldSchema = Joi.object({
  fieldName: Joi.string().allow(null, ""),
  key: Joi.string(),
});

const validateUniqueness = (value, helpers) => {
  const fieldNames = value.emailBodyParagraphs.map((item) => item.fieldName);
  const allFields = [...fieldNames, value.emailSubject];

  const duplicates = _(allFields)
    .countBy()
    .pickBy((count) => count > 1)
    .keys()
    .value();

  if (duplicates.length > 0) {
    return helpers.error("object.unique", {
      duplicates: duplicates.join(", "),
    });
  }

  return value;
};

const emailObjectSchema = Joi.object({
  emailBodyParagraphs: Joi.array().items(customFieldSchema),
  emailSubject: Joi.string().allow(null, ""),
})
  .custom(validateUniqueness, "Unique fields validation")
  .messages({
    "object.unique": `Custom field ({{#duplicates}}) is already in use. Please choose a different field or remove the duplicate.`,
  });

export const outreachFormSchema = Joi.object({
  account: emailObjectSchema,
  prospect: emailObjectSchema,
});
