import { Box, Collapse, Typography } from "@mui/material";
import React from "react";
import {
  CancelConfirmButtons,
  ErrLabel,
  StyledTextfield,
} from "../common/StyledComponents";
import axios from "axios";
import { rule5properties } from "../properties";
import { useDialog } from "../context/DialogContext";

export default function CreateApiKey(props) {
  const { loadApiKeys } = props;
  const dialogContext = useDialog();

  const [keyName, setKeyName] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  function createApiKey(name) {
    const params = {
      apiKeyName: name,
    };
    axios.post(rule5properties.apiKeys, params).then((res) => {
      console.log(res);
      if (res.status === 200) {
        loadApiKeys();
        dialogContext.closeModal();
      } else {
        console.log(res);
        if (res.data.message) {
          setErrorMessage(res.data.message);
        }
      }
    });
  }

  return (
    <Box>
      <Typography>Enter a name for your new API key.</Typography>
      <StyledTextfield
        fullWidth
        sx={{ mt: 2, mb: 1 }}
        onChange={(e) => {
          if (errorMessage.length > 0) {
            setErrorMessage("");
          }
          setKeyName(e.target.value);
        }}
      />
      <Collapse in={errorMessage.length > 0}>
        <ErrLabel>{errorMessage}</ErrLabel>
      </Collapse>
      <CancelConfirmButtons
        sx={{ width: "260px", ml: "auto", mt: 1 }}
        cancelText="Cancel"
        cancelFunction={() => {
          dialogContext.closeModal();
        }}
        confirmText="Confirm"
        confirmFunction={() => {
          createApiKey(keyName);
        }}
        // confirmDisabled={keyName.trim().length < 3}
      />
    </Box>
  );
}
