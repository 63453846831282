import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ALLOWED_ACTIONS, PLAN_TYPES, useUser } from "../context/UserContext";
import { isArrayLike, isNumber } from "lodash";
import axios from "axios";
import { rule5properties } from "../properties.js";
import { StyledTableRow, StyledTableCell } from "../common/StyledComponents";
import {
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useSnack from "../context/Snack.js";
import { isSuccessStatus } from "../common/RequestUtils.js";
import { formatDate } from "../common/Utils.js";
import PlanFeaturesTable from "./plans/PlanFeaturesTable.js";
import PricingTable from "./plans/PricingTable.js";

export default function ManagePlan(props) {
  const user = useUser();
  const [consumptionCounts, setConsumptionCounts] = React.useState({
    peopleCount: null,
    accountsCount: null,
    publicCount: null,
    privateCount: null,
    totalCompanyCount: null,
  });
  const [stripeDetails, setStripeDetails] = React.useState({
    portalSession: null,
    invoices: null,
  });
  const [loading, setLoading] = useState(true);

  const planType = user?.userSubscription.planType;
  const allowedActions = user?.userSubscription?.allowedActions;
  const plansWithNoBilling = [PLAN_TYPES.basicMonitor, PLAN_TYPES.premium];

  React.useEffect(() => {
    setLoading(true);
    const onLoadRequests = [];
    onLoadRequests.push(
      axios.get(rule5properties.agentAccounts + "/consumption")
    );
    onLoadRequests.push(
      axios.get(rule5properties.agentPeople + "/consumption")
    );
    onLoadRequests.push(axios.get(rule5properties.opptyConsumption));
    if (!plansWithNoBilling.includes(planType)) {
      onLoadRequests.push(axios.get(rule5properties.invoices));
    }

    Promise.all(onLoadRequests).then((resp) => {
      // TODO process resp based on endpoint instead of index in array
      setConsumptionCounts({
        accountsCount: resp?.[0].data?.count,
        peopleCount: resp?.[1].data?.count,
        publicCount: resp?.[2].data?.publicAccountsCount,
        privateCount: resp?.[2].data?.privateAccountsCount,
        totalCompanyCount: resp?.[2].data?.count,
      });
      if (!plansWithNoBilling.includes(planType)) {
        setStripeDetails({
          invoices: resp?.[3]?.data,
        });
      }
      setLoading(false);
    });
  }, []);

  // probably remove this and just do fade instead
  if (loading) {
    return (
      <div
        style={{
          height: "70vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CircularProgress size={40} />
      </div>
    );
  }

  function calculateDaysUntil(dateString) {
    const today = new Date();
    const targetDate = new Date(dateString);

    // Set the time of both dates to 00:00:00 to ignore time differences within the same day
    today.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);

    const differenceInTime = targetDate.getTime() - today.getTime();

    // Convert time difference from milliseconds to days
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return Math.round(differenceInDays);
  }

  let ResearchLimitComponents = [];
  if (user.userSubscription?.publicCompaniesLimit) {
    ResearchLimitComponents.push(
      <ConsumptionCount
        count={consumptionCounts.publicCount}
        label="Public companies researched"
        caption="Number of US public companies researched in Account IQ"
        limit={user.userSubscription?.publicCompaniesLimit}
        key="publicCount"
      />
    );
  }

  if (user.userSubscription?.privateCompaniesLimit) {
    ResearchLimitComponents.push(
      <ConsumptionCount
        count={consumptionCounts.privateCount}
        label="Private companies researched"
        caption="Number of US private + non-US companies researched in Account IQ"
        limit={user.userSubscription?.privateCompaniesLimit}
        key="privateCount"
      />
    );
  }

  if (
    user.userSubscription?.opportunitiesLimit ||
    ResearchLimitComponents.length === 0
  ) {
    ResearchLimitComponents.push(
      <ConsumptionCount
        count={consumptionCounts.totalCompanyCount}
        label="Companies researched"
        caption="Number of total companies researched in Account IQ"
        limit={user.userSubscription?.opportunitiesLimit}
        key="totalCount"
      />
    );
  }

  return (
    <Box sx={{ padding: "30px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Box sx={{ display: "inline" }}>
            <Typography sx={{ display: "inline" }} variant="settingsMainHeader">
              Your current plan is{" "}
              <strong>{user.userSubscription?.displayPlanType}</strong>
            </Typography>
            {user.userSubscription?.endDate && (
              <Typography variant="subtitle" sx={{ opacity: 0.7 }}>
                {" "}
                {isNumber(user.userSubscription?.trialPeriodDays)
                  ? "expires"
                  : "renews"}{" "}
                on {user.userSubscription?.endDate}
              </Typography>
            )}
          </Box>
          {user.userSubscription?.trialPeriodDays > 0 && (
            <Typography sx={{ display: "inline", mb: 2, textAlign: "right" }}>
              Trial days remaining:{" "}
              <strong>
                {calculateDaysUntil(user.userSubscription?.endDate)}
              </strong>
            </Typography>
          )}
        </Box>
        {user.userSubscription?.planType !== PLAN_TYPES.premiumContent && ( //todo
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              // mb: 5,
            }}
          >
            {allowedActions.includes(ALLOWED_ACTIONS.monitor) && (
              <>
                <ConsumptionCount
                  count={consumptionCounts.accountsCount}
                  label="Companies monitored"
                  caption="Number of companies monitored in Signal IQ"
                  limit={user.userSubscription?.monitorAccountsLimit}
                />
                <ConsumptionCount
                  count={consumptionCounts.peopleCount}
                  label="People monitored"
                  caption="Number of people monitored in Signal IQ"
                  limit={user.userSubscription?.monitorContactsLimit}
                />
              </>
            )}
            {allowedActions.includes(ALLOWED_ACTIONS.viewResearch) && (
              <>
                {ResearchLimitComponents}
              </>
            )}
          </Box>
        )}
        <Divider sx={{ my: 3 }} />
        <LimitsDescription />
        <Divider sx={{ my: 3 }} />
        <Typography
          variant="settingsMainHeader"
          sx={{ fontWeight: 500, mb: 2 }}
        >
          Plans
        </Typography>
      </Box>

      <PricingTable />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        <PlanFeaturesTable />
        {!plansWithNoBilling.includes(planType) && (
          <>
            <Divider sx={{ my: 3 }} />
            <PaymentMethods />
            <Divider sx={{ my: 3 }} />
            <InvoiceTable invoices={stripeDetails.invoices} />
          </>
        )}
      </Box>
    </Box>
  );
}

function LimitsDescription(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        maxWidth: "1200px",
      }}
    >
      <Typography variant="settingsMainHeader" sx={{ fontWeight: 500 }}>
        Limits
      </Typography>
      <Typography sx={{ mb: 1 }}>
        Limits for usage and access to features of rule5 are set based on the
        type of your subscription.
      </Typography>
      <Typography variant="settingsMainHeader">Usage quota</Typography>
      <Typography sx={{ mb: 1 }}>
        Different usage quotas are associated with each subscription type. Usage
        quota associated with your subscription controls how many accounts you
        can research, how many companies you can monitor, etc.
      </Typography>
      <Typography variant="settingsMainHeader">
        Access to products & features
      </Typography>
      <Typography sx={{ mb: 1 }}>
        Access to products and features of rule5 is allowed based on type of
        your subscription. For example, with 'Research' subscription, you can
        access Account IQ and Signal IQ but with 'Scale' subscription you can
        access Account IQ, Signal IQ and Action IQ.
      </Typography>
    </Box>
  );
}

function ConsumptionCount(props) {
  const { count, label, caption, limit } = props;
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "320px 1fr 130px",
        gap: "80px",
        alignItems: "center",
      }}
    >
      <Stack direction="column" sx={{ lineHeight: 1.3 }}>
        <Typography variant="settingsHeader">{label}</Typography>
        <Typography variant="caption" sx={{ opacity: 0.7 }}>
          {caption}
        </Typography>
      </Stack>
      <ProgressBar portionComplete={count / limit} />
      <Typography textAlign="right" variant="settingsHeader">
        {count} / <strong>{limit || "Unlimited"}</strong>
      </Typography>
    </Box>
  );
}

const ProgressBar = ({ portionComplete }) => {
  if (!isFinite(portionComplete)) {
    portionComplete = 0;
  }

  // Calculate the width of the colored portion based on the portionComplete
  const coloredWidth = `${portionComplete * 100}%`;
  // Calculate the width of the gray portion (remaining percentage)
  const grayWidth = `${(1 - portionComplete) * 100}%`;

  return (
    <div
      style={{
        display: "flex",
        height: "12px",
        width: "100%",
        borderLeft: "1px solid #1976d2",
      }}
    >
      {/* Colored portion */}
      <div
        style={{
          width: coloredWidth,
          backgroundColor: "#1976d2",
          height: "100%",
        }}
      ></div>
      {/* Gray portion */}
      <div
        style={{
          width: grayWidth,
          backgroundColor: "#ccd2d999",
          height: "100%",
        }}
      ></div>
    </div>
  );
};

function PaymentMethods(props) {
  const snackBar = useSnack();

  const onPortalOpenClick = () => {
    axios.post(rule5properties.portalSession).then((resp) => {
      if (isSuccessStatus(resp.status)) {
        window.open(resp.data.url, "_blank");
      } else {
        snackBar.createSnack(
          "Unable to open customer portal. " + resp.data?.message
        );
      }
    });
  };

  return (
    <Stack sx={{}}>
      <Typography variant="settingsMainHeader" sx={{ fontWeight: 500, mb: 1 }}>
        Manage billing details
      </Typography>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button variant="outlined" onClick={onPortalOpenClick}>
          Payment methods
        </Button>
        <Button variant="outlined" onClick={onPortalOpenClick}>
          Billing information
        </Button>
      </Box>
    </Stack>
  );
}

function InvoiceTable(props) {
  const { invoices } = props;

  if (!isArrayLike(invoices)) {
    return null;
  }

  return (
    <>
      <Typography sx={{ mb: 1, fontWeight: 500 }} variant="settingsMainHeader">
        Billing history
      </Typography>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table sx={{ minWidth: 650 }} aria-label="kpi table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left">Date</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell align="right">Amount</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="center">{/* actions */}</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {invoices?.map((row, index) => {
              let formattedDate = "";
              if (row?.effectiveDate) {
                const date = new Date(row?.effectiveDate);
                formattedDate = formatDate(date);
              }

              return (
                <StyledTableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <StyledTableCell>{formattedDate}</StyledTableCell>
                  <StyledTableCell>{row.description}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount}</StyledTableCell>
                  <StyledTableCell align="left">{row.status}</StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton size="small" href={row.invoicePdf}>
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      href={row.invoiceUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <OpenInNewIcon fontSize="small" />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
