import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import tasksIcon from "../res/icons/outline/16px_bullet-list.svg";
import contactsIcon from "../res/icons/outline/16px_multiple.svg";
import tasksIconFilled from "../res/icons/fill/16px_bullet-list.svg";
import contactsIconFilled from "../res/icons/fill/16px_multiple.svg";
import chemistryIcon from "../res/icons/outline/16px_chemistry.svg";
import chemistryIconFilled from "../res/icons/fill/16px_chemistry.svg";
import boardIcon from "../res/icons/outline/16px_board.svg";
import boardIconFilled from "../res/icons/fill/16px_board-2.svg";
import layoutIcon from "../res/icons/outline/16px_layout-11.svg";
import layoutIconFilled from "../res/icons/fill/16px_layout-11.svg";

const useStyles = makeStyles(() => ({
  menuText: {
    transition: "0.2s",
    color: "rgba(0,0,0,0.7)",
    fontSize: "14px",
    width: "90%",
  },
  selectedMenuText: {
    transition: "0.2s",
    color: "rgb(25, 118, 210)",
    fontSize: "14px",
    width: "90%",
    fontWeight: 500,
    paddingLeft: 6,
  },
  menuImage: {
    height: "16px",
    width: "16px",
  },
  menuImageSelected: {
    height: "16px",
    width: "16px",
    filter:
      "invert(38%) sepia(98%) saturate(744%) hue-rotate(179deg) brightness(85%) contrast(102%)",
  },
  menuIcon: {
    minWidth: "40px",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
  },
  listItem: {
    transition: "0.2s",
    backgroundColor: "#FFFFFF",
    height: "48px",
    margin: "4px 0px 4px 0px",
    borderRadius: "12px",
    paddingLeft: 20,
    overflowX: "hidden",
    "&:active": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
  selectedListItem: {
    transition: "0.2s",
    backgroundColor: "rgb(227, 242, 253)",
    height: "48px",
    margin: "4px 0px 4px 0px",
    borderRadius: "12px",
    paddingLeft: 20,
    overflowX: "hidden",
    "&:hover": {
      backgroundColor: "rgb(222, 240, 252)",
    },
    "&:active": {
      backgroundColor: "rgb(215, 234, 247)",
    },
  },
  mainList: {
    flexGrow: 1,
  },
  subList: {
    paddingBottom: "30%",
  },
}));

export function DetMenuList(props) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <List dense={false} className={classes.mainList}>
      <Link to="/det/managecards" className={classes.link}>
        <ListItem
          button
          disableRipple
          className={
            location.pathname.startsWith("/det/managecards")
              ? classes.selectedListItem
              : classes.listItem
          }
        >
          <ListItemIcon className={classes.menuIcon}>
            {location.pathname.startsWith("/det/managecards") ? (
              <img
                src={boardIconFilled}
                alt="board"
                className={classes.menuImageSelected}
              />
            ) : (
              <img src={boardIcon} alt="board" className={classes.menuImage} />
            )}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: location.pathname.startsWith("/det/managecards")
                ? classes.selectedMenuText
                : classes.menuText,
            }}
            primary="Cards"
          />
        </ListItem>
      </Link>

      <Link to="/det/manageresearch" className={classes.link}>
        <ListItem
          button
          disableRipple
          className={
            location.pathname.startsWith("/det/manageresearch")
              ? classes.selectedListItem
              : classes.listItem
          }
        >
          <ListItemIcon className={classes.menuIcon}>
            {location.pathname.startsWith("/det/manageresearch") ? (
              <img
                src={chemistryIconFilled}
                alt="chemistry"
                className={classes.menuImageSelected}
              />
            ) : (
              <img
                src={chemistryIcon}
                alt="chemistry"
                className={classes.menuImage}
              />
            )}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: location.pathname.startsWith("/det/manageresearch")
                ? classes.selectedMenuText
                : classes.menuText,
            }}
            primary="Research"
          />
        </ListItem>
      </Link>

      <Link to="/det/managedetusers" className={classes.link}>
        <ListItem
          button
          disableRipple
          className={
            location.pathname.startsWith("/det/managedetusers")
              ? classes.selectedListItem
              : classes.listItem
          }
        >
          <ListItemIcon className={classes.menuIcon}>
            {location.pathname.startsWith("/det/managedetusers") ? (
              <img
                src={contactsIconFilled}
                alt="users"
                className={classes.menuImageSelected}
              />
            ) : (
              <img
                src={contactsIcon}
                alt="users"
                className={classes.menuImage}
              />
            )}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: location.pathname.startsWith("/det/managedetusers")
                ? classes.selectedMenuText
                : classes.menuText,
            }}
            primary="Newton Users"
          />
        </ListItem>
      </Link>

      <Link to="/det/managecompanies" className={classes.link}>
        <ListItem
          button
          disableRipple
          className={
            location.pathname.startsWith("/det/managecompanies")
              ? classes.selectedListItem
              : classes.listItem
          }
        >
          <ListItemIcon className={classes.menuIcon}>
            {location.pathname.startsWith("/det/managecompanies") ? (
              <img
                src={tasksIconFilled}
                alt="search"
                className={classes.menuImageSelected}
              />
            ) : (
              <img src={tasksIcon} alt="search" className={classes.menuImage} />
            )}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: location.pathname.startsWith("/det/managecompanies")
                ? classes.selectedMenuText
                : classes.menuText,
            }}
            primary="Buyer Companies"
          />
        </ListItem>
      </Link>

      <Link to="/det/manageorgs" className={classes.link}>
        <ListItem
          button
          disableRipple
          className={
            location.pathname.startsWith("/det/manageorgs")
              ? classes.selectedListItem
              : classes.listItem
          }
        >
          <ListItemIcon className={classes.menuIcon}>
            {location.pathname.startsWith("/det/manageorgs") ? (
              <img
                src={layoutIconFilled}
                alt="layout"
                className={classes.menuImageSelected}
              />
            ) : (
              <img
                src={layoutIcon}
                alt="layout"
                className={classes.menuImage}
              />
            )}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: location.pathname.startsWith("/det/manageorgs")
                ? classes.selectedMenuText
                : classes.menuText,
            }}
            primary="Seller Companies"
          />
        </ListItem>
      </Link>
    </List>
  );
}
