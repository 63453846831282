import React from "react";
import {
  Collapse,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { StyledTextfield, ErrLabel } from "../common/StyledComponents";
import axios from "axios";
import { rule5properties } from "../properties";
import { useDialog } from "../context/DialogContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useUser } from "../context/UserContext";

// TODO this should probably be standardized with Feedback modal which is very similar.
export default function ContactSupport(props) {
  const [comment, setComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState("");

  const dialog = useDialog();
  const user = useUser();

  const submitForm = (event) => {
    setLoading(true);
    event.preventDefault();

    const params = {
      to: "support@rule5.io",
      body: comment,
      subject: `User contacting support: ${user.email}`,
    };
    axios
      .post(rule5properties.contactSupport, params)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          dialog.setModalTitle(
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon
                sx={{ display: "inline", mr: 1 }}
                color="primary"
              />
              <Typography
                sx={{ display: "inline", mt: "2px", fontWeight: 500 }}
              >
                Thank you for reaching out.
              </Typography>
            </Box>
          );
          setSuccess(true);
        } else {
          setError(res.data?.message);
        }
      })
      .catch((err) => {
        console.log("error contacting support: " + JSON.stringify(err));
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      {!success ? (
        <Box sx={{}}>
          <form
            style={{ display: "flex", flexDirection: "column" }}
            onSubmit={submitForm}
          >
            <StyledTextfield
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
                setError("");
              }}
              sx={{
                display: "block",
                fontSize: "16px",
                minHeight: "160px",
                maxHeight: "480px",
                overflowY: "auto",
              }}
              multiline
              placeholder="How can we help?"
            />
            <Collapse in={error !== ""}>
              <ErrLabel>{error}</ErrLabel>
            </Collapse>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                mt: 2,
                alignItems: "flex-end",
              }}
            >
              <Button
                type="submit"
                disabled={loading || comment.trim() === ""}
                sx={{ width: "140px", marginLeft: "auto" }}
                variant="outlined"
              >
                <Typography
                  variant="button"
                  sx={{ textTransform: "none", mt: "3px" }}
                >
                  Submit
                </Typography>
                {loading ? (
                  <CircularProgress color="inherit" size={16} sx={{ ml: 2 }} />
                ) : null}
              </Button>
            </Box>
          </form>
        </Box>
      ) : null}
    </React.Fragment>
  );
}
