import React from "react";
import { makeStyles } from "@mui/styles";
import Toolbar from "../common/Toolbar";
import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box } from "@mui/system";
import Card from "@mui/material/Card";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { cardTypes } from "../workspaces/opportunity/research/OPXResearch";
import { useDialog } from "../context/DialogContext";
import NewCard from "./modal/NewCard";
import axios from "axios";
import CardFilterForm from "./util/DetailsFilterForm";
import { rule5properties } from "../properties";
import { formatDataGridColumnDate, formatDate } from "./ResearchDetail";
import { dataGridStyles } from "./common/utils";
import CustomSnackbar from "../common/CustomSnackbar";
import { useImmer } from "use-immer";

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "2%",
    marginTop: "1%",
    marginBottom: "1%",
  },
  toolbarButton: {
    padding: "10px 18px 10px 0px",
    margin: "7px 0px",
    color: "rgba(0,0,0,0.5)",
    borderRadius: "8px",
    textTransform: "none",
  },
}));

export default function ManageCards(props) {
  const classes = dataGridStyles();
  const openDialog = useDialog();
  const [Preview, setPreview] = React.useState(null);
  const [previewData, setPreviewData] = React.useState(null);
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [selectionMode, setSelectionMode] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  // Using immer so can conveniently reset page state to 0 when searchFilters are updated.
  const [searchCriteria, setSearchCriteria] = useImmer({
    page: 0,
    pageSize: 20,
    searchFilters: null,
  });
  const [loading, setLoading] = React.useState(false);
  const [researchResponse, setResearchResponse] = React.useState({
    total: 0,
    results: [],
  });
  const theme = useTheme();

  const onFindClick = (data) => {
    setSearchCriteria((draft) => {
      draft.page = 0;
      draft.searchFilters = data;
    });
  };
  const getCards = ({ searchFilters, page, pageSize }) => {
    if (!searchFilters) {
      return;
    }
    setLoading(true);
    const queryString = new URLSearchParams({
      ...(searchFilters.type ? { type: searchFilters.type } : {}),
      ...(searchFilters.scope ? { scope: searchFilters.scope } : {}),
      ...(searchFilters.buyerCompany
        ? { companyId: parseInt(searchFilters.buyerCompany.id) }
        : {}),
      ...(searchFilters.sellerCompany
        ? { orgId: parseInt(searchFilters.sellerCompany.id) }
        : {}),
      ...(searchFilters.functionalArea
        ? { functionalArea: searchFilters.functionalArea }
        : {}),
      ...(searchFilters.status ? { status: searchFilters.status } : {}),
      ...(searchFilters.assignee ? { assignee: searchFilters.assignee } : {}),
      ...(searchFilters.maxVersion
        ? { maxVersion: searchFilters.maxVersion }
        : {}),
      pageSize: pageSize,
      pageIndex: page,
    });
    axios
      .get(rule5properties.detCards + `?` + queryString)
      .catch(function (error) {
        setLoading(false);
        alert("ERROR: " + JSON.stringify(error));
      })
      .then((response) => {
        setLoading(false);
        setResearchResponse(response.data);
        setPreview(null);
      });
  };
  React.useEffect(() => getCards(searchCriteria), [searchCriteria]);

  const onPreviewClick = (id) => (event) => {
    event.stopPropagation();
    const row = researchResponse.results.find((row) => {
      return row.id === id;
    });
    if (row) {
      setPreviewData(row);
      setPreview(
        cardTypes.find((cardType) => {
          return row.type === cardType.cardType;
        }).dom
      );
    }
  };

  const onRowClick = (id) => {
    const card = researchResponse.results.find((row) => {
      return row.id === id;
    });
    props.history.push({
      pathname: "/det/editcards",
      state: { ...card, isDraftSaved: true },
    });
  };

  //get cardtype and json to copy from source
  //get if desti cards exist and get id's
  //post if new
  //patch if exist

  const getDestinationCards = (form) => {
    let destinationCards = [];
    selectedRows.forEach((id) => {
      const sourceCard = researchResponse.results.find((row) => {
        return row.id === id;
      });

      destinationCards.push({
        card_info: sourceCard.cardInfo,
        type: sourceCard.type,
        scope: form.scope,
        ...(form.buyerCompany
          ? { company_id: parseInt(form.buyerCompany.id) }
          : {}),
        ...(form.sellerCompany
          ? { org_id: parseInt(form.sellerCompany.id) }
          : {}),
        ...(form.functionalArea
          ? { functional_area: form.functionalArea }
          : {}),
        ...(form.assignee
          ? {
              action_info: { assignee: form.assignee },
            }
          : {}),
      });
    });

    return destinationCards;
  };

  const onCopySubmit = (form) => {
    //todo use promise.allsucceed or w/e.
    getDestinationCards(form).forEach((card) => {
      // Make a get request to see if the card already exists.
      const queryString = new URLSearchParams({
        type: card.type,
        scope: card.scope,
        ...(card.company_id ? { companyId: card.company_id } : {}),
        ...(card.org_id ? { orgId: card.org_id } : {}),
        ...(card.functional_area
          ? { functionalArea: card.functional_area }
          : { functionalArea: "NA" }),
        pageSize: 10,
      });
      axios
        .get(rule5properties.detCards + `?` + queryString)
        .catch(function (error) {
          alert("ERROR: " + JSON.stringify(error));
        })
        .then((response) => {
          const results = response.data?.results;
          if (results?.length === 0) {
            // Card does not exist yet. No preview or anything to display.
            // New creates.
            const params = {
              type: card.type,
              scope: card.scope,
              ...(card.company_id ? { company_id: card.company_id } : {}),
              ...(card.org_id ? { org_id: card.org_id } : {}),
              ...(card.functional_area && card.functional_area !== "NA"
                ? { functional_area: card.functional_area }
                : {}),
              card_info: card.card_info,
              ...(card.action_info
                ? {
                    action_info: card.action_info,
                  }
                : {}),
            };
            axios
              .post(rule5properties.detCards, params)
              .catch(function (error) {
                alert("ERROR: " + JSON.stringify(error));
              })
              .then((response) => {});
          } else {
            // Get the most recent version.
            const maxVersion = results?.reduce(function (prev, current) {
              return prev.version > current.version ? prev : current;
            });

            card = {
              ...card,
              id: parseInt(maxVersion.id),
              original_card_id: parseInt(maxVersion.originalCardId),
              status: maxVersion.status,
            };

            // Existing card
            if (card.status === "Draft") {
              let message =
                "CardType: " +
                card.type +
                "\nYou are not editing the most recent version of this card. The latest version is in " +
                card.status +
                " status. Would you like to overwrite the latest draft with these edits?";

              // eslint-disable-next-line no-restricted-globals
              if (!confirm(message)) {
                // Don't make any update if user rejects.
                return;
              }
            }
            // Updates
            const params = {
              id: card.id,
              original_card_id: parseInt(card.original_card_id),
              card_info: card.card_info,
              ...(card.action_info
                ? {
                    action_info: card.action_info,
                  }
                : {}),
            };

            axios
              .patch(rule5properties.detCards, params)
              .catch(function (error) {
                alert("ERROR: " + JSON.stringify(error));
              })
              .then((patchResponse) => {
                let updatedCard = patchResponse.data;
                // If a save was performed then response is the latest version.
              });
          }
        });
    });

    setSnackbarMessage("Copy complete!.");
    setOpenSnackbar(true);
  };

  function CustomToolbar() {
    const dgclasses = useStyles();
    return (
      <div style={{ padding: "0px 7px 10px 7px" }}>
        <GridToolbarContainer>
          <GridToolbarFilterButton
            variant="filled"
            color="inherit"
            className={dgclasses.toolbarButton}
          />
          <div style={{ flex: 1 }}> </div>
          {selectionMode ? (
            <Typography
              variant="subtitle2"
              sx={{ color: "#1976d2", paddingRight: "25px" }}
            >
              {selectedRows.length} cards to copy
            </Typography>
          ) : (
            <>
              <GridToolbarColumnsButton
                variant="filled"
                color="inherit"
                className={dgclasses.toolbarButton}
              />
              <GridToolbarExport
                variant="filled"
                color="inherit"
                className={dgclasses.toolbarButton}
              />
            </>
          )}
          <Button
            variant="filled"
            color="inherit"
            className={dgclasses.toolbarButton}
            onClick={() => {
              if (selectionMode) {
                setSelectedRows([]);
              }
              setSelectionMode((prevMode) => !prevMode);
            }}
          >
            {selectionMode ? (
              <DoNotDisturbAltIcon sx={{ marginRight: "8px" }} />
            ) : (
              <ContentCopyIcon sx={{ marginRight: "8px" }} />
            )}
            {selectionMode ? "Cancel" : "Copy Cards"}
          </Button>
        </GridToolbarContainer>
      </div>
    );
  }

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<RemoveRedEyeOutlinedIcon />}
            label="Preview"
            onClick={onPreviewClick(id)}
            color="primary"
          />,
        ];
      },
    },
    { field: "buyerName", headerName: "Buyer Company", width: 150 },
    { field: "sellerName", headerName: "Seller Company", width: 100 },
    { field: "type", headerName: "Type", width: 150 },
    { field: "scope", headerName: "Scope", width: 150 },
    { field: "functionalArea", headerName: "Functional Area", width: 150 },
    { field: "version", headerName: "Version", width: 125 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "assignee",
      headerName: "Assignee",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo && (
            <Tooltip title={cellValues.row.actionInfo.assignee || ""}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.actionInfo.assignee}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo && (
            <Tooltip title={cellValues.row.actionInfo.created_by || ""}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.actionInfo.created_by}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "approved_by",
      headerName: "Approved By",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo && (
            <Tooltip title={cellValues.row.actionInfo.approved_by || ""}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.actionInfo.approved_by}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "approval_date",
      headerName: "Approved On",
      width: 200,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo &&
          cellValues.row.actionInfo.approval_date && (
            <span className={classes.tableCellTrucate}>
              {formatDate(cellValues.row.actionInfo.approval_date)}
            </span>
          )
        );
      },
    },
    {
      field: "published_by",
      headerName: "Published By",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo && (
            <Tooltip title={cellValues.row.actionInfo.published_by || ""}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.actionInfo.published_by}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "published_date",
      headerName: "Published On",
      width: 200,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo &&
          cellValues.row.actionInfo.published_date && (
            <span className={classes.tableCellTrucate}>
              {formatDate(cellValues.row.actionInfo.published_date)}
            </span>
          )
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated On",
      width: 200,
      valueGetter: formatDataGridColumnDate,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      width: 200,
      valueGetter: formatDataGridColumnDate,
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        maxWidth: "100%",
        paddingBottom: `${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar>
        <div className={classes.title}>Manage Cards</div>
      </Toolbar>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          pt: 1,
          borderRadius: "8px",
          height: "100%",
          flexDirection: "column",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "2%",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        >
          {/* <Typography variant="h6">Find existing cards</Typography> */}
          <Button
            style={{ textTransform: "none" }} // Remove automatic all-caps
            onClick={(event) =>
              openDialog.openModal("Create New Card", NewCard)
            }
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add card
          </Button>
        </div>

        <div
          style={{ marginLeft: "5px" }}
          // style={{ marginLeft: "2%", marginRight: "2%", marginBottom: "2%" }}
        >
          <CardFilterForm
            requiresAllFields={false}
            onSubmit={onFindClick}
            buttonLabel="Find"
          />
        </div>
        <div style={{ height: "100%", overflow: "scroll" }}>
          {Preview && (
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Typography variant="h5" sx={{}}>
                Preview
              </Typography>
              <IconButton
                color="primary"
                aria-label="refresh"
                component="span"
                onClick={() => {
                  setPreview(null);
                }}
              >
                <ClearIcon />
              </IconButton>

              <div style={{ width: "100%" }}>
                <Preview
                  expanded={isExpanded}
                  previewData={previewData.cardInfo}
                  opportunity={{ Account: previewData.buyerName }}
                  toggleExpanded={() => {
                    setIsExpanded(!isExpanded);
                  }}
                ></Preview>
              </div>
            </Box>
          )}
          {searchCriteria.searchFilters && (
            <>
              {selectionMode && (
                // In selection mode, show the destnation selection menu.
                <div style={{ padding: "15px" }}>
                  <Typography variant="h5" sx={{ paddingLeft: "8px" }}>
                    Copy Destination Details
                  </Typography>
                  <CardFilterForm
                    requiresAllFields={true}
                    onSubmit={onCopySubmit}
                    buttonLabel="Copy"
                    excludeType={true}
                    ignorePrepopulatedFilters={true}
                  />
                </div>
              )}
              <div
                style={{
                  marginLeft: "2%",
                  marginRight: "2%",
                  height: "100%",
                }}
              >
                <DataGridPro
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  className={classes.root}
                  loading={loading}
                  pagination
                  rows={researchResponse.results}
                  rowCount={researchResponse.total}
                  columns={columns}
                  disableColumnSelector={true}
                  pageSizeOptions={[10, 20, 50]}
                  paginationMode="server"
                  paginationModel={searchCriteria}
                  onPaginationModelChange={(newModel) =>
                    setSearchCriteria((draft) => {
                      draft.pageSize = newModel.pageSize;
                      draft.page = newModel.page;
                    })
                  }
                  onRowSelectionModelChange={(newSelection) => {
                    setSelectedRows(newSelection);
                  }}
                  rowSelectionModel={selectedRows}
                  // Current default click behavior goes to research.
                  onRowClick={(params) => {
                    if (selectionMode) {
                      if (
                        selectedRows.find((selected) => {
                          const sourceCard = researchResponse.results.find(
                            (row) => {
                              return row.id === selected;
                            }
                          );
                          return sourceCard.type === params.row.type;
                        })
                      ) {
                        setSnackbarMessage(
                          "Cannot copy multiple of the same card type."
                        );
                        setOpenSnackbar(true);
                        setSelectedRows(selectedRows);
                        return;
                      }
                      let index = selectedRows.indexOf(params.row.id);
                      if (index > -1) {
                        // This item is already selected, remove it from the list.
                        let spliced = [...selectedRows];
                        spliced.splice(index, 1);
                        setSelectedRows(spliced);
                      } else {
                        setSelectedRows([...selectedRows, params.row.id]);
                      }
                    } else {
                      onRowClick(params.row.id);
                    }
                  }}
                />
              </div>
              <CustomSnackbar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                snackbarMessage={snackbarMessage}
              />
            </>
          )}
        </div>
      </Card>
    </div>
  );
}
