import React, { createContext, useContext, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar } from "@mui/material";

const SnackContext = createContext();

function RenderSnack({ id, message, open, handleClose }) {
  const messageId = `message-${id}`;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": messageId,
      }}
      message={<span id={messageId}>{message}</span>}
      action={
        <IconButton
          aria-label="Close"
          size="small"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}

let uniqueId = 2;

export const SnackProvider = ({ children }) => {
  const [{ current, queue }, setState] = useState({ current: null, queue: [] });

  function createSnack(message, options) {
    const id = uniqueId++;
    const snack = { id, message, open: true, options };

    if (current) {
      setState({ current, queue: queue.concat(snack) });
    } else {
      setState({ queue, current: snack });
    }

    return id;
  }

  function handleClose() {
    setState((currentState) => ({
      ...currentState,
      current: { ...currentState.current, open: false },
    }));
    // time to snack close animation
    setTimeout(openNext, 1000);
  }

  function openNext() {
    if (queue.length) {
      setState({ current: queue[0], queue: queue.slice(1) });
    } else {
      setState({ current: null, queue: [] });
    }
  }

  return (
    <SnackContext.Provider value={{ createSnack }}>
      {current && (
        <RenderSnack key={current.id} {...current} handleClose={handleClose} />
      )}
      {children}
    </SnackContext.Provider>
  );
};

export default function useSnack() {
  return useContext(SnackContext);
}
