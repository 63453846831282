import { Box, Collapse, Stack, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import ConfigureActions from "../workspaces/monitor/common/ConfigureActions";
import ConfigureSignals from "../workspaces/monitor/common/ConfigureSignals";
import ConfigureAgent from "../workspaces/monitor/common/ConfigureAgent";
import {
  CancelConfirmButtons,
  ErrorBox,
  GrayCircularProgress,
} from "../common/StyledComponents";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { rule5properties } from "../properties";
import { useDialog } from "../context/DialogContext";
import EditAgentList from "../workspaces/monitor/common/EditAgentList";
import { validateEmailString } from "../common/Utils";
import {
  regionObjectsToStrings,
  signalObjectsToStrings,
} from "../workspaces/monitor/common/monitor_util";
import MonitorService from "../workspaces/monitor/common/monitor_service";
import {
  accountsAgentType,
  peopleAgentType,
} from "../workspaces/monitor/common/AgentTypes";

export default function EditAgent(props) {
  const { defaultPage, modAgent, loadAgentRows } = props;

  const agentType = [peopleAgentType, accountsAgentType].find(
    (type) => type.type === modAgent.type
  );

  const modAgentEmails =
    modAgent.action?.agentActionInfo?.notifications?.emails;
  const defaultValues = {
    name: modAgent.name,
    description: modAgent.description,
    signals: [],
    regions: [],
    action: {
      agentActionInfo: {
        notifications: {
          emails: modAgentEmails?.length > 0 ? modAgentEmails : [],
          slackChannels: [],
          isNotificationEnabled:
            modAgent.action?.agentActionInfo?.notifications
              ?.isNotificationEnabled == null
              ? true
              : modAgent.action.agentActionInfo.notifications
                  .isNotificationEnabled,
        },
        salesPlatforms: {
          platform: modAgent?.action?.agentActionInfo?.salesPlatforms?.platform
            ? modAgent?.action?.agentActionInfo?.salesPlatforms?.platform
            : "",
          deliveryOption: modAgent?.action?.agentActionInfo?.salesPlatforms
            ?.deliveryOption
            ? modAgent?.action?.agentActionInfo?.salesPlatforms?.deliveryOption
            : "",
          isSalesPlatformEnabled:
            modAgent.action?.agentActionInfo?.salesPlatforms
              ?.isSalesPlatformEnabled == null
              ? false
              : modAgent.action.agentActionInfo.salesPlatforms
                  .isSalesPlatformEnabled,
        },
      },
    },
  };

  const methods = useForm({
    defaultValues: getProcessedDefaultValues,
  });
  const watchAgent = methods.watch();
  const dialog = useDialog();

  const [selectedTab, setSelectedTab] = React.useState(
    defaultPage || "general"
  );
  const [agentValid, setAgentValid] = React.useState(true);
  // For initial load delay to prevent data fetching render
  const [initialLoading, setInitialLoading] = React.useState(true);
  // Save changes, etc.
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  // Temporary saved email to collect if API called without creating chip.
  const [tempEmail, setTempEmail] = React.useState("");
  // Available userMessageChannels for Slack notifications
  const [userMessageChannels, setUserMessageChannels] = React.useState([]);

  // Fetch signals and return a default form value.
  async function getProcessedDefaultValues() {
    try {
      return MonitorService.getProcessedDefaultValues(
        defaultValues,
        modAgent,
        setUserMessageChannels
      );
    } catch (err) {
      console.log(err);
    }
  }

  // Validate the agent form values on change.
  useEffect(() => {
    // console.log(watchAgent);
    if (Object.keys(watchAgent).length > 0) {
      setInitialLoading(false);
    }
    if (validateAgentValue(watchAgent)) {
      setAgentValid(true);
    } else {
      setAgentValid(false);
    }
  }, [watchAgent]);

  // Check which fields are dirty and call endpoints based on fields.
  function saveChanges(values) {
    setLoading(true);
    const promiseArray = [];

    // Check name & description for agents endpoint call.
    if (
      methods.formState.dirtyFields.name ||
      methods.formState.dirtyFields.description
    ) {
      const agentParams = {};
      if (methods.formState.dirtyFields.name) {
        agentParams.name = values.name;
      }
      if (methods.formState.dirtyFields.description) {
        agentParams.description = values.description;
      }
      promiseArray.push(
        axios.patch(
          rule5properties.agents + `/${modAgent.agentId}`,
          agentParams
        )
      );
    }

    // Process and save actions.
    if (
      methods.formState.dirtyFields.signals ||
      methods.formState.dirtyFields.regions ||
      methods.formState.dirtyFields.action ||
      tempEmail.trim().length > 0
    ) {
      // Process signals to strings to be saved.
      const processedSignals = signalObjectsToStrings(values.signals);
      // Process regions to strings to be saved.
      const processedRegions = regionObjectsToStrings(values.regions);
      // Process actions to strings so that it can be saved.
      const processedAction = values.action;
      if (processedAction.agentActionInfo?.notifications?.slackChannels) {
        processedAction.agentActionInfo.notifications.slackChannels =
          processedAction.agentActionInfo.notifications.slackChannels.map(
            (slackChannel) => slackChannel.id
          );
      }
      // Check signals & actions for signals-actions endpoint.
      const signalsRegionsActionsParams = {};
      // Set signals.
      if (methods.formState.dirtyFields.signals) {
        signalsRegionsActionsParams.signals = processedSignals;
      }
      if (methods.formState.dirtyFields.regions) {
        signalsRegionsActionsParams.regions = processedRegions;
      }
      // Process emails array to remove invalid chips and collect pending input
      if (values.action.agentActionInfo?.notifications?.emails) {
        const processedEmails = [
          ...values.action.agentActionInfo.notifications.emails,
        ];
        // Include pending emails that have not been submitted into a chip.
        if (
          tempEmail &&
          tempEmail.trim().length > 0 &&
          validateEmailString(tempEmail)
        ) {
          processedEmails.push(tempEmail);
          setTempEmail("");
        }
        // Show chip creation.
        methods.setValue(
          "action.agentActionInfo.notifications.emails",
          processedEmails.reduce((result, currEmail) => {
            if (validateEmailString(currEmail)) {
              result.push(currEmail);
            }
            return result;
          }, []),
          { shouldDirty: true }
        );
        if (processedAction.agentActionInfo?.notifications) {
          processedAction.agentActionInfo.notifications.emails =
            processedEmails;
        }
      }
      // Add actions to param object.
      if (methods.formState.dirtyFields.action) {
        signalsRegionsActionsParams.action = processedAction;
      }
      if (Object.keys(signalsRegionsActionsParams).length > 0) {
        promiseArray.push(
          axios.put(
            rule5properties.agents + `/${modAgent.agentId}/signals-actions`,
            signalsRegionsActionsParams
          )
        );
      }
    }
    Promise.all(promiseArray)
      .then((resolvedArray) => {
        // TODO: partial error handling, i.e. name saved but actions not.
        const errors = resolvedArray.filter(
          (response) => response.status !== 200
        );
        if (errors.length === 0) {
          // Success.
          loadAgentRows();
          dialog.closeModal();
        } else {
          let errorString = "";
          errors.forEach((errRes) => {
            errorString += errRes.data?.message;
          });
          setErrorMessage(errorString);
          console.log(errorString);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        console.log("There was an error saving agent configuration.");
      });
  }

  // Validate agent values.
  function validateAgentValue(val) {
    if (val.name?.length > 0) {
      return true;
    } else return false;
  }

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={(e, newVal) => {
          setSelectedTab(newVal);
        }}
        sx={{ mb: 2 }}
      >
        <Tab
          label="General"
          value="general"
          disableRipple
          sx={{ textTransform: "none" }}
        />
        {modAgent.type === "Accounts" && (
          <Tab
            label="Edit signals"
            value="signals"
            disableRipple
            sx={{ textTransform: "none" }}
          />
        )}
        <Tab
          label="Edit Actions"
          value="actions"
          disableRipple
          sx={{ textTransform: "none" }}
        />
      </Tabs>
      <Collapse in={!initialLoading} unmountOnExit>
        <form onSubmit={methods.handleSubmit(saveChanges)}>
          <FormProvider {...methods}>
            <Box>
              {selectedTab === "general" && (
                <ConfigureAgent modAgent={modAgent} />
              )}
              {selectedTab === "accounts" && (
                <EditAgentList rows={modAgent.accounts} modAgent={modAgent} />
              )}
              {selectedTab === "actions" && (
                <ConfigureActions
                  agentType={agentType}
                  tempEmail={tempEmail}
                  setTempEmail={setTempEmail}
                  userMessageChannels={userMessageChannels}
                  setUserMessageChannels={setUserMessageChannels}
                />
              )}
              {selectedTab === "signals" && (
                <ConfigureSignals modAgent={modAgent} />
              )}
            </Box>
            <Stack sx={{ width: "100%" }} alignItems="center">
              <Collapse
                in={
                  (methods.formState.isDirty || tempEmail.trim().length > 0) &&
                  !loading
                }
              >
                <CancelConfirmButtons
                  sx={{ mt: 4, width: "360px" }}
                  cancelText="Cancel"
                  cancelFunction={() => {
                    methods.reset();
                  }}
                  confirmText="Save changes"
                  confirmDisabled={!agentValid}
                />
              </Collapse>
              {errorMessage?.length > 0 && (
                <ErrorBox sx={{ my: 3, width: "100%" }}>
                  {errorMessage}
                </ErrorBox>
              )}
              <Collapse in={loading}>
                <GrayCircularProgress size={24} sx={{ mt: 4 }} />
              </Collapse>
            </Stack>
          </FormProvider>
        </form>
      </Collapse>
      <Collapse in={initialLoading} unmountOnExit>
        <Stack sx={{ width: "100%", py: 2 }} alignItems="center">
          <GrayCircularProgress />
        </Stack>
      </Collapse>
    </Box>
  );
}
