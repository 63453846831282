import React from "react";
import { useHistory } from "react-router-dom";
import { useDialog } from "../../context/DialogContext";
import CardFilterForm from "../util/DetailsFilterForm";

export default function NewCard(props) {
  const openDialog = useDialog();
  const onSubmit = (data) => {
    // alert(JSON.stringify(data));
    if (data.buyerCompany) {
      [data.buyerName, data.companyId] =
        data.buyerCompany && Object.values(data.buyerCompany);
    }

    if (data.sellerCompany) {
      [data.sellerName, data.orgId] = Object.values(data.sellerCompany);
    }

    // Shouldn't need this. In the Controller for the AssigneeSelector,
    // setting name={actionInfo.assignee} should do this, but it was buggy
    data.actionInfo = { assignee: data.assignee };

    openDialog.closeModal();
    history.push({
      pathname: "/det/editcards",
      state: data,
    });
  };
  let history = useHistory();

  return (
    <CardFilterForm
      requiresAllFields={true}
      onSubmit={onSubmit}
      buttonLabel="Create"
    />
  );
}
