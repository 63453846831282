import { Box } from "@mui/material";
import { useCampaignErrors } from "../../api/campaigns";
import { useParams } from "react-router-dom";
import CampaignResultsDataGrid from "./CampaignResultsDataGrid";

export default function CampaignErrors() {
  const { campaignId } = useParams();
  const { data: campaignErrors, isLoading } = useCampaignErrors(campaignId);

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
      <CampaignResultsDataGrid 
        rows={campaignErrors} 
        isLoading={isLoading}
        idField="campaignErrorId"
        isErrorView={true}
      />
    </Box>
  );
}
