import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { rule5properties } from "../../../../properties";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import Image from "../../../../common/Image";
import { hasRteContent, numberAbbrev } from "../../../../common/Utils";
import RichTextReader from "../../../../common/RichTextReader";
import { convertFromRaw, EditorState } from "draft-js";
import { Stack } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import DoughnutLabel from "chartjs-plugin-doughnutlabel-rebourne";
import { Masonry } from "@mui/lab";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Map from "../util/Map";

Chart.register(ArcElement, DoughnutLabel, ChartDataLabels);

const useStyles = makeStyles(() => ({
  leftContent: {
    borderRight: "solid 1px rgba(0, 0, 0, 0.4)",
    width: "50%",
    minWidth: "200px",
    paddingRight: "24px",
  },
  rightContent: {
    width: "50%",
    paddingLeft: "24px",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  otherRightContent: {
    width: "50%",
    paddingLeft: "24px",
    display: "flex",
    flexFlow: "column",
  },
  flexContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  rteReader: {
    "& .rdw-editor-main": {
      overflow: "hidden",
    },
    paddingBottom: "20px",
  },
}));

function KeyValueText(props) {
  const { keyText, valueText } = props;
  if (!valueText) {
    return null;
  }

  return (
    <Typography variant="body2" align="left" style={{ lineHeight: 1.5 }}>
      <strong>{keyText}:</strong> {valueText.toLocaleString("en-US")}
    </Typography>
  );
}

export default function Operations(props) {
  const defaultDetail = {};
  const [operationsDetail, setOperationsDetail] = useState(defaultDetail);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [hasError, setError] = React.useState(false);
  const previewData = props.previewData;

  useEffect(() => {
    setIsLoading(true);
    if (previewData) {
      setOperationsDetail({ ...defaultDetail, ...previewData });
      setIsLoading(false);
      return;
    }
    axios
      .get(`${rule5properties.getCard}/${props.cardId}`)
      .then((response) => {
        let rawdata = response.data;
        if (rawdata.Title) {
          // Validate content
          setOperationsDetail({ ...defaultDetail, ...rawdata });
        } else {
          // API call failed
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setError(true);
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
      });
  }, [previewData]);

  if (isLoading) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity?.id}
        cardId={props.cardId}
        title={props.cardType || ""}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <CircularProgress />
      </Researchcard>
    );
  }

  if (hasError) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity?.id}
        cardId={props.cardId}
        title={props.cardType || ""}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }

  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity?.id}
      cardId={props.cardId}
      title={operationsDetail.Title || ""}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <OperationsContent
        operationsDetail={operationsDetail}
      ></OperationsContent>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}

export function OperationsContent(props) {
  const { operationsDetail, isExecSummary } = props;
  const classes = useStyles();
  const hasCharts =
    (operationsDetail.PieCharts && operationsDetail.PieCharts.length > 0) ||
    operationsDetail.Map?.Revenue;

  return (
    <div className={classes.flexContainer}>
      <div className={classes.leftContent}>
        {!isExecSummary && hasRteContent(operationsDetail.AtAGlanceBody) && (
          <>
            <h4 style={{ textAlign: "left", margin: "0px 0px 10px 0px" }}>
              {operationsDetail.AtAGlanceHeader}
            </h4>
            <div className={classes.rteReader}>
              <RichTextReader
                editorState={EditorState.createWithContent(
                  convertFromRaw(operationsDetail.AtAGlanceBody)
                )}
              />
            </div>
          </>
        )}
        <KeyValueText
          keyText="Brands"
          valueText={operationsDetail.Brands?.join(", ")}
          isValueArray={true}
        />
        <KeyValueText
          keyText="Products"
          valueText={operationsDetail.Products?.join(", ")}
          isValueArray={true}
        />
        {operationsDetail.Logos && (
          <Masonry
            defaultColumns={3}
            defaultSpacing={2}
            columns={Math.min(operationsDetail.Logos.length, 3)}
            spacing={4}
            style={{ padding: "15px" }}
          >
            {operationsDetail.Logos?.map((image) => (
              <Image key={image} src={image} alt={"Product image"} style={{}} />
            ))}
          </Masonry>
        )}
        <KeyValueText
          keyText="Services"
          valueText={operationsDetail.Services?.join(", ")}
          isValueArray={true}
        />
        <KeyValueText
          keyText="Sales Channels"
          valueText={operationsDetail.Channels?.join(", ")}
          isValueArray={true}
        />
        <KeyValueText
          keyText="Markets"
          valueText={operationsDetail.Markets?.join(", ")}
          isValueArray={true}
        />
        <KeyValueText
          keyText="Components/Parts"
          valueText={operationsDetail.Components?.join(", ")}
          isValueArray={true}
        />
        <KeyValueText
          keyText="Countries"
          valueText={operationsDetail.Countries?.join(", ")}
          isValueArray={true}
        />
        {hasCharts && (
          <FlexContent operationsDetail={operationsDetail}></FlexContent>
        )}
      </div>
      {hasCharts ? (
        <div className={classes.rightContent}>
          {operationsDetail.Map?.Revenue?.[0] && (
            <div
              style={{
                flexGrow: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Map
                mapDetail={operationsDetail.Map}
                isExecSummary={isExecSummary}
              ></Map>
            </div>
          )}
          {operationsDetail.PieCharts &&
            operationsDetail.PieCharts.length > 0 && (
              <>
                {operationsDetail.PieCharts.map((pieChart) => (
                  <PieChart pieChart={pieChart} isExecSummary={isExecSummary} />
                ))}
              </>
            )}
        </div>
      ) : (
        <div className={classes.otherRightContent}>
          <FlexContent operationsDetail={operationsDetail}></FlexContent>
        </div>
      )}
    </div>
  );
}

function FlexContent(props) {
  const { operationsDetail } = props;

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          justifyContent: "space-between",
          paddingTop: "15px",
        }}
      >
        <KeyValueText
          keyText="Products"
          valueText={operationsDetail.ProductsCount}
        />
        <KeyValueText keyText="SKUs" valueText={operationsDetail.SKUs} />
        <KeyValueText
          keyText="Suppliers"
          valueText={operationsDetail.Suppliers}
        />
        <KeyValueText
          keyText="Production Facilities"
          valueText={operationsDetail.ProductionFacilities}
        />
        <KeyValueText
          keyText="Warehouses"
          valueText={operationsDetail.Warehouses}
        />
        <KeyValueText
          keyText="Distribution & Logistics Centers"
          valueText={operationsDetail.DistributionAndLogisticsCenters}
        />
        <KeyValueText
          keyText="Customers"
          valueText={operationsDetail.Customers}
        />
        <KeyValueText
          keyText="Office Locations"
          valueText={operationsDetail.OfficeLocations}
        />
        <KeyValueText keyText="Stores" valueText={operationsDetail.Stores} />
        <KeyValueText
          keyText="Partners"
          valueText={operationsDetail.Partners}
        />
        <KeyValueText
          keyText="Resellers"
          valueText={operationsDetail.Resellers}
        />
      </div>

      {operationsDetail.RteFields && operationsDetail.RteFields.length > 0 && (
        <div>
          {operationsDetail.RteFields.map((rteField) => (
            <div style={{ paddingTop: "15px" }} key={rteField.Title}>
              {hasRteContent(rteField.RichText) && (
                <Stack>
                  <h4 style={{ textAlign: "left", margin: "0px" }}>
                    {rteField.Title}
                  </h4>
                  <RichTextReader
                    fontSize={14}
                    editorState={EditorState.createWithContent(
                      convertFromRaw(rteField.RichText)
                    )}
                  />
                </Stack>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function PieChart(props) {
  const { pieChart } = props;

  let total = 0;
  pieChart.Revenue.forEach((item) => {
    total += item.Amount;
  });
  const data = {
    labels: pieChart.Revenue.map(
      (revenue) =>
        `${((revenue.Amount / total) * 100).toFixed(0)}% ${
          revenue.Type
        }                                      ` // Extra trailing whitespace just a hack to
      // push legend to the left in cases where legend labels are short. This ensures the
      // legend consistently renders in the same spot which looks much better when
      // multiple piecharts are stacked. There's no support for standardizing this in the api.
    ),
    innerText: total,
    datasets: [
      {
        label: pieChart.Revenue.map((revenue) => revenue.Type),
        data: pieChart.Revenue.map((revenue) => revenue.Amount),
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };
  const yearText = pieChart.Year ? `(FY ${pieChart.Year})` : "";

  return (
    <div
      style={{
        flexGrow: 1,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <Doughnut
        className="splitFlag"
        key={pieChart.Type}
        style={{ maxHeight: "400px", width: "auto", maxWidth: "575px" }}
        data={data}
        options={{
          animation: false,
          responsive: true,
          aspectRatio: "2",
          plugins: {
            legend: {
              position: "right",
              align: "end",
              fullSize: false,
              maxWidth: 200,
              labels: {
                usePointStyle: true,
                sort(a, b, chart) {
                  if (
                    data.datasets[0].data[a.index] <
                    data.datasets[0].data[b.index]
                  ) {
                    return 1;
                  }
                  if (
                    data.datasets[0].data[a.index] >
                    data.datasets[0].data[b.index]
                  ) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                },
              },
            },
            title: {
              display: true,
              text: `${pieChart.Type} ${yearText}`.trim(),
              position: "top",
              align: "start",
              fullSize: "true",
              padding: 10,
              font: {
                size: 14,
                family: "'Roboto','Helvetica','Arial',sans-serif",
                weight: "550",
              },
            },
            datalabels: {
              display: true,
              color: "black",
              anchor: "center",
              align: "center",
              formatter: function (value, context) {
                const percent = (value / total) * 100;
                if (percent < 5) return "";
                return numberAbbrev(pieChart.Currency, value);
              },
            },
            doughnutlabel: {
              labels: [
                {
                  text: numberAbbrev(pieChart.Currency, total),
                  font: {
                    size: 16,
                  },
                },
              ],
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${
                    context.dataset.label[context.dataIndex]
                  }: ${numberAbbrev(pieChart.Currency, context.parsed)}`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}
