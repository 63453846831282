import React from "react";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import axios from "axios";
import { rule5properties } from "../../../../properties";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Researchcard from "./Researchcard";
import Image from "../../../../common/Image";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import produce from "immer";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { ListItemAvatar } from "@mui/material";

const useStyles = makeStyles(() => ({
  cardstyle: {
    boxShadow: "none",
  },
  cardContent: {
    paddingTop: "20px",
    paddingBottom: "0px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
  },
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    padding: "5px",
  },
  peopleImage: {
    height: 50,
    width: 50,
    borderRadius: "50%",
    float: "left",
    marginRight: "16px",
    verticalAlign: "middle",
  },
  flexContent: {
    flexGrow: 1,
    position: "relative",
    margin: "10px",
    width: 320,
    height: 200,
    overflowX: "hidden",
    overflowY: "hidden",
    "&::after": {
      position: "absolute",
      bottom: 0,
      height: "15%",
      width: "100%",
      content: '""',
      background:
        "linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 0.7), rgba(255,255,255, 1));",
      pointerEvents: "none" /* so the text is still selectable */,
    },
  },
  flexContentImage: {
    position: "relative",
    margin: "10px",
    padding: "10px",
    borderRadius: "5px",
    width: 330,
    height: 98 + 20,
  },
  newsImage: {
    width: "120px",
    float: "left",
    marginRight: "10px",
  },
  centerImages: {
    display: "flex",
    alignItems: "center",
  },
  authorContent: {
    color: "green",
  },
  contentType: {
    textDecorationLine: "underline",
    fontWeight: "bold",
  },
  boldStyle: {
    fontWeight: "800",
  },
  cardTitle: {
    fontSize: 14,
    textAlign: "left",
    color: "#333333",
  },
  root: {
    "& .MuiTableCell-root": {
      borderBottom: "0px solid",
      padding: "0px",
      /*       paddingTop: "5px",
      paddingBottom: "5px", */
      paddingRight: "10px",
    },
    "& .MuiPaper-root": {
      boxShadow: "none",
    },
    "& .MuiToolbar-regular": {
      minHeight: "0px",
    },
    "& .hideAddIcon": {
      visibility: "hidden",
    },
    "&:hover .hideAddIcon": {
      visibility: "visible",
    },
    "& .MuiList-padding": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      cursor: "default",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      cursor: "text !important",
    },
    "&$focused $notchedOutline": {
      borderColor: "green",
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  autoCompleteTextField: {
    padding: 10,
    width: "100%",
    border: `1px solid #DDD`,
    borderRadius: "5px",
    "& input": {
      borderRadius: 10,
      backgroundColor: "#fff",
      padding: 8,
      border: `1px solid #DDD`,
      fontSize: 14,
    },
    "&:focus": {
      outline: "none",
    },
  },
  typographyText: {
    whiteSpace: "pre",
  },
  typographyH6: {
    "&:focus": {
      outline: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
    },
    "[contenteditable=true]:empty:before": {
      content: "attr(placeholder)",
      display: "block",
      color: "#aaa",
    },
    "&:hover": {
      outline: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
    },
    cursor: "default",
    // height: "10px"
  },
  listItemStyle: {
    width: "100%",
    maxWidth: "fit-content",
    "&:hover, &:focus": { "& svg": { visibility: "visible" } },
    paddingBottom: "5px",
    paddingTop: "5px",
    paddingLeft: "0px",
    paddingRight: "30px",
    right: "15px",
  },
  hideRemovePeopleIcon: {
    fontSize: "20px",
    visibility: "hidden",
  },
  topRightIconPosition: {
    position: "relative",
    top: "-20px",
    zIndex: "1",
    width: "35px !important",
    height: "35px !important",
    left: "20px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  profilePic: {
    position: "relative",
    left: "8px",
    padding: 0,
    right: 10,
    width: "45px",
    height: "45px",
    borderRadius: "70px",
  },
  competitionPic: {
    position: "relative",
    marginLeft: "10px",
    left: "5px",
    maxWidth: "60px",
    maxHeight: "60px",
    display: "block",
  },
  competitionAvatar: {
    position: "relative",
    marginLeft: "15px",
    left: "5px",
    maxWidth: "60px",
    maxHeight: "60px",
  },
  listStyleWidth: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    width: "100%",
    flexWrap: "wrap",
  },
  peopleMenuPicture: {
    maxHeight: "45px",
    maxWidth: "45px",
    borderRadius: "20px",
  },
  selectorStyle: {
    "& .MuiInputBase-input": {
      borderRadius: 0,
      position: "relative",
      backgroundColor: "#fff",
      border: "none",
      fontSize: "0.875rem",
      //  textAlign: "left"
    },
    "&:focus": {
      borderColor: "none",
      border: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "none", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "none", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "none", // Solid underline on focus
    },
    "& .MuiInput-root:after": {
      borderBottomColor: "none", // Solid underline on focus
    },
  },
  competitionMenu: {
    "& .MuiListItemAvatar-root": {
      minWidth: "90px",
      display: "flex",
      justifyContent: "center",
    },
  },
  scoreStyle: {
    width: "60px",
    display: "inline - block",
    borderRadius: "12px",
    justifyContent: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    color: "white",
    marginLeft: "10px",
  },
  textBoxStyle: {
    border: "none",
  },
}));

const RightColumnCells = ({
  row,
  onChange,
  deletePerson,
  peopleData,
  sellerCompData,
  anchorEl,
  handleClick,
  handleClose,
  addPersonToList,
  handleCloseDialog,
  submitNewPerson,
  secondMenuClick,
  submitNewCompetitions,
}) => {
  const classes = useStyles();

  if (row.dataType === "dropdownList" && row.sourceType === "People") {
    return (
      <TableCell align="left" style={{ paddingLeft: "10px" }}>
        <React.Fragment>
          <List className={classes.listStyleWidth}>
            {row.values.map((value, index) => {
              if (value.title !== null) {
                return (
                  <ListItem
                    key={row.name + "_" + index}
                    className={classes.listItemStyle}
                    secondaryAction={
                      <IconButton
                        className={classes.topRightIconPosition}
                        onClick={() => deletePerson(row, value.title)}
                      >
                        <HighlightOffOutlinedIcon
                          className={classes.hideRemovePeopleIcon}
                        />
                      </IconButton>
                    }
                  >
                    {CreateAvatarImg(value, classes, row.sourceType)}
                    <ListItemText
                      style={{ marginLeft: "10px" }}
                      primary={value.title}
                      secondary={value.secondaryText}
                      primaryTypographyProps={{ fontSize: "0.875rem" }}
                      secondaryTypographyProps={{ fontSize: ".700rem" }}
                    />
                  </ListItem>
                );
              }
            })}
            <ListItem
              style={{ width: "40px", maxwidth: "100px" }}
              padding="none"
            >
              {row.dataType === "dropdownList"
                ? PeopleSelectMenu(
                    row,
                    peopleData,
                    anchorEl,
                    handleClick,
                    handleClose,
                    addPersonToList,
                    handleCloseDialog,
                    submitNewPerson,
                    secondMenuClick,
                    submitNewCompetitions
                  )
                : null}
            </ListItem>
          </List>
        </React.Fragment>
      </TableCell>
    );
  } else if (
    row.dataType === "dropdownList" &&
    row.sourceType === "Seller Competition"
  ) {
    return (
      <TableCell align="left" style={{ padding: "0px" }}>
        <React.Fragment>
          <List className={classes.listStyleWidth}>
            {row.values.map((value, index) => {
              return (
                <ListItem
                  key={row.name + "_" + index}
                  className={classes.listItemStyle}
                  secondaryAction={
                    <IconButton
                      className={classes.topRightIconPosition}
                      onClick={() => deletePerson(row, value.title)}
                    >
                      <HighlightOffOutlinedIcon
                        className={classes.hideRemovePeopleIcon}
                      />
                    </IconButton>
                  }
                >
                  {CreateAvatarImg(value, classes, row.sourceType)}
                  <ListItemText
                    style={{ marginLeft: "15px" }}
                    primary={value.title}
                    primaryTypographyProps={{ fontSize: "0.875rem" }}
                  />
                </ListItem>
              );
            })}
            <ListItem
              style={{ width: "40px", maxwidth: "100px" }}
              padding="none"
            >
              {row.dataType === "dropdownList"
                ? PeopleSelectMenu(
                    row,
                    sellerCompData,
                    anchorEl,
                    handleClick,
                    handleClose,
                    addPersonToList,
                    handleCloseDialog,
                    submitNewPerson,
                    secondMenuClick,
                    submitNewCompetitions
                  )
                : null}
            </ListItem>
          </List>
        </React.Fragment>
      </TableCell>
    );
  } else if (row.dataType === "textbox") {
    return (
      <TableCell align="left">
        {row.values.length === 0 ? (
          <TextField
            key={row.name + "_editField_"}
            className={classes.typographyH6}
            placeholder="..."
            multiline
            fullWidth
            onChange={(e) => onChange(e, row, 0)}
            InputProps={{ style: { fontSize: "0.875rem" } }}
          />
        ) : (
          row.values.map((value, index) => {
            return (
              <TextField
                key={row.name + "_editField_" + index}
                className={classes.typographyH6}
                placeholder="..."
                multiline
                fullWidth
                defaultValue={value.title ? value.title : ""}
                InputProps={{
                  style: { padding: 1, fontSize: "0.875rem" },
                  classes: { notchedOutline: classes.textBoxStyle },
                }}
                onChange={(e) => onChange(e, row, index)}
              />
            );
          })
        )}
      </TableCell>
    );
  }
};

const CreateAvatarImg = (value, classes, sourceType) => {
  let avatarImg;
  if (value.link !== null && value.link !== "") {
    avatarImg = (
      <ListItemAvatar>
        <Image
          src={value.link}
          className={
            sourceType === "People"
              ? classes.profilePic
              : classes.competitionPic
          }
        />
      </ListItemAvatar>
    );
  } else if (value.title !== null) {
    avatarImg = (
      <ListItemAvatar>
        <Avatar
          className={
            sourceType === "People"
              ? classes.profilePic
              : classes.competitionAvatar
          }
        >
          {value.title.charAt(0)}
        </Avatar>
      </ListItemAvatar>
    );
  } else {
    avatarImg = null;
  }

  return avatarImg;
};

const createCheckBox = (row, scoreUpdate) => {
  let text = "";
  row.values.map((element) => {
    text += element.title;
  });

  if (text !== "null" && text !== "") {
    return (
      <Checkbox
        disableRipple
        sx={{ ml: "-10px" }}
        key={row.name + "_checkbox_key"}
        value={row.score}
        onChange={(e) => scoreUpdate(e, row)}
        checked={row.status}
      />
    );
  } else {
    return (
      <Checkbox
        sx={{ ml: "-10px" }}
        key={row.name + "_checkbox_disabled_key"}
        value={row.score}
        disabled
      />
    );
  }
};

const createPeopleOptions = (selectionMenuData) => {
  let names = [];

  Array.from(selectionMenuData.keys()).map((key, index) => {
    names.push({
      name: key,
      indexNumber: index,
      value: selectionMenuData.get(key),
    });
  });

  return names;
};

const CustomPopper = (props) => {
  const classes = useStyles();
  return (
    <Popper
      {...props}
      style={{ minWidth: "150px", minHeight: "300px" }}
      placement="bottom"
    />
  );
};

const addPersonFromAutoComplete = (e, row, personToAdd, addPersonToList) => {
  if (e.key === "Enter") {
    if (personToAdd !== undefined) {
      addPersonToList(row, personToAdd);
    }
  }
};

const PeopleSelectMenu = (
  row,
  selectionData,
  anchorEl,
  handleClick,
  handleClose,
  addPersonToList,
  handleCloseDialog,
  submitNewPerson,
  secondMenuClick,
  submitNewCompetitions
) => {
  const classes = useStyles();

  const open = Boolean(anchorEl);

  let selectionMenuData = new Map();

  let menuText;

  if (row.sourceType === "People") {
    menuText = "Add person";
  } else if (row.sourceType === "Seller Competition") {
    menuText = "Add competition";
  }

  //Build Map of People Data
  if (selectionData !== null) {
    if (row.sourceType === "People") {
      selectionData.Person.reduce(function (map, person) {
        selectionMenuData.set(person.FullName, person);
      }, {});
    } else if (row.sourceType === "Seller Competition") {
      selectionData.Organizations.reduce(function (map, org) {
        selectionMenuData.set(org.CompanyName, org);
      }, {});
    }
    //Remove People from Menu if they are suppose to be displayed
    row.values.map((value, index) => {
      if (selectionMenuData.has(value.title)) {
        selectionMenuData.delete(value.title);
      }
    });
  }

  return (
    <div id={row.name + "-" + "div"}>
      <IconButton
        className="hideAddIcon"
        id={row.name + "-" + "basic-button"}
        key={row.name + "-" + "icon"}
        aria-haspopup="true"
        aria-controls="basic-menu"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClick(e, row.name)}
      >
        <AddCircleOutlineIcon
          style={{ color: "rgb(221, 221, 221)" }}
          fontSize="medium"
        />
      </IconButton>
      <Menu
        style={{
          paddingBottom: "0px",
        }}
        id={row.name + "-menu"}
        key={row.name + "-menu-icon"}
        anchorEl={anchorEl && anchorEl[row.name]}
        open={Boolean(anchorEl && anchorEl[row.name])}
        onClose={handleClose}
        autoFocus={false}
        disableAutoFocusItem={true}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            minWidth: "20ch",
          },
        }}
      >
        {row.sourceType === "People" ? (
          <MenuItem
            key="autocomplete"
            value="autocomplete"
            style={{ maxHeight: "55px", backgroundColor: "none" }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Autocomplete
              id="people-auto-complete"
              options={createPeopleOptions(selectionMenuData)}
              getOptionLabel={(option) => option.name}
              sx={{ width: "100%" }}
              classes={{
                option: classes.option,
                listbox: classes.listbox,
              }}
              PopperComponent={CustomPopper}
              renderOption={(props, option) => (
                <Box {...props}>
                  <Image
                    style={{
                      maxHeight: "40px",
                      maxWidth: "40px",
                      paddingRight: "5px",
                    }}
                    src={option.value.Avatar}
                  />
                  <ListItemText
                    style={{ marginLeft: "5px" }}
                    primaryTypographyProps={{ fontSize: "0.875rem" }}
                    secondaryTypographyProps={{ fontSize: "0.720rem" }}
                    primary={option.name}
                    secondary={
                      row.sourceType === "People" ? option.value.Title : ""
                    }
                  />
                </Box>
              )}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    type="text"
                    {...params.inputProps}
                    className={classes.autoCompleteTextField}
                    placeholder="Search Person"
                    onKeyDown={(e) =>
                      addPersonFromAutoComplete(
                        e,
                        row,
                        selectionMenuData.get(e.target.value),
                        addPersonToList
                      )
                    }
                  />
                </div>
              )}
            />
          </MenuItem>
        ) : null}
        {Array.from(selectionMenuData.keys()).map((key, index) => {
          return (
            <MenuItem
              key={row.name + "-" + index}
              value={row.name}
              style={{ maxHeight: "55px" }}
              onClick={(e) => addPersonToList(row, selectionMenuData.get(key))}
            >
              {(() => {
                switch (row.sourceType) {
                  case "People":
                    return (
                      <ListItemAvatar>
                        <Image
                          src={selectionMenuData.get(key).Avatar}
                          className={classes.peopleMenuPicture}
                        />
                      </ListItemAvatar>
                    );
                  case "Seller Competition":
                    return (
                      <ListItemAvatar>
                        <Image
                          src={selectionMenuData.get(key).Logo}
                          style={{
                            maxWidth: "40px",
                            maxHeight: "40px",
                            display: "block",
                            justifyContent: "center",
                          }}
                        />
                      </ListItemAvatar>
                    );
                }
              })()}
              <ListItemText
                style={{ marginLeft: "5px" }}
                primaryTypographyProps={{ fontSize: "0.875rem" }}
                secondaryTypographyProps={{ fontSize: "0.720rem" }}
                primary={key}
                secondary={
                  row.sourceType === "People"
                    ? selectionMenuData.get(key).Title
                    : ""
                }
              />
            </MenuItem>
          );
        })}
        {row.sourceType === "People" ||
        row.sourceType === "Seller Competition" ? (
          <div>
            <MenuItem
              key="addNewPersonMenu"
              onClick={(e) => secondMenuClick(e, "addPerson")}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <ListItemAvatar
                style={{
                  display: "inline-flex",
                  position: "relative",
                  paddingLeft: "10px",
                }}
              >
                <AddCircleOutlineIcon
                  sx={{
                    color: "rgb(221, 221, 221)",
                    width:
                      row.sourceType === "Seller Competition" ? "53px" : "27px",
                    position: "relative",
                    right:
                      row.sourceType === "Seller Competition" ? "15px" : "0",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                style={{
                  marginLeft:
                    row.sourceType === "Seller Competition" ? "0px" : "5px",
                }}
                primaryTypographyProps={{ fontSize: "0.875rem" }}
              >
                {menuText}
              </ListItemText>
              <Menu
                id="add-person-menu"
                anchorEl={anchorEl && anchorEl["addPerson"]}
                open={Boolean(anchorEl && anchorEl["addPerson"])}
                onClose={(e) => handleCloseDialog(e)}
                MenuListProps={{
                  "aria-labelledby": "add-person-button",
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {row.sourceType === "People" ? (
                  <div
                    style={{ paddingLeft: 10, paddingRight: 10, width: "20em" }}
                    tabIndex={0}
                  >
                    <TextField
                      label="LinkedIn URL"
                      id="add-person-name"
                      autoFocus
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      type="text"
                      name="linkedinUrl"
                      placeholder="Enter LinkedIn URL..."
                      onKeyDown={(e) => submitNewPerson(e, row)}
                    />
                  </div>
                ) : (
                  <div
                    style={{ paddingLeft: 10, paddingRight: 10, width: "20em" }}
                    tabIndex={0}
                  >
                    <TextField
                      // id="outlined-basic"
                      label="Competition Name"
                      id="add-competition-name"
                      autoFocus
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      type="text"
                      name="linkedinUrl"
                      placeholder="Enter Competition to Add..."
                      onKeyDown={(e) => submitNewCompetitions(e, row)}
                    />
                  </div>
                )}
              </Menu>
            </MenuItem>
          </div>
        ) : (
          <div>Not Added</div>
        )}
      </Menu>
    </div>
  );
};

export default function Methodology(props) {
  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [methodologyData, setMethodologyData] = React.useState({
    Name: [],
    score: "",
    Selected: "",
    MEDDICCitems: [],
    SPINitems: [],
    NEATitems: [],
    BANTitems: [],
  });

  const [peopleData, setPeopleData] = React.useState(null);

  const [firstTime, setFirstTime] = React.useState(true);

  const [tableData, setTableData] = React.useState([]);

  const [previousState, setPreviousState] = React.useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [sellerCompData, setSellerCompData] = React.useState({
    Organizations: [],
  });

  const [openAddPersonBox, setOpenAddPersonBox] = React.useState(null);

  const [score, setScore] = React.useState(null);

  const selectorChange = (e) => {
    setMethodologyData({ ...methodologyData, Selected: e.target.value });
  };

  const handleClick = (event, rowName) => {
    setAnchorEl({ ...anchorEl, [rowName]: event.currentTarget });
  };

  const secondMenuClick = (event, rowName) => {
    setOpenAddPersonBox(true);
    setAnchorEl({ ...anchorEl, [rowName]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = (e) => {
    setOpenAddPersonBox(false);
    setAnchorEl(null);
    e.stopPropagation();
  };

  const addPersonToList = (row, elementToAdd) => {
    let newScore = parseInt(methodologyData.score);
    let newPerson = null;

    if (row.sourceType === "People") {
      newPerson = {
        title: elementToAdd.FullName,
        link: elementToAdd.Avatar,
        description: null,
        secondaryText: elementToAdd.Title,
      };
    } else if (row.sourceType === "Seller Competition") {
      newPerson = {
        title: elementToAdd.CompanyName,
        link: elementToAdd.Logo,
        description: elementToAdd.Description,
      };
    }

    const newRow = produce(row, (draftState) => {
      draftState.values.push(newPerson);

      if (draftState.status === false && draftState.values.length !== 0) {
        draftState.status = true;
        newScore = newScore + parseInt(draftState.score);
      }
    });

    const newTableData = produce(tableData, (draftState) => {
      const replaceIndex = draftState.findIndex(
        (element) => element.name === newRow.name
      );

      if (replaceIndex !== -1) {
        draftState[replaceIndex] = newRow;
      }
    });

    setMethodData(newTableData, newScore);
    setAnchorEl(null);
  };

  const onChange = (e, row, index) => {
    if (!previousState[row.name]) {
      setPreviousState((state) => ({ ...state, [row.name]: row }));
    }

    const newTextChange =
      e.target.value === "" || e.target.value === "<div><br></div>"
        ? null
        : e.target.value;
    const name = row.name;

    let newScore = parseInt(methodologyData.score);

    const newRow = produce(row, (draftState) => {
      if (row.values.length === 0) {
        draftState.values.push([
          {
            title: newTextChange,
            link: null,
            description: null,
          },
        ]);
      } else {
        let replacedValue = { ...draftState.values[index] };
        replacedValue.title = newTextChange;
        draftState.values[index] = replacedValue;
      }

      if (newTextChange === null || newTextChange.length === 0) {
        draftState.status = false;
        if (newScore !== 0) {
          newScore = newScore - parseInt(row.score);
        }
      } else if (newTextChange !== 0 && draftState.status === false) {
        newScore = newScore + parseInt(row.score);
      }
    });

    const newTableData = produce(tableData, (draftState) => {
      const replaceIndex = draftState.findIndex((r) => r.name === name);

      if (replaceIndex !== -1) {
        draftState[replaceIndex] = newRow;
      }
    });

    setMethodData(newTableData, newScore);
  };

  const submitNewPerson = (e, row) => {
    if (e.keyCode === 13) {
      const params = { profileUrl: e.target.value };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 7000,
      };

      setSnackbarMessage("Creating new person from LinkedIn Link...");
      setOpenSnackbar(true);

      axios
        .post(rule5properties.requestPerson, params, options)
        .then((response) => {
          if (response.status === 200) {
            let newRow = { ...row };
            let newScore = parseInt(methodologyData.score);

            const newPerson = {
              title: response.data.full_name,
              link: response.data.avatar_url,
              description: null,
            };

            const r = produce(row, (draftState) => {
              if (
                draftState.status === false &&
                draftState.values.length === 0
              ) {
                draftState.status = true;
                newScore = newScore + parseInt(row.score);
              }
              draftState.values.push(newPerson);
            });

            const newTableData = produce(tableData, (draftState) => {
              const index = draftState.findIndex(
                (element) => element.name === r.name
              );
              if (index !== -1) {
                draftState[index] = r;
              }
            });

            setMethodData(newTableData, newScore);
            handleClose();
          } else {
            // API call failed
            setSnackbarMessage("There's a problem creating a new Person");

            setOpenSnackbar(true);
            handleClose();
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
            setSnackbarMessage("There's a problem creating a new Person");
            setOpenSnackbar(true);
            handleClose();
          }
        });

      const jiraParam = {
        operation: "Edit",
        module: "Research",
        opportunityId: props.opportunity.id,
        comment:
          "Add new person for this opportunity, " +
          e.target.value +
          "\n " +
          e.target.value,
        cardId: props.peopleCardId,
      };

      axios
        .post(rule5properties.requests, jiraParam, options)
        .then((response) => {
          if (response.status === 200) {
          }
        });
      //Close the pop by up the handle
    }
  };

  const submitNewCompetitions = (e, row) => {
    if (e.keyCode === 13) {
      let newRow = { ...row };
      let newScore = parseInt(methodologyData.score);

      const newPerson = {
        title: e.target.value,
        link: null,
        description: null,
      };

      const r = produce(row, (draftState) => {
        if (draftState.status === false && draftState.values.length === 0) {
          draftState.status = true;
          newScore = newScore + parseInt(row.score);
        }
        draftState.values.push(newPerson);
      });

      const newTableData = produce(tableData, (draftState) => {
        const index = draftState.findIndex(
          (element) => element.name === r.name
        );
        if (index !== -1) {
          draftState[index] = r;
        }
      });

      if (newRow.status === false && newRow.values.length === 0) {
        newRow.status = true;
        newScore = newScore + parseInt(row.score);
      }

      const jiraParam = {
        operation: "Edit",
        module: "Research",
        opportunityId: props.opportunity.id,
        comment: "Add Competition for this opportunity, " + e.target.value,
        cardId: props.peopleCardId,
      };

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 7000,
      };

      axios
        .post(rule5properties.requests, jiraParam, options)
        .then((response) => {
          if (response.status === 200) {
          }
        });
      setMethodData(newTableData, newScore);
      handleClose();
    }
  };

  const deletePerson = (row, name) => {
    let newRow = { ...row };
    let newScore = parseInt(methodologyData.score);

    const r = produce(row, (draftState) => {
      const index = draftState.values.findIndex(
        (person) => person.title === name
      );

      if (index !== -1) {
        draftState.values.splice(index, 1);
      }

      if (draftState.values.length === 0) {
        draftState.status = false;
        if (newScore !== 0) {
          newScore = newScore - parseInt(row.score);
        }
      } else if (
        draftState.status === false &&
        draftState.values.length !== 0
      ) {
        draftState.status = true;
        newScore = newScore + parseInt(row.score);
      }
    });

    const newTableData = produce(tableData, (draftState) => {
      const index = draftState.findIndex(
        (tableRow) => tableRow.name === newRow.name
      );

      if (index !== -1) {
        draftState[index] = r;
      }
    });

    setMethodData(newTableData, newScore);
  };

  const setMethodData = (data, newScore) => {
    const newMethodologyData = produce(methodologyData, (draftState) => {
      switch (draftState.Selected) {
        case "MEDDICC":
          draftState.MEDDICCitems = data;
          break;
        case "SPIN":
          draftState.SPINitems = data;
          break;
        case "NEAT":
          draftState.NEATitems = data;
          break;
        case "BANT":
          draftState.BANTitems = data;
          break;
      }

      if (newScore !== undefined) {
        draftState.score = newScore;
      }
    });

    setMethodologyData(newMethodologyData);
  };

  const scoreUpdate = (e, row) => {
    const newRow = { ...row, status: e.target.checked };

    const newTableData = produce(tableData, (draftState) => {
      const index = draftState.findIndex(
        (element) => element.name === row.name
      );
      if (index !== -1) {
        draftState[index] = newRow;
      }
    });

    setMethodData(newTableData);
  };

  const saveMethodologyCard = () => {
    const params = {
      id: props.cardId,
      card_info: methodologyData,
    };
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
    };

    axios
      .patch(rule5properties.getCard, params, options)
      .then((response) => {
        let rawdata = response.data.cardInfo;
        if (!Object.keys(rawdata) !== 0) {
          // Validate content
          // Array reference will update the value

          setScore(rawdata.score);
        } else {
          // API call failed
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
      });
  };

  const scoreColor = (score) => {
    if (score >= 85) {
      return "#58CB58";
    } else if (score >= 50) {
      return "rgb(237 207 45)";
    } else if (score >= 30) {
      return "rgb(255,153,0)";
    } else {
      return "rgb(255,0,0)";
    }
  };

  const scoreConvert = (score) => {
    if (score >= 85) {
      return "Excellent";
    } else if (score >= 50) {
      return "Good";
    } else if (score >= 30) {
      return "Fair";
    } else {
      return "Incomplete";
    }
  };

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      // Call API
      let parameter = "/" + props.cardId; // cardId hardcoded for now
      axios
        .get(rule5properties.getCard + parameter)
        .then((response) => {
          let rawdata = response.data;
          if (!Object.keys(rawdata) !== 0) {
            // Validate content
            // Array reference will update the value

            setMethodologyData(rawdata);

            if (rawdata.Selected === "MEDDICC") {
              setTableData(rawdata.MEDDICCitems);
            } else if (rawdata.Selected === "SPIN") {
              setTableData(rawdata.SPINitems);
            } else if (rawdata.Selected === "NEAT") {
              setTableData(rawdata.NEATitems);
            } else if (rawdata.Selected === "BANT") {
              setTableData(rawdata.BANTitems);
            }
          } else {
            // API call failed
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        });

      if (props.peopleCardId !== null) {
        axios
          .get(rule5properties.getCard + "/" + props.peopleCardId)
          .then((response) => {
            let rawdata = response.data;

            if (rawdata.Person) {
              // Validate content
              setPeopleData(rawdata);
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem with the retrieving People Card " +
                  props.peopleCardId +
                  " Card"
              );
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with retrieving People Card " +
                  props.peopleCardId +
                  " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }

      if (props.sellerCompetitionCardId !== null) {
        axios
          .get(rule5properties.getCard + "/" + props.sellerCompetitionCardId)
          .then((response) => {
            let rawdata = response.data;

            if (rawdata.Organizations) {
              // Validate content
              setSellerCompData(rawdata);
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem with the retrieving Seller Competition Card " +
                  props.sellerCompetitionCardId +
                  " Card"
              );
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with retrieving Seller Competition Card " +
                  props.sellerCompetitionCardId +
                  " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }

    if (methodologyData.Selected === "MEDDICC") {
      setTableData(methodologyData.MEDDICCitems);
    } else if (methodologyData.Selected === "SPIN") {
      setTableData(methodologyData.SPINitems);
    } else if (methodologyData.Selected === "NEAT") {
      setTableData(methodologyData.NEATitems);
    } else if (methodologyData.Selected === "BANT") {
      setTableData(methodologyData.BANTitems);
    }

    if (methodologyData.Selected !== "") {
      saveMethodologyCard();
    }
  }, [firstTime, methodologyData]);

  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      // title={methodologyData.Title ? methodologyData.Title : props.cardType}
      title="Methodology"
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <div
        style={{
          position: "relative",
          display: "inline-flex",
          flexWrap: "row wrap",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          style={{
            position: "absolute",
            left: methodologyData.Selected === "MEDDICC" ? "0px" : "-20px",
          }}
        >
          <FormControl
            variant="standard"
            style={{ minWidth: 80, borderStyle: "none" }}
          >
            <Select
              SelectDisplayProps={{
                style: {
                  marginTop: 0,
                  padding: 0,
                  marginRight:
                    methodologyData.Selected === "MEDDICC" ? "25px" : "5px",
                },
              }}
              labelId="methodology-selector-label"
              id="methodology-selector"
              value={methodologyData.Selected}
              onChange={selectorChange}
              autoWidth
              className={classes.selectorStyle}
              disableUnderline
            >
              {methodologyData.Name !== 0
                ? methodologyData.Name.map((name) => {
                    return (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </Box>
        <div
          style={{
            position: "absolute",
            right: "-40px",
          }}
        >
          <Typography variant="body2">
            r5 score{" "}
            <span
              className={classes.scoreStyle}
              style={{ background: scoreColor(score) }}
            >
              {scoreConvert(score)}
            </span>{" "}
          </Typography>
        </div>
      </div>
      <Card className={classes.cardstyle}>
        <TableContainer style={{ paddingTop: "20px" }}>
          <Table>
            <TableBody>
              {tableData
                ? tableData.map((row) => (
                    <TableRow key={row.name + "_"} className={classes.root}>
                      <TableCell
                        style={{
                          width: "10px",
                          maxwidth: "100px",
                        }}
                        align="left"
                      >
                        {createCheckBox(row, scoreUpdate)}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          width:
                            methodologyData.Selected === "SPIN" ||
                            methodologyData.Selected === "NEAT"
                              ? "200px"
                              : "130px",
                        }}
                      >
                        <Typography variant="body">{row.name}</Typography>
                      </TableCell>
                      <RightColumnCells
                        {...{
                          row,
                          onChange,
                          deletePerson,
                          peopleData,
                          sellerCompData,
                          anchorEl,
                          handleClick,
                          handleClose,
                          addPersonToList,
                          handleCloseDialog,
                          submitNewPerson,
                          secondMenuClick,
                          submitNewCompetitions,
                        }}
                      />
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}
