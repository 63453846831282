import {
  Box,
  Button,
  Fade,
  Stack,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDialog } from "../../context/DialogContext";
import {
  StyledDataGrid,
  titleButton,
  titleButtonInOpp,
} from "../../common/StyledComponents";
import Toolbar from "../../common/Toolbar";
import EditAgent from "../../modal/EditAgent";
import AccountAgentResults from "./AccountAgentResults";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Workspace from "../Workspace";
import ChevronRight from "@mui/icons-material/ChevronRight";
import axios from "axios";
import { rule5properties } from "../../properties";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreateAgent from "../../modal/CreateAgent";
import { accountsAgentType } from "./common/AgentTypes";
import {
  ACCOUNT_AGENT_COLUMNS,
  ACCOUNT_AGENT_RESULT_COLUMNS,
  AgentName,
} from "./common/monitorColumns";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import { NoCompanyAgentsOverlay } from "./common/NoResultsOverlay";
import { isSuccessStatus } from "../../common/RequestUtils";
import GenericConfirmation from "../../modal/GenericConfirmation";
import AgentsToolbar from "./common/AgentsToolbar";

export default function MonitorAccounts(props) {
  const dialog = useDialog();
  const history = useHistory();
  const location = useLocation();

  const defaultSearchValue = React.useRef("");

  const [monitorButtonHover, setMonitorButtonHover] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [displayedAgentIndex, setDisplayedAgentIndex] = React.useState(-1);

  // Callback to modify agent when edited.
  function updateAgentRow(id, newAgentVals) {
    const newRows = [...rows];
    console.log(newRows);
    const editedAgentIndex = rows.findIndex((agent) => agent.agentId === id);
    if (editedAgentIndex >= 0) {
      const tempAgent = {
        ...rows[editedAgentIndex],
        ...newAgentVals,
      };
      newRows.splice(editedAgentIndex, 1, tempAgent);
      setRows(newRows);
    } else {
      console.log("Couldn't find edited agent.");
    }
  }

  // Delete agent callback.
  function deleteAgent(agent) {
    dialog.openModal(
      "Delete agent",
      GenericConfirmation,
      {
        children: (
          <Typography variant="body1">
            The agent, <strong>{agent.name}</strong>, will be deleted.
          </Typography>
        ),
        confirmFunction: (callback) => {
          axios
            .delete(`${rule5properties.agents}/${agent.agentId}`)
            .then((resp) => {
              if (isSuccessStatus(resp.status)) {
                loadAccountAgentRows(defaultSearchValue.current);
                if (callback) callback(resp);
              }
            });
        },
      },
      "sm"
    );
  }

  // Calls the API to retrieve agents.
  const loadAccountAgentRows = React.useCallback((searchValue) => {
    defaultSearchValue.current = searchValue;
    setLoading(true);
    const params = new URLSearchParams();
    params.append("type", "Accounts");
    if (searchValue) {
      params.append("searchValue", encodeURIComponent(searchValue));
    }
    const accountAgentPromises = [
      axios.get(rule5properties.agents + "?" + params.toString()),
    ];

    Promise.all(accountAgentPromises)
      .then((resp) => {
        setLoading(false);
        if (resp?.[0]?.status === 200 && resp?.[0]?.data) {
          setRows(resp[0].data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  // Initial load.
  useEffect(() => {
    loadAccountAgentRows(defaultSearchValue.current);
    return () => {
      if (processingAgentPollingId.current) {
        console.log("Clearing interval " + processingAgentPollingId.current);
        clearInterval(processingAgentPollingId.current);
      }
    };
  }, []);

  // Keep a running list of processing agents, and routinely check API to see if processing has completed.
  const [processingAgentIds, setProcessingAgentIds] = React.useState([]);
  const processingAgentPollingId = React.useRef(null);
  useEffect(() => {
    const tempProcessingAgentIds = [];
    // Find the agents that are in processing state.
    rows.forEach((agent) => {
      if (
        agent.monitorStatus === "Processing" ||
        agent.monitorStatus === "Failed"
      ) {
        tempProcessingAgentIds.push(agent.agentId);
      }
    });
    setProcessingAgentIds(tempProcessingAgentIds);
  }, [rows]);

  // When processingAgentIds are updated, poll the API every 30s and update rows.
  useEffect(() => {
    if (processingAgentIds.length > 0) {
      if (processingAgentPollingId.current === null) {
        processingAgentPollingId.current = setInterval(() => {
          loadAccountAgentRows(defaultSearchValue.current);
        }, 30000);
      } else {
      }
    } else {
      clearInterval(processingAgentPollingId.current);
      processingAgentPollingId.current = null;
    }
  }, [processingAgentIds]);

  let showingResults =
    location.pathname.split("/").length > 4 &&
    location.pathname.split("/")[4] === "results";

  return (
    <Box sx={{ height: "100vh" }}>
      <Toolbar>
        <Stack direction="row" alignItems="center">
          <Box
            onClick={() => {
              history.push("/main/monitor/accounts");
            }}
            sx={showingResults ? titleButtonInOpp : titleButton}
            onMouseEnter={() => {
              setMonitorButtonHover(true);
            }}
            onMouseLeave={() => {
              setMonitorButtonHover(false);
            }}
          >
            Company agents
          </Box>
          <Fade in={showingResults && !monitorButtonHover} timeout={300}>
            <Stack direction="row" justifyContent="space-between">
              <ChevronRight
                sx={{
                  transition: ".2s",
                  m: 1,
                  transform: monitorButtonHover ? "scaleX(-1)" : "none",
                }}
              />
            </Stack>
          </Fade>
          <Fade in={showingResults && displayedAgentIndex >= 0} unmountOnExit>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ flexGrow: 1 }}
            >
              <span
                style={{
                  transition: "0.3s",
                  opacity: monitorButtonHover ? 0 : 1,
                }}
              >
                {`Results for ${rows[displayedAgentIndex]?.name}`}
              </span>
            </Stack>
          </Fade>
        </Stack>
        {showingResults ? (
          <Button
            startIcon={<ArrowBackIcon />}
            sx={{
              alignSelf: "center",
              mr: 2,
              borderRadius: "8px",
            }}
            disableRipple
            onClick={() => {
              history.push(`/main/monitor/accounts/`);
            }}
          >
            Return to agents
          </Button>
        ) : (
          <Button
            onClick={() => {
              dialog.openModal(
                "Create company agent",
                CreateAgent,
                {
                  agentType: accountsAgentType,
                  loadAgentRows: loadAccountAgentRows,
                },
                "lg"
              );
            }}
            variant="contained"
            disableRipple
            disableElevation
            sx={{ alignSelf: "center" }}
          >
            Create company agent
          </Button>
        )}
      </Toolbar>
      <Workspace>
        <Switch>
          <Route
            exact
            path="/main/monitor/accounts"
            render={() => (
              <StyledDataGrid
                sx={{
                  mx: "30px",
                  "& .MuiDataGrid-cell": {
                    alignSelf: "center",
                  },
                }}
                disableColumnReorder
                rows={rows}
                columns={ACCOUNT_AGENT_COLUMNS(
                  loadAccountAgentRows,
                  deleteAgent,
                  dialog,
                  updateAgentRow
                )}
                loading={loading}
                slots={{
                  toolbar: AgentsToolbar,
                  loadingOverlay: LinearProgress,
                  noRowsOverlay: NoCompanyAgentsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    loadAgentRows: loadAccountAgentRows,
                    defaultSearchValue: defaultSearchValue.current,
                  },
                }}
                getRowId={(row) => row.agentId}
                getRowHeight={() => "auto"}
                pagination
                disableMultipleRowSelection
                click
                disableVirtualization
                disableRowSelectionOnClick
                disableColumnMenu
                filterMode="server"
                editMode="row"
                onRowClick={(params, event) => {
                  dialog.openModal(
                    <AgentName
                      syncResearchAccounts={params.row.syncResearchAccounts}
                      name={params.row.name}
                      prependedText={"Configure Agent - "}
                    />,
                    EditAgent,
                    {
                      defaultPage: "general",
                      modAgent: params.row,
                      loadAgentRows: loadAccountAgentRows,
                      agentType: accountsAgentType,
                    },
                    "md"
                  );
                }}
              />
            )}
          />
          <Route
            exact
            path="/main/monitor/accounts/results/:agentId"
            render={() => (
              <Box sx={{ mx: "30px" }}>
                <AccountAgentResults
                  agentRows={rows}
                  setDisplayedAgentIndex={setDisplayedAgentIndex}
                  columns={ACCOUNT_AGENT_RESULT_COLUMNS}
                />
              </Box>
            )}
          />
        </Switch>
      </Workspace>
    </Box>
  );
}
