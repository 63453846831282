import axios from "axios";
import React, { useEffect, useState } from "react";
import { rule5properties } from "../../properties";
import ResearchPreview from "../util/ResearchPreview";
import { approverRoles } from "../DetMainPage";
import ResearchAddCard from "../util/ResearchAddCard";
import { useDialog } from "../../context/DialogContext";
import { useUser } from "../../context/UserContext";
import Typography from "@mui/material/Typography";
import ResearchButtons from "../util/ResearchButtons";

export default function ResearchPopup(props) {
  const [research, setResearch] = useState(props);
  const researchCardInfo = research.cardInfo.info;
  // const research = props;
  const [cardDetails, setCardDetails] = useState(null);
  const [firstTime, setFirstTime] = useState(true);
  const [isApprovable, setIsApprovable] = useState(false);
  const user = useUser();

  const getCardsDetails = (researchCards) => {
    setCardDetails(null);
    let requests = researchCards.map((card) => {
      //create a promise for each API call
      return axios.get(rule5properties.detCards + `?id=${card.cardId}`);
    });
    Promise.all(requests)
      .then((body) => {
        //this gets called when all the promises have resolved/rejected.
        setCardDetails(body);
        const something = body.some(
          (responseBody) =>
            !["Published", "Approved"].includes(responseBody.data.status)
        );
        setIsApprovable(!something);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      getCardsDetails(researchCardInfo);
    }
  }, [firstTime, researchCardInfo]);

  const onUpdate = (updatedResearch) => {
    getCardsDetails(updatedResearch.cardInfo.info);
    setResearch(updatedResearch);
  };

  if (!cardDetails) {
    return "Loading...";
  }
  return (
    <>
      <Typography variant="body1">
        {research.status} / Version: {research.version} / Updated:{" "}
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "medium",
          timeStyle: "short",
        }).format(new Date(research.updatedAt))}
      </Typography>
      {approverRoles.includes(user.role) && (
        <div style={{ padding: "10px 0px" }}>
          <ResearchButtons
            research={research}
            onUpdate={onUpdate}
            isApprovable={isApprovable}
          ></ResearchButtons>
        </div>
      )}
      <ResearchPreview research={research} cardDetails={cardDetails} />
    </>
  );
}
