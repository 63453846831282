export function signalObjectsToStrings(signalObjects) {
  return signalObjects.reduce((result, signal) => {
    if (signal.checked) {
      result.push(signal.signalName);
    }
    return result;
  }, []);
}

export function regionObjectsToStrings(regionObjects) {
  return regionObjects.reduce((result, region) => {
    if (region.checked) {
      result.push(region.regionName);
    }
    return result;
  }, []);
}
