import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import { linkStyle, LoadedAvatar } from "../../../../common/StyledComponents";
import { Button, Link, Stack } from "@mui/material";
import {
  formatPhoneNumber,
  getColorFromString,
  getLinkedInProfile,
  regulateProfile,
} from "../../../../common/Utils";
// Icons
import LinkedIn from "@mui/icons-material/LinkedIn";
import Phone from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import { Fade } from "@mui/material";
import ConnectionsInPersonPopup from "../../../../common/ConnectionsInPersonPopup";
import { useDialog } from "../../../../context/DialogContext";
import PersonProfile from "../../../../modal/PersonProfile";
import { checkPersonProfileExists } from "../../../../common/Utils";
import axios from "axios";
import { rule5properties } from "../../../../properties";
import { roleToColor, roleToIcon } from "../../../../common/Utils";

const useStyles = makeStyles(() => ({
  containerWrapper: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.03)",
      "& $roleLabel": {
        opacity: 1,
      },
    },
    borderRadius: "8px",
  },
  personContainer: {
    cursor: "pointer",
    position: "relative",
    margin: "10px",
    padding: "10px",
    borderRadius: "8px",
    width: (props) => (props.isFlashCard ? 280 : 330),
    height: 120,
    display: "flex",
    flexDirection: "row",
  },
  peopleImage: {
    height: (props) => (props.isFlashCard ? 80 : 98),
    width: (props) => (props.isFlashCard ? 80 : 98),
    borderRadius: "50%",
    float: "left",
    fontSize: "36px",
    boxSizing: "border-box",
  },
  imageContainer: {
    position: "relative",
    marginRight: (props) => (props.isFlashCard ? 8 : 16),
  },
  peopleImageCover: {
    backgroundColor: "none",
    cursor: "pointer",
    position: "absolute",
    opacity: 0,
    height: 98,
    width: 98,
    borderRadius: "50%",
    transition: "0.2s",
    // "&:hover": {
    //   backgroundColor: "rgba(0,0,0,0.1)",
    //   opacity: 1,
    // },
  },
  roleLabel: {
    display: "flex",
    textAlign: "center",
    // height: "20px",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px",
    fontSize: "11px",
    color: "white",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "-12px",
    borderRadius: "6px",
  },
  moreVertClass: {
    position: "absolute",
    backgroundColor: "",
    top: -10,
    right: -10,
    visibility: "hidden",
  },
  popupLabel: {
    textAlign: "left",
    verticalAlign: "top",
    fontWeight: "bold",
    fontSize: "10pt",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "1px solid gray",
  },
  popupContent: {
    textAlign: "left",
    fontSize: "10pt",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "1px solid gray",
  },
  iconLink: {
    color: "#1976d2", //theme.common.primaryAccent,
    "&:hover": {
      color: "#176cbf",
    },
    "&:active": {
      color: "#11599e",
    },
  },
  personDetail: {
    padding: "20px",
  },
  linkStyle: linkStyle,
  connectionsProfilePic: {
    position: "relative",
    padding: 0,
    width: "20px",
    height: "20px",
    borderRadius: "70px",
  },
}));

export default function Person(props) {
  const dialog = useDialog();
  const classes = useStyles(props);
  const { row, connections, orgName, companyName } = props;
  const index = props.rowIndex;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [personProfileExists, setPersonProfileExists] = React.useState(true);
  const [loadingProfile, setLoadingProfile] = React.useState(false);

  const [managementQuotes, setManagementQuotes] = React.useState([]);

  useEffect(() => {}, [anchorEl]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    if (props.managementQuotesCardId) {
      let params = {
        quotesCardId: props.managementQuotesCardId,
        fullName: row.FullName,
      };
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post(rule5properties.getManagementQuotes, params, options)
        .then((res) => {
          if (res.data && Array.isArray(res.data)) {
            setManagementQuotes(res.data);
          }
        });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowAdditionalInfo = () => {
    if (!checkPersonProfileExists(row.PersonProfile)) {
      setLoadingProfile(true);
      let urlParams = new URLSearchParams();
      urlParams.append("profileUrl", row.LinkedIn);
      axios
        .get(rule5properties.coreKeyPeopleProfile + "?" + urlParams.toString())
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            let profile = regulateProfile(data);
            if (checkPersonProfileExists(profile)) {
              setPersonProfileExists(true);
              row.PersonProfile = profile;
              // profile exists
              dialog.openModal(
                "Profile: " + row.FullName,
                PersonProfile,
                {
                  data: {
                    PersonProfile: profile,
                    Title: row.title,
                    managementQuotes: managementQuotes,
                  },
                  customPb: row.Title ? "0px" : "20px",
                },
                "md"
              );
            } else {
              setPersonProfileExists(false);
            }
          } else {
            console.log(res);
          }
          setLoadingProfile(false);
        })
        .catch((err) => {
          setLoadingProfile(false);
          console.log(err);
        });
    } else {
      setPersonProfileExists(true);
      dialog.openModal(
        "Profile: " + row.FullName,
        PersonProfile,
        {
          data: { ...row, managementQuotes: managementQuotes },
          customPb: row.Title ? "0px" : "20px",
        },
        "md"
      );
    }
  };

  const linkedInProfile = getLinkedInProfile(row.LinkedIn);
  const personConnections =
    connections?.[linkedInProfile]?.connectionPersonInfo;
  return (
    <Box className={classes.containerWrapper} sx={{}}>
      <Box key={index} className={classes.personContainer} onClick={handleOpen}>
        <Box className={classes.imageContainer}>
          <LoadedAvatar
            source={row.Avatar}
            className={classes.peopleImage}
            style={{
              backgroundColor: getColorFromString(row.FullName),
              outline: row.Role ? `3px solid ${roleToColor(row.Role)}` : "none",
              outlineOffset: "3px",
            }}
          >
            {row.FullName.charAt(0)}
          </LoadedAvatar>
          {row.Role && (
            <>
              <Box
                className={classes.roleLabel}
                sx={{
                  width: "20px",
                  backgroundColor: roleToColor(row.Role),
                }}
              >
                {roleToIcon(row.Role)}
              </Box>
              <Box
                className={classes.roleLabel}
                sx={{
                  fontWeight: 500,
                  opacity: 0,
                  transition: ".2s",
                  backgroundColor: roleToColor(row.Role),
                }}
              >
                {row.Role}
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "90%",
            marginTop: "6px",
            ml: 1,
          }}
        >
          <div style={{ display: "flex" }}>
            <Typography
              style={{
                marginTop: "0px",
                fontSize: "16px",
                fontWeight: 500,
                flexGrow: "1",
              }}
            >
              {row.FullName}
            </Typography>
          </div>
          <Typography style={{ fontSize: "14px" }}>{row.Title}</Typography>
          <Typography
            style={{
              fontSize: "14px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {row.Department}
          </Typography>
          {personConnections?.length > 0 && (
            <Typography
              style={{
                fontSize: "14px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <strong>{personConnections?.length}</strong> connections
            </Typography>
          )}
        </Box>
      </Box>
      <Menu
        id="person-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: -120,
        }}
        sx={{ boxShadow: 0 }}
        MenuListProps={{
          sx: {
            padding: "4px",
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: {
            borderRadius: "10px",
            overflow: "visible",
            ml: 1.5,
            "&:before": {
              borderLeft: "1px solid rgba(0,0,0,0.1)",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              content: '""',
              display: "block",
              position: "absolute",
              ml: -0.8,
              left: 0,
              top: "50%",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <Box className={classes.personDetail}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Link
              sx={{ width: "fit-content", mr: 6 }}
              className={classes.linkStyle}
              href={row.LinkedIn}
              target="_blank"
            >
              <div
                style={{
                  cursor: "pointer",
                  color: "#176cbf",
                  display: "flex",
                }}
              >
                <Typography sx={{ fontWeight: 500 }}>{row.FullName}</Typography>{" "}
                <LinkedIn sx={{ ml: 1, mt: "-2px" }} color="inherit" />
              </div>
            </Link>
            <div
              style={{
                marginBottom: "2px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
              }}
            >
              {row.Role && (
                <Stack
                  alignItems="center"
                  direction="row"
                  sx={{
                    px: 0.5,
                    py: 0.25,
                    mb: 0.25,
                    borderRadius: "4px",
                    backgroundColor: roleToColor(row.Role),
                  }}
                >
                  {roleToIcon(row.Role)}
                  <Typography
                    sx={{
                      color: "white",
                      display: "inline",
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {row.Role}
                  </Typography>
                </Stack>
              )}
            </div>
          </div>
          <Typography sx={{ mt: 1 }}>
            {row.Title} {row.Department ? `, ${row.Department}` : null}
          </Typography>
          {(row.City || row.State) && (
            <Typography sx={{ mt: "2px" }}>
              {row.City + (row.State && row.City ? ", " : "") + row.State}
            </Typography>
          )}
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <Phone sx={{ mr: 1, color: "#176cbf" }} />

            {row?.Phone[0]?.Value ? (
              <Link
                className={classes.linkStyle}
                href={"tel:" + row.Phone[0].Value}
              >
                {formatPhoneNumber(
                  row.Phone[0].CountryCode,
                  row.Phone[0].Value
                )}
              </Link>
            ) : (
              "Unspecified"
            )}
          </Box>
          <Box sx={{ mt: 1, mb: 1, display: "flex", alignItems: "center" }}>
            <MailOutlineIcon sx={{ mr: 1, color: "#176cbf" }} />
            {row.Email ? (
              <Link className={classes.linkStyle} href={"mailto:" + row.Email}>
                {row.Email}
              </Link>
            ) : (
              "Unspecified"
            )}
          </Box>
          <Box
            sx={{
              mb: 1,
              width: "320px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Typography sx={{ mt: "2px" }} variant="caption">
              Start a conversation:
            </Typography>
            <IconButton sx={{ ml: 1 }}>
              <ChatBubbleOutlineIcon />
            </IconButton>
            <IconButton>
              <VideoCameraFrontIcon />
            </IconButton>
            <IconButton>
              <MailOutlineIcon />
            </IconButton>
            <IconButton>
              <Phone />
            </IconButton>
          </Box>
          <Tooltip
            title={
              loadingProfile
                ? ""
                : personProfileExists
                ? ""
                : "We don't have additional information for this person."
            }
            placement="right"
          >
            <span>
              <Button
                disabled={!personProfileExists || loadingProfile}
                disableElevation
                disableRipple
                variant="contained"
                onClick={handleShowAdditionalInfo}
                sx={{
                  mt: 1,
                  textTransform: "none",
                  width: "200px",
                  borderRadius: "6px",
                }}
              >
                {loadingProfile ? "Loading..." : "Additional Info"}
              </Button>
            </span>
          </Tooltip>
          {personConnections?.length > 0 && (
            <ConnectionsInPersonPopup
              personConnections={personConnections}
              allConnections={Object.values(connections)}
              orgName={orgName}
              companyName={companyName}
            ></ConnectionsInPersonPopup>
          )}
        </Box>
      </Menu>
    </Box>
  );
}

/**
 * Helps so images don't have to reload when the search or sort are changed.
 */
export const MemoPerson = React.memo(Person, (prevProps, nextProps) => {
  if (prevProps.row.personId === nextProps.row.personId) {
    return true;
  }
  return false;
});
