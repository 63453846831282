import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar, useTheme } from "@mui/material";
import ManageSfdcAccountPopup from "../modal/ManageSfdcAccountPopup";
import Image from "../common/Image";
import { getColorFromString } from "../common/Utils";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import Toolbar from "../common/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { useDialog } from "../context/DialogContext";
import CustomSnackbar from "../common/CustomSnackbar";
import { rule5properties } from "../properties";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { COREAPP_USER_ROLES, useUser } from "../context/UserContext";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

LicenseInfo.setLicenseKey(rule5properties.MUIXProLicenseKey);

const defaultRow = [
  {
    id: -1,
    accountName: "",
    companyName: "",
    domain: "",
    salesForceId: "",
  },
];

const useStyles = makeStyles(() => ({
  tableCellTrucate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  actionGroup: {
    marginLeft: "2%",
    marginTop: "1%",
    marginBottom: "1%",
    width: "95%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  spinnerContainer: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    zIndex: 1001,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.7)",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  spinner: {
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  root: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginLeft: "30px",
      marginRight: "30px",
    },
    card: {},
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "hidden",
      lineHeight: "20px",
      whiteSpace: "normal",
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "100px",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      height: "56px",
      color: "#999CA0",
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
          "& .MuiDataGrid-menuIcon": {
            "& .MuiButtonBase-root": {
              color: "#999CA0",
            },
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
            marginLeft: "-6px",
            "& .MuiDataGrid-iconButtonContainer": {
              "& .MuiButtonBase-root": {
                color: "#999CA0",
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      "& .MuiTablePagination-root": {
        color: "rgba(0,0,0,0.7)",
        "& .MuiSelect-select": {
          color: "#000000",
          border: "1px solid #F0F0F0",
        },
        "& .MuiSelect-icon": {
          // color: theme.common.selectIconColor,
        },
        "& .MuiTablePagination-actions": {
          "& .MuiButtonBase-root": {
            "& .MuiSvgIcon-root": {
              color: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.01)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiGridPanelWrapper-root": {
      opacity: 0.1,
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,1)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiDataGrid-pinnedColumns--left": {
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,1)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none",
    },
  },
  columnText: {
    color: "#000",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5,
  },
  menuButton: {
    minWidth: "10px",
  },
  summaryCard: {
    height: "72px",
    marginBottom: "16px",
  },
  toolbarButton: {
    padding: "10px 18px 10px 18px",
    margin: "7px",
    color: "rgba(0,0,0,0.5)",
    borderRadius: "8px",
    textTransform: "none",
  },
  avatar: {
    cursor: "pointer",
    width: "38px",
    height: "38px",
    transition: "0.2s",
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: "20px",
    "-webkit-transition": "-webkit-filter 500ms linear",
    "&:hover": {
      "-webkit-filter": "brightness(90%)",
    },
  },
}));

export default function ManageSfdcAccounts(props) {
  const classes = useStyles();
  const dialog = useDialog();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState(defaultRow);
  const [token, setToken] = useState("");
  const [pageSize, setPageSize] = React.useState(50);
  const [sfdcAccounts, setSfdcAccounts] = useState([]);
  const user = useUser();
  const theme = useTheme();

  useEffect(() => {
    setToken(user.accessToken);
    getUsers();
  }, []);

  function getUsers() {
    setIsLoading(true);

    if (
      user.role !== COREAPP_USER_ROLES.orgAdmin &&
      user.role !== COREAPP_USER_ROLES.salesRep
    ) {
      setIsLoading(false);
      setSnackbarMessage("You don't have permission.");
      setOpenSnackbar(true);
      return;
    }

    let accounts;
    let salesforceAccounts;
    axios
      .get(rule5properties.accounts, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 403) {
          setIsLoading(false);
          setSnackbarMessage("You don't have permission.");
          setOpenSnackbar(true);
          return;
        }

        if (response.data && response.data.accounts.length > 0) {
          salesforceAccounts = response.data.sfdcAccounts;
          setSfdcAccounts(response.data.sfdcAccounts);
          accounts = response.data.accounts;
        }
      })
      .then(async () => {
        let id = 0;

        accounts.forEach((account) => {
          account.id = id;
          id += 1;
          if (account.hasMatch === true) {
            salesforceAccounts.find((sfdc) => {
              if (sfdc.Id === account.crmAccountId) {
                account.domain = sfdc.Website;
                account.companyName = sfdc.Name;
              }
            });
          }
        });

        setRows(accounts);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          setSnackbarMessage("There was a problem with listing users.");
          setOpenSnackbar(true);
        }
      });
  }

  const onSelectionChange = (event) => {
    const row = rows.find((row) => {
      return row.id === event[0];
    });

    if (user.crmSystem === "Salesforce" && row.hasMatch === undefined) {
      axios
        .get(rule5properties.accountMatch + "?accountId=" + row.accountId)
        .then((response) => {
          if (response.status === 200) {
            let accounts = sfdcAccounts;

            if (response.data !== "") {
              let element = accounts.find(
                (account) => account.Id === response.data.Id
              );

              if (element === undefined) {
                accounts.push(response.data);
              }
            }

            dialog.openModal("Account information", ManageSfdcAccountPopup, {
              updateCRM,
              recordForEdit: row,
              sfdcAccounts: accounts,
            });
          }
        });
    } else {
      dialog.openModal("Account information", ManageSfdcAccountPopup, {
        updateCRM,
        recordForEdit: row,
      });
    }
  };

  function updateCRM(account, resetForm) {
    setIsLoading(true);
    axios
      .patch(
        `${rule5properties.accountUpdate}`,
        {
          accountId: account.accountId,
          crmAccountId: account.crmAccountId,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dialog.closeModal();
          resetForm();
          setIsLoading(false);
          getUsers();
          return;
        }
        setIsLoading(false);
        setSnackbarMessage("There was a problem with updating user.");
        setOpenSnackbar(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          setSnackbarMessage("There was a problem with updating user.");
          setOpenSnackbar(true);
        }
      });
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{ position: "relative", left: "15px", justifyContent: "flex-end" }}
      >
        <GridToolbarFilterButton className={classes.toolbarButton} />
        <div style={{ flex: 1 }}> </div>
        <GridToolbarDensitySelector className={classes.toolbarButton} />
        <GridToolbarExport className={classes.toolbarButton} />
        <IconButton
          color="primary"
          aria-label="refresh"
          component="span"
          onClick={() => {
            getUsers();
          }}
          className={classes.toolbarButton}
        >
          <RefreshIcon />
        </IconButton>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "profileAvatar",
      headerName: "",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      width: 80,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.company.companyInfo.icon || ""}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.company.companyInfo?.icon !== undefined ? (
                  <Image
                    src={cellValues.row.company.companyInfo.icon}
                    style={{
                      height: "auto",
                      maxWidth: 40,
                      maxHeight: 40,
                      display: "block",
                    }}
                  />
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: getColorFromString(
                        cellValues.row.name + cellValues.row.company.companyId
                      ),
                    }}
                  >
                    {cellValues.row.name.charAt(0)}
                  </Avatar>
                )}
              </span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "name",
      headerName: "Account Name",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.accountName || ""}>
            <span className={classes.tableCellTrucate}>
              <Typography sx={{ textTransform: "capitalize" }}>
                {cellValues.row.name}
              </Typography>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "domain",
      headerName: "Domain",
      width: 280,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.domain || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.domain}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "companyName",
      headerName: "Salesforce Account Name",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.companyName || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.companyName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "crmAccountId",
      headerName: "Salesforce Account Id",
      width: 280,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.crmAccountId || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.crmAccountId}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <div style={{ height: "80%", width: "100%" }}>
        <Toolbar>
          <div className={classes.title}>Manage Accounts</div>
        </Toolbar>
        <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.spinner} />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        maxWidth: "100%",
        paddingBottom: `${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar>
        <div className={classes.title}>Manage Accounts</div>
      </Toolbar>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          pt: 1,
          borderRadius: "8px",
          height: "calc(100vh - " + theme.toolbar.height + "px - 20px)",
          paddingBottom: `${theme.workspace.commonPadding}px`,
          flexDirection: "column",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        {!isLoading && (
          <div
            style={{
              marginLeft: "2%",
              marginRight: "2%",
              height: "100%",
            }}
          >
            <DataGridPro
              initialState={{
                sorting: {
                  sortModel: [{ field: "firstName", sort: "asc" }],
                },
              }}
              components={{
                Toolbar: CustomToolbar,
              }}
              className={classes.root}
              rowHeight={theme.opportunityList.rowHeight}
              rows={rows}
              columns={columns}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableColumnSelector={true}
              onRowSelectionModelChange={onSelectionChange}
              disableMultipleSelection={true}
            />
          </div>
        )}

        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Card>
    </div>
  );
}
