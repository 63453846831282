import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CompanyAvatar } from "./ChatLandingPage";

const useStyles = makeStyles(() => ({
  promptTile: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(227 239 248)",
    border: "none",
    borderRadius: "12px",
    outline: "none",
    padding: "15px",
    height: "100%",
    minHeight: "150px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ddebf7",
    },
    "&:focus": {
      backgroundColor: "#ddebf7",
    },
  },
  questionText: {
    color: " rgb(75 106 133)",
    width: "100%",
    textAlign: "center",
    marginBottom: "12px",
    textOverflow: "ellipsis",
  },
}));

export default function PromptTile({
  prompt,
  tileOpptyCompany,
  selectedCompany,
  isContentUser,
  handleNewPrompt,
  setPromptText,
  setSelectedContents,
  setSelectedCardTypes,
  functionalArea,
}) {
  const classes = useStyles();

  const handleClick = () => {
    if (prompt?.contents) {
      setSelectedContents(prompt.contents);
    }
    if (prompt?.cardTypes) {
      setSelectedCardTypes({
        types:
          prompt?.cardTypes &&
          !(
            prompt?.cardTypesContext === "Include All" ||
            prompt?.cardTypesContext === "Exclude All"
          )
            ? prompt?.cardTypes?.map((cardType) => cardType.type)
            : [],
        excludeAll: prompt?.cardTypesContext === "Exclude All",
      });
    }

    if (isContentUser) {
      setPromptText(prompt);
    } else if (tileOpptyCompany) {
      const tileFunctionalArea =
        functionalArea || tileOpptyCompany?.oppties[0]?.functionalArea;
      const requestFields = {
        prompt: prompt?.prompt || prompt,
        action: "userPrompt",
        companyId:
          selectedCompany?.companyId?.toString() ||
          tileOpptyCompany?.companyId?.toString(),
        functionalArea: tileFunctionalArea,
        opportunityId:
          selectedCompany?.oppties
            ?.find((oppty) => oppty.functionalArea === functionalArea)
            ?.id?.toString() || tileOpptyCompany?.oppties?.[0]?.id?.toString(),
        ...(prompt?.contents
          ? {
              contentIds: prompt.contents.map((content) => content.contentId),
            }
          : {}),
        ...(prompt?.cardTypes
          ? {
              cardTypes: prompt.cardTypes.map((cardType) => cardType.type),
            }
          : {}),
      };
      handleNewPrompt({
        requestFields: requestFields,
        company: selectedCompany || tileOpptyCompany,
        functionalArea: tileFunctionalArea,
      });
    }
  };

  return (
    <button className={classes.promptTile} onClick={handleClick}>
      {tileOpptyCompany && !selectedCompany && (
        <CompanyAvatar
          name={tileOpptyCompany?.companyInfo?.name}
          icon={tileOpptyCompany?.companyInfo?.icon}
        />
      )}
      <Typography
        className={classes.questionText}
        sx={{ fontWeight: prompt?.name ? "600" : "normal" }}
      >
        {prompt?.name || prompt}
      </Typography>
    </button>
  );
}
