import React from "react";
import { Chip } from "@mui/material";

export default function LabelChip(props) {
  const { isHighlighted, label, deletePromptLabels } = props;

  let bgColor = label?.labelInfo?.color;
  let color = label?.labelInfo?.color ? "white" : "#525252";

  if (isHighlighted) {
    bgColor = "#FFCE88";
    color = "inherit";
  }

  return (
    <Chip
      size="small"
      label={label.name}
      onDelete={deletePromptLabels ? deletePromptLabels : undefined}
      sx={{
        fontSize: "12px",
        height: "24px",
        width: "fit-content",
        backgroundColor: bgColor,
        color: color,
        cursor: "inherit",
      }}
    />
  );
}
