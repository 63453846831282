import React from "react";
import { useFormContext } from "react-hook-form";
import { TitleInput } from "../util/CardEditorButtons";

export default function DiscoveryEditor(props) {
  const { register } = useFormContext();

  return (
    <div>
      <TitleInput register={register} />
    </div>
  );
}
