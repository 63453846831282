import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { convertFromRaw, EditorState } from "draft-js";
import Image from "../../../../common/Image";
import RichTextReader from "../../../../common/RichTextReader";

const useStyles = makeStyles(() => ({
  flexContainer: {
    position: "relative",
    marginBottom: "20px",
    border: "1px solid #EEE",
    borderRadius: "5px",
    textAlign: "left",
    padding: 16,
    paddingBottom: 0,
  },
  authorContent: {
    color: "green",
  },
  newsImage: {
    width: "120px",
    float: "left",
    marginRight: "10px",
  },
  logo: {
    maxHeight: "30px",
  },
  competitorName: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function Competitor(props) {
  const classes = useStyles();
  const row = props.row;
  const index = props.index;
  const alt = "Competitor#" + (index + 1);

  return (
    <div className={classes.flexContainer}>
      <div className={classes.competitorName}>
        {!row.Logo ? (
          <Typography variant="h6" gutterBottom component="div">
            {row.Name}
          </Typography>
        ) : (
          <Image src={row.Logo} alt={alt} className={classes.logo} />
        )}
      </div>
      <div style={{ padding: "30px 0px" }}>
        <RichTextReader
          editorState={EditorState.createWithContent(
            convertFromRaw(row.Overview)
          )}
        />
      </div>
      {row.Items.map((item, itemIndex) => {
        const itemAlt = "CompetitorItem#" + itemIndex;
        return (
          <>
            {item.Thumbnail && (
              <Image
                src={item.Thumbnail}
                alt={itemAlt}
                className={classes.newsImage}
              />
            )}
            <Typography variant="subtitle2" gutterBottom component="div">
              {item.Link ? (
                <a href={item.Link} target="_blank" rel="noreferrer">
                  {item.Title}
                </a>
              ) : (
                item.Title
              )}
            </Typography>
            {item.Authors && (
              <Typography
                variant="caption"
                className={classes.authorContent}
                gutterBottom
                component="div"
              >
                {item.Authors.map((authorItem, authorIndex) => {
                  if (item.Authors.length > 1 && authorIndex > 0) {
                    if (authorIndex + 1 === item.Authors.length) {
                      return " & " + authorItem;
                    } else return ", " + authorItem;
                  } else return authorItem;
                })}
              </Typography>
            )}
            <Typography variant="body1" gutterBottom component="div">
              {item.Source.map((sourceRow, sourceIndex) => {
                return (
                  <div key={sourceIndex}>
                    {sourceRow.Name + " - " + item.PublishedDate}&nbsp;
                    <a href={sourceRow.URL} target="_blank" rel="noreferrer">
                      (Source)
                    </a>
                  </div>
                );
              })}
            </Typography>
            <Typography variant="body2">{item.Content}</Typography>
            <br></br>
          </>
        );
      })}
    </div>
  );
}

export default function CompetitionIntelligence(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [competitorsData, setCompetitorsData] = React.useState(null);
  const [firstTime, setFirstTime] = React.useState(true);
  const previewData = props.previewData;

  useEffect(() => {
    let isMounted = true; // note mutable flag
    if (previewData) {
      // Call Stub
      setCompetitorsData(previewData);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId;
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            if (isMounted) {
              let rawdata = response.data;
              if (rawdata.Trend) {
                // Validate content
                setCompetitorsData(rawdata);
              } else {
                // API call failed
                setSnackbarMessage(
                  "There was a problem with the " + props.cardType + " Card"
                );
                setOpenSnackbar(true);
              }
            }
          })
          .catch((error) => {
            if (error.response && isMounted) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
    return () => {
      isMounted = true;
    };
  }, [firstTime, previewData]);

  if (!competitorsData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={competitorsData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        {competitorsData.Competitors.map((row, index) => {
          return <Competitor key={index} row={row} index={index} />;
        })}
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
}
