import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { rule5properties } from "../properties";
import React from "react";
import { isSuccessStatus } from "../common/RequestUtils";

/** Factory to create react query agent fetcher. */
const createAgentHook = (agentType) => {
  const fetchAgents = async (searchValue) => {
    const params = new URLSearchParams();
    params.append("type", agentType);
    if (searchValue) {
      params.append("searchValue", encodeURIComponent(searchValue));
    }
    const response = await axios.get(
      `${rule5properties.agents}?${params.toString()}`
    );
    return response.data;
  };

  return (initialSearchValue = "") => {
    const queryClient = useQueryClient();
    const [staleTime, setStaleTime] = React.useState(5 * 60 * 1000);
    console.log("initialSearchValue:" + initialSearchValue + ".");

    const queryKey = [agentType, initialSearchValue];

    const {
      data: rows,
      isFetching,
      error,
      refetch,
    } = useQuery(
      queryKey,
      React.useCallback(
        () => fetchAgents(initialSearchValue),
        [initialSearchValue]
      ),
      {
        staleTime: staleTime,
        onError: (err) => console.log(err),
        enabled: initialSearchValue.length !== 1,
        placeholderData: [],
        refetchInterval: (data) => {
          if (!Array.isArray(data)) {
            return false;
          }
          const hasProcessingStatus = data?.some(
            (row) => row.monitorStatus === "Processing"
          );
          console.log("hasProcessign: " + hasProcessingStatus);
          return hasProcessingStatus ? 30000 : false;
        },
      }
    );

    return {
      rows,
      isFetching,
      error,
      refetch,
    };
  };
};

// TODO WIP
export const useAccountAgents = createAgentHook("Accounts");
export const usePeopleAgents = createAgentHook("People");

export const useAccountAgentsByCompany = (company) => {
  const companyId = company?.companyId || company?.id;

  return useQuery(
    ["companyAgents", companyId],
    async () => {
      const response = await axios.get(
        `${rule5properties.agentAccounts}?companyId=${companyId}`
      );
      if (!isSuccessStatus(response.status)) {
        throw new Error("Unexpected error loading agents.");
      }
      return response.data;
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!companyId,
      onError: (error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
        }
      },
    }
  );
};

export const useSyncedResearchAccounts = () => {
  return useQuery(
    "syncedResearchAccounts",
    async () => {
      const response = await axios.get(rule5properties.syncAccounts);
      if (response.status !== 200 || !response.data) {
        throw new Error("Failed to fetch synced research accounts");
      }
      return response.data;
    },
    {
      select: (data) => data.length > 0,
      onError: (error) => {
        console.error("Error fetching synced research accounts:", error);
      },
    }
  );
};
