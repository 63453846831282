import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { rule5properties } from "../properties";

const fetchCampaigns = async () => {
  const response = await axios.get(rule5properties.campaigns);
  return response.data;
};

export const useCampaigns = () => {
  return useQuery("campaigns", fetchCampaigns, {
    staleTime: 60000, // Consider data fresh for 1 minute
    placeholderData: [],
  });
};

export const useCampaignResults = (campaignId) => {
  return useQuery(
    ["campaignResults", campaignId],
    async () => {
      try {
        const url = new URL(rule5properties.campaignResults);

        if (campaignId != null) {
          url.searchParams.append("campaignId", campaignId);
        }

        const campaignResultsResponse = await axios.get(url.toString());

        if (campaignResultsResponse.data) {
          return campaignResultsResponse.data;
        }
        throw new Error("No results found");
      } catch (error) {
        console.error("Error loading results:", error);
        throw error;
      }
    },
    {
      staleTime: 60000 * 5, // Consider data fresh for 5 minutes);
    }
  );
};

export const useCampaignErrors = (campaignId) => {
  return useQuery(
    ["campaignErrors", campaignId],
    async () => {
      try {
        const campaignErrorsResponse = await axios.get(
          `${rule5properties.campaignErrors}`,
          {
            params: { campaignId },
          }
        );
        if (campaignErrorsResponse.data) {
          return campaignErrorsResponse.data;
        }
        throw new Error("No errors found");
      } catch (error) {
        console.error("Error loading errors:", error);
        throw error;
      }
    },
    {
      enabled: !!campaignId,
      staleTime: 60000 * 5, // Consider data fresh for 5 minutes)
    }
  );
};
