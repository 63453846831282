import {
  Box,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import { scrollbarStyleThin } from "../common/StyledComponents";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import FeedbackForm from "../components/FeedbackForm";
import { copyMixedHtml } from "../common/Utils";
import { rule5properties } from "../properties";

export default function ViewCampaignResult(props) {
  const { campaignResult, setFeedbackById, refetchRows } = props;

  const [localFeedbackInfo, setLocalFeedbackInfo] = React.useState(
    campaignResult.feedbackInfo
  );

  const [feedbackMode, setFeedbackMode] = React.useState();
  const [copyStatus, setCopyStatus] = React.useState("Copy message");

  function toggleFeedbackMode() {
    setFeedbackMode(!feedbackMode);
  }

  function copyMessage() {
    copyMixedHtml(campaignResult.personalizedMessage);
    setCopyStatus("Copied!");
    setTimeout(() => {
      setCopyStatus("Copy message");
    }, 2000);
  }

  return (
    <Stack
      sx={{ height: "auto", overflow: "hidden" }}
      flexDirection="column"
      alignItems="start"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography variant="modalHeader1">
          {campaignResult.campaignName}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mt: 0.5, width: "100%" }}
      >
        <Typography variant="modalHeader2">
          {"Subject: "}
          {campaignResult.emailSubject}
        </Typography>
      </Stack>
      <Typography variant="modalHeader2">{"Personalized message: "}</Typography>
      <Box
        style={{
          width: "100%",
          backgroundColor: "rgba(0,0,0,.03)",
          margin: "16px 0px 8px 0px",
          padding: "16px 24px",
          borderRadius: "8px",
          overflow: "auto",
          whiteSpace: "pre-wrap",
          maxHeight: "60vh",
        }}
        sx={scrollbarStyleThin}
        dangerouslySetInnerHTML={{ __html: campaignResult.personalizedMessage }}
      ></Box>
      {!feedbackMode && (
        <Stack direction="row" justifyContent="space-between">
          <Button
            endIcon={<ContentCopyIcon />}
            onClick={copyMessage}
            sx={{ width: "140px", mr: 1 }}
          >
            {copyStatus}
          </Button>
          <Button
            endIcon={<RateReviewOutlinedIcon />}
            onClick={toggleFeedbackMode}
          >
            Feedback
          </Button>
        </Stack>
      )}
      {feedbackMode && (
        <FeedbackForm
          defaultFeedbackInfo={localFeedbackInfo}
          setLocalFeedbackInfo={setLocalFeedbackInfo}
          toggleFeedbackMode={toggleFeedbackMode}
          selectedId={campaignResult.campaignResultId}
          setFeedbackById={setFeedbackById}
          resultsUrl={rule5properties.campaignResults}
          refetchRows={refetchRows}
        />
      )}
    </Stack>
  );
}
