import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Toolbar from "../common/Toolbar";
import Box from "@mui/system/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { rule5properties } from "../properties";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Image from "../common/Image";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(() => ({
  container: {
    overflow: "hidden",
  },
  workspace: {
    maxWidth: "100%",
    overflowY: "auto",
    padding: "0px",
    backgroundColor: "#FAFAFA",
    color: "#000000",
    height: "calc(100vh - 72px)",
    boxShadow: "inset 0 0 1px rgb(0,0,0,.2)",
  },
}));

export default function ManageOrgs(props) {
  const classes = useStyles();
  const [orgs, setOrgs] = React.useState(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm();
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [response, setResponse] = React.useState(null);
  const [icon, setIcon] = React.useState(null);
  const [logo, setLogo] = React.useState(null);
  const theme = useTheme();

  useEffect(() => {
    axios.get(rule5properties.detOrgs).then((response) => {
      setOrgs(response.data);
    });
  }, [response]);

  if (!orgs) {
    return "Loading...";
  }

  const onSubmitOrg = (data) => {
    // Kinda hacky chained request. Two different endpoints are used.
    // TODO ideally should b checking if auto share is a dirtyField using react-hook-form formState
    axios
      .patch(rule5properties.detOrgs, {
        auto_share_opportunities: watch("auto_share_opportunities"),
        id: data.id,
      })
      .catch(function (error) {
        alert("ERROR: " + JSON.stringify(error));
        setIsSuccess(false);
      })
      .then((response1) => {
        // Call the image api if both icon and logo are present.
        // TODO Using FormData as below isn't the right way to use react-hook-form
        if (data.icon1[0] && data.logo1[0]) {
          const formData = new FormData();
          formData.append("icon", data.icon1[0]);
          formData.append("logo", data.logo1[0]);
          formData.append("id", data.id);
          axios
            .patch(rule5properties.detOrgsImage, formData)
            .catch(function (error) {
              alert("ERROR: " + JSON.stringify(error));
              setIsSuccess(false);
            })
            .then((response) => {
              setResponse(response.data);
              setIsSuccess(true);
              setIcon(response.data.icon);
              setLogo(response.data.logo);
            });
        } else {
          setResponse(response1.data.organization_info);
          setIsSuccess(true);
        }
      });
  };

  return (
    <div
      style={{
        height: "100%",
        maxWidth: "100%",
        paddingBottom: `${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar>
        <div className={classes.title}>Manage Seller Companies</div>
      </Toolbar>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          // pt: 1,
          borderRadius: "8px",
          flexDirection: "column",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        {isSuccess && (
          <div>
            <Typography variant="h5" sx={{ padding: "15px" }}>
              Successfully updated details for {response.name}!
            </Typography>
            <Typography variant="body2" sx={{ paddingLeft: "15px" }}>
              {response.icon}
            </Typography>
            <Typography variant="body2" sx={{ paddingLeft: "15px" }}>
              {response.logo}
            </Typography>
          </div>
        )}

        <Box sx={{ padding: "15px" }}>
          <Box
            sx={{
              "& .MuiTextField-root": { width: "225px" },
              maxWidth: "800px",
              padding: "20px",
            }}
          >
            {icon != null && (
              <div style={{ display: "flex", paddingBottom: "15px" }}>
                Icon:
                <Image
                  src={icon}
                  style={{
                    height: "auto",
                    maxWidth: 40,
                    maxHeight: 40,
                    display: "block",
                  }}
                />
              </div>
            )}
            {logo != null && (
              <div style={{ display: "flex", paddingBottom: "15px" }}>
                Logo:
                <Image
                  src={logo}
                  style={{
                    height: "auto",
                    maxWidth: 160,
                    maxHeight: 400,
                    display: "block",
                  }}
                />
              </div>
            )}
            <Typography variant="h5" sx={{ paddingBottom: "30px" }}>
              Set seller company details
            </Typography>
            <form onSubmit={handleSubmit(onSubmitOrg)}>
              <Controller
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    options={orgs.map((row, index) => {
                      return { label: row.name, id: row.id };
                    })}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField {...params} label="Seller Company" />
                    )}
                    onChange={(e, data) => {
                      setIsSuccess(false);
                      if (data) {
                        const organizationInfo = orgs.find(
                          (org) => org.id === data.id
                        )?.organizationInfo;
                        setValue(
                          "auto_share_opportunities",
                          organizationInfo?.auto_share_opportunities
                        );
                        if (organizationInfo?.logo && organizationInfo?.icon) {
                          setLogo(organizationInfo.logo);
                          setIcon(organizationInfo.icon);
                        } else {
                          setLogo(null);
                          setIcon(null);
                        }
                      } else {
                        setLogo(null);
                        setIcon(null);
                        setValue("auto_share_opportunities", false);
                      }
                      return onChange(data ? data.id : null);
                    }}
                  />
                )}
                defaultValue={null}
                name="id"
                control={control}
                rules={{
                  required: { value: true, message: "Org is required" },
                }}
              />
              {errors?.id && <p>{errors.id.message}</p>}
              <div style={{ marginTop: "20px" }}>
                <input
                  {...register(`auto_share_opportunities`)}
                  type="checkbox"
                />
                <label>Auto share opportunities</label>
              </div>
              <h4>Select logo</h4>
              <input
                type="file"
                {...register(`logo1`)}
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
              <br />
              <h4>Select Icon</h4>

              <input
                type="file"
                {...register(`icon1`)}
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
              <br />
              <br />
              <Button
                style={{ textTransform: "none", marginTop: "1%" }}
                variant="contained"
                type="submit"
              >
                Update details
              </Button>
            </form>
          </Box>
        </Box>
      </Card>
    </div>
  );
}
