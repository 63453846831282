import { Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useDialog } from "../context/DialogContext";
import { rule5properties } from "../properties";
import { EMPTY_CONVERSATION } from "../workspaces/WSChat";
import { useHistory } from "react-router-dom";

export default function DeleteConversation(props) {
  const { name, id, setConversation } = props;
  const history = useHistory();
  const dialog = useDialog();

  return (
    <React.Fragment>
      <Stack sx={{ alignItems: "center" }}>
        <Typography textAlign="left" color="#525252" width="100%">
          Are you sure you would like to delete this conversation?
        </Typography>
        {name && (
          <Typography
            textAlign="left"
            color="#525252"
            width="100%"
            padding="15px 0px"
            fontWeight="600"
          >
            {name}
          </Typography>
        )}
        <div style={{ alignSelf: "end" }}>
          <Button
            sx={{
              marginTop: "20px",
              marginRight: "20px",
              textTransform: "none",
            }}
            color="primary"
            variant="outlined"
            onClick={() => dialog.closeModal()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ textTransform: "none", marginTop: "20px" }}
            onClick={(event) => {
              axios
                .delete(`${rule5properties.conversations}/${id}`)
                .then((resp) => {
                  history.push(`/main/chat`);
                  dialog.closeModal();
                });
            }}
          >
            Delete
          </Button>
        </div>
      </Stack>
    </React.Fragment>
  );
}
