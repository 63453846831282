import axios from "axios";
import { rule5properties } from "../../properties";

export const removeCard = (cardId, research, onUpdate) => {
  let updateData = research.cardInfo.info;
  const toRemove = updateData.findIndex((researchCard) => {
    return researchCard.cardId === parseInt(cardId);
  });

  if (toRemove === -1) {
    return;
  }
  updateData.splice(toRemove, 1);
  let updateDataCopy = {
    info: updateData.map((researchCard) => {
      if (researchCard.index > toRemove) {
        researchCard.index -= 1;
      }
      return researchCard;
    }),
  };
  axios
    .patch(rule5properties.detResearch, {
      id: research.id,
      card_info: updateDataCopy,
    })
    .then((response) => {
      onUpdate(response.data);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      }
    });
};

export const addCard = (card, research, onUpdate) => {
  let updateData = research.cardInfo.info || [];
  const toReplace = updateData.findIndex((researchCard) => {
    return researchCard.cardType === card.type;
  });
  if (toReplace !== -1) {
    updateData[toReplace].cardId = parseInt(card.id);
  } else {
    updateData.push({
      hide: false,
      index: updateData.length,
      cardId: parseInt(card.id),
      viewed: false,
      cardType: card.type,
      originalCardId: parseInt(card.originalCardId),
    });
  }
  let updateDataCopy = {
    info: updateData,
  };
  axios
    .patch(rule5properties.detResearch, {
      id: research.id,
      card_info: updateDataCopy,
    })
    .then((response) => {
      onUpdate(response.data);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
      }
    });
};
