import { Autocomplete, Box, Collapse, Stack, Typography } from "@mui/material";
import { rule5properties } from "../../properties";
import { COREAPP_USER_ROLES, useUser } from "../../context/UserContext";
import {
  CancelConfirmButtons,
  ErrLabel,
  GrayCircularProgress,
  StyledTextfield,
} from "../../common/StyledComponents";
import axios from "axios";
import outreachLogo from "../../res/outreach_logo.webp";
import React from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { max } from "lodash";
import {
  useOutreachConnection,
  useOutreachLabels,
  useOutreachMappings,
  useUpdateOutreachMappings,
} from "../../api/integrations";
import { joiResolver } from "@hookform/resolvers/joi";
import { outreachFormSchema } from "./outreachFormSchema";
import { styled } from "@mui/material/styles";

const GridBox = styled(Box)({
  display: "grid",
  gridTemplateColumns: "150px 400px 400px",
  gap: "20px",
  alignItems: "center",
});

export function OutreachIntegration(props) {
  const { data: configuredOutreachConnection } = useOutreachConnection();

  if (!configuredOutreachConnection) {
    return <AddToOutreachButton />;
  }

  return <OutreachMappingForm />;
}

function OutreachMappingForm(props) {
  const user = useUser();
  const disabled = user?.role !== COREAPP_USER_ROLES.orgAdmin;
  const { data: labels, isLoading: isLoadingLabels } = useOutreachLabels();
  const { data: outreachMappings, isLoading: isLoadingMappings } =
    useOutreachMappings();
  const updateMutation = useUpdateOutreachMappings();

  const methods = useForm({
    values: outreachMappings,
    resolver: joiResolver(outreachFormSchema),
  });

  const onSubmit = (updatedOutreachMappings) => {
    updateMutation.mutate(updatedOutreachMappings);
  };

  if (isLoadingLabels || isLoadingMappings) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "80%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GrayCircularProgress />
      </Stack>
    );
  }

  const values = methods.watch();

  const MIN_PARAPGRAPH_COUNT = 3;
  const maxParagraphCount = max([
    values?.prospect?.emailBodyParagraphs?.length,
    values?.account?.emailBodyParagraphs?.length,
    MIN_PARAPGRAPH_COUNT,
  ]);
  const duplicateError =
    methods.formState.errors?.prospect?.root?.message ||
    methods.formState.errors?.account?.root?.message;

  return (
    <FormProvider {...methods}>
      <Typography sx={{ gridColumn: "1 / span 3", mb: 5, mt: 1 }}>
        Personalized messages generated by rule5 can be sent via email through
        Outreach. Complete the field mapping below, and rule5 will generate
        personalized email content and store it in the selected Outreach custom
        fields, ready for use in sequences.
      </Typography>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          overflow: "auto",
        }}
      >
        <GridBox sx={{}}>
          <div></div>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography
              sx={{ textAlign: "left", fontWeight: 500, flexGrow: 1 }}
            >
              Prospect personalization field
            </Typography>
            <Typography sx={{ textAlign: "left", fontWeight: 500 }}>
              Custom field
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography
              sx={{ textAlign: "left", fontWeight: 500, flexGrow: 1 }}
            >
              Account personalization field
            </Typography>
            <Typography sx={{ textAlign: "left", fontWeight: 500 }}>
              Custom field
            </Typography>
          </Box>
        </GridBox>
        <OutreachMappingRow
          name={"emailSubject"}
          title={"Email subject"}
          labels={labels}
          disabled={disabled}
        />
        {Array(maxParagraphCount)
          .fill()
          .map((_, i) => {
            return (
              <OutreachMappingRow
                name={`emailBodyParagraphs[${i}].fieldName`}
                title={`Email paragraph ${i + 1}`}
                labels={labels}
                disabled={disabled}
                key={i}
              />
            );
          })}
        <Stack sx={{ width: "100%" }} alignItems="center">
          <Collapse in={methods.formState.isDirty && !disabled}>
            <CancelConfirmButtons
              sx={{ mt: 4, width: "360px" }}
              cancelText="Cancel"
              cancelFunction={() => {
                methods.reset();
              }}
              confirmText="Save changes"
              confirmDisabled={methods.formState.errors?.length > 0}
            />
          </Collapse>
          {duplicateError && (
            <ErrLabel sx={{ mt: 1 }}>{duplicateError}</ErrLabel>
          )}
        </Stack>
      </form>
      {disabled && (
        <Typography sx={{ opacity: 0.7, mt: 2, textAlign: "center" }}>
          Only organization admins can update Outreach integration details.
        </Typography>
      )}
    </FormProvider>
  );
}

function OutreachMappingRow(props) {
  const { title, labels, name, disabled } = props;
  const { watch } = useFormContext();

  return (
    <>
      <GridBox>
        <Typography>{title}</Typography>
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <OutreachLabelSelector
            name={`prospect.${name}`}
            type="Prospect custom field"
            options={labels.prospects}
            disabled={disabled}
          />
          <Typography sx={{ opacity: 0.6, minWidth: "92px" }}>
            {wrapParens(watch(`prospect.${name}`))}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <OutreachLabelSelector
            name={`account.${name}`}
            type="Account custom field"
            options={labels.accounts}
            disabled={disabled}
          />
          <Typography sx={{ opacity: 0.6, minWidth: "92px" }}>
            {wrapParens(watch(`account.${name}`))}
          </Typography>
        </Box>
      </GridBox>
    </>
  );
}

function wrapParens(string) {
  if (!string) {
    return "";
  }

  return "" + string + "";
}

function OutreachLabelSelector(props) {
  const { options, type, name, disabled } = props;
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          {...field}
          sx={{ width: "100%" }}
          disabled={disabled}
          value={
            field.value
              ? {
                  labelName: options.find(
                    (option) => option.fieldName === field.value
                  )?.labelName,
                  fieldName: field.value,
                }
              : null
          }
          options={options}
          getOptionLabel={(option) => {
            return option.labelName;
          }}
          isOptionEqualToValue={(option, value) => {
            return option.fieldName === value.fieldName;
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Typography>{option.labelName}</Typography>
            </li>
          )}
          renderInput={(params, sup, yo) => {
            const { InputLabelProps, ...rest } = params;
            const InputProps = {
              ...params.InputProps,
            };
            return (
              <>
                <StyledTextfield
                  autoFocus
                  placeholder={type}
                  {...rest}
                  {...InputProps}
                  {...params}
                />
              </>
            );
          }}
          onChange={(_, data) => {
            field.onChange(data ? data.fieldName : null);
            return data;
          }}
        />
      )}
      name={name}
      control={control}
    />
  );
}

export function AddToOutreachButton(props) {
  return (
    <button
      onClick={() => {
        axios.get(rule5properties.outreachLogin).then((res) => {
          window.open(res.data.redirectUrl, "_blank");
        });
      }}
      style={{
        alignItems: "center",
        color: "black",
        backgroundColor: "rgb(222, 224, 231)",
        border: "0",
        borderRadius: "4px",
        display: "inline-flex",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        fontWeight: "600",
        height: "48px",
        justifyContent: "center",
        textDecoration: "none",
        width: "236px",
        cursor: "pointer",
      }}
    >
      <img
        src={outreachLogo}
        alt="outreach_logo"
        style={{ height: "20px", width: "20px", marginRight: "12px" }}
      />
      Add to Outreach
    </button>
  );
}
