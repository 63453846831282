import React, { useEffect } from "react";
import MainPage from "./MainPage.js";
import DetMainPage from "./det/DetMainPage.js";
import { useUser } from "./context/UserContext.js";
import { Redirect, useLocation } from "react-router-dom";
import JwtService from "./user/JwtService.js";

function AuthenticatedMain(props) {
  const user = useUser();
  return (
    <div style={{ overflow: "hidden" }}>
      {JwtService.getToken() && user ? (
        <MainPage
          switchTheme={props.switchTheme}
          themeIndex={props.themeIndex}
        />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { referrer: props.redirectUrl } }}
        />
      )}
    </div>
  );
}

function AuthenticatedDet(props) {
  const user = useUser();
  return (
    <div style={{ overflow: "hidden" }}>
      {JwtService.getToken() && user ? (
        <DetMainPage />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { referrer: props.redirectUrl } }}
        />
      )}
    </div>
  );
}

export { AuthenticatedMain, AuthenticatedDet };
