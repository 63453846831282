import React from "react";
import DetCardEditor from "../DetCardEditor";

export default function EditCardPopup(props) {
  const { card, reloadCallback } = props;

  return (
    <DetCardEditor
      location={{ state: { ...card, isDraftSaved: true } }}
      hideToolbar={true}
      reloadCallback={reloadCallback}
    />
  );
}
