import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Chip,
} from "@mui/material";
import Link from "@mui/material/Link";
import { rule5AppUrl } from "../../../properties";
import { Box } from "@mui/material";

export function PlatformGroup(props) {
  const { disabled, configuredOutreachConnection, name } = props;
  const { control } = useFormContext();

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="settingsCaption">
          Select SEP for message delivery.
        </Typography>
        {configuredOutreachConnection ? (
          <Link
            variant="settingsCaption"
            href={rule5AppUrl + "/main/settings/integrations"}
            target="_blank"
          >
            Click here to configure SEP integration.
          </Link>
        ) : (
          <Chip
            label="Click here to configure SEP integration"
            component="a"
            href={rule5AppUrl + "/main/settings/integrations"}
            target="_blank"
            clickable
            size="small"
            color="primary"
          />
        )}
      </Box>
      <Controller
        name={name}
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <RadioGroup {...field} sx={{ flexGrow: 1 }} row>
            <RadioOption
              value={"Outreach"}
              text={"Outreach"}
              disabled={disabled || !configuredOutreachConnection}
            />
            <RadioOption value={"Hubspot"} text={"Hubspot"} disabled={true} />
            <RadioOption
              value={"Salesloft"}
              text={"Salesloft"}
              disabled={true}
            />
          </RadioGroup>
        )}
      />
    </Box>
  );
}

function RadioOption(props) {
  const { value, text, disabled } = props;

  return (
    <FormControlLabel
      disabled={disabled}
      sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
      value={value}
      control={<Radio size="small" />}
      label={<CustomLabelWrapper>{text}</CustomLabelWrapper>}
    />
  );
}

const CustomLabelWrapper = ({ children, ...props }) => (
  <div {...props}>
    {typeof children === "function" ? children(props) : children}
  </div>
);

export default PlatformGroup;
