import React from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import thumbUp from "../../res/icons/outline/thumb-up.svg";
import thumbUpFill from "../../res/icons/fill/thumb-up.svg";
import thumbDownFill from "../../res/icons/fill/thumb-down.svg";
import thumbDown from "../../res/icons/outline/thumb-down.svg";
import fileCopy from "../../res/icons/outline/file-copy.svg";
import axios from "axios";
import { rule5properties } from "../../properties";
import {
  cardTypeToId,
  clipTextWithEllipsis,
  copyMixedHtml,
  getColorFromString,
} from "../../common/Utils";
import { useUser } from "../../context/UserContext";
import rule5logo from "../../res/rule5gpt_logo.svg";
import ChatNegativeFeedback from "../../modal/ChatNegativeFeedback";
import { useDialog } from "../../context/DialogContext";
import { useGptConversationContext } from "../../context/GptConversationContext";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";
import { StyledTextfield } from "../../common/StyledComponents";
import { useImmer } from "use-immer";
import { cloneDeep, isEqual } from "lodash";
import ChatVersionSelector from "./ChatVersionSelector";
import { isMobile } from "react-device-detect";
import AddToPromptBookButton from "./AddToPromptBooksButton";

export const RESPONSE_COLOR = "rgb(244,244,244)";
export const RESPONSE_COLOR_HOVER = "rgb(238,238,238)";
export const RESPONSE_COLOR_SELECTED = "#eaf0f9";
export const RESPONSE_COLOR_SELECTED_HOVER = "#dfe8f5";

const useStyles = makeStyles(() => ({
  message: {
    whiteSpace: "pre-line",
    overflowWrap: "anywhere",
    lineHeight: "28px",
    color: "#525252",
    display: "inline-block",
    "& ol, ul": {
      display: "flex",
      paddingLeft: "1rem",
      flexDirection: "column",
      margin: "auto",
    },
    "& li": {
      paddingLeft: "6px",
    },
    "& p": {
      margin: "0px",
    },
  },
  "@keyframes blinker": {
    to: { visibility: "hidden" },
  },
  processingMessage: {
    "& ol:last-child li:last-child::after, & ul:last-child li:last-child::after":
      {
        content: (props) =>
          (props.message?.unclosedTags?.includes("ol") ||
            props.message?.unclosedTags?.includes("ul")) &&
          '"▋"',
        display: "inline-block",
        animationName: "$blinker",
        animationDuration: "1s",
        animationTimingFunction: "steps(5,start)",
        animationIterationCount: "infinite",
        marginLeft: ".25rem",
        verticalAlign: "baseline",
      },

    "&::after": {
      content: (props) =>
        props.message?.isProcessing &&
        !(
          props.message?.unclosedTags?.includes("ol") ||
          props.message?.unclosedTags?.includes("ul")
        ) &&
        '"▋"',
      display: "inline-block",
      animationName: "$blinker",
      animationDuration: "1s",
      animationTimingFunction: "steps(5,start)",
      animationIterationCount: "infinite",
      marginLeft: ".25rem",
      verticalAlign: "baseline",
    },
  },
  messageContainer: {
    margin: "auto",
    maxWidth: "54rem",
    display: "flex",
    columnGap: "1.5rem",
    padding: "1rem",
    flexWrap: "wrap",
    alignItems: "center",
  },
  actionsContainer: {
    alignSelf: "end",
    display: "flex",
    gap: "10px",
    paddingRight: "10px",
    marginBottom: "10px",
  },
  emailPrompt: {
    minHeight: "72px",
    textAlign: "left",
    display: "inline-block",
    flexGrow: 1,
    border: "1px solid rgb(167,201,234)",
    borderRadius: "12px",
    padding: "20px",
    backgroundColor: "rgb(247,252,255)",
    margin: "12px 0px",
    color: "rgb(107,149,185)",
    width: "90%",
  },
  relevantCardsContainer: {
    marginTop: "10px",
    paddingTop: "10px",
    width: "100%",
    margin: "auto",
    borderBottom: "1px solid gainsboro",
    display: "flex",
    alignItems: "center",
    columnGap: "7px",
    flexWrap: "wrap",
    paddingBottom: "10px",
  },
}));

/**
 * Helps so existing messages don't have to reload when the processaging message is painting.
 */
export const MemoizedChatMessage = React.memo(
  ChatMessage,
  (prevProps, nextProps) => {
    if (
      !prevProps.message.isProcessing &&
      !nextProps.message.isProcessing &&
      prevProps.opportunityId === nextProps.opportunityId
    ) {
      return true;
    }
    return false;
  }
);

function ChatMessage(props) {
  const { message, userImage, handleNewPrompt, opportunityId } = props;
  const classes = useStyles(props);
  const user = useUser(); // TODO this isn't really right, should actually be the userId from conversation which is not necessarily current user
  const dialog = useDialog();
  const gptConversationContext = useGptConversationContext();

  const [rating, setRating] = React.useState(
    message?.promptCompletionInfo?.feedback?.rating
  );
  const [completionCopyText, setCompletionCopyText] =
    React.useState("Copy to clipboard");
  const [promptCopyText, setPromptCopyText] =
    React.useState("Copy to clipboard");

  let defaultEmailGenerationState = null;
  if (Array.isArray(message?.prompt)) {
    const defaultActivePrompt = message.prompt.length - 1;
    defaultEmailGenerationState = {
      activePromptIndex: defaultActivePrompt,
      activeCompletionIndex: message.completion
        ? message.completion?.[defaultActivePrompt]?.length - 1
        : 0,
      emailPrompt: message.prompt[defaultActivePrompt].emailPrompt,
    };
  }
  const [emailGenerationState, setEmailGenerationState] = useImmer(
    defaultEmailGenerationState
  );

  function removeLeadingNewlines(html) {
    if (!html?.replace) {
      return html;
    }
    return html?.replace(/^\n+/, "");
  }

  const isEditingMessage =
    gptConversationContext.selection.activePromptId === message.promptId;

  let numPrompts = null;
  let numCompletions = null;
  if (Array.isArray(message?.prompt)) {
    numPrompts = message.prompt.length;
    numCompletions =
      message.completion?.[emailGenerationState.activePromptIndex]?.length;
  }

  function handlePromptChange(newPromptIndex) {
    setEmailGenerationState((draft) => {
      draft.activePromptIndex = newPromptIndex;
      draft.activeCompletionIndex =
        message.completion?.[newPromptIndex]?.length - 1;
      draft.emailPrompt = message.prompt[newPromptIndex]?.emailPrompt;
    });
    if (isEditingMessage) {
      gptConversationContext.setSelection((draft) => {
        draft.selectedMessages =
          message.prompt[newPromptIndex]?.promptCompletionIds;
      });
    }
  }

  let completionHtml =
    emailGenerationState !== null
      ? removeLeadingNewlines(
          message?.completion?.[emailGenerationState?.activePromptIndex]?.[
            emailGenerationState?.activeCompletionIndex
          ]
        )
      : removeLeadingNewlines(message?.completion);

  const displayRelevantCards =
    message.prompt?.relevantCards &&
    !isMobile &&
    completionHtml !==
      "I don't know. I can only provide information available on the rule5 platform.";

  return (
    <>
      <div>
        {/* User prompt */}
        <div
          className={classes.messageContainer}
          style={{ flexWrap: "nowrap" }}
        >
          <Avatar
            variant="rounded"
            src={userImage}
            style={{
              backgroundColor: user.userSettingInfo?.profilePicture
                ? "rgba(0,0,0,0.3)"
                : getColorFromString(`${user.firstName} ${user.lastName}`),
              width: "40px",
              height: "40px",
              marginLeft: "auto",
              alignSelf: "start",
              marginTop: "8px",
            }}
          >
            {user?.firstName?.toUpperCase().charAt(0)}
          </Avatar>
          {emailGenerationState !== null &&
          message.prompt[emailGenerationState?.activePromptIndex]?.action ===
            "generateEmail" ? (
            <div className={classes.emailPrompt}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "50px 1fr",
                  marginBottom: "20px",
                }}
              >
                <LightbulbIcon
                  style={{
                    height: "32px",
                    width: "32px",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                />
                <Typography
                  fontSize="17px"
                  textAlign="left"
                  alignSelf="center"
                  display="inline-block"
                >
                  {`Generate an email using ${
                    message.prompt[emailGenerationState?.activePromptIndex]
                      .promptCompletionIds.length
                  } selected message${
                    message.prompt[emailGenerationState?.activePromptIndex]
                      .promptCompletionIds.length === 1
                      ? ""
                      : "s"
                  } and the following prompt.`}
                </Typography>
              </div>
              {isEditingMessage ? (
                <StyledTextfield
                  autoFocus
                  multiline
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(247,252,255)",
                    marginBottom: "20px",
                  }}
                  value={emailGenerationState.emailPrompt}
                  onChange={(event) =>
                    setEmailGenerationState((draft) => {
                      draft.emailPrompt = event.target.value;
                    })
                  }
                ></StyledTextfield>
              ) : (
                <Typography
                  textAlign="left"
                  display="inline-block"
                  marginBottom="20px"
                  backgroundColor="rgb(230,241,252)"
                  borderRadius="6px"
                  padding="20px"
                  width="100%"
                  lineHeight="28px"
                  whiteSpace="pre-line"
                >
                  <i>
                    {
                      message.prompt[emailGenerationState?.activePromptIndex]
                        .emailPrompt
                    }
                  </i>
                </Typography>
              )}
              <div style={{ display: "flex" }}>
                {/* TODO ternaries everywhere.. pretty unreadable */}
                {!isEditingMessage ? (
                  <>
                    {numPrompts > 1 && (
                      <ChatVersionSelector
                        handleNext={() =>
                          handlePromptChange(
                            emailGenerationState.activePromptIndex + 1
                          )
                        }
                        handleBack={() =>
                          handlePromptChange(
                            emailGenerationState.activePromptIndex - 1
                          )
                        }
                        activeStep={emailGenerationState.activePromptIndex}
                        maxSteps={message.prompt.length}
                        name="Prompt"
                      ></ChatVersionSelector>
                    )}
                    {numCompletions > 1 && (
                      <ChatVersionSelector
                        handleNext={() =>
                          setEmailGenerationState((draft) => {
                            draft.activeCompletionIndex += 1;
                          })
                        }
                        handleBack={() =>
                          setEmailGenerationState((draft) => {
                            draft.activeCompletionIndex -= 1;
                          })
                        }
                        activeStep={emailGenerationState?.activeCompletionIndex}
                        maxSteps={
                          message.completion[
                            emailGenerationState?.activePromptIndex
                          ]?.length
                        }
                        name="Response"
                      ></ChatVersionSelector>
                    )}
                    {!message.isProcessing ? (
                      <div style={{ marginLeft: "auto" }}>
                        <IconButton
                          aria-label="Enter edit email generation mode"
                          size="small"
                          style={{ justifySelf: "end" }}
                          onClick={() => {
                            gptConversationContext.setSelection((draft) => {
                              draft.selectedMessages =
                                message.prompt[
                                  emailGenerationState?.activePromptIndex
                                ].promptCompletionIds;
                              draft.selectionMode = true;
                              draft.activePromptId = message.promptId;
                            });
                          }}
                        >
                          <EditIcon fontSize="small"></EditIcon>
                        </IconButton>
                        <IconButton
                          aria-label="Regenerate prompt"
                          size="small"
                          style={{ justifySelf: "end" }}
                          onClick={() => {
                            const promptIndex =
                              emailGenerationState.activePromptIndex;
                            const newCompletionIndex =
                              message.completion?.[promptIndex]?.length;
                            const newPrompt = {
                              existingPromptCompletionId: message.promptId,
                              action: "generateEmail",
                              promptIndex: promptIndex,
                            };

                            setEmailGenerationState((draft) => {
                              draft.activeCompletionIndex = newCompletionIndex;
                            });
                            const existingArray = [
                              ...message.completion[promptIndex],
                            ];
                            existingArray.push("");
                            const completion = {
                              ...message.completion,
                              [promptIndex]: existingArray,
                            };
                            const params = {
                              // Fields for the generate request
                              requestFields: newPrompt,
                              // Fields for displaying new completion response
                              activePromptIndex: promptIndex,
                              activeCompletionIndex: newCompletionIndex,
                              completion: completion,
                              messagePrompt: message.prompt,
                            };
                            handleNewPrompt(params);
                          }}
                        >
                          <ReplayIcon fontSize="small"></ReplayIcon>
                        </IconButton>
                      </div>
                    ) : (
                      <Typography
                        textAlign="left"
                        display="inline-block"
                        marginLeft="auto"
                      >
                        <i>{"Generating…"}</i>
                      </Typography>
                    )}
                  </>
                ) : (
                  <Fade in={isEditingMessage}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginLeft: "auto",
                      }}
                    >
                      <Button
                        sx={{
                          marginRight: "12px",
                          textTransform: "none",
                        }}
                        color="primary"
                        variant="outlined"
                        onClick={() => gptConversationContext.resetSelection()}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          textTransform: "none",
                        }}
                        variant="contained"
                        disableElevation
                        disableRipple
                        disabled={
                          gptConversationContext.selection.selectedMessages
                            .length === 0 ||
                          (isEqual(
                            gptConversationContext.selection.selectedMessages,
                            message.prompt[
                              emailGenerationState?.activePromptIndex
                            ]?.promptCompletionIds
                          ) &&
                            emailGenerationState?.emailPrompt ===
                              message.prompt[
                                emailGenerationState?.activePromptIndex
                              ]?.emailPrompt)
                        }
                        onClick={() => {
                          const newPromptIndex = message.prompt.length; // increment by 1
                          const newPrompt = {
                            emailPrompt: emailGenerationState.emailPrompt,
                            promptCompletionIds:
                              gptConversationContext.selection.selectedMessages,
                            existingPromptCompletionId: message.promptId,
                            action: "generateEmail",
                          };

                          setEmailGenerationState((draft) => {
                            draft.activePromptIndex = newPromptIndex;
                            draft.activeCompletionIndex = 0;
                          });
                          const completion = {
                            ...message.completion,
                            [newPromptIndex]: [""],
                          };
                          let newPromptArray = cloneDeep(message.prompt);
                          newPromptArray.push(newPrompt);
                          const params = {
                            // Fields for the generate request
                            requestFields: newPrompt,
                            // Fields for displaying new completion response
                            activePromptIndex: newPromptIndex,
                            activeCompletionIndex: 0,
                            completion: completion,
                            messagePrompt: newPromptArray,
                          };
                          handleNewPrompt(params);
                          gptConversationContext.resetSelection();
                        }}
                      >
                        Save & generate
                      </Button>
                    </div>
                  </Fade>
                )}
              </div>
            </div>
          ) : (
            <>
              <Typography
                className={classes.message}
                textAlign="left"
                display="inline-block"
                padding="15px"
                borderRadius="16px"
                backgroundColor="rgb(230,241,252)"
                color="rgb(107,149,185)"
              >
                {message.prompt.prompt}
              </Typography>
              <div className={classes.actionsContainer}>
                <>
                  <AddToPromptBookButton
                    prompt={message.prompt}
                  ></AddToPromptBookButton>
                  <Tooltip title={promptCopyText}>
                    <IconButton
                      style={{ color: "#1976d2" }}
                      aria-label="Copy prompt"
                      size="small"
                      onClick={() => {
                        copyMixedHtml(message.prompt.prompt);
                        setPromptCopyText("Copied!");
                        setTimeout(() => {
                          setPromptCopyText("Copy to clipboard");
                        }, 2000);
                      }}
                    >
                      <img
                        style={{ maxHeight: "16px", maxWidth: "16px" }}
                        src={fileCopy}
                        alt="notifications"
                      />
                    </IconButton>
                  </Tooltip>
                </>
              </div>
            </>
          )}
        </div>
      </div>
      {/* rule5GPT completion response */}
      <Box
        sx={{
          overflowWrap: "anywhere",
          cursor: gptConversationContext.selection.selectionMode
            ? "pointer"
            : "auto",
          "&:hover": gptConversationContext.selection.selectionMode
            ? {
                backgroundColor:
                  gptConversationContext.selection.selectedMessages.includes(
                    message.promptId
                  )
                    ? RESPONSE_COLOR_SELECTED_HOVER
                    : RESPONSE_COLOR_HOVER,
              }
            : null,
        }}
        onClick={
          gptConversationContext.selection.selectionMode
            ? () => {
                const selectedIndex =
                  gptConversationContext.selection.selectedMessages.findIndex(
                    (el) => el === message.promptId
                  );
                let modifiedList = [
                  ...gptConversationContext.selection.selectedMessages,
                ];
                if (selectedIndex > -1) {
                  // Already exists, remove from the list.
                  modifiedList.splice(selectedIndex, 1);
                  gptConversationContext.setSelection((draft) => {
                    draft.selectedMessages = modifiedList;
                  });
                } else {
                  // Not yet added to the list, add to selection.
                  if (modifiedList.length < 5) {
                    modifiedList.push(message.promptId);
                    gptConversationContext.setSelection((draft) => {
                      draft.selectedMessages = modifiedList;
                    });
                  }
                }
              }
            : null
        }
      >
        <div className={classes.messageContainer}>
          <Avatar
            variant="rounded"
            sx={{
              width: "40px",
              height: "40px",
              bgcolor: "#1976d2",
              alignSelf: "start",
            }}
          >
            <img
              src={rule5logo}
              alt="rule5GPT"
              style={{
                height: "auto",
                maxWidth: 20,
                maxHeight: 20,
                display: "block",
              }}
            />
          </Avatar>
          {message.error ? (
            <Typography
              className={classes.message}
              sx={{
                border: "1px solid #f08080",
                borderRadius: "12px",
                padding: "12px !important",
                backgroundColor: "#fef8f8",
                margin: "12px 0px",
              }}
            >
              {message.error}
            </Typography>
          ) : (
            <>
              <Typography
                textAlign="left"
                marginRight="auto"
                sx={{
                  flexGrow: 1,
                  flexBasis: "73%",
                  paddingTop: "5px",
                }}
                className={
                  classes.message +
                  `${
                    message.isProcessing ? " " + classes.processingMessage : ""
                  }`
                }
                dangerouslySetInnerHTML={{
                  __html: completionHtml,
                }}
              >
                {/* using dangerouslySetInnerHTML at the moment */}
              </Typography>
              <div className={classes.actionsContainer}>
                <Tooltip title={completionCopyText}>
                  <IconButton
                    style={{ color: "#1976d2" }}
                    aria-label="Copy response"
                    size="small"
                    onClick={() => {
                      copyMixedHtml(completionHtml);
                      setCompletionCopyText("Copied!");
                      setTimeout(() => {
                        setCompletionCopyText("Copy to clipboard");
                      }, 2000);
                    }}
                  >
                    <img
                      style={{ maxHeight: "16px", maxWidth: "16px" }}
                      src={fileCopy}
                      alt="notifications"
                    />
                  </IconButton>
                </Tooltip>
                {rating !== "thumbsDown" ? (
                  <IconButton
                    aria-label="Thumbs Up"
                    size="small"
                    onClick={() => {
                      const params = {
                        promptCompletionId: message.promptId,
                        feedback: { rating: "thumbsUp" },
                      };
                      axios
                        .post(rule5properties.conversationFeedback, params)
                        .then((resp) => {
                          setRating("thumbsUp");
                        })
                        .catch((error) => console.log(error));
                    }}
                  >
                    <img
                      style={{ maxHeight: "16px", maxWidth: "16px" }}
                      src={rating === "thumbsUp" ? thumbUpFill : thumbUp}
                      alt="notifications"
                    />
                  </IconButton>
                ) : (
                  <div style={{ height: "28px", width: "28px" }}></div>
                )}
                {rating !== "thumbsUp" ? (
                  <IconButton
                    aria-label="Thumbs Down"
                    size="small"
                    onClick={() =>
                      dialog.openModal(
                        "Feedback for Action IQ",
                        ChatNegativeFeedback,
                        { setRating: setRating, promptId: message.promptId },
                        "sm"
                      )
                    }
                  >
                    <img
                      style={{ maxHeight: "16px", maxWidth: "16px" }}
                      src={rating === "thumbsDown" ? thumbDownFill : thumbDown}
                      alt="notifications"
                    />
                  </IconButton>
                ) : (
                  <div style={{ height: "28px", width: "28px" }}></div>
                )}
              </div>
              {displayRelevantCards && (
                <div className={classes.relevantCardsContainer}>
                  <Typography
                    sx={{
                      textTransform: "none",
                      fontWeight: "300",
                      fontSize: "12px",
                      color: "inherit",
                    }}
                  >
                    Sources
                  </Typography>
                  <div
                    style={{ gap: "6px", display: "flex", flexWrap: "wrap" }}
                  >
                    {message.prompt.relevantCards.map((card, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={
                          card.cardType ||
                          `${clipTextWithEllipsis(card.contentName, 30)} p.${
                            card.pageNumber
                          }`
                        }
                        component="a"
                        href={
                          card.cardType
                            ? `/main/opportunities/${opportunityId}/research#${cardTypeToId(
                                card.cardType
                              )}`
                            : `/main/content/${card?.contentId}?page=${card?.pageNumber}&startIndex=${card?.startIndex}&length=${card?.length}`
                        }
                        target="_blank"
                        clickable
                      ></Chip>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Box>
    </>
  );
}
