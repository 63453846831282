import React, { useState, useEffect } from "react";
import axios from "axios";
import UserDetailPopup from "../modal/UserDetailPopup";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import Toolbar from "../common/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { useDialog } from "../context/DialogContext";
import CustomSnackbar from "../common/CustomSnackbar";
import { rule5properties } from "../properties";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { useUser } from "../context/UserContext";
import Card from "@mui/material/Card";
import {  makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

LicenseInfo.setLicenseKey(rule5properties.MUIXProLicenseKey);

const defaultRow = [
  {
    id: -1,
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    status: "",
    role: "",
    createdBy: "",
    creationDate: "",
    lastUpdatedBy: "",
    lastUpdateDate: "",
  },
];

const useStyles = makeStyles(() => ({
  tableCellTrucate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  actionGroup: {
    marginLeft: "2%",
    marginTop: "1%",
    marginBottom: "1%",
    width: "95%",
    display: "flex",
    justifyContent: "space-between",
  },
  spinnerContainer: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    zIndex: 1001,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.7)",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  spinner: {
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  root: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginLeft: "30px",
      marginRight: "30px",
    },
    card: {},
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "hidden",
      lineHeight: "20px",
      whiteSpace: "normal",
      color: "#999CA0",
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "100px",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      height: "56px",
      color: "#999CA0",
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
          "& .MuiDataGrid-menuIcon": {
            "& .MuiButtonBase-root": {
              color: "#999CA0",
            },
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
            marginLeft: "-6px",
            "& .MuiDataGrid-iconButtonContainer": {
              "& .MuiButtonBase-root": {
                color: "#999CA0",
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      "& .MuiTablePagination-root": {
        color: "rgba(0,0,0,0.7)",
        "& .MuiSelect-select": {
          color: "#000000",
          border: "1px solid #F0F0F0",
        },
        "& .MuiSelect-icon": {
          // color: theme.common.selectIconColor,
        },
        "& .MuiTablePagination-actions": {
          "& .MuiButtonBase-root": {
            "& .MuiSvgIcon-root": {
              color: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
    },
    "& .MuiDataGrid-row": {
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.01)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiGridPanelWrapper-root": {
      opacity: 0.1,
    },
  },
  columnText: {
    color: "#000",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5,
  },
  menuButton: {
    minWidth: "10px",
  },
  toolbarButton: {
    padding: "10px 18px 10px 18px",
    margin: "7px",
    color: "rgba(0,0,0,0.5)",
    borderRadius: "8px",
    textTransform: "capitalize",
  },
  summaryCard: {
    height: "72px",
    marginBottom: "16px",
  },
}));

export default function ManageDetUsers() {
  const classes = useStyles();
  const dialog = useDialog();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState(defaultRow);
  const [token, setToken] = useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const user = useUser();
  const theme = useTheme();

  useEffect(() => {
    setToken(user.accessToken);
    searchUsers();
  }, []);

  function searchUsers() {
    setIsLoading(true);

    if (user.role !== "Super User") {
      setIsLoading(false);
      setSnackbarMessage("You don't have permission.");
      setOpenSnackbar(true);
      return;
    }

    axios
      .get(`${rule5properties.detGetUsers}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 403) {
          setIsLoading(false);
          setSnackbarMessage("You don't have permission.");
          setOpenSnackbar(true);
          return;
        }

        if (response.data && response.data.length > 0) {
          let id = 0;
          response.data.forEach((element) => {
            element.id = id;
            id += 1;
          });
        }
        setRows(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          setSnackbarMessage("There was a problem with listing users.");
          setOpenSnackbar(true);
        }
      });
  }

  function createNewUser(user, resetForm, response) {
    if (response === 200) {
      dialog.closeModal();
      resetForm();
      setIsLoading(false);
      searchUsers();
      return;
    }

    setIsLoading(false);
    setSnackbarMessage("There was a problem with creating user.");
    setOpenSnackbar(true);
  }

  function updateUser(user, resetForm) {
    setIsLoading(true);
    axios
      .patch(
        `${rule5properties.detUpdateUser}`,
        {
          email: user.email,
          role: user.role,
          status: user.status,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dialog.closeModal();
          resetForm();
          setIsLoading(false);
          searchUsers();
          return;
        }
        setIsLoading(false);
        setSnackbarMessage("There was a problem with updating user.");
        setOpenSnackbar(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          setSnackbarMessage("There was a problem with updating user.");
          setOpenSnackbar(true);
        }
      });
  }

  const addOrEdit = (user, resetForm, response) => {
    if (user.id === -1) {
      createNewUser(user, resetForm, response);
    } else {
      updateUser(user, resetForm);
    }
  };

  const onSelectionChange = (event) => {
    const row = rows.find((row) => {
      return row.id === event[0];
    });
    dialog.openModal("User Information", UserDetailPopup, {
      addOrEdit,
      recordForEdit: row,
    });
  };

  const columns = [
    {
      field: "email",
      headerName: "Email",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.email || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.email}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.firstName || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.firstName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.lastName || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.lastName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.status || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.status}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.role || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.role}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <div style={{ height: "80%", width: "100%" }}>
        <Toolbar>
          <div className={classes.title}>Manage Newton Users</div>
        </Toolbar>
        <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.spinner} />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        maxWidth: "100%",
        paddingBottom: `${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar>
        <div className={classes.title}>Manage Newton Users</div>
      </Toolbar>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          pt: 1,
          borderRadius: "8px",
          height: "100%",
          flexDirection: "column",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        <div className={classes.actionGroup}>
          <Button
            style={{ textTransform: "none" }}
            color="primary"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              dialog.openModal("Add new user", UserDetailPopup, {
                addOrEdit,
                recordForEdit: null,
              });
            }}
          >
            Add new user
          </Button>

          <IconButton
            color="primary"
            aria-label="refresh"
            component="span"
            onClick={() => {
              searchUsers();
            }}
          >
            <RefreshIcon />
          </IconButton>
        </div>

        {!isLoading && (
          <div
            style={{
              marginLeft: "2%",
              marginRight: "2%",
              height: "100%",
            }}
          >
            <DataGridPro
              components={{
                Toolbar: GridToolbar,
              }}
              className={classes.root}
              // rowHeight={theme.opportunityList.rowHeight}
              rows={rows}
              columns={columns}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableColumnSelector={true}
              onRowSelectionModelChange={onSelectionChange}
              disableMultipleSelection={true}
            />
          </div>
        )}

        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Card>
    </div>
  );
}
