import React from "react";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import {
  GridActionsCellItem,
  GridFooter,
  GridFooterContainer,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  mapValues,
  omit,
  assign,
  maxBy,
  pick,
  pickBy,
  isFinite,
  size,
  keys,
  head,
  unionBy,
  sortBy,
  capitalize,
} from "lodash";
import useSnack from "../../../context/Snack";

import { rule5properties } from "../../../properties";
import { isSuccessStatus } from "../../../common/RequestUtils";
import {
  ErrLabel,
  ErrorTooltip,
  StyledDataGrid,
} from "../../../common/StyledComponents";
import { renderEditInputCell } from "../../../common/EditInputCell";
import AddIcon from "@mui/icons-material/Add";
import { accountsAgentType, peopleAgentType } from "./AgentTypes";
import AgentListStatus from "./AgentListStatus";
import { useUser } from "../../../context/UserContext";
import upgradeFeatureCheckBlue from "../../../res/upgrade_feature_check_blue.svg";
import { useQueryClient } from "react-query";

export function CustomToolbar(props) {
  const { rowCount } = props;
  if (!rowCount) {
    return null;
  }
  return (
    <div style={{ padding: "0px 15px 10px 7px" }}>
      <GridToolbarContainer>
        <GridToolbarQuickFilter
          sx={{
            width: "250px",
            color: "rgba(0,0,0,0.5)",
            borderRadius: "8px",
            textTransform: "none",
          }}
          debounceMs={500}
        />
        <div style={{ flex: 1 }} />
      </GridToolbarContainer>
    </div>
  );
}

function AddRowButton(props) {
  const {
    rows,
    setRows,
    rowModesModel,
    setRowModesModel,
    agentType,
    setValue,
    modAgent,
  } = props;

  const user = useUser();
  const isEditing = size(rowModesModel) > 0;
  const consumptionLimit = user.userSubscription?.[agentType.consumptionKey];
  const [consumptionCount, setConsumptionCount] = React.useState(null);

  const handleClick = () => {
    const maxTempId = maxBy(rows, "tempId")?.tempId;
    const nextTempId = isFinite(maxTempId) ? maxTempId + 1 : 1;
    const newPayload = [
      ...rows,
      {
        tempId: nextTempId,
        isNew: true,
        ...assign(
          ...agentType.columns.map((column) => {
            return { [column.key]: "" };
          })
        ),
      },
    ];
    setValue && setValue("payload", newPayload);
    setRows(newPayload);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [nextTempId]: {
        mode: GridRowModes.Edit,
        fieldToFocus: agentType.columns[0].key,
      },
    }));
  };

  React.useEffect(() => {
    if (!modAgent) {
      return;
    }

    axios.get(agentType.endpoint + "/consumption").then((resp) => {
      setConsumptionCount(resp.data?.count);
    });
  }, [modAgent, agentType, rows]);

  const isLimitExceeded =
    consumptionLimit && consumptionCount >= consumptionLimit;

  return (
    <>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClick}
        disabled={isEditing || isLimitExceeded}
      >
        Add {agentType.singularText}
      </Button>
      {isLimitExceeded && (
        <ErrLabel>{`Your subscription has reached its ${consumptionLimit} ${agentType.singularText} quota. To upgrade your subscription, please contact sales@rule5.io.`}</ErrLabel>
      )}
    </>
  );
}

const CustomFooter = ({ rowModesModel, agentType, rows, modAgent }) => {
  const isEditing = size(rowModesModel) > 0;
  const hasInvalidRows = rows.some((row) => row.isValid === false);

  return (
    <GridFooterContainer>
      {(isEditing || hasInvalidRows) && (
        <Stack direction="column" gap="5px" sx={{ mb: "5px" }}>
          <Typography variant="body2" sx={{ mt: 2, opacity: 0.7 }}>
            {agentType.helperText}
          </Typography>
          {isEditing && modAgent && (
            <ErrLabel>
              Please click the check mark (✓) to save your changes before
              proceeding.
            </ErrLabel>
          )}
        </Stack>
      )}
      <GridFooter sx={{ ml: "auto", minWidth: "150px" }} />
    </GridFooterContainer>
  );
};

export default function EditAgentList(props) {
  const {
    agentType,
    modAgent,
    setValue,
    loadAgentRows = () => {},
    lite,
  } = props;

  const [rows, setRows] = React.useState(props.rows || []);
  const [rowModesModel, setRowModesModel] = React.useState(
    props.rowModesModel || {}
  );
  const [loading, setLoading] = React.useState(!!modAgent);

  const apiRef = useGridApiRef();
  const snackBar = useSnack();
  const queryClient = useQueryClient();

  /* Response differs based on agentType. */
  const parseResponse = React.useCallback(
    (response) => {
      let idField;
      if (agentType.type === accountsAgentType.type) {
        idField = "agentAccountId";
      } else if (agentType.type === peopleAgentType.type) {
        idField = "agentPeopleId";
      }

      return response.data.map((agent) => ({
        tempId: agent[idField],
        ...pick(agent, [
          "status",
          "errorMessage",
          ...agentType.columns.map((column) => column.key),
        ]),
      }));
    },
    [agentType]
  );

  const fetchAndSetExistingRows = React.useCallback(() => {
    if (typeof modAgent?.agentId === "number") {
      setLoading(true);
      axios
        .get(`${agentType.endpoint}?agentId=${modAgent.agentId}`)
        .then((resp) => {
          setLoading(false);
          if (isSuccessStatus(resp.status)) {
            setRows(parseResponse(resp));
          } else {
            const message = resp.data?.message;
            snackBar.createSnack(
              message ? message : "Unexpected error getting agent info."
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          snackBar.createSnack("Unexpected error creating agent.");
          console.log(error);
        });
    }
  }, [agentType, modAgent, snackBar, parseResponse]);

  React.useEffect(() => {
    fetchAndSetExistingRows();
  }, []);

  const handleDeleteClick = React.useCallback(
    (row, id) => () => {
      const newPayload = Array.from(apiRef.current.getRowModels().values());
      newPayload.splice(
        newPayload.findIndex((row) => row.tempId === id),
        1
      );
      if (!modAgent && setValue) {
        // Case for creates
        setValue("payload", newPayload, { shouldValidate: true });
        setRows(newPayload);
      } else if (modAgent) {
        // Case for updates
        setLoading(true);
        axios
          .delete(`${agentType.endpoint}/${row.tempId}`)
          .then((resp) => {
            setLoading(false);
            if (isSuccessStatus(resp.status)) {
              snackBar.createSnack(
                `Successfully deleted ${agentType.singularText}.`
              );
              setRows(newPayload);
              queryClient.invalidateQueries({
                queryKey: ["companyAgents"],
                refetchType: "none",
              });
              // Update parent in background
              loadAgentRows();
            } else {
              const message = resp.data?.message;
              snackBar.createSnack(
                message ? message : "Unexpected error getting agent info."
              );
            }
          })
          .catch((error) => {
            setLoading(false);
            snackBar.createSnack("Unexpected error creating agent.");
            console.log(error);
          });
      }
    },
    [agentType, apiRef, snackBar, modAgent, setValue, loadAgentRows]
  );

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut && modAgent) {
      // Prevent the row from exiting edit mode on focusOut if it's for an existing agent
      // For new agents, exit edit mode if eg user focuses out to click Next button
      event.defaultMuiPrevented = true;
    } else if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      event.stopPropagation();
      // event.defaultMuiPrevented = true;
    } else if (params.reason === GridRowEditStopReasons.enterKeyDown) {
      if (!event.shiftKey) {
        event.preventDefault(); // prevent newline from looking like it's added during submission
      } else {
        event.defaultMuiPrevented = true;
      }
    } else if (params.reason === GridRowEditStopReasons.tabKeyDown) {
      // event.stopPropagation();
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = React.useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const handleCancelClick = React.useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.tempId === id);
      if (editedRow?.isNew) {
        const updated = rows.filter((row) => row.tempId !== id);
        setRows(updated);
        setValue && setValue("payload", updated, { shouldValidate: true });
      }
    },
    [rowModesModel, rows, setValue]
  );

  const addRow = async (newRow) => {
    // Remove falsey values since backend doesn't want them and
    // they've already been validated if here.
    let params = pickBy(newRow, (value) => value);
    delete params.isNew;
    delete params.tempId;
    setLoading(true);
    try {
      const resp = await axios.post(
        `${rule5properties.agents}/${modAgent.agentId}/${agentType.type}`,
        [params]
      );
      setLoading(false);
      if (isSuccessStatus(resp.status)) {
        queryClient.invalidateQueries({
          queryKey: ["companyAgents"],
          refetchType: "none",
        });
        const updates = parseResponse(resp);
        if (updates[0]?.status === "Active") {
          snackBar.createSnack(`Successfully added ${agentType.singularText}.`);
        } else {
          snackBar.createSnack(
            `${capitalize(agentType.singularText)} added with error.`
          );
        }
        const newRows = sortBy(
          unionBy(
            updates,
            rows.filter((row) => !row.isNew),
            "tempId"
          ),
          "tempId"
        );
        setRows(newRows);
        loadAgentRows();
        return {
          ...updates?.[0],
          isNew: false,
        };
      } else {
        const message = resp.data?.message;
        snackBar.createSnack(
          message
            ? message
            : `Unexpected error adding ${agentType.singularText}.`
        );
      }
    } catch (error) {
      setLoading(false);
      snackBar.createSnack(
        `Unexpected error adding ${agentType.singularText}.`
      );
      console.log(error);
    }
  };

  const editRow = async (updatedRow) => {
    let params = pickBy(updatedRow, (value) => value); // remove entries with falsey values
    params = omit(params, ["tempId", "status", "errorMessage"]);
    setLoading(true);
    return axios
      .patch(`${agentType.endpoint}/${updatedRow.tempId}`, params)
      .then((resp) => {
        setLoading(false);
        if (isSuccessStatus(resp.status)) {
          snackBar.createSnack(
            `${capitalize(agentType.singularText)} updated ${
              resp.data.status === "Active" ? "successfully" : "with error"
            }.`
          );
          // Ugh using timeout since some display bug with the snackbar when snackbar, loadAgentRows, and returning updated
          // row to processRowUpdate all happen and I can't figure out a better fix.
          setTimeout(() => {
            loadAgentRows();
          }, 100);

          const newRow = {
            ...updatedRow,
            ...pick(
              resp.data,
              columns.map((column) => column.field)
            ),
          };

          setRows((prevRows) =>
            prevRows.map((row) =>
              row.tempId === newRow.tempId ? { ...row, ...newRow } : row
            )
          );

          return newRow;
        } else {
          const message = resp.data?.message;
          snackBar.createSnack(
            message ? message : "Unexpected error getting agent info."
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        snackBar.createSnack("Unexpected error creating agent.");
        console.log(error);
      });
  };

  const processRowUpdate = (updatedRow, originalRow) => {
    if (!modAgent) {
      // Case where creating new agent.
      updatedRow.isValid = true;
      let newPayload = Array.from(apiRef.current.getRowModels().values());
      const editingIndex = newPayload.findIndex(
        (row) => row.tempId === updatedRow.tempId
      );
      newPayload[editingIndex] = updatedRow;
      setValue("payload", newPayload, { shouldValidate: true });
      setRows(newPayload);
      return updatedRow;
    } else if (updatedRow.isNew) {
      // Adding record to existing agent.
      return addRow(updatedRow);
    } else {
      // Editing existing record
      return editRow(updatedRow);
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    // Some early exit conditions.
    if (size(newRowModesModel) > 1) {
      // Don't allow more than one row to edit at a time.
      return;
    }

    if (
      "Active" ===
      rows.find((row) => row.tempId === +head(keys(newRowModesModel)))?.status
    ) {
      // Prevent editing an active row.
      return;
    }

    setRowModesModel(newRowModesModel);
  };

  const handleEditClick = React.useCallback(
    (id) => () => {
      if (size(rowModesModel) === 0) {
        setRowModesModel({
          ...rowModesModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: agentType.columns[0].key,
          },
        });
      }
    },
    [rowModesModel, agentType]
  );

  const agentCols = React.useMemo(
    () =>
      agentType.columns.map((agentColumn) => {
        return {
          field: agentColumn.key,
          headerName: agentColumn.displayName,
          editable: true,
          preProcessEditCellProps: (params) => {
            const errorMessage =
              // (params.row.isNew && !params.hasChanged) ||
              agentColumn.validate(params.props.value, {
                ...mapValues(
                  params.otherFieldsProps,
                  (value, key) => value.value
                ),
                [agentColumn.key]: params.props.value,
              })
                ? false
                : agentColumn.errorMessage;
            return { ...params.props, error: errorMessage };
          },
          renderEditCell: renderEditInputCell,
          cellClassName: (params) =>
            params.cellMode !== "edit" &&
            !agentColumn.validate(params.row?.[agentColumn.key], params.row) &&
            `rule5-datagrid-error`,
          renderCell: (cellValues) => {
            return (
              <ErrorTooltip
                title={
                  !agentColumn.validate(
                    cellValues.row?.[agentColumn.key],
                    cellValues.row
                  ) && agentColumn.errorMessage
                }
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "inherit",
                    display: "flex",
                  }}
                >
                  {cellValues.row?.[agentColumn.key]}
                </Box>
              </ErrorTooltip>
            );
          },
          flex: agentColumn.relativeWidth,
        };
      }),
    [agentType]
  );

  const columns = React.useMemo(
    () => [
      ...agentCols,
      ...[
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          renderCell: ({ row }) => <AgentListStatus row={row} />,
        },
      ],
      { field: "isValid", width: 0 },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => {
          const { row, id } = params;
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (!isInEditMode) {
            return [
              <Tooltip
                title={
                  row.status === "Active" || size(rowModesModel) > 0
                    ? "Company details cannot be edited while it is active. To make changes, either delete the company or add a new one"
                    : ""
                }
              >
                <span>
                  <GridActionsCellItem
                    icon={
                      <Tooltip title={"Edit " + agentType.singularText}>
                        <EditIcon />
                      </Tooltip>
                    }
                    disabled={
                      row.status === "Active" || size(rowModesModel) > 0
                    }
                    label="Edit"
                    onClick={handleEditClick(id)}
                  />
                </span>
              </Tooltip>,
              <GridActionsCellItem
                icon={
                  <Tooltip title={"Delete " + agentType.singularText}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label="Delete"
                onClick={handleDeleteClick(row, id)}
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Save">
                    <img
                      src={upgradeFeatureCheckBlue}
                      alt="checkmark"
                      style={{ width: "18px" }}
                    />
                  </Tooltip>
                }
                label="Save"
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title="Cancel">
                    <CancelIcon />
                  </Tooltip>
                }
                label="Add"
                onClick={handleCancelClick(id)}
              />,
            ];
          }
        },
      },
    ],
    [
      rowModesModel,
      handleSaveClick,
      handleCancelClick,
      handleDeleteClick,
      handleEditClick,
      agentCols,
      modAgent,
      agentType,
    ]
  );

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: 5,
      bottom: 5,
    };
  }, []);
  const getRowHeight = React.useCallback(() => "auto", []);

  return (
    <div
      style={{
        minHeight: !lite ? "200px" : "0px",
      }}
    >
      <StyledDataGrid
        sx={{
          "& .MuiDataGrid-main": {
            maxHeight: "45vh",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflowY: "scroll !important", // hacky fix. I think autoHeight sets this to hidden on the element hence important. Removing autoHeight has other negative effects I can't figure out better solutions for
            overflowX: "hidden",
          },
        }}
        getEstimatedRowHeight={() => 51}
        getRowHeight={getRowHeight}
        autoHeight
        loading={loading}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        disableRowSelectionOnClick
        disableVirtualization
        getRowId={(row) => row?.tempId}
        getRowSpacing={getRowSpacing}
        initialState={{
          columns: {
            columnVisibilityModel: {
              isValid: false,
              status: !!modAgent,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        localeText={{
          noRowsLabel: `No ${agentType.displayName} being monitored.`,
        }}
        apiRef={apiRef}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        onRowEditStop={handleRowEditStop}
        onCellKeyDown={(params, event) => {
          if (params.cellMode === "edit" && event.code === "Tab") {
            event.defaultMuiPrevented = true;
          }
        }}
        slots={{
          footer: CustomFooter,
          toolbar: CustomToolbar,
        }}
        slotProps={{
          footer: { rowModesModel, agentType, rows, modAgent },
          toolbar: { rowCount: rows?.length },
          cell: { tabIndex: -1 }, // let the editCell textarea get the focus
        }}
      />
      <AddRowButton
        rows={rows}
        setRows={setRows}
        rowModesModel={rowModesModel}
        setRowModesModel={setRowModesModel}
        agentType={agentType}
        setValue={setValue}
        modAgent={modAgent}
      />
    </div>
  );
}
