import axios from "axios";
import { rule5properties } from "../../properties";

/* Notifications service to manage the polling interval of retrieving notifications. This is just a starter and will be
 ** expanded upon when notification features become more advanced. */
const NotificationsService = () => {
  let intervalId = null;

  // Get notifications and reset the 1-minute interval.
  function getNotifications(callback) {
    let ret = { all: [], new: [], old: [] };
    return axios
      .get(rule5properties.notifications)
      .then((res) => {
        if (res.data && Array.isArray(res.data)) {
          let all = res.data;
          let tempNew = [];
          let tempOld = [];
          all.sort(function (a, b) {
            return new Date(b.notificationDate) - new Date(a.notificationDate);
          });
          all.forEach((notif) => {
            if (notif.notificationStatus === "New") {
              tempNew.push(notif);
            } else {
              tempOld.push(notif);
            }
          });
          ret = {
            all: all,
            new: tempNew,
            old: tempOld,
          };
          if (callback) {
            callback(ret);
          }
          return ret;
        } else {
          console.log("Error retrieving notifications.");
          if (callback) {
            callback(ret);
          }
          return ret;
        }
      })
      .catch((err) => {
        console.log(err);
        if (callback) {
          callback();
        }
        return ret;
      });
  }

  function getIntervalId() {
    return intervalId;
  }

  function setIntervalId(newIntervalId) {
    intervalId = newIntervalId;
  }

  return {
    getIntervalId,
    setIntervalId,
    getNotifications,
  };
};

export default NotificationsService();
