import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Divider from "@mui/material/Divider";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import axios from "axios";
import { rule5properties } from "../../properties";
import { getLinkedInProfile } from "../../common/Utils";
import {
  Button,
  Card,
  Box,
  Typography,
  TextField,
  Autocomplete,
  LinearProgress,
} from "@mui/material";
import { Checkbox } from "@mui/material";

export default function PeopleEditor(props) {
  const formStyle = props.formStyle;
  const { register, control, trigger, setValue, getValues } = useFormContext();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "Person",
  });

  const [lastDeleted, setLastDeleted] = useState(null);

  const getKeyProfileInfo = (profileUrl, peopleIndex) => {
    let urlParams = new URLSearchParams();
    urlParams.append("profileUrl", profileUrl);
    return axios
      .get(rule5properties.detKeyPeopleProfile + "?" + urlParams.toString())
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          setValue(`Person[${peopleIndex}].PersonProfile.Boards`, data.boards, {
            shouldDirty: true,
          });
          setValue(
            `Person[${peopleIndex}].PersonProfile.Education`,
            data.education,
            { shouldDirty: true }
          );
          setValue(
            `Person[${peopleIndex}].PersonProfile.WorkExperience`,
            data.workExperience,
            { shouldDirty: true }
          );
          setValue(
            `Person[${peopleIndex}].PersonProfile.Summary`,
            data.summary,
            { shouldDirty: true }
          );
          setValue(
            `Person[${peopleIndex}].PersonProfile.Languages`,
            data.Languages,
            { shouldDirty: true }
          );
          setValue(
            `Person[${peopleIndex}].SocialMedia`,
            [{ source: "newval", account: "newval2" }],
            { shouldDirty: true }
          );
        }
      })
      .catch((err) => {
        console.log("Error retrieving key profile info. " + err);
      });
  };

  const getPersonDetails = (linkedInUrl, peopleIndex) => {
    // Ensure standardized link that will work with downstream iScraper api.
    // Foreign subdomains e.g. ca.linkedin.com/... need to be cleansed.
    const linkedInProfileId = getLinkedInProfile(linkedInUrl);

    if (!linkedInProfileId) {
      return linkedInUrl;
    }
    linkedInUrl = "https://www.linkedin.com/in/" + linkedInProfileId + "/";
    const params = [
      {
        linkedin: linkedInUrl,
        override: false,
      },
    ];
    axios
      .post(rule5properties.detPersonsUpload, params)
      .then((response) => {
        const profile = response.data?.[0]?.linkedinProfile;
        setValue(`Person[${peopleIndex}].FullName`, profile.full_name);
        setValue(`Person[${peopleIndex}].Title`, profile.title);
        setValue(`Person[${peopleIndex}].Email`, profile.contact_info.email);
        setValue(`Person[${peopleIndex}].City`, profile.city?.trim());
        setValue(`Person[${peopleIndex}].State`, profile.state?.trim());
        setValue(`Person[${peopleIndex}].Country`, profile.country?.trim());
        setValue(`Person[${peopleIndex}].Avatar`, profile.avatar_url);
      })
      .catch(function (error) {
        alert("ERROR: " + JSON.stringify(error));
      });

    return linkedInUrl;
  };

  const updateWithCustomAvatar = (publicUrl, peopleIndex) => {
    if (!publicUrl || publicUrl.includes("storage.googleapis.com")) {
      return publicUrl;
    }
    const linkedIn = getValues(`Person[${peopleIndex}].LinkedIn`);
    if (linkedIn && !/.*(www\.linkedin\.com\/in\/).*/.test(linkedIn)) {
      alert(
        "Image not converted. Please either remove linkedIn or ensure it matches pattern like 'www.linkedin.com/in/<profile-id>"
      );
      return publicUrl;
    }
    const params = [
      {
        ...(linkedIn ? { linkedin: linkedIn } : {}),
        override: true,
        url: publicUrl,
      },
    ];
    axios
      .post(rule5properties.detPersonsUpload, params)
      .then((response) => {
        setValue(
          `Person[${peopleIndex}].Avatar`,
          response.data[0]?.storageUrl + "?" + new Date().getTime()
        );
      })
      .catch(function (error) {
        alert("ERROR: " + JSON.stringify(error));
      });

    return publicUrl;
  };

  const title = props.functionalArea
    ? props.functionalArea + " Buyer Personas"
    : "Buyer Personas";

  return (
    <div>
      <TitleInput register={register} defaultValue={title} />
      {fields.map((people, peopleIndex) => (
        <Card variant="outlined" sx={{ my: 2, borderRadius: "8px" }}>
          <div key={people.id} className={formStyle}>
            <Controller
              name={`Person[${peopleIndex}].isFlashCard`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  style={{ width: "45px" }}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
            />
            <label>Include in Flash Card</label>
            LinkedIn (paste url)
            <Controller
              name={`Person[${peopleIndex}].LinkedIn`}
              control={control}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        getPersonDetails(e.target.value, peopleIndex)
                      )
                    }
                  />
                );
              }}
            />
            Full Name
            <input {...register(`Person[${peopleIndex}].FullName`)} />
            Title
            <input {...register(`Person[${peopleIndex}].Title`)} />
            Role
            <select {...register(`Person[${peopleIndex}].Role`)}>
              <option value="">n/a</option>
              <option value="Economic Buyer">Economic Buyer</option>
              <option value="Champion">Champion</option>
              <option value="Decision Maker">Decision Maker</option>
              <option value="Technical Buyer">Technical Buyer</option>
              <option value="Technical Super User">Technical Super User</option>
            </select>
            Department
            <input {...register(`Person[${peopleIndex}].Department`)} />
            Email
            <input {...register(`Person[${peopleIndex}].Email`)} />
            Phone Numbers
            <PhoneNumbers
              nestIndex={peopleIndex}
              {...{ control, register, formStyle }}
            />
            Avatar
            <Controller
              name={`Person[${peopleIndex}].Avatar`}
              control={control}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        updateWithCustomAvatar(e.target.value, peopleIndex)
                      )
                    }
                  />
                );
              }}
            />
            Social Media
            <SocialMedia
              nestIndex={peopleIndex}
              {...{ control, register, formStyle }}
            />
            City
            <input {...register(`Person[${peopleIndex}].City`)} />
            State
            <input {...register(`Person[${peopleIndex}].State`)} />
            Country
            <input {...register(`Person[${peopleIndex}].Country`)} />
            Person Profile
            <PersonProfile
              loadKeyProfileInfo={() => {
                const linkedIn = getValues(`Person[${peopleIndex}].LinkedIn`);
                return getKeyProfileInfo(linkedIn, peopleIndex);
              }}
              nestIndex={peopleIndex}
              {...{ getValues, control, register, formStyle }}
            />
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(peopleIndex, moveToIndex);
                trigger();
              }}
              index={peopleIndex}
              options={[...Array(getValues("Person").length).keys()]}
            ></MoveAutocomplete>
          </div>
          <DeleteButton
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              const isDelete = confirm("Remove this person?");
              if (isDelete === true) {
                setLastDeleted(people);
                remove(peopleIndex);
                trigger();
              }
            }}
          >
            Delete Person
          </DeleteButton>
          {/* <Divider light style={{ marginBottom: "1%", marginTop: "1%" }} /> */}
        </Card>
      ))}
      <AddButton
        onClick={() => {
          appendFuncClickHandler(append, lastDeleted);
        }}
      >
        Add Person
      </AddButton>
      <br />
      <br />
      Source Name:
      <input {...register(`Source.Name`)} />
      <br />
      Source Logo:
      <input {...register(`Source.Logo`)} />
      <br />
      <br />
    </div>
  );
}

/** Nested form array */
function PhoneNumbers({ nestIndex, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Person.${nestIndex}.Phone`,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className={formStyle}>
            CountryCode:
            <input
              {...register(`Person.${nestIndex}.Phone.${k}.CountryCode`, {
                // required: true
              })}
            />
            Value:
            <input {...register(`Person.${nestIndex}.Phone.${k}.Value`)} />
            Type:
            <input {...register(`Person.${nestIndex}.Phone.${k}.Type`)} />
            <DeleteButton onClick={() => remove(k)}>Delete Phone</DeleteButton>
          </div>
        );
      })}

      <AddButton
        onClick={() =>
          append({
            CountryCode: "",
            Value: "",
            Type: "Phone Number",
          })
        }
      >
        Add Phone
      </AddButton>
    </div>
  );
}

/** Nested form array */
function SocialMedia({ nestIndex, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Person.${nestIndex}.SocialMedia`,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className={formStyle}>
            Source:
            <input
              {...register(`Person.${nestIndex}.SocialMedia.${k}.source`, {
                // required: true
              })}
            />
            Value:
            <input
              {...register(`Person.${nestIndex}.SocialMedia.${k}.account`)}
            />
            <DeleteButton onClick={() => remove(k)}>
              Delete Social Media
            </DeleteButton>
          </div>
        );
      })}

      <AddButton
        onClick={() =>
          append({
            source: "",
            account: "",
          })
        }
      >
        Add Social Media
      </AddButton>
    </div>
  );
}

// Functional components for KEY PEOPLE PROFILES.

function PersonProfile({
  loadKeyProfileInfo,
  nestIndex,
  control,
  register,
  formStyle,
}) {
  const [languageValue, setLanguageValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  return (
    <Box sx={{ width: "100%" }}>
      <Button
        sx={{ textTransform: "none", mb: 1 }}
        variant="outlined"
        onClick={() => {
          setLoading(true);
          loadKeyProfileInfo().then((res) => {
            setLoading(false);
          });
        }}
      >
        Load Profile Info
      </Button>
      {loading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
            Boards
          </Typography>
          <Boards {...{ nestIndex, control, register, formStyle }} />
          <Typography sx={{ fontSize: "18px", fontWeight: 500, mt: 3 }}>
            Education
          </Typography>
          <Education {...{ nestIndex, control, register, formStyle }} />
          <Typography sx={{ fontSize: "18px", fontWeight: 500, mt: 3 }}>
            Work Experience
          </Typography>
          <WorkExperience {...{ nestIndex, control, register, formStyle }} />
          <Typography sx={{ fontSize: "18px", fontWeight: 500, mt: 3, mb: 1 }}>
            Languages
          </Typography>
          <Controller
            name={`Person.${nestIndex}.PersonProfile.Languages`}
            control={control}
            render={({ field }) => {
              return (
                <Autocomplete
                  autoSelect
                  value={field.value}
                  fullWidth
                  freeSolo
                  multiple
                  multiline
                  onChange={(event, value) => {
                    field.onChange(value);
                  }}
                  options={[]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Languages" />
                  )}
                  inputValue={languageValue}
                  onInputChange={(event, value) => {
                    setLanguageValue(value.trim());
                  }}
                  onKeyDown={(event) => {
                    if (
                      event.key === "Tab" ||
                      event.key === "," ||
                      event.key === "Enter" ||
                      event.key === " "
                    ) {
                      if (languageValue.trim().length > 0) {
                        event.preventDefault();
                        let trimmed = languageValue.trim();
                        field.onChange([...field.value, trimmed]);
                        setLanguageValue("");
                      } else {
                        event.preventDefault();
                      }
                    }
                  }}
                />
              );
            }}
          />
          <Typography sx={{ fontSize: "18px", fontWeight: 500, mt: 3, mb: 1 }}>
            Summary
          </Typography>
          <TextField
            fullWidth
            multiline
            InputProps={{ style: { padding: "6px" } }}
            {...register(`Person.${nestIndex}.PersonProfile.Summary`)}
          />
        </React.Fragment>
      )}
    </Box>
  );
}

function WorkExperience({ nestIndex, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Person.${nestIndex}.PersonProfile.WorkExperience`,
  });
  return (
    <Card variant="outlined" sx={{ borderRadius: "8px", mt: 1, py: 2 }}>
      {fields.map((item, k) => {
        return (
          <React.Fragment key={k}>
            {k > 0 ? <Divider sx={{ mb: 2, mx: 2 }} flexItem /> : null}
            <Box sx={{ ml: 2 }} className={formStyle}>
              Company
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.WorkExperience.${k}.company`
                )}
              />
              Title
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.WorkExperience.${k}.title`
                )}
              />
              Location
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.WorkExperience.${k}.location`
                )}
              />
              Start Year
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.WorkExperience.${k}.startYear`
                )}
              />
              End Month
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.WorkExperience.${k}.endMonth`
                )}
              />
              End Year
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.WorkExperience.${k}.endYear`
                )}
              />
              <DeleteButton onClick={() => remove(k)}>Delete Work</DeleteButton>
            </Box>
          </React.Fragment>
        );
      })}
      <AddButton
        sx={{ ml: 2 }}
        onClick={() =>
          append({
            company: "",
            title: "",
            location: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
          })
        }
      >
        Add Work Experience
      </AddButton>
    </Card>
  );
}
function Boards({ nestIndex, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Person.${nestIndex}.PersonProfile.Boards`,
  });
  return (
    <Card variant="outlined" sx={{ borderRadius: "8px", mt: 1, py: 2 }}>
      {fields.map((item, k) => {
        return (
          <React.Fragment key={k}>
            {k > 0 ? <Divider sx={{ mb: 2, mx: 2 }} flexItem /> : null}
            <Box sx={{ ml: 2 }} className={formStyle}>
              Company
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Boards.${k}.company`
                )}
              />
              Title
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Boards.${k}.title`
                )}
              />
              Location
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Boards.${k}.location`
                )}
              />
              Start Month
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Boards.${k}.startMonth`
                )}
              />
              Start Year
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Boards.${k}.startYear`
                )}
              />
              End Month
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Boards.${k}.endMonth`
                )}
              />
              End Year
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Boards.${k}.endYear`
                )}
              />
              <DeleteButton onClick={() => remove(k)}>
                Delete Board
              </DeleteButton>
            </Box>
          </React.Fragment>
        );
      })}
      <AddButton
        sx={{ ml: 2 }}
        onClick={() =>
          append({
            company: "",
            title: "",
            location: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
          })
        }
      >
        Add Board
      </AddButton>
    </Card>
  );
}

function Education({ nestIndex, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Person.${nestIndex}.PersonProfile.Education`,
  });

  return (
    <Card variant="outlined" sx={{ borderRadius: "8px", mt: 1, py: 2 }}>
      {fields.map((item, k) => {
        return (
          <React.Fragment>
            {k > 0 ? <Divider sx={{ mb: 2, mx: 2 }} flexItem /> : null}
            <Box key={k} sx={{ ml: 2 }} className={formStyle}>
              Degree
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Education.${k}.degree`
                )}
              />
              Field of Study
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Education.${k}.fieldOfStudy`
                )}
              />
              School
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Education.${k}.school`
                )}
              />
              Start Month
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Education.${k}.startMonth`
                )}
              />
              Start Year
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Education.${k}.startYear`
                )}
              />
              End Month
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Education.${k}.endMonth`
                )}
              />
              End Year
              <input
                {...register(
                  `Person.${nestIndex}.PersonProfile.Education.${k}.endYear`
                )}
              />
              <DeleteButton onClick={() => remove(k)}>
                Delete Education
              </DeleteButton>
            </Box>
          </React.Fragment>
        );
      })}
      <AddButton
        sx={{ ml: 2 }}
        onClick={() =>
          append({
            degree: "",
            school: "",
            fieldOfStudy: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
          })
        }
      >
        Add Education
      </AddButton>
    </Card>
  );
}

// let testData =
// {
//     "Title": "People",
//     "Person": [
//         {
//             "FullName": "Ben Schneider",
//             "Title": "Senior Director",
//             "Role": "Economic Buyer",
//             "Department": "Human Resources",
//             "Email": "benschneider@pfizer.com",
//             "Phone": [
//                 {
//                     "CountryCode": "1",
//                     "Value": 2819194747,
//                     "Type": "Phone Number"
//                 },
//                 {
//                     "CountryCode": "1",
//                     "Value": 8282719101,
//                     "Type": "Phone Number"
//                 }
//             ],
//             "Avatar": "https://drive.google.com/file/d/1haS4uUFN1xEFmJodSnJEqD8FDu4YkWrk/view?usp=sharing",
//             "SocialMedia": [
//                 {
//                     "Twitter": "@benschneider",
//                     "Facebook": "@schneider",
//                     "Instagram": "@bensch1"
//                 }
//             ],
//             "City": "San Francisco",
//             "State": "CA",
//             "Country": "US",
//             "LinkedIn": "https://www.linkedin.com/in/ben-schneider",
//             "Interest": [
//                 {
//                     "Category": "Sports",
//                     "Name": [
//                         "San Francisco Giants",
//                         "San Jose Sharks"
//                     ]
//                 },
//                 {
//                     "Category": "Food",
//                     "Name": [
//                         "Asian",
//                         "Indian"
//                     ]
//                 }
//             ],
//             "RelatedHistory": [
//                 {
//                     "FunctionalArea": "Human Resources",
//                     "Purchase": [
//                         "SAP",
//                         "Workday"
//                     ],
//                     "Implementation": [
//                         "Success Factor",
//                         "Workday"
//                     ]
//                 }
//             ]
//         },
//         {
//             "FullName": "Scott Bilsby",
//             "Title": "Vice President",
//             "Role": "Decision Maker",
//             "Department": "Sales",
//             "Email": "scottb@pfizer.com",
//             "PhoneNumber": {},
//             "Phone": [
//                 {
//                     "CountryCode": "1",
//                     "Value": 7161691991,
//                     "Type": "Phone Number"
//                 },
//                 {
//                     "CountryCode": "1",
//                     "Value": 3102719101,
//                     "Type": "Phone Number"
//                 }
//             ],
//             "Avatar": "https://drive.google.com/file/d/1IpyRoTB7IWpFk2w--olLq_SS1LG8MlKI/view?usp=sharing",
//             "SocialMedia": [
//                 {
//                     "Twitter": "@stevefirst",
//                     "Facebook": "@firsts",
//                     "Instagram": "@steve2292"
//                 }
//             ],
//             "City": "Austin",
//             "State": "TX",
//             "Country": "US",
//             "LinkedIn": "https://www.linkedin.com/in/steve-first",
//             "Interest": [
//                 {
//                     "Category": "Sports",
//                     "Name": [
//                         "San Francisco 49'ers",
//                         "Golden State Warriors"
//                     ]
//                 },
//                 {
//                     "Category": "Food",
//                     "Name": [
//                         "Persian",
//                         "Mediterranean"
//                     ]
//                 }
//             ],
//             "RelatedHistory": [
//                 {
//                     "FunctionalArea": "Cloud Database",
//                     "Purchase": [
//                         "MongoDB",
//                         "Spanner"
//                     ],
//                     "Implementation": [
//                         "Spanner"
//                     ]
//                 }
//             ]
//         },
//         {
//             "FullName": "Steve First",
//             "Title": "Vice President",
//             "Department": "Global Benefits",
//             "Email": "sfirst@pfizer.com",
//             "PhoneNumber": {},
//             "Phone": [
//                 {
//                     "CountryCode": "1",
//                     "Value": 7811910029,
//                     "Type": "Phone Number"
//                 },
//                 {
//                     "CountryCode": "1",
//                     "Value": 6727191000,
//                     "Type": "Phone Number"
//                 }
//             ],
//             "Avatar": "https://drive.google.com/file/d/1W5XrFglt9slAaQpAzg_8xmB-qETEotlQ/view?usp=sharing",
//             "SocialMedia": [
//                 {
//                     "Twitter": "@stevefirst",
//                     "Facebook": "@firsts",
//                     "Instagram": "@steve2292"
//                 }
//             ],
//             "City": "Austin",
//             "State": "TX",
//             "Country": "US",
//             "LinkedIn": "https://www.linkedin.com/in/steve-first",
//             "Interest": [
//                 {
//                     "Category": "Movie",
//                     "Name": [
//                         "Schindler's List",
//                         "Casa Blanca"
//                     ]
//                 },
//                 {
//                     "Category": "Charity",
//                     "Name": [
//                         "Red Cross",
//                         "Save the children"
//                     ]
//                 }
//             ],
//             "RelatedHistory": [
//                 {
//                     "FunctionalArea": "ERP",
//                     "Purchase": [
//                         "NetSuite",
//                         "Oracle eBusiness"
//                     ],
//                     "Implementation": [
//                         "Oracle Supply chain"
//                     ]
//                 }
//             ]
//         }
//     ],
//     "Source": {
//         "Name": "LinkedIn",
//         "Logo": "https://drive.google.com/file/d/1IXlvw8c-KguehZ1LDjwXTyl-h8-dm_YJ/view?usp=sharing"
//     },
//     "NewUpdate": false
// };
