import React from "react";
import {
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Image from "../common/Image";
import PeopleConnectionsList from "../common/PeopleConnectionsList";
import { getColorFromString } from "./Utils";

const useStyles = makeStyles(() => ({
  connectionsProfilePic: {
    position: "relative",
    padding: 0,
    width: "20px",
    height: "20px",
    borderRadius: "70px",
  },
}));

export default function ConnectionsInPersonPopup(props) {
  const classes = useStyles();
  const { personConnections, allConnections, orgName, companyName } = props;

  const orgMembers = personConnections?.filter(
    (connection) => connection.connectionOrgName === orgName
  );
  const extendedTeamsGroupedByOrg = Object.values(
    personConnections
      ?.filter((connection) => connection.connectionOrgName !== orgName)
      .reduce(function (connectionExtendedOrgs, obj) {
        let key = obj["connectionOrgName"];
        if (!connectionExtendedOrgs[key]) {
          // First time aggregating on this org.
          connectionExtendedOrgs[key] = { connections: [] };
        }
        connectionExtendedOrgs[key].connections.push(obj);
        return connectionExtendedOrgs;
      }, {})
  );
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
      }}
    >
      <Typography sx={{ mt: "18px", fontWeight: 500 }}>Connections:</Typography>
      <List className={classes.listStyle}>
        {orgMembers.map((orgPerson, index) => {
          return (
            <PersonPopupConnection
              key={index}
              connectionPerson={orgPerson}
              companyName={companyName}
              allConnections={allConnections}
              index={index}
            ></PersonPopupConnection>
          );
        })}
        {extendedTeamsGroupedByOrg.map((extendedTeam, teamIndex) => {
          return (
            <React.Fragment key={teamIndex}>
              {extendedTeam.connections.map((orgPerson, index) => {
                return (
                  <PersonPopupConnection
                    key={index}
                    connectionPerson={orgPerson}
                    companyName={companyName}
                    allConnections={allConnections}
                    index={index}
                    isExtended={true}
                  ></PersonPopupConnection>
                );
              })}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
}

function PersonPopupConnection(props) {
  const classes = useStyles();

  const connectionPerson = props.connectionPerson;
  const companyName = props.companyName;
  const allConnections = props.allConnections;
  const isExtended = props.isExtended;
  const index = props.index;

  return (
    <ListItem
      alignItems="flex-start"
      disableGutters
      key={connectionPerson.profile_id + "_" + index}
    >
      <ListItemAvatar style={{ minWidth: "0px" }}>
        <Avatar
          className={classes.connectionsProfilePic}
          sx={{
            fontSize: ".750rem",
            bgcolor: getColorFromString(connectionPerson?.full_name),
          }}
        >
          {connectionPerson?.avatar_url != null ? (
            <Image
              src={connectionPerson?.avatar_url}
              className={classes.connectionsProfilePic}
            />
          ) : (
            connectionPerson?.full_name?.toUpperCase().charAt(0)
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        style={{ marginLeft: "8px", marginTop: "0px" }}
        primary={
          <Typography style={{ fontSize: "0.875rem" }}>
            <>
              <Link
                color="inherit"
                href={
                  "https://www.linkedin.com/in/" + connectionPerson?.profile_id
                }
                target="_blank"
              >
                {connectionPerson?.full_name}
              </Link>
              , {connectionPerson?.title}
              {isExtended && ` @ ${connectionPerson.connectionOrgName}`}
            </>
          </Typography>
        }
        secondary={
          <PeopleConnectionsList
            companyName={companyName}
            connections={Object.values(
              allConnections.reduce(function (connection, obj) {
                if (
                  obj.connectionPersonInfo.find(
                    (objConnection) =>
                      objConnection.profile_id ===
                        connectionPerson.profile_id &&
                      obj.companyName === companyName
                  )
                ) {
                  let key = obj?.linkedinProfileId;
                  connection[key] = obj;
                }
                return connection;
              }, {})
            )}
          ></PeopleConnectionsList>
        }
        primaryTypographyProps={{ fontSize: "0.875rem" }}
      />
    </ListItem>
  );
}
