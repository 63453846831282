import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import Image from "../../../../common/Image";
import Stack from "@mui/material/Stack";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Box,
  IconButton,
} from "@mui/material";
import MuiTooltip from "@mui/material/Tooltip";
import RichTextReader from "../../../../common/RichTextReader";
import { convertFromRaw, EditorState } from "draft-js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  LineElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  hasRteContent,
  numberAbbrev,
  percentFormatter,
} from "../../../../common/Utils";
import Typography from "@mui/material/Typography";
import {
  StyledTableCell,
  StyledTableRow,
  scrollbarStyle,
} from "../../../../common/StyledComponents";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  PointElement,
  LineController,
  LineElement
);

const useStyles = makeStyles(() => ({
  cardContent: {
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
  },
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  chartContent: {
    fontSize: "10pt",
    textAlign: "left",
    paddingRight: "0px",
    paddingBottom: "20px",
    height: "300px",
    flexGrow: 1,
  },
  image: {
    maxWidth: "100%",
    marginBottom: "16px",
    border: "1px solid #EEE",
    borderRadius: "5px",
  },
  icon: {
    maxWidth: "50px",
    maxHeight: "50px",
  },
  rteReader: {
    "& .rdw-editor-main": {
      overflow: "hidden",
    },
    display: "flex",
    justifyContent: "center",
    rowGap: "10px",
  },
}));

// Copies a table to clipboard given the table ID.
function copyTableId(tableId) {
  var copyTable = document.querySelector(`#${tableId}`);
  var range = document.createRange();
  range.selectNode(copyTable);
  window.getSelection().addRange(range);
  document.execCommand("copy");
  // Clear selection.
  window.getSelection().empty();
}

export default function Trends(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;
  const [trendsData, setTrendsData] = React.useState(null);
  const [firstTime, setFirstTime] = React.useState(true);

  useEffect(() => {
    if (previewData) {
      // Call Stub
      setTrendsData(previewData);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId; // cardId hardcoded for now
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            let rawdata = response.data;
            if (rawdata.Trends) {
              // Validate content
              setTrendsData(rawdata);
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
  }, [firstTime, previewData]);

  if (!trendsData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={trendsData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <TrendRender trendsData={trendsData}></TrendRender>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
}

function TrendRender(props) {
  const { trendsData } = props;
  const classes = useStyles();
  if (trendsData.KpiTrends) {
    // Kinda hacky adding in the icon here so that backfill not needed in case icon was missing
    // when AnnualFinancials initially generated.
    // Eventually icon will be automatically added in Newton based on ticker.
    const annualFinancials = trendsData.AnnualFinancials?.map((year, index) => {
      return {
        ...year,
        icon: trendsData.KpiTrends[0].icon,
        companyName: trendsData.KpiTrends[0].companyName,
      };
    });

    return (
      <>
        {trendsData.RevenueNetIncome?.[0]?.currency && (
          <Box className="splitFlag">
            <Bar
              options={getOptions(trendsData.RevenueNetIncome[0]?.currency)}
              data={getBarData(trendsData.RevenueNetIncome)}
            />
            {hasRteContent(trendsData.ChartAnalysisTextList) && (
              <div className={classes.rteReader}>
                <RichTextReader
                  editorState={EditorState.createWithContent(
                    convertFromRaw(trendsData.ChartAnalysisTextList)
                  )}
                />
              </div>
            )}
          </Box>
        )}
        {trendsData.KpiTrends?.[0] && (
          <>
            <Box className="splitFlag">
              <KpiTable
                tableTitle="KPI Trends"
                rows={trendsData.KpiTrends}
                copyId="kpi-trends-table-1"
              ></KpiTable>
              {hasRteContent(trendsData.TableAnalysisTextList) && (
                <div className={classes.rteReader}>
                  <RichTextReader
                    editorState={EditorState.createWithContent(
                      convertFromRaw(trendsData.TableAnalysisTextList)
                    )}
                  />
                </div>
              )}
            </Box>
            {"inventory" in trendsData.KpiTrends?.[0] && (
              <Box className="splitFlag" style={{ paddingTop: "10px" }}>
                {trendsData.KpiTrends?.[0] && (
                  <D3CPeerGroupTable
                    tableTitle="KPI Trends"
                    copyId="kpi-trends-table-2"
                    rows={trendsData.KpiTrends}
                  ></D3CPeerGroupTable>
                )}
                {hasRteContent(trendsData.D3cPeerGroupAnalysisTextList) && (
                  <div className={classes.rteReader}>
                    <RichTextReader
                      editorState={EditorState.createWithContent(
                        convertFromRaw(trendsData.D3cPeerGroupAnalysisTextList)
                      )}
                    />
                  </div>
                )}
              </Box>
            )}
          </>
        )}
        {trendsData.AnnualFinancials && (
          <>
            <Box className="splitFlag">
              {trendsData.KpiTrends?.[0] && (
                <D3CPeerGroupTable
                  tableTitle="Annual Financials"
                  copyId="annual-financials-table"
                  rows={annualFinancials}
                  isAnnualFinancials={true}
                ></D3CPeerGroupTable>
              )}
            </Box>
            <Box
              className="splitFlag"
              style={{ paddingTop: "15px", height: "450px" }}
            >
              <Line
                options={{
                  animation: false,
                  maintainAspectRatio: false,
                  responsive: true,
                  pointRadius: 6,
                  scales: {
                    y: {
                      grace: "5%",
                      title: {
                        display: true,
                        text: "Days",
                        font: {
                          size: 18,
                        },
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      position: "top",
                      labels: { font: { weight: 700 } },
                    },
                    title: {
                      display: true,
                      text: `Operational KPI Trends`,
                      font: {
                        size: 24,
                      },
                    },
                    datalabels: {
                      align: "end",
                      anchor: "end",
                      display: "auto",
                      color: "black",
                      formatter: function (value, context) {
                        return value.toFixed(0);
                      },
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          return `${
                            context.dataset.label
                          }: ${context.raw.toFixed(1)}`;
                        },
                      },
                    },
                  },
                }}
                data={getLineData(annualFinancials)}
              />
            </Box>
          </>
        )}
      </>
    );
  } else if (trendsData.Trends[0]?.Image) {
    // Just legacy stuff here and below.
    return (
      <div>
        {trendsData.Trends.map((row, index) => {
          if (!row.Image) {
            return <></>;
          }
          return (
            <Box className="splitFlag">
              <Image
                key={index}
                src={row.Image}
                className={classes.image}
                alt={`Trend ${index}`}
              />
            </Box>
          );
        })}
      </div>
    );
  } else if (trendsData.Trends[0]?.Profit) {
    return (
      <Box className="splitFlag">
        <Bar
          options={getOptions("USD")}
          data={getBarDataOld(trendsData.Trends)}
        />
      </Box>
    );
  } else {
    return null;
  }
}

function CompanyCell(props) {
  const classes = useStyles();
  const row = props.row;
  const isD3cPeerGroup = props.isD3cPeerGroup;

  if (!row) {
    return null;
  }

  if (row.icon) {
    return (
      <MuiTooltip title={row.companyName?.label}>
        {/* div needed for tooltip with custom Image component */}
        <div style={{ maxWidth: "100px" }}>
          <Stack>
            <Image
              src={row.icon}
              className={classes.icon}
              alt={`${row.companyName?.label} icon`}
            />
            {isD3cPeerGroup && (
              <Typography
                variant="caption"
                sx={{ fontStyle: "italic", mt: "10px" }}
              >
                {"FY " + row.lastCalendarYear}
              </Typography>
            )}
          </Stack>
        </div>
      </MuiTooltip>
    );
  } else if (row.companyName?.label === "Peer Average") {
    return row.companyName.label;
  } else if (row.stockSymbol) {
    return row.stockSymbol;
  } else if (row?.companyName?.label) {
    return row?.companyName?.label;
  } else {
    return null;
  }
}

function getCompanyNameColumnValue(row) {
  if (row.companyName?.label === "Peer Average") {
    return "";
  } else {
    return row.companyName?.label;
  }
}

function KpiTable(props) {
  const rows = props.rows;

  if (!rows[0].stockSymbol && !rows[0].companyName) {
    return null;
  }

  function getRowRange(row) {
    return row?.firstCalendarYear
      ? `(${row.firstCalendarYear} - ${row.lastCalendarYear})`
      : "";
  }

  let yearRange = getRowRange(rows?.[0]);
  let lastCalendarYear = rows?.[0]?.lastCalendarYear;

  // Don't print years in column header if rows are inconsistent.
  if (
    !rows.every(
      (row) =>
        row.companyName?.label === "Peer Average" ||
        getRowRange(row) === yearRange
    )
  ) {
    yearRange = "(last 4 years)";
    lastCalendarYear = "Last Year";
  }

  function getSingleYearColumnHeader(columnName) {
    return `${lastCalendarYear} ${columnName}`;
  }

  function getYearRangeColumnHeader(columnName) {
    return `${columnName} ${yearRange}`;
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", ...scrollbarStyle }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="kpi table" id={props.copyId}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ minWidth: "80px" }}>
                Company
              </StyledTableCell>
              {/* Empty cell for company name column */}
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="right">
                {getSingleYearColumnHeader("Revenues")}
              </StyledTableCell>
              <StyledTableCell align="right">
                {getYearRangeColumnHeader("Rev CAGR")}
              </StyledTableCell>
              <StyledTableCell align="right">
                {getSingleYearColumnHeader("Net Income")}
              </StyledTableCell>
              <StyledTableCell align="right" style={{ minWidth: "120px" }}>
                {getYearRangeColumnHeader("Net Income CAGR")}
              </StyledTableCell>
              <StyledTableCell align="right">
                {getSingleYearColumnHeader("Net Income Margin")}
              </StyledTableCell>
              <StyledTableCell align="right" style={{ minWidth: "120px" }}>
                {getYearRangeColumnHeader("Average Net Income Margin")}
              </StyledTableCell>
              <StyledTableCell align="right">
                {getSingleYearColumnHeader("Return on Equity")}
              </StyledTableCell>
              <StyledTableCell align="right" style={{ minWidth: "120px" }}>
                {getYearRangeColumnHeader("Average Return on Equity")}
              </StyledTableCell>
              {/* Newly added column */}
              {"inventory" in rows[0] && (
                <StyledTableCell align="right">
                  {getSingleYearColumnHeader("Inventory")}
                </StyledTableCell>
              )}
              <StyledTableCell align="right">
                {getSingleYearColumnHeader("Days Inventory Outstanding")}
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const isPeerAverage = row.companyName?.label === "Peer Average";
              return (
                <StyledTableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    fontStyle: `${isPeerAverage ? "italic" : "normal"}`,
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    <CompanyCell row={row} />
                  </StyledTableCell>
                  <StyledTableCell>
                    {getCompanyNameColumnValue(row)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {!isPeerAverage
                      ? numberAbbrev(row.currency, row.revenue)
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {percentFormatter(row.revCAGR)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {!isPeerAverage
                      ? numberAbbrev(row.currency, row.netIncome)
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {percentFormatter(row.netIncomeCAGR)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {percentFormatter(row.netIncomePercent)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {percentFormatter(row.netIncomePercent4YearAvg)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {percentFormatter(row.roe)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {percentFormatter(row.roe4YearAvg)}
                  </StyledTableCell>
                  {/* Newly added column */}
                  {"inventory" in rows[0] && (
                    <StyledTableCell align="right">
                      {!isPeerAverage
                        ? numberAbbrev(row.currency, row.inventory)
                        : ""}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="right">
                    {row.daysInventoryOutstanding
                      ? parseFloat(row.daysInventoryOutstanding).toFixed(2)
                      : ""}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <MuiTooltip title={`Copy ${props.tableTitle} table`}>
        <IconButton
          sx={{ float: "right" }}
          size="small"
          onClick={() => {
            copyTableId(props.copyId);
          }}
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </MuiTooltip>
    </>
  );
}

function getOptions(currency) {
  // Formatter here's only purpose is fetching currency symbol.
  const moneyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  });
  return {
    animation: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Revenue and Net Income (${currency} in Millions)`,
        font: {
          size: 24,
        },
      },
      datalabels: {
        display: true,
        color: "black",
        anchor: "end",
        align: "end",
        formatter: function (value, context) {
          return moneyFormatter.format(value) + "M";
        },
      },
    },
  };
}

function getBarData(revenueNetIncome) {
  const reversed = [...revenueNetIncome].reverse();
  return {
    labels: reversed.map((yearData) => yearData.calendarYear),
    datasets: [
      {
        label: "Revenue",
        data: reversed.map((yearData) => yearData.revenue / 1000000),
        backgroundColor: "#6fa9db",
      },
      {
        label: "Net Income",
        data: reversed.map((yearData) => yearData.netIncome / 1000000),
        backgroundColor: "#92c57c",
      },
    ],
  };
}

function getLineData(annualFinancials) {
  const reversed = annualFinancials.reverse();
  return {
    labels: reversed.map((yearData) => yearData.lastCalendarYear),
    datasets: [
      {
        label: "Days Inventory Outstanding",
        data: reversed.map((yearData) => yearData.daysInventoryOutstanding),
        backgroundColor: "rgba(255, 99, 132, 1)",
        borderColor: "rgba(255, 99, 132, 1)",
      },
      {
        label: "Days Payable Outstanding",
        data: reversed.map((yearData) => yearData.daysPayableOutstanding),
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
      },
      {
        label: "Days Sales Outstanding",
        data: reversed.map((yearData) => yearData.daysSalesOutstanding),
        backgroundColor: "rgba(255, 206, 86, 1)",
        borderColor: "rgba(255, 206, 86, 1)",
      },
      {
        label: "Cash Conversion Cycle",
        data: reversed.map((yearData) => yearData.cashConversionCycle),
        backgroundColor: "rgba(75, 192, 192, 1)",
        borderColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };
}

function getBarDataOld(trendsData) {
  return {
    labels: trendsData.map((yearData) => yearData.name),
    datasets: [
      {
        label: "Revenue",
        data: trendsData.map((yearData) => yearData.Revenue / 1000000),
        backgroundColor: "#6fa9db",
      },
      {
        label: "Profit",
        data: trendsData.map((yearData) => yearData.Profit / 1000000),
        backgroundColor: "#92c57c",
      },
    ],
  };
}

function D3CPeerGroupTable(props) {
  const rows = props.rows;
  const isAnnualFinancials = props.isAnnualFinancials;

  if (rows != null) {
    return (
      <>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", ...scrollbarStyle }}
        >
          <Table
            sx={{ minWidth: 650 }}
            aria-label="kpi table"
            id={props.copyId}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Company</StyledTableCell>
                {/* Empty cell for company name column */}
                {!isAnnualFinancials && <StyledTableCell></StyledTableCell>}
                <StyledTableCell align="right">Revenues</StyledTableCell>
                <StyledTableCell align="right">
                  Accounts Receivable
                </StyledTableCell>
                <StyledTableCell align="right">
                  Accounts Payable
                </StyledTableCell>
                <StyledTableCell align="right">COGS</StyledTableCell>
                <StyledTableCell align="right">Inventory</StyledTableCell>
                <StyledTableCell align="right">
                  Operating Cash Flow
                </StyledTableCell>
                <StyledTableCell align="right">EBIDTA</StyledTableCell>
                <StyledTableCell align="right">
                  Days Inventory Outstanding
                </StyledTableCell>
                <StyledTableCell align="right">
                  Days Sales Outstanding
                </StyledTableCell>
                <StyledTableCell align="right">
                  Days Payable Outstanding
                </StyledTableCell>
                <StyledTableCell align="right">
                  Cash Conversion Cycle
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isPeerAverage = row.companyName?.label === "Peer Average";
                return (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      fontStyle: `${isPeerAverage ? "italic" : "normal"}`,
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <CompanyCell row={row} isD3cPeerGroup={true} />
                    </StyledTableCell>
                    {!isAnnualFinancials && (
                      <StyledTableCell>
                        {getCompanyNameColumnValue(row)}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="right">
                      {!isPeerAverage
                        ? numberAbbrev(
                            row.currency,
                            row.revenue ? row.revenue : row.sales
                          )
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {percentFormatter(row.accountsReceivablesPercent)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {percentFormatter(row.accountsPayablesPercent)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {percentFormatter(row.cogsPercent)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {percentFormatter(row.inventoryPercent)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {percentFormatter(row.operatingCashFlowPercent)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {percentFormatter(row.ebitdaPercent)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.daysInventoryOutstanding
                        ? parseFloat(row.daysInventoryOutstanding).toFixed(2)
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.daysSalesOutstanding
                        ? parseFloat(row.daysSalesOutstanding).toFixed(2)
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.daysPayableOutstanding
                        ? parseFloat(row.daysPayableOutstanding).toFixed(2)
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.cashConversionCycle
                        ? parseFloat(row.cashConversionCycle).toFixed(2)
                        : ""}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <MuiTooltip title={`Copy ${props.tableTitle} table`}>
          <IconButton
            sx={{ float: "right" }}
            size="small"
            onClick={() => {
              copyTableId(props.copyId);
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </MuiTooltip>
      </>
    );
  } else {
    return <div></div>;
  }
}
