import React, { useEffect } from "react";
import { Box, Typography, Avatar, Stack, Link, Button } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import Image, { getSrcFromApi } from "../common/Image";
import { getColorFromString } from "../common/Utils";
import PeopleConnectionsList from "../common/PeopleConnectionsList";
import CircularProgress from "@mui/material/CircularProgress";
import { useUser } from "../context/UserContext";
import _ from "lodash";

export default function ConnectionsList(props) {
  const { peopleConnections, orgName } = props;

  const [loadedConnections, setLoadedConnections] = React.useState(
    peopleConnections.slice(0, 20)
  );
  const [initiated, setInitiated] = React.useState(false);
  const [pagesLoaded, setPagesLoaded] = React.useState(1);
  const [imageMap, setImageMap] = React.useState(new Map());
  const user = useUser();

  function loadMoreConnections() {
    setLoadedConnections(peopleConnections.slice(0, (pagesLoaded + 1) * 20));
    setPagesLoaded(pagesLoaded + 1);
  }

  function handleConnectionsScroll(e) {
    // Load 20 more connections into the list when the user scrolls to the bottom
    let element = e.target;
    if (element.clientHeight + element.scrollTop === element.scrollHeight) {
      loadMoreConnections();
    }
  }

  useEffect(() => {
    // Allows modal to open instantly without delay from image loading (feeling of unresponsiveness)
    setTimeout(() => setInitiated(true), 500);
  }, []);

  useEffect(() => {
    // Get the org people images as they appear in loaded connections.
    // Improves performance over getting each Image individually as these have a high rate of duplicates.
    const imageRequests = new Map();
    loadedConnections.forEach((person, index) => {
      person.connections?.forEach((connection) => {
        if (
          !imageRequests.has(connection.linkedinProfileId) &&
          !imageMap.has(connection.linkedinProfileId)
        ) {
          if (connection.personInfo.avatar_url) {
            imageRequests.set(
              connection.linkedinProfileId,
              getSrcFromApi(connection.personInfo.avatar_url, user)
            );
          }
        }
      });
    });
    const imageMapCopy = _.clone(imageMap);
    Promise.all(imageRequests.values()).then((body) => {
      body.forEach((image, index) => {
        imageMapCopy.set(Array.from(imageRequests.keys())[index], image);
      });
      setImageMap(imageMapCopy);
    });
  }, [loadedConnections]);

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      {!initiated ? (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Box
          sx={{
            pr: 1,
            maxHeight: "calc(70vh - 80px)",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "&::-webkit-scrollbar-track": {
              margin: "5px 0px 5px 0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.1)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgba(0,0,0,.15)",
            },
          }}
          onScroll={handleConnectionsScroll}
        >
          {loadedConnections.map((person, index) => (
            <React.Fragment
              key={person.personInfo.full_name + index + "-fragment"}
            >
              <div
                style={{
                  borderRadius: "8px",
                  width: "100%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {person.personInfo.avatar_url !== null ? (
                    <Image
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "70px",
                        padding: "10px",
                      }}
                      src={person.personInfo.avatar_url}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "70px",
                        padding: "10px",
                        margin: "10px",
                        backgroundColor: getColorFromString(
                          person.personInfo.full_name
                        ),
                      }}
                    >
                      {person.personInfo.full_name.charAt(0)}
                    </Avatar>
                  )}
                  <Stack>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="h6">
                        {person.personInfo.full_name}
                      </Typography>
                      <Link
                        sx={{ width: "fit-content", mr: 6 }}
                        href={
                          "https://www.linkedin.com/in/" +
                          person.linkedinProfileId
                        }
                        target="_blank"
                      >
                        <LinkedIn
                          sx={{ ml: 1, mt: "-2px" }}
                          style={{
                            position: "relative",
                            color: "#0e76a8",
                            top: "5px",
                          }}
                        />
                      </Link>
                    </div>
                    <Typography variant="body2" style={{}}>
                      {person.personInfo.title}
                    </Typography>
                    {person.connections && (
                      <PeopleConnectionsList
                        orgName={orgName}
                        connections={person.connections}
                        displayEcosystemOrgs={true}
                        imageMap={imageMap}
                      ></PeopleConnectionsList>
                    )}
                  </Stack>
                </Box>
              </div>
            </React.Fragment>
          ))}
          <Button
            sx={{ width: "100%", height: "40px", textTransform: "none" }}
            onClick={loadMoreConnections}
          >
            Load more connections
          </Button>
        </Box>
      )}
    </Box>
  );
}
