import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { rule5properties } from "../properties";
import {
  CustomPopper,
  ErrLabel,
  StyledLabel,
  StyledTextfield,
  listboxStyle,
} from "../common/StyledComponents";
import { Controller, useForm } from "react-hook-form";
import { useDialog } from "../context/DialogContext";
import { useQueryClient } from "react-query";

export default function ConfigureSlackChannel(props) {
  const { modConfig } = props;

  const dialog = useDialog();
  const { control, getValues } = useForm({
    defaultValues: modConfig
      ? {
          publishedResearchNotification:
            modConfig.channelInfo?.publishedResearchNotification,
          sharedOpportunityNotification:
            modConfig.channelInfo?.sharedOpportunityNotification,
          updatedResearchNotification:
            modConfig.channelInfo?.updatedResearchNotification,
        }
      : {},
  });

  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [availableChannels, setAvailableChannels] = React.useState([]);
  const [selectedChannel, setSelectedChannel] = React.useState(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    // Once channels are available, if there is a modConfig provided, set the selected channel.
    if (modConfig) {
      availableChannels.forEach((channel) => {
        if (modConfig.channelId === channel.id) {
          setSelectedChannel(channel);
        }
      });
    }
  }, [availableChannels, modConfig]);

  useEffect(() => {
    axios.get(rule5properties.slackListChannels).then((res) => {
      if (res.status === 200 && Array.isArray(res.data)) {
        setAvailableChannels(res.data);
      }
      setLoading(false);
    });
  }, []);

  function saveNewUserMessageChannel() {
    // Adding a new channel config
    let values = getValues();
    const params = {
      channelId: selectedChannel.id,
      system: "Slack",
      channelName: selectedChannel.name,
      channelType: "Notification",
      channelInfo: values,
    };
    axios
      .post(rule5properties.userMessageChannels, params)
      .then((res) => {
        if (res.status === 200) {
          // Let react-query know that slackConfig data is stale now
          queryClient.invalidateQueries({
            queryKey: ["slackConfig"],
            refetchType: "none",
          });
          dialog.closeModal();
        } else {
          if (res.data?.message) {
            setErrorMessage(res.data.message);
          }
          console.log(res.status + ": Failed to update user message channel.");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function updateUserMessageChannel() {
    if (modConfig) {
      let values = getValues();
      const params = {
        channelId: selectedChannel.id,
        system: "Slack",
        channelName: selectedChannel.name,
        channelType: "Notification",
        channelInfo: values,
      };
      setLoading(true);
      axios
        .patch(
          rule5properties.userMessageChannels + `/${parseInt(modConfig.id)}`,
          params
        )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            // Let react-query know that slackConfig data is stale now
            queryClient.invalidateQueries({
              queryKey: ["slackConfig"],
              refetchType: "none",
            });
            dialog.closeModal();
          } else {
            if (res.data?.message) {
              setErrorMessage(res.data.message);
            }
            console.log(
              res.status + ": Failed to update user message channel."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }

  return (
    <Box>
      <Autocomplete
        disabled={loading}
        autoComplete
        autoHighlight
        autoSelect
        openOnFocus
        blurOnSelect
        includeInputInList
        disableClearable
        renderOption={(props, option) => {
          return (
            <Box {...props}>
              <Typography variant="body1">#{option.name}</Typography>
            </Box>
          );
        }}
        value={selectedChannel}
        onChange={(e, val) => {
          setSelectedChannel(val);
        }}
        getOptionLabel={(x) => `#${x.name}`}
        ListboxProps={{ sx: listboxStyle }}
        PopperComponent={CustomPopper}
        options={availableChannels}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <React.Fragment>
              <StyledLabel>Channel</StyledLabel>
              <StyledTextfield
                id="channel-name"
                {...params.InputProps}
                {...rest}
                autoComplete="off"
                disabled={loading}
              />
            </React.Fragment>
          );
        }}
        sx={{ mb: 3 }}
      />
      <StyledLabel>Notifications</StyledLabel>
      <FormGroup sx={{ flexGrow: 1 }}>
        <FormControlLabel
          sx={{ fontSize: "10px" }}
          control={
            <Controller
              name="publishedResearchNotification"
              control={control}
              defaultValue={true}
              render={({ field, onChange }) => (
                <Checkbox {...field} checked={field.value} />
              )}
            />
          }
          label={
            <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
              New research published
            </Typography>
          }
        />
        <FormControlLabel
          sx={{ fontSize: "10px" }}
          control={
            <Controller
              name="updatedResearchNotification"
              control={control}
              defaultValue={true}
              render={({ field, onChange }) => (
                <Checkbox {...field} checked={field.value} />
              )}
            />
          }
          label={
            <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
              Research updates
            </Typography>
          }
        />
      </FormGroup>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Button
          disabled={!selectedChannel || loading}
          type="submit"
          variant="outlined"
          sx={{ textTransform: "none", my: 2, width: "200px" }}
          onClick={
            modConfig ? updateUserMessageChannel : saveNewUserMessageChannel
          }
        >
          Save preferences
        </Button>
      </Stack>
      <Collapse in={errorMessage.length > 0}>
        <ErrLabel>{errorMessage}</ErrLabel>
      </Collapse>
    </Box>
  );
}
