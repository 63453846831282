import React, { useEffect } from "react";
import {
  Checkbox,
  FormGroup,
  Stack,
  Typography,
  FormControlLabel,
  Box,
  Button,
  Divider,
  Fade,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { rule5properties } from "../properties";

export default function Notifications(props) {
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm();

  const onSubmit = (data) => console.log(data);
  const [loaded, setLoaded] = React.useState(false);

  function saveNotificationSettings() {
    let params = getValues();
    axios
      .patch(rule5properties.updateNotificationPreferences, params)
      .then((res) => {
        reset(params);
      });
  }

  useEffect(() => {
    axios.get(rule5properties.getNotificationPreferences).then((res) => {
      if (res.status === 200) {
        console.log("Setting form defaults.");
        reset(res.data);
        setLoaded(true);
      }
    });
  }, [reset]);

  return (
    <Fade in={loaded}>
      <Stack sx={{ width: "500px", flexGrow: 1 }}>
        <Typography variant="h5" sx={{ width: "100%", mb: 2, fontWeight: 500 }}>
          Notification Preferences
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", mb: 1, opacity: 0.9 }}
          >
            <Typography variant="settingsHeader">
              Email Notifications
            </Typography>
          </Box>
          <FormGroup onSubmit={handleSubmit(onSubmit)} sx={{ flexGrow: 1 }}>
            <FormControlLabel
              sx={{ fontSize: "10px" }}
              control={
                <Controller
                  name="publishedResearchNotification"
                  control={control}
                  defaultValue={true}
                  render={({ field, onChange }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label={
                <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                  New research published
                </Typography>
              }
            />
            <FormControlLabel
              sx={{ fontSize: "10px" }}
              control={
                <Controller
                  name="sharedOpportunityNotification"
                  control={control}
                  defaultValue={true}
                  render={({ field, onChange }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label={
                <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                  Opportunity shared with you
                </Typography>
              }
            />
            <FormControlLabel
              sx={{ fontSize: "10px" }}
              control={
                <Controller
                  name="updatedResearchNotification"
                  control={control}
                  defaultValue={true}
                  render={({ field, onChange }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label={
                <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                  Research updates
                </Typography>
              }
            />
          </FormGroup>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Button
              disabled={!isDirty}
              type="submit"
              variant="outlined"
              sx={{ textTransform: "none", mt: 4, width: "200px" }}
              onClick={saveNotificationSettings}
            >
              Save preferences
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Fade>
  );
}
