import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import Toolbar from "../common/Toolbar";
import Box from "@mui/system/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { rule5properties } from "../properties";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Image from "../common/Image";
import _ from "lodash";
import { Chip, useTheme } from "@mui/material";

const useStyles = makeStyles(() => ({
  container: {
    overflow: "hidden",
  },
  workspace: {
    maxWidth: "100%",
    overflowY: "auto",
    padding: "0px",
    backgroundColor: "#FAFAFA",
    color: "#000000",
    height: "calc(100vh - 72px)",
    boxShadow: "inset 0 0 1px rgb(0,0,0,.2)",
  },
}));

export default function ManageCompanies(props) {
  const classes = useStyles();
  const [companies, setCompanies] = React.useState(props.companies);
  const [tickers, setTickers] = React.useState([]);
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    register,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: { ticker: "", ...props.company, secondaryDomains: [] },
  });
  const [response, setResponse] = React.useState(null);
  const [icon, setIcon] = React.useState(null);
  const [logo, setLogo] = React.useState(null);
  const theme = useTheme();

  useEffect(() => {
    axios.get(rule5properties.detCompanies).then((response) => {
      setCompanies(response.data);
    });
  }, [response]);

  if (!companies) {
    return "Loading...";
  }

  const loadTickers = (name) => {
    if (name) {
      axios
        .get(rule5properties.detStocks + "?name=" + encodeURIComponent(name))
        .then((response) => {
          if (Array.isArray(response.data)) {
            setTickers(response.data);
          }
        });
    } else {
      setTickers([]);
    }
  };

  const uploadImages = async (data) => {
    if ((data.icon?.[0] || data.logo?.[0]) && data.id) {
      // If only one image was given, just set it to both.
      if (!data.icon?.[0]) {
        data.icon = data.logo;
      }
      if (!data.logo?.[0]) {
        data.logo = data.icon;
      }
      const formData = new FormData();
      formData.append("icon", data.icon[0]);
      formData.append("logo", data.logo[0]);
      formData.append("id", data.id.id);

      axios
        .patch(rule5properties.detCompaniesImage, formData)
        .catch(function (error) {
          alert("ERROR: " + JSON.stringify(error));
        })
        .then((response) => {
          setResponse(response.data);
          setIcon(response.data.icon);
          setLogo(response.data.logo);
          return;
        });
    } else {
      return Promise.resolve();
    }
  };

  const onSubmitCompany = (data) => {
    const iconsRemoved = _.pickBy(
      _.omit(data, ["icon", "logo"]),
      (value) => value?.length > 0 || _.isBoolean(value) // Remove empty form fields.
      // In case of e.g. ticker: making this request with no ticker attribute is the way
      // to remove an existing ticker
    );
    // Creating new companies
    if (data.id == null) {
      delete iconsRemoved.active;
      delete iconsRemoved.id;
      axios
        .post(rule5properties.detCompanies, iconsRemoved)
        .catch(function (error) {
          alert("ERROR: " + JSON.stringify(error));
        })
        .then((response) => {
          // Now can upload images once have the id generated and returned;
          data.id = { id: response.data.id, label: data.name };
          setResponse(response.data);
          if (response.data.id) {
            axios.get(rule5properties.detCompanies).then((response) => {
              setCompanies(response.data);
              props.updateCompaniesCallback?.(response.data);
              setTimeout(() => setValue("id", data.id));
            });

            uploadImages(data);
          }
        });
    } else {
      // Update existing companies
      // Separate api call for icon/logo
      uploadImages(data).then((unused) => {
        iconsRemoved.id = data.id.id;
        axios
          .patch(rule5properties.detCompanies, iconsRemoved)
          .catch(function (error) {
            alert("ERROR: " + JSON.stringify(error));
          })
          .then((response) => {
            setResponse(response.data);
          });
      });
    }
  };

  return (
    <div
      style={{
        height: "100%",
        maxWidth: "100%",
        paddingBottom: `${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Toolbar>
        <div className={classes.title}>Manage Buyer Companies</div>
      </Toolbar>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          overflow: "auto",
          borderRadius: "8px",
          flexDirection: "column",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        <Box sx={{ padding: "15px" }}>
          <Box
            sx={{
              "& .MuiTextField-root": { width: "225px" },
              padding: "20px",
            }}
          >
            <form onSubmit={handleSubmit(onSubmitCompany)}>
              <h4>Update existing companies:</h4>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    options={companies.map((row, index) => {
                      return { label: row.name, id: row.id };
                    })}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%" }}
                        {...params}
                        label="Company"
                      />
                    )}
                    onChange={(e, data) => {
                      if (data) {
                        const company = companies.find(
                          (company) => company.id === data.id
                        );
                        const companyInfo = company.companyInfo;
                        setValue("ticker", companyInfo.ticker);
                        loadTickers(company.name);
                        setValue("domain", company.domain);
                        setValue(
                          "secondaryDomains",
                          companyInfo.secondary_domains || []
                        );
                        setValue("active", company.active);
                        setValue("name", company.name);
                        if (companyInfo.logo && companyInfo.icon) {
                          setLogo(companyInfo.logo);
                          setIcon(companyInfo.icon);
                        } else {
                          setLogo(null);
                          setIcon(null);
                        }
                      } else {
                        setLogo(null);
                        setIcon(null);
                        reset({ secondaryDomains: [] });
                        setResponse(null);
                      }
                      return field.onChange(data || null);
                    }}
                  />
                )}
                defaultValue={props.company ? props.company : null}
                name="id"
                control={control}
              />
              <br />
              <br />
              {!watch("id") && <h3>Or create new:</h3>}

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "20px",
                }}
              >
                <div>
                  <div style={{}}>
                    <input
                      {...register(`active`)}
                      type="checkbox"
                      defaultChecked={true}
                    />
                    <label>Is Active</label>
                  </div>
                  <h4>Name</h4>
                  <input style={{ width: "100%" }} {...register(`name`)} />
                  <h4>Domain</h4>
                  <input style={{ width: "100%" }} {...register(`domain`)} />
                  <h4>Secondary Domains</h4>
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        options={[]}
                        onChange={(e, data) => field.onChange(data)}
                        freeSolo
                        autoSelect
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            style={{ width: "100%" }}
                            // value={""}
                            {...params}
                            variant="filled"
                            label="Secondary domains"
                            placeholder="Press enter to add entries"
                          />
                        )}
                      />
                    )}
                    name={"secondaryDomains"}
                    type="select"
                    control={control}
                  />
                  <h4>Ticker</h4>
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        freeSolo
                        onInputChange={(e) => {
                          if (e?.target?.value) {
                            field.onChange(e.target.value);
                          }
                        }}
                        onChange={(e, data) => {
                          // Case if selected ticker option came from tickers endpoint
                          if (data?.ticker) {
                            field.onChange(data?.ticker);
                          } else {
                            // Otherwise if ticker is cleared.
                            field.onChange(e.target?.value || "");
                          }
                        }}
                        value={field.value}
                        options={tickers}
                        getOptionLabel={(option) => option?.ticker || option}
                        renderInput={(params) => (
                          <TextField style={{ width: "100%" }} {...params} />
                        )}
                      />
                    )}
                    name={"ticker"}
                    control={control}
                  />
                  <br />
                  <br />
                  <Button
                    style={{ textTransform: "none", marginTop: "1%" }}
                    variant="contained"
                    type="submit"
                    disabled={!isDirty}
                  >
                    {watch("id") == null ? "Create Company" : "Update details"}
                  </Button>
                </div>
                <div>
                  {icon != null && (
                    <div style={{ display: "flex", padding: "15px 0px" }}>
                      Icon:
                      <Image
                        src={icon}
                        style={{
                          height: "auto",
                          maxWidth: 40,
                          maxHeight: 40,
                          display: "block",
                          border: "1px solid black",
                        }}
                      />
                    </div>
                  )}
                  {logo != null && (
                    <div style={{ display: "flex", paddingBottom: "15px" }}>
                      Logo:
                      <Image
                        src={logo}
                        style={{
                          height: "auto",
                          maxWidth: 160,
                          maxHeight: 400,
                          display: "block",
                          border: "1px solid black",
                        }}
                      />
                    </div>
                  )}
                  <h4>Set logo</h4>
                  <input
                    type="file"
                    {...register(`logo`)}
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />
                  <br />
                  <h4>Set Icon</h4>
                  <input
                    type="file"
                    {...register(`icon`)}
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />

                  {response && (
                    <>
                      {response.message ? (
                        <Typography variant="h5">{response.message}</Typography>
                      ) : (
                        <div>
                          <Typography variant="h5" sx={{ padding: "15px" }}>
                            Successfully updated company {response.name} at{" "}
                            {new Date().toLocaleString()}!
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ paddingLeft: "15px" }}
                          >
                            {response.icon}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ paddingLeft: "15px" }}
                          >
                            {response.logo}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </form>
          </Box>
        </Box>
      </Card>
    </div>
  );
}
