import {
  Box,
  Card,
  Collapse,
  Stack,
  Typography,
  Avatar,
  IconButton,
  Divider,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CloseIcon from "@mui/icons-material/Close";
import { getColorFromString, getRelativeTimestamp } from "../Utils";

/* Aggregates all notifications by company and functional area. Solution map is a map with company IDs as keys.
 ** The value for each company ID is an map of opportunity IDs. The value for each opportunity ID is an array of
 ** notifications. */
function aggregateNotifications(notificationArray) {
  const companyMap = new Map();
  notificationArray?.forEach((notif) => {
    let opportunityMap;
    let company = {
      companyId: notif.companyId,
      companyName: notif.companyName,
    };
    if (companyMap.has(notif.companyId)) {
      // Company ID already exists in the map, check to see if the opportunity exists in the company map.
      opportunityMap = companyMap.get(notif.companyId).opportunities;
      let opportunity;
      if (opportunityMap.has(notif.opportunityId ? notif.opportunityId : -1)) {
        // Opportunity ID already exists - there have already been notifications added for this opportunity.
        opportunity = opportunityMap.get(notif.opportunityId);
        opportunity.notifications?.push(notif);
        opportunityMap.set(notif.opportunityId, opportunity);
      } else {
        // Array of notifications for this opportunity has not been initialized.
        opportunity = {
          opportunityId: notif.opportunityId,
          functionalArea: notif.functionalArea,
          notifications: [notif],
        };
        opportunityMap.set(
          notif.opportunityId ? notif.opportunityId : -1,
          opportunity
        );
      }
    } else {
      // Company has no notifications yet, must create a map of opportunity IDs for it with an initialized array of
      // notifications.
      // Has an opportunity ID.
      opportunityMap = new Map();
      let opportunity = {
        opportunityId: notif.opportunityId,
        functionalArea: notif.functionalArea,
        notifications: [notif],
      };
      // If opportunity ID exists, set it to the correct opportunity ID - otherwise, use -1 to represent no-opp
      // notification.
      opportunityMap.set(
        notif.opportunityId ? notif.opportunityId : -1,
        opportunity
      );
    }
    // Update the modified opportunity map in the company map.
    company.opportunities = opportunityMap;
    companyMap.set(notif.companyId, company);
  });
  return Array.from(companyMap.values());
}

export default function CompanyNotifications(props) {
  const [loadedNotifications, setLoadedNotifications] = React.useState([]);
  useEffect(() => {
    // Call endpoint to get notifications.
    setLoadedNotifications(aggregateNotifications(props.notifData));
  }, []);
  return (
    <Box sx={{ mt: 2 }}>
      {loadedNotifications.map((company) => (
        <CompanyItem company={company} {...props} />
      ))}
      {loadedNotifications.length === 0 ? (
        <Stack sx={{ flexGrow: 1, mt: 3 }} alignItems="center">
          <Typography sx={{ opacity: 0.6 }}>
            No new company updates to display.
          </Typography>
        </Stack>
      ) : null}
    </Box>
  );
}

function CompanyItem(props) {
  const { company } = props;
  const opportunities = Array.from(company.opportunities?.values());

  return (
    <Box sx={{ mt: 1, borderRadius: "8px" }}>
      <Stack direction="horizontal" alignItems="center" sx={{ mb: 1 }}>
        <Avatar
          sx={{
            ml: 1,
            width: "40px",
            height: "40px",
            bgcolor: getColorFromString(company.companyName),
          }}
        >
          {company.companyName?.toUpperCase().charAt(0)}
        </Avatar>
        <Stack sx={{ ml: 1, px: 1, flexGrow: 1 }}>
          <Typography
            noWrap
            sx={{
              fontWeight: 500,
              opacity: 0.9,
            }}
          >
            {company.companyName}
          </Typography>
          <Typography
            noWrap
            sx={{
              opacity: 0.5,
            }}
          >
            {`${opportunities?.length} opportunit${
              opportunities?.length > 1 ? "ies" : "y"
            }`}
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ ml: 1 }}>
        {opportunities?.map((opportunity, index) => (
          <OpportunityItem
            opportunity={opportunity}
            companyName={company.companyName}
            key={index}
            {...props}
          />
        ))}
      </Box>
      <Divider sx={{ my: 2 }} />
    </Box>
  );
}

function OpportunityItem(props) {
  const { opportunity, companyName } = props;
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        mt: 0.5,
        px: 2,
        py: 1,
        borderRadius: "6px",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,.03)",
        },
        cursor: "pointer",
      }}
      onClick={() => {
        props.clickFunction(opportunity.notifications);
      }}
    >
      {getFunctionalAreaIcon(opportunity.functionalArea)}
      <Box sx={{ overflow: "hidden" }}>
        <Stack direction="row" alignItems="center">
          <Typography
            noWrap
            sx={{
              fontWeight: 500,
              opacity: 0.9,
              textOverflow: "ellipsis",
            }}
          >
            {opportunity.functionalArea
              ? opportunity.functionalArea
              : "Company Update"}
          </Typography>
          <Typography sx={{ mx: 0.7, opacity: 0.6 }}>•</Typography>
          <Typography sx={{ opacity: 0.6, fontSize: "15px" }}>
            {getRelativeTimestamp(
              opportunity.notifications.map((item) => item.notificationDate)
            )}
          </Typography>
        </Stack>
        <Box>
          <Typography sx={{ opacity: 0.6, mt: 0.5 }}>
            {opportunity.notifications[0].notificationInfo?.Description}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
}

function getFunctionalAreaIcon(type) {
  switch (type) {
    case "Marketing":
      return <AnalyticsIcon fontSize="medium" sx={{ opacity: 0.4, mr: 2 }} />;
    case "Supply Chain":
      return (
        <LocalShippingIcon fontSize="medium" sx={{ opacity: 0.4, mr: 2 }} />
      );
    case "Information Technology":
      return (
        <DeveloperBoardIcon fontSize="medium" sx={{ opacity: 0.4, mr: 2 }} />
      );
    default:
      return (
        <ErrorOutlineIcon fontSize="medium" sx={{ opacity: 0.4, mr: 2 }} />
      );
  }
}

function getNotificationIcon(type) {
  switch (type) {
    case "research":
      return <SearchIcon />;
    default:
      return <ErrorOutlineIcon />;
  }
}
