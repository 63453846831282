import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Researchcard from "./Researchcard";
import axios from "axios";
import { rule5properties } from "../../../../properties";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { Line } from "react-chartjs-2";
import { Chart, Filler, TimeSeriesScale } from "chart.js";
import "chartjs-adapter-date-fns";
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { numberAbbrev } from "../../../../common/Utils";

Chart.register(Filler);
Chart.register(TimeSeriesScale);

const timeframes = ["6M", "1Y", "3Y", "5Y"];

const useStyles = makeStyles(() => ({
  gridItem: { alignItems: "center", display: "flex", height: "20px" },
}));

export default function ShareholderReturn(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [stockSymbol, setStockSymbol] = React.useState("");
  const [companyInfo, setCompanyInfo] = React.useState({});
  const [priceData, setPriceData] = React.useState([]);
  const [chartData, setChartData] = React.useState({
    datasets: [],
  });
  // default timeframe is 1Y.
  const [timeframe, setTimeframe] = React.useState(1);

  // If this card is loaded in Newton, we don't need to call core API to get stock symbol.
  const previewData = props.previewData;

  const classes = useStyles();

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "timeseries",
        time: {
          unit: "month",
          displayFormats: {
            month: "MMM yy",
          },
          tooltipFormat: "MMM dd yyyy",
        },
        display: true,
        ticks: {
          major: {
            enabled: true,
          },
        },
        grid: {
          borderDash: [3, 6],
        },
      },
      y: {
        grace: "10%",
        grid: {
          color: "rgba(0,0,0,0.02)",
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        mode: "nearest",
      },
    },
  };

  useEffect(() => {
    if (previewData) {
      setStockSymbol(previewData.StockSymbol);
    } else {
      let parameter = "/" + props.cardId;
      axios
        .get(rule5properties.getCard + parameter)
        .then((response) => {
          let rawdata = response.data;
          if (rawdata.StockSymbol) {
            // Validate content
            setStockSymbol(rawdata.StockSymbol);
          } else {
            // API call failed
            setSnackbarMessage(
              "There was a problem with the " +
                props.cardType +
                " Card - Internal API failure"
            );
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
            setSnackbarMessage(
              "There was a problem with the " +
                props.cardType +
                " Card - Internal API failure"
            );
            setOpenSnackbar(true);
          }
        });
    }
  }, [props]);

  // When stockSymbol is set or changed, load the market data.
  useEffect(() => {
    setLoading(true);
    if (stockSymbol?.length > 0) {
      console.log("Fetching market information for " + stockSymbol);
      let missingData = false;
      axios
        .get(
          `https://financialmodelingprep.com/api/v3/historical-price-full/${stockSymbol}?serietype=line&apikey=408c4a94a460635a91498b95644beeb2`
        )
        .then((res) => {
          let data = res.data;
          if (data) {
            if (data.historical?.length > 0) {
              setPriceData(data);
            } else {
              console.log(
                "Wasn't able to retrieve stock historical price data."
              );
              missingData = true;
            }
          }
          axios
            .get(
              `https://financialmodelingprep.com/api/v3/quote/${stockSymbol}?apikey=408c4a94a460635a91498b95644beeb2`
            )
            .then((res) => {
              if (Array.isArray(res.data) && res.data.length > 0) {
                setCompanyInfo(res.data[0]);
              } else {
                missingData = true;
              }
              if (missingData) {
                console.log(
                  "Wasn't able to retrieve stock company information."
                );
                setHasError(true);
              } else {
                setHasError(false);
              }
              setLoading(false);
            });
        })
        .catch((err) => {
          // API call failed
          setSnackbarMessage(
            "There was a problem retrieving shareholder return information."
          );
          setHasError(true);
          setOpenSnackbar(true);
          setLoading(false);
        });
    }
  }, [stockSymbol]);

  // When historical price data is set, or timeframe is changed, process and load the chart.
  useEffect(() => {
    let daysInFrame = 0;
    switch (timeframe) {
      case 0:
        daysInFrame = 126;
        break;
      case 1:
        daysInFrame = 252;
        break;
      case 2:
        daysInFrame = 756;
        break;
      case 3:
        daysInFrame = 1260;
        break;
      default:
    }
    // Truncate the historical price data to the past year / IPO date.
    let truncatedData = priceData.historical?.slice(
      0,
      Math.min(daysInFrame, priceData.historical?.length)
    );
    let labels = [];
    let pricedata = [];
    truncatedData?.forEach((day) => {
      labels.push(day.date);
      pricedata.push(day.close);
    });
    setChartData({
      labels: labels,
      datasets: [
        {
          fill: true,
          label: "Price",
          data: pricedata,
          borderWidth: 2,
          borderColor: "rgba(126, 189, 144, .8)",
          backgroundColor: "rgba(126, 189, 144, 0.3)",
          pointStyle: "circle",
          pointRadius: labels.map((el, index) => {
            return index === 0 ? 3 : 0;
          }),
          pointBackgroundColor: "rgb(126, 189, 144)",
          tension: 0.5,
        },
      ],
    });
  }, [priceData, timeframe]);

  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={props.cardType}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      {!loading ? (
        hasError ? (
          "There was an error retrieving stock info"
        ) : (
          <Stack direction="row" alignItems="stretch" sx={{ mt: 1 }}>
            <Box sx={{ textAlign: "left", width: "250px", mr: 2 }}>
              <Typography
                textAlign="left"
                sx={{ fontSize: "20px", fontWeight: 600, mb: "2px" }}
              >
                {companyInfo.name} ({stockSymbol})
              </Typography>
              <Typography textAlign="left" sx={{ fontSize: "12px", mb: 1 }}>
                {companyInfo.exchange}
              </Typography>
              <Typography
                display="inline"
                sx={{ fontSize: "32px", fontWeight: 800 }}
              >
                {companyInfo.price?.toFixed(2)}
              </Typography>
              <Box sx={{ display: "inline" }}>
                <Typography
                  display="inline"
                  sx={{
                    ml: 1,
                    fontSize: "16px",
                    fontWeight: 700,
                    color:
                      parseFloat(companyInfo.change) >= 0 ? "green" : "red",
                  }}
                >
                  {companyInfo.change?.toFixed(2)} (
                  {companyInfo.changesPercentage?.toFixed(2)}%)
                </Typography>
              </Box>
              <Grid container columns={{ xs: 2 }} sx={{ mt: "12px", mb: 2 }}>
                <Grid item xs={1} className={classes.gridItem}>
                  <Typography sx={{ fontSize: "11px" }}>
                    <strong>Mkt Cap: </strong>
                    {numberAbbrev("USD", companyInfo.marketCap)}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.gridItem}>
                  <Typography sx={{ fontSize: "11px" }}>
                    <strong>{timeframes[timeframe]} Change: </strong>
                    {(
                      (chartData.datasets[0]?.data[0] /
                        chartData.datasets[0]?.data[
                          chartData.datasets[0]?.data.length - 1
                        ] -
                        1) *
                      100
                    ).toFixed(2)}
                    %
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.gridItem}>
                  <Typography sx={{ fontSize: "11px" }}>
                    <strong>Day High:</strong> {companyInfo.dayHigh}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.gridItem}>
                  <Typography sx={{ fontSize: "11px" }}>
                    <strong>Day Low:</strong> {companyInfo.dayLow}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.gridItem}>
                  <Typography sx={{ fontSize: "11px" }}>
                    <strong>Year High: </strong>
                    {companyInfo.yearHigh}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.gridItem}>
                  <Typography sx={{ fontSize: "11px" }}>
                    <strong>Year Low:</strong> {companyInfo.yearLow}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                width: "500px",
                height: "220px",
                flexGrow: 1,
                overflowX: "auto",
                overflowY: "hidden",
              }}
            >
              <Line options={options} data={chartData} />
            </Box>
            <Box sx={{ marginLeft: "-60px", width: "60px", pt: 2 }}>
              <Chip
                label={timeframes[timeframe]}
                size="small"
                sx={{ width: "48px" }}
                clickable
                disableRipple
                onClick={() => {
                  setTimeframe((currTimeframe) => {
                    return currTimeframe === timeframes.length - 1
                      ? 0
                      : currTimeframe + 1;
                  });
                }}
              />
            </Box>
          </Stack>
        )
      ) : (
        <CircularProgress />
      )}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}
