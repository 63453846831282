import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import ExpandMore from "../../../../common/ExpandMore";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { convertFromRaw, EditorState } from "draft-js";
import Button from "@mui/material/Button";
import Image from "../../../../common/Image";
import RichTextReader from "../../../../common/RichTextReader";

/*
const stubData = {
  "Title": "Risks",
  "Risk": [
    {
      "Title": "Risk factors point to the need for a more agile and adaptive technology foundation",
      "Link": "https://www.google.com/url?q=https://app.quotemedia.com/data/downloadFiling?webmasterId%3D101533%26ref%3D114814511%26type%3DHTML%26symbol%3DGPC%26companyName%3DGenuine%2BParts%2BCompany%26formType%3D10-K%26dateFiled%3D2020-02-21%26CK%3D40987&sa=D&source=editors&ust=1634591799253000&usg=AOvVaw29zQbOGQiGx8_LyQ6gF1-U",
      "Assessment": "Several of the Genuine Parts risk factors in the 10K are relevant to TIBCO.  1. There is an overall theme of a need for flexibility to mitigate risks associated with pricing, consumer preferences, entering new markets, manage costs, grow through acquisitions and integration,  adapting to new technologies, weather related risks, and risks due to political unrest.  2. There are specific needs relative to the successful implementation and operation of eCommerce capabilities.",
      "Excerpt": {
        "blocks": [
          {
            "key": "b4b1l",
            "text": "We may not be able to successfully implement our business initiatives in each of our business segments to grow our sales and earnings, which could adversely affect our business, financial condition, results of operations and cash flows.",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "8grmi",
            "text": "the competitive environment in our end markets may force us to reduce prices below our desired pricing level or to increase promotional spending;",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 145,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "mpra",
            "text": "our ability to anticipate changes in consumer preferences and to meet customers’ needs for our products in a timely manner;",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 123,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "cbn4g",
            "text": "our ability to successfully enter new markets, including by successfully identifying and acquiring suitable acquisition targets in these new markets;",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 149,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "3jknh",
            "text": "our ability to effectively manage our costs;",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 44,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "6hoj",
            "text": "our ability to continue to grow through acquisitions and successfully integrate acquired businesses in our existing operations, including in particular the challenges associated with the integration of foreign operations to ensure the adequacy of internal controls;",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 265,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "dbnbb",
            "text": "our ability to identify and successfully implement appropriate technological, digital and e-commerce solutions;",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 111,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "bcjlg",
            "text": "the rate of adoption of electric vehicles, hybrid vehicles, ride sharing services, alternative transportation means and autonomously driven vehicles and future legislation related thereto;",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 188,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "fep3l",
            "text": "the occurrence of unusually severe weather events, which can disrupt our operations (forcing temporary closure of retail and distribution centers, prohibiting shipment of inventory and products) and negatively impact our results in the affected geographies;",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 257,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "9bs7j",
            "text": "the occurrence of political unrest and strikes, which can disrupt our operations and negatively impact our results in the affected geographies;",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 143,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "96a5g",
            "text": "volatility in oil prices, which could have a negative impact on the global economy and the economy of each of the nations in which we operate, in particular;",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 157,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "7pam",
            "text": "the adequacy of our disclosure controls and procedures and internal controls over financial reporting; and",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 106,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "3tve5",
            "text": "the economy of each of the nations in which we operate in general, including the monetary policies of the Federal Reserve, which are influenced by various factors, including inflation, unemployment and short-term and long-term changes in the international trade balance and the fiscal policies of the U.S. government.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 317,
                "style": "SMALL"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "ajbpt",
            "text": "We recognize the growing demand for business-to-business and business-to-customer e-commerce options and solutions, and we could lose business if we fail to provide the e-commerce options and solutions our customers wish to use.",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
          }
        ],
        "entityMap": {}
      },
      "Thumbnail": "",
      "Source": [
        {
          "Name": "10K",
          "URL": "https://genuineparts.investorroom.com/sec-filings?year=2020&cat=1"
        }
      ],
      "PublishedDate": "02/21/2020",
      "Authors": [
        "Prashant Vaze"
      ]
    }
  ],
  "NewUpdate": false
}
*/

const useStyles = makeStyles(() => ({
  cardContent: {
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
  },
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  flexContainer: {
    position: "relative",
    marginBottom: "20px",
    border: "1px solid #EEE",
    borderRadius: "5px",
    textAlign: "left",
    padding: 16,
    paddingBottom: 0,
  },
  flexContent: {
    padding: "20px",
    paddingTop: 0,
    textAlign: "left",
  },
  flexCollapsed: {
    padding: "20px",
    paddingTop: 0,
    textAlign: "left",
    "&::after": {
      position: "absolute",
      bottom: 0,
      height: "30%",
      width: "100%",
      content: '""',
      background:
        "linear-gradient(to bottom, rgba(255,255,255, 0.4), rgba(255,255,255, 0.8), rgba(255,255,255, 0.9));",
      pointerEvents: "none" /* so the text is still selectable */,
    },
  },
  image: {
    maxWidth: "100%",
    marginBottom: "16px",
    border: "1px solid #EEE",
    borderRadius: "5px",
  },
  authorContent: {
    color: "green",
  },
}));

function RiskItem(props) {
  const classes = useStyles();
  let row = props.row;
  let index = props.index;
  let alt = "Risk#" + (index + 1);

  const [expanded, setExpanded] = React.useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const editorState = EditorState.createWithContent(
    convertFromRaw(row.Excerpt)
  );

  if (row.Image) {
    return <Image src={row.Image} className={classes.image} alt={alt} />;
  }

  return (
    <div className={classes.flexContainer}>
      <Typography variant="h6" gutterBottom component="div">
        <a href={row.Link} target="_blank" rel="noreferrer">
          {row.Title}
        </a>
      </Typography>
      <Typography variant="body2" gutterBottom component="div">
        {row.Assessment}
      </Typography>
      {row.Authors && (
        <Typography
          variant="caption"
          className={classes.authorContent}
          gutterBottom
          component="div"
        >
          {row.Authors.map((authorRow, authorIndex) => {
            if (row.Authors.length > 1 && authorIndex > 0) {
              if (authorIndex + 1 === row.Authors.length) {
                return " & " + authorRow;
              } else return ", " + authorRow;
            } else return authorRow;
          })}
        </Typography>
      )}
      <Typography variant="h6subtitle2" gutterBottom component="div">
        {row.Source.map((sourceRow, sourceIndex) => {
          return (
            <div key={sourceIndex}>
              {sourceRow.Name + " - " + row.PublishedDate}&nbsp;
              <a href={sourceRow.URL} target="_blank" rel="noreferrer">
                (Source)
              </a>
            </div>
          );
        })}
      </Typography>
      <Button sx={{ textTransform: "none" }} onClick={handleExpandClick}>
        {expanded ? "Hide" : "Show"} Details
      </Button>
      <ExpandMore
        expand={expanded}
        aria-expanded={expanded}
        onClick={handleExpandClick}
        aria-label="show more"
      />
      <Collapse
        in={expanded}
        collapsedSize={0}
        style={{ position: "relative" }}
      >
        <div
          key={index}
          className={expanded ? classes.flexContent : classes.flexCollapsed}
        >
          <RichTextReader editorState={editorState} />
        </div>
      </Collapse>
    </div>
  );
}

export default function RiskFactors10K(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;
  // const previewData = stubData;

  const [riskFactorsData, setRiskFactorsData] = React.useState(null);

  // eslint-disable-next-line
  const [firstTime, setFirstTime] = React.useState(true);
  useEffect(() => {
    let isMounted = true; // note mutable flag
    if (previewData) {
      // Call Stub
      setRiskFactorsData(previewData);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId;
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            if (isMounted) {
              let rawdata = response.data;
              if (rawdata.Risk) {
                // Validate content
                setRiskFactorsData(rawdata);
              } else {
                // API call failed
                setSnackbarMessage(
                  "There was a problem with the " + props.cardType + " Card"
                );
                setOpenSnackbar(true);
              }
            }
          })
          .catch((error) => {
            if (error.response && isMounted) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
    return () => {
      isMounted = true;
    };
  }, [firstTime, previewData]);

  if (!riskFactorsData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={riskFactorsData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ paddingBottom: 0 }}>
          {riskFactorsData.Risk.map((row, index) => {
            return <RiskItem key={index} row={row} index={index} />;
          })}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
}
