import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomSnackbar(props) {
  // Snackbar Start
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpenSnackbar(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  let children = null;
  if (props.children) {
    children = props.children;
  }

  return (
    <Snackbar
        open={props.openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={props.snackbarMessage}
        action={action}>
      {children}
    </Snackbar>
  );
}

CustomSnackbar.propTypes = {
  openSnackbar: PropTypes.bool.isRequired,
  setOpenSnackbar: PropTypes.func.isRequired,
  snackbarMessage: PropTypes.string.isRequired
};
