import { cloneDeep, isFinite } from "lodash";
import { useState, useEffect } from "react";
import { pdfjs } from "react-pdf";

export const usePdfPageHighlighter = (file, page, startIndex, length) => {
  const [textItems, setTextItems] = useState([]);

  useEffect(() => {
    if (
      !isFinite(page) ||
      !isFinite(length) ||
      !isFinite(startIndex) ||
      !file
    ) {
      return;
    }
    // preload the page to highlight..
    pdfjs.getDocument(file).promise.then((docData) => {
      docData.getPage(page).then((pageData) => {
        return pageData.getTextContent().then((textContent) => {
          setTextItems(addMarkTags(textContent.items));
        });
      });
    });
  }, [file]);

  function addMarkTags(items) {
    let accumulatedChars = 0;

    if (startIndex < 0 || length <= 0) {
      return items; // Invalid parameters, return the original strings
    }

    return items.map((item) => {
      const newAccumulatedChars = accumulatedChars + item.str.length;
      const endIndex = startIndex + length;

      if (newAccumulatedChars < startIndex || accumulatedChars > endIndex) {
        // Return item as is if either before highlighting begins or after it ends.
        accumulatedChars = newAccumulatedChars;
        return item;
      }

      let newItem = cloneDeep(item);
      // Add closing tags.
      if (newAccumulatedChars > endIndex) {
        newItem.str =
          newItem.str.slice(0, endIndex - accumulatedChars) +
          "</mark>" +
          newItem.str.slice(endIndex - accumulatedChars);
      } else if (accumulatedChars >= startIndex) {
        newItem.str = newItem.str + "</mark>"; // Each span needs its own mark tag in case large block is highlighted
      }

      // Add opening tags.
      if (accumulatedChars <= startIndex) {
        newItem.str =
          newItem.str.slice(0, startIndex - accumulatedChars) +
          "<mark>" +
          newItem.str.slice(startIndex - accumulatedChars);
      } else if (accumulatedChars <= endIndex) {
        newItem.str = "<mark>" + newItem.str; // Each span needs its own mark tag in case large block is highlighted
      }

      accumulatedChars = newAccumulatedChars;
      return newItem;
    });
  }

  return textItems;
};
