import { useMemo, useCallback } from "react";
import { useUser } from "../context/UserContext";

export function useFormatDateTime() {
  const user = useUser();

  const formatter = useMemo(() => {
    return new Intl.DateTimeFormat("en-US", {
      timeZone: user?.timezone || undefined,
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }, [user?.timezone]);

  const formatDateTime = useCallback(
    (dateTimeString) => {
      if (!dateTimeString) return "";
      const date = new Date(dateTimeString);
      return formatter.format(date);
    },
    [formatter]
  );

  return formatDateTime;
}
