import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import ExpandMore from "../../../../common/ExpandMore";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { convertFromRaw, EditorState } from "draft-js";
import Button from "@mui/material/Button";
import Image from "../../../../common/Image";
import RichTextReader from "../../../../common/RichTextReader";
/*
const stubData = {
  "Title": "Industry Trends",
  "Trend": [
    {
      "Title": "Beyond demand levels, the fundamental structure of the aftermarket industry will likely change.",
      "Link": "https://www.mckinsey.com/industries/automotive-and-assembly/our-insights/beyond-coronavirus-the-road-ahead-for-the-automotive-aftermarket",
      "Content": {
        "blocks": [
          {
            "key": "b4b1l",
            "text": "Car parc. The car parc is the after market’s core performance driver each year, accounting for about 45 to 50 percent of its growth. New car sales expand the parc by 6 to 10 percent each year, depending on region, and COVID-19 will cause disruption to this inflow. The impact will differ by aftermarket channel: temporarily, the original-equipment aftermarket channel will drop as the number of vehicles under warranty will decrease. The independent aftermarket could potentially decrease in size around 2025 because COVID-19 has sharply reduced sales of new vehicles, thus reducing the number of cars requiring aftermarket services at five years—a point when more frequent repairs are often necessary.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 8,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "9iin1",
            "text": "E-commerce and digital channels. Many consumers and businesses have already migrated to e-commerce for parts purchases, and this trend may persist. In response, aftermarket players will likely offer omnichannel models similar to those of retailers—for instance, the ability to make purchases online and then make a quick pickup. The increased affinity for internet purchases may create an emerging opportunity for new business models or service options.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 31,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "9cikh",
            "text": "Supply chain. The aftermarket supply chain will change if distributors and retailers trade far-flung sources for near-shore ones, perceiving a lower risk of disruption. For the United States, this could mean more NAFTA sourcing at the expense of Asian manufacturers. Single-sourcing might also decline as the need for supply security outweighs the cost of qualifying second sources.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 12,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "csboj",
            "text": "Automation. Automation of manufacturing, distribution centers, and warehouses is likely to accelerate to hedge against additional disruptions of the labor force, enable physical distancing among employees, and reduce the risk of new infections.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 10,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "62d88",
            "text": "Consumer preferences. Changing consumer workshop preferences may necessitate new service offerings. A new category of do-it-yourself (DIY) may emerge, with video support provided. The potential uplift from DIY will be limited, because not all consumers are able to repair vehicles themselves. Upcoming vehicle generations and technologies will decrease the ranks of DIYers even further.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 20,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "86bfm",
            "text": "Shifting consumer travel preferences. Increased private-car use may enhance VMT as people avoid public transportation for their commutes. Many may also have an increased preference for traveling by car, rather than by plane or train (if travel distances allow the switch). In case of stalled new-car sales, this preference change would imply prolonged usage of current vehicles.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 36,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "em6nc",
            "text": "Competition. The competitive landscape may shift, since economic pressures could force smaller service locations and distributors to exit the market. In the United States, independent body shops constitute 35 percent of industry revenue but have increasingly consolidated into mid-size independents and multishop operations. This trend may accelerate because of crisis-related bankruptcies and business closures.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 11,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          }
        ],
        "entityMap": {}
      },
      "Thumbnail": "",
      "Source": [
        {
          "Name": "McKinsey",
          "URL": "https://www.mckinsey.com"
        }
      ],
      "PublishedDate": "05/01/2020",
      "Authors": [
        "Alex Brotschi",
        "Daniel Christof",
        "Joe Dertouzos",
        "Sebastian Kempf",
        "Prashant Vaze"
      ]
    },
    {
      "Title": "Impact of COVID on Auto Aftermarket",
      "Link": "https://www.mckinsey.com/industries/automotive-and-assembly/our-insights/beyond-coronavirus-the-road-ahead-for-the-automotive-aftermarket",
      "Content": {
        "blocks": [
          {
            "key": "b4b1l",
            "text": "Lower vehicle miles traveled. In many regions, roads are eerily empty and cities are free of crowds. Congestion indicators released by the navigation-system manufacturer TomTom show a dramatic decline in private and business travel, including commuting. Consider Italy and Spain, two countries that were hard hit in February and March. Car density was down to 17 percent of levels from a year earlier in both Bergamo and Milano, and to 18 percent in Madrid as of March 20. In Germany, where a curfew is in effect in some locations, the traffic load on urban roads had decreased approximately 40 percent by end of March.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 28,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "7udah",
            "text": "Fewer collisions. With regulations keeping people home and lowering car density, the collision rate has dropped in many cities. For instance, Seattle has seen about 50 percent fewer collisions since the government introduced measures to control the spread of coronavirus, while San Francisco has had a drop of about 60 percent. Very similar developments can be observed in Europe. This is clearly excellent news, as fewer accidents translate into fewer injuries and deaths. For the aftermarket, revenue planning should account for fewer crash-repair jobs.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 16,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "7n414",
            "text": "Decreased retail and workshop business. Many people are delaying inspections or discretionary repairs, and some government transportation agencies are relaxing deadlines for mandatory technical controls and inspections. Consumers with lower incomes are also delaying avoidable repairs. These changes are dramatically reducing visits to garages, service stations, and repair workshops, even though these businesses are typically allowed to remain open and can still easily receive parts from distributors. For instance, German garages report that business is down about 50 percent.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 38,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "g9t8",
            "text": "Increase in digital channels and e-commerce volumes. In the United States, more end-consumers are now shopping for parts online, which helps mitigate the adverse effect of curfews and closed workshops. This trend was already prevalent in the B2B market, especially those in Asia and Eastern Europe, before it hit B2C.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 51,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "d5nd7",
            "text": "Lower use of public transportation. Many people are taking fewer trips via public transport and shared-mobility services to reduce social contact. (In some cases, ride-sharing services have also temporarily ceased operations). The increased reliance on personal vehicles may spur demand for aftermarket services once people begin routinely traveling by car again, but it is difficult to predict when that might occur. With many people still remaining close to home, it is unlikely that lower use of public transport will have a substantial impact on aftermarket spending in 2020.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 34,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          }
        ],
        "entityMap": {}
      },
      "Thumbnail": "",
      "Source": [
        {
          "Name": "McKinsey",
          "URL": "https://www.mckinsey.com"
        }
      ],
      "PublishedDate": "05/01/2020",
      "Authors": [
        "Alex Brotschi",
        "Daniel Christof",
        "Joe Dertouzos",
        "Sebastian Kempf",
        "Prashant Vaze"
      ]
    }
  ],
  "NewUpdate": false
}
*/

const useStyles = makeStyles(() => ({
  cardContent: {
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
  },
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  flexContainer: {
    position: "relative",
    marginBottom: "20px",
    border: "1px solid #EEE",
    borderRadius: "5px",
    textAlign: "left",
    padding: 16,
    paddingBottom: 0,
  },
  flexContent: {
    padding: "20px",
    paddingTop: 0,
    textAlign: "left",
  },
  flexCollapsed: {
    padding: "20px",
    paddingTop: 0,
    textAlign: "left",
    "&::after": {
      position: "absolute",
      bottom: 0,
      height: "30%",
      width: "100%",
      content: '""',
      background:
        "linear-gradient(to bottom, rgba(255,255,255, 0.4), rgba(255,255,255, 0.8), rgba(255,255,255, 0.9));",
      pointerEvents: "none" /* so the text is still selectable */,
    },
  },
  trendImage: {
    maxWidth: "100%",
    marginBottom: "16px",
    border: "1px solid #EEE",
    borderRadius: "5px",
  },
  authorContent: {
    color: "green",
  },
  newsImage: {
    width: "120px",
    float: "left",
    marginRight: "10px",
  },
}));

function TrendItem(props) {
  const classes = useStyles();
  let row = props.row;
  let index = props.index;
  let alt = "Trend#" + (index + 1);

  const [expanded, setExpanded] = React.useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const editorState = EditorState.createWithContent(
    convertFromRaw(row.Content)
  );

  if (row.Image) {
    return <Image src={row.Image} className={classes.trendImage} alt={alt} />;
  }

  return (
    <div className={classes.flexContainer}>
      {row.Thumbnail && (
        <Image src={row.Thumbnail} alt={alt} className={classes.newsImage} />
      )}
      <Typography variant="h6" gutterBottom component="div">
        {row.Link ? (
          <a href={row.Link} target="_blank" rel="noreferrer">
            {row.Title}
          </a>
        ) : (
          row.Title
        )}
      </Typography>
      {row.Authors && (
        <Typography
          variant="caption"
          className={classes.authorContent}
          gutterBottom
          component="div"
        >
          {row.Authors.map((authorRow, authorIndex) => {
            if (row.Authors.length > 1 && authorIndex > 0) {
              if (authorIndex + 1 === row.Authors.length) {
                return " & " + authorRow;
              } else return ", " + authorRow;
            } else return authorRow;
          })}
        </Typography>
      )}
      <Typography variant="subtitle2" gutterBottom component="div">
        {row.Source.map((sourceRow, sourceIndex) => {
          return (
            <div key={sourceIndex}>
              {sourceRow.Name + " - " + row.PublishedDate}&nbsp;
              <a href={sourceRow.URL} target="_blank" rel="noreferrer">
                (Source)
              </a>
            </div>
          );
        })}
      </Typography>
      {row.Thumbnail && <br />}
      <Button sx={{ textTransform: "none" }} onClick={handleExpandClick}>
        {expanded ? "Hide" : "Show"} Details
      </Button>
      <ExpandMore
        expand={expanded}
        aria-expanded={expanded}
        onClick={handleExpandClick}
        aria-label="show more"
      />
      <Collapse
        in={expanded}
        collapsedSize={0}
        style={{ position: "relative" }}
      >
        <div
          key={index}
          className={expanded ? classes.flexContent : classes.flexCollapsed}
        >
          <RichTextReader editorState={editorState} />
        </div>
      </Collapse>
    </div>
  );
}

export default function RteArrayCard(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;
  const [industryTrendsData, setIndustryTrendsData] = React.useState(null);

  // eslint-disable-next-line
  const [firstTime, setFirstTime] = React.useState(true);
  useEffect(() => {
    let isMounted = true; // note mutable flag
    if (previewData) {
      // Call Stub
      setIndustryTrendsData(previewData);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId;
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            if (isMounted) {
              let rawdata = response.data;
              if (rawdata.Trend) {
                // Validate content
                setIndustryTrendsData(rawdata);
              } else {
                // API call failed
                setSnackbarMessage(
                  "There was a problem with the " + props.cardType + " Card"
                );
                setOpenSnackbar(true);
              }
            }
          })
          .catch((error) => {
            if (error.response && isMounted) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
    return () => {
      isMounted = true;
    };
  }, [firstTime, previewData]);

  if (!industryTrendsData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity?.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity?.id}
        cardId={props.cardId}
        title={industryTrendsData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        {industryTrendsData.Trend.map((row, index) => {
          return <TrendItem key={index} row={row} index={index} />;
        })}
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
}
