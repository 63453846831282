import React from "react";
import UserForm from "../common/UserForm";

export default function UserDetailPopup(props) {
  const { addOrEdit, recordForEdit, isCoreApp } = props;

  return (
    <UserForm
      recordForEdit={recordForEdit}
      addOrEdit={addOrEdit}
      isCoreApp={isCoreApp}
    />
  );
}
