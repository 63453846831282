import React from "react";
import { Avatar, Box, Card, Typography } from "@mui/material";
import axios from "axios";
import {
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { rule5properties } from "../../properties";
import { StyledDataGrid, StyledTextfield } from "../../common/StyledComponents";
import { useDialog } from "../../context/DialogContext";
import GenericConfirmation from "../../modal/GenericConfirmation";
import {
  formatDate,
  formatName,
  getColorFromString,
  highlightSubstring,
} from "../../common/Utils";
import useSnack from "../../context/Snack";
import CreateCategory from "../../modal/CreateCategory";
import { downloadPdfFileUrl, isSuccessStatus } from "../../common/RequestUtils";
import Image from "../../common/Image";
import { useUser } from "../../context/UserContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function EditInputCell(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event, newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  return (
    <StyledTextfield
      multiline
      name="texfield"
      value={value}
      onChange={handleChange}
      style={{ width: "100%", margin: "0px 10px" }}
    />
  );
}

export const renderEditInputCell = (params) => {
  return <EditInputCell {...params} />;
};

function CustomToolbar() {
  return (
    <div style={{ padding: "0px 15px 10px 7px" }}>
      <GridToolbarContainer>
        <GridToolbarQuickFilter
          sx={{
            width: "250px",
            padding: "10px 5px 10px 5px",
            margin: "7px",
            color: "rgba(0,0,0,0.5)",
            borderRadius: "8px",
            textTransform: "none",
          }}
          debounceMs={500}
        />
        <div style={{ flex: 1 }} />
      </GridToolbarContainer>
    </div>
  );
}

export default function ContentTable(props) {
  const {
    loadContent,
    rows,
    loading,
    searchValue,
    setSearchValue,
    selectedContent,
    setSelectedContent,
  } = props;

  const [rowModesModel, setRowModesModel] = React.useState({});

  const dialog = useDialog();
  const snackBar = useSnack();
  const user = useUser();
  const history = useHistory();

  const handleContentDeleteClick = (content) => (event) => {
    dialog.openModal(
      "Delete content",
      GenericConfirmation,
      {
        children: (
          <Typography>
            The following content will be deleted:<br></br>
            <br></br>
            {content.name}
          </Typography>
        ),
        confirmFunction: (callback) => {
          axios
            .delete(`${rule5properties.contents}/${content.contentId}`)
            .then((response) => {
              loadContent();
              if (callback) callback(response);
            });
        },
      },
      "sm"
    );
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    const inputValue = filterModel?.quickFilterValues?.join(" ");
    if (inputValue?.length === 1) {
      return;
    }
    setSearchValue(inputValue);
  }, []);

  const processRowUpdate = (updatedRow, originalRow) => {
    const params = {
      updateContentObj: {
        ...(updatedRow.name !== originalRow.name
          ? { name: updatedRow.name }
          : {}),
        ...(updatedRow.description !== originalRow.description
          ? { description: updatedRow.description }
          : {}),
        //todo scope..
      },
    };
    axios
      .patch(`${rule5properties.contents}/${updatedRow.contentId}`, params)
      .then((resp) => {
        if (isSuccessStatus(resp.status)) {
          snackBar.createSnack("Content info successfully updated.");
        } else {
          snackBar.createSnack("Error updating content info.");
        }
        loadContent();
      });
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "name",
      headerName: "Content name",
      flex: 1,
      editable: true,
      renderEditCell: renderEditInputCell,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "10px 0px",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: highlightSubstring(cellValues.row.name, searchValue),
              }}
            ></div>
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Last modified",
      editable: false,
      renderEditCell: renderEditInputCell,
      valueGetter: ({ row }) => {
        return row.updatedAt;
      },
      renderCell: (cellValues) => {
        return formatDate(new Date(cellValues.row.updatedAt));
      },
      width: 150,
    },
    {
      field: "updatedUser.firstName",
      headerName: "Modified by",
      valueGetter: (params) => formatName(params.row.updatedUser),
      width: 250,
      renderCell: (cellValues) => {
        return (
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                mr: 2,
              }}
            >
              <Avatar
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: getColorFromString(cellValues.row.updatedUser.email),
                }}
              >
                {cellValues.row.updatedUser?.userSettingInfo?.profilePicture ? (
                  <Image
                    src={
                      cellValues.row.updatedUser?.userSettingInfo
                        ?.profilePicture
                    }
                    style={{
                      height: "auto",
                      maxWidth: 40,
                      maxHeight: 40,
                      display: "block",
                    }}
                  />
                ) : (
                  cellValues.row.updatedUser.firstName?.toUpperCase().charAt(0)
                )}
              </Avatar>
            </Box>
            <Typography fontSize="inherit">
              {user.email === cellValues.row.updatedUser.email
                ? "me"
                : `${cellValues.row.updatedUser.firstName} ${cellValues.row.updatedUser.lastName}`}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      renderEditCell: renderEditInputCell,
      valueGetter: ({ row }) => {
        return row.description;
      },
      renderCell: (cellValues) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: highlightSubstring(
                cellValues.row.description,
                searchValue
              ),
            }}
          ></div>
        );
      },
      flex: 1.5,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 190,
      getActions: ({ row, id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (!isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<FileOpenIcon />}
              label="View file"
              onClick={() => {
                setSelectedContent(row);
                history.push(`/main/content/${row.contentId}`);
              }}
              disabled={!row.contentInfo?.storageUrl}
            />,
            <GridActionsCellItem
              icon={<DownloadIcon />}
              label="Download"
              onClick={() =>
                downloadPdfFileUrl(
                  row.contentInfo?.storageUrl,
                  row.contentInfo?.originalFileName
                )
              }
              disabled={!row.contentInfo?.storageUrl}
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleContentDeleteClick(row)}
            />,
            <GridActionsCellItem
              icon={<AddIcon />}
              label="Add"
              onClick={() => {
                setSelectedContent(row); // Feels redundant but needed as per https://github.com/mui/mui-x/issues/891#issuecomment-1318728783
                dialog.openModal(
                  `Content Categories`,
                  CreateCategory,
                  {
                    loadContent: loadContent,
                    content: row,
                  },
                  "sm"
                );
              }}
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<DoneIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Add"
              onClick={handleCancelClick(id)}
            />,
          ];
        }
      },
    },
  ];

  const getRowHeight = React.useCallback(() => "auto", []);

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexGrow: "2",
        pt: 1,
        borderRadius: "8px",
        minWidth: "550px",
        minHeight: "10px",
        height: "100%",
        width: "auto",
      }}
    >
      <StyledDataGrid
        sx={{ mx: "30px" }} // Todo probably makes more sense to standardize this margin in Workspace component
        getRowHeight={getRowHeight}
        rows={rows}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.contentId}
        pagination
        slots={{
          toolbar: CustomToolbar,
        }}
        disableMultipleRowSelection
        click
        disableVirtualization
        disableColumnMenu
        filterMode="server"
        onFilterModelChange={onFilterChange}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onRowClick={(params, event) => {
          setSelectedContent(params.row);
        }}
        rowSelectionModel={selectedContent ? [selectedContent.contentId] : []}
      />
    </Card>
  );
}
