import { useState, useEffect } from "react";
import axios from "axios";
import { rule5properties } from "../properties";
import extensibilitySdk from "@getoutreach/extensibility-sdk";

const useOutreach = () => {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      let outreachContext;
      try {
        outreachContext = await extensibilitySdk.init();
        console.log(
          "outreachContext details:",
          JSON.stringify(outreachContext)
        );
      } catch (error) {
        console.log("outreach sdk init failed");
        return;
      }

      const domain =
        outreachContext.account?.domain ||
        outreachContext.prospect?.accountDomain;
      const companyName =
        outreachContext.account?.name || outreachContext.prospect?.accountName;

      try {
        const companyInfoResponse = await axios.get(
          `${rule5properties.companyInfo}?domain=${domain}`
        );

        if (companyInfoResponse.status === 200) {
          const companyResponse = await axios.get(
            `${rule5properties.companies}?id=${companyInfoResponse.data?.companyId}`
          );
          setCompany(companyResponse.data);
        } else {
          setCompany({ name: companyName });
        }
      } catch (error) {
        console.error("Error fetching company info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyInfo();
  }, []);

  return { company, loading };
};

export default useOutreach;
