import React, { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  AddButton,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
  appendFuncClickHandler,
} from "../util/CardEditorButtons";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { rule5properties } from "../../properties";
import { QuestionAndAnswer } from "./AnnualReportsEditor";
import { Typography } from "@mui/material";
import {
  convertHtmlToRawContentState,
  removeTrailingTags,
} from "../common/utils";

export default function CompanyInsightsEditor(props) {
  const formStyle = props.formStyle;

  const { register, control, trigger, getValues } = useFormContext();

  const [loading, setLoading] = useState(false);

  //temp
  /** Removes any unwanted strings and converts answer to rte rawContentState */
  function convertQuestionAnswer(questionAnswerArray) {
    const textToRemove =
      " Classify them and list the titles in bold. Return lists surrounded by HTML tags ol and li.";
    return questionAnswerArray
      ?.filter((qa) => qa.answer !== "Sorry, I don't know")
      ?.map((qa) => {
        qa.question = qa.question?.replace(textToRemove, "");
        qa.answer = convertHtmlToRawContentState(removeTrailingTags(qa.answer));
        return qa;
      });
  }

  return (
    <div>
      <TitleInput register={register} />
      <LoadingButton
        loading={loading}
        style={{ textTransform: "none", marginTop: "2%" }}
        color="primary"
        variant="outlined"
        onClick={() => {
          setLoading(true);
          const cardDataParams = new URLSearchParams({
            functionalArea: props.functionalArea,
            cardType: "Company Insights",
            companyId: props.companyId,
          });

          axios
            .get(rule5properties.detCardData + "?" + cardDataParams)
            .then((res) => {
              setLoading(false);
              if (res.status === 200) {
                const cardInfo = {
                  Title: "Company Insights",
                  ...(res.data.valuation?.amount || //temp
                  res.data.total_funding_amount?.amount ||
                  res.data.last_funding_amount?.amount ||
                  res.data.last_funding_date ||
                  res.data.funding_status ||
                  res.data.funding_round
                    ? {
                        funding: {
                          valuation: res.data.valuation,
                          total_funding_amount: res.data.total_funding_amount,
                          last_funding_amount: res.data.last_funding_amount,
                          last_funding_date: res.data.last_funding_date,
                          funding_status: res.data.funding_status,
                          funding_round: res.data.funding_round,
                        },
                      }
                    : {}),
                  ...res.data,
                  insights: convertQuestionAnswer(res.data.insights),
                };
                props.setForm((draft) => {
                  draft.cardInfo = cardInfo;
                  draft.isDraftSaved = false;
                });
                props.setHasPreview(true);
              } else {
                window.alert(
                  `Card data response code: ${
                    res.status
                  }. Details: ${JSON.stringify(res.data)}`
                );
              }
            })
            .catch((err) => {
              window.alert("Error retrieving on card data: " + err);
              setLoading(false);
            });
        }}
      >
        Generate Data
      </LoadingButton>
      <Typography variant="h6" padding="10px">
        Funding
      </Typography>
      <div className={formStyle}>
        total_funding_amount.amount
        <input {...register("funding.total_funding_amount.amount")} />
        total_funding_amount.currency e.g. USD
        <input {...register("funding.total_funding_amount.currency")} />
        valuation.amount
        <input {...register("funding.valuation.amount")} />
        valuation.currency e.g. USD
        <input {...register("funding.valuation.currency")} />
        funding_status
        <input {...register("funding.funding_status")} />
        funding_round (currently not included in card display)
        <input {...register("funding.funding_round")} />
        last_funding_amount.amount
        <input {...register("funding.last_funding_amount.amount")} />
        last_funding_amount.currency e.g. USD
        <input {...register("funding.last_funding_amount.currency")} />
        last_funding_date
        <input {...register("funding.last_funding_date")} />
      </div>
      <Typography variant="h6" padding="10px">
        Job Openings
      </Typography>
      <JobOpenings {...{ trigger, control, register, getValues, formStyle }} />
      <Typography variant="h6" padding="10px">
        Company Insights Q&A
      </Typography>
      <QuestionAndAnswer
        {...{ trigger, control, register, getValues, formStyle }}
        name="insights"
      />
    </div>
  );
}

/** Form array */
function JobOpenings({
  trigger,
  control,
  register,
  getValues,
  formStyle,
  name = "job_openings",
}) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: name,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, itemIndex) => (
        <div key={itemIndex} className={formStyle}>
          Department:
          <input {...register(`${name}[${itemIndex}].department`)} />
          Open positions:
          <input {...register(`${name}[${itemIndex}].open_positions`)} />
          <MoveAutocomplete
            onChange={(moveToIndex) => {
              move(itemIndex, moveToIndex);
              trigger();
            }}
            index={itemIndex}
            options={[...Array(getValues(name).length).keys()]}
          />
          <DeleteButton
            onClick={() => {
              setLastDeleted(item);
              remove(itemIndex);
              trigger();
            }}
          >
            {`Delete department`}
          </DeleteButton>
        </div>
      ))}
      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        {`Add department`}
      </AddButton>
    </div>
  );
}
