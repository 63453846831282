import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Divider from "@mui/material/Divider";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  TitleInput,
} from "../util/CardEditorButtons";
import axios from "axios";
import { rule5properties } from "../../properties";
import { Autocomplete, TextField } from "@mui/material";
import { getLinkedInProfile } from "../../common/Utils";

/* Recursively adds child counts to each node and finally returns count of all nodes */
export const addChildCounts = (data = [], countField = "childCount") =>
  data.reduce(
    (total, cur) =>
      total + (cur[countField] = addChildCounts(cur.People || [], countField)),
    data.length
  );

const uft = require("un-flatten-tree");
export const unflattenList = (peopleList) => {
  let unflattened = uft.unflatten(
    peopleList,
    (node, parentNode) => node.ParentName === parentNode.FullName,
    (node, parentNode) => {
      parentNode.People.push(node);
    },
    (node) => ({
      FullName: node.FullName,
      People: [],
      Title: node.Title,
      Email: node.Email,
      Phone: node.Phone,
      LinkedIn: node.LinkedIn,
      Avatar: node.Avatar,
    })
  );
  // Mutates the data. Not using return value.
  addChildCounts(unflattened, "childCount");
  return unflattened;
};

export const flattenTree = (peopleTree) => {
  return uft.flatten(
    peopleTree,
    (node) => node.People,
    (node, parentNode) => ({
      FullName: node.FullName,
      ParentName: parentNode !== undefined ? parentNode.FullName : "",
      Title: node.Title,
      Email: node.Email,
      Phone: node.Phone,
      LinkedIn: node.LinkedIn,
      Avatar: node.Avatar,
    })
  );
};

export default function OrgChartEditor(props) {
  const formStyle = props.formStyle;
  const {
    register,
    control,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "People",
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  const getPersonDetails = (linkedInUrl, peopleIndex) => {
    // Ensure standardized link that will work with downstream iScraper api.
    // Foreign subdomains e.g. ca.linkedin.com/... need to be cleansed.
    const linkedInProfileId = getLinkedInProfile(linkedInUrl);

    if (!linkedInProfileId) {
      return linkedInUrl;
    }
    linkedInUrl = "https://www.linkedin.com/in/" + linkedInProfileId + "/";
    const params = [
      {
        linkedin: linkedInUrl,
        override: false,
      },
    ];
    axios
      .post(rule5properties.detPersonsUpload, params)
      .then((response) => {
        const profile = response.data?.[0]?.linkedinProfile;
        setValue(`People[${peopleIndex}].FullName`, profile.full_name);
        setValue(`People[${peopleIndex}].Title`, profile.title);
        setValue(`People[${peopleIndex}].Email`, profile.contact_info.email);
        setValue(`People[${peopleIndex}].Avatar`, profile.avatar_url);
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });

    return linkedInUrl;
  };

  const updateWithCustomAvatar = (publicUrl, peopleIndex) => {
    if (!publicUrl || publicUrl.includes("storage.googleapis.com")) {
      return publicUrl;
    }
    const linkedIn = getValues(`People[${peopleIndex}].LinkedIn`);
    if (linkedIn && !/.*(www\.linkedin\.com\/in\/).*/.test(linkedIn)) {
      alert(
        "Image not converted. Please either remove linkedIn or ensure it matches pattern like 'www.linkedin.com/in/<profile-id>"
      );
      return publicUrl;
    }
    const params = [
      {
        ...(linkedIn ? { linkedin: linkedIn } : {}),
        override: true,
        url: publicUrl,
      },
    ];
    axios
      .post(rule5properties.detPersonsUpload, params)
      .then((response) => {
        setValue(
          `People[${peopleIndex}].Avatar`,
          response.data[0].storageUrl + "?" + new Date().getTime()
        );
      })
      .catch(function (error) {
        alert("ERROR: " + JSON.stringify(error));
        console.log(JSON.stringify(error));
      });

    return publicUrl;
  };

  const getParentNameOptions = (index) => {
    let valuesCopy = [...getValues("People")];
    valuesCopy?.splice(index, 1);
    return valuesCopy.map((person) => person.FullName).concat("");
  };

  return (
    <div>
      <TitleInput register={register} />
      {fields.map((news, peopleIndex) => (
        <div>
          <div key={news.id} className={formStyle}>
            Parent name (must refer to another 'People' item's Full Name or else
            be empty if root node)
            <div>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={getParentNameOptions(peopleIndex)}
                    getOptionLabel={(option) =>
                      option === "" ? "<Root>" : option
                    }
                    onChange={(e, data) => {
                      return field.onChange(data);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        // placeholder=""
                      />
                    )}
                  />
                )}
                name={`People[${peopleIndex}].ParentName`}
                type="select"
                control={control}
                defaultValue=""
                rules={{
                  validate: {
                    validName: (value) =>
                      value === "" ||
                      getValues("People")?.some(
                        (person) => person.FullName === value
                      ) ||
                      "Invalid parent name",
                    invalidRoot: (value) => {
                      const numRoots = getValues("People")?.filter(
                        (person) => person.ParentName === ""
                      ).length;
                      return (
                        numRoots === 1 ||
                        `Must Have exactly one root. Found ${numRoots}.`
                      );
                    },
                  },
                }}
              />
              {errors?.People?.[peopleIndex]?.ParentName && (
                <p style={{ color: "red" }}>
                  {errors.People[peopleIndex].ParentName.message}
                </p>
              )}
            </div>
            LinkedIn
            <Controller
              name={`People[${peopleIndex}].LinkedIn`}
              control={control}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        getPersonDetails(e.target.value, peopleIndex)
                      )
                    }
                  />
                );
              }}
            />
            Full Name (Required)
            <div>
              <input
                style={{ width: "100%" }}
                {...register(`People[${peopleIndex}].FullName`, {
                  required: "FullName is required.",
                })}
              />
              {errors?.People?.[peopleIndex]?.FullName && (
                <p style={{ color: "red" }}>
                  {errors.People[peopleIndex].FullName.message}
                </p>
              )}
            </div>
            Title
            <input {...register(`People[${peopleIndex}].Title`)} />
            Email
            <input {...register(`People[${peopleIndex}].Email`)} />
            Phone numbers
            <Phones
              nestIndex={peopleIndex}
              {...{ control, register, formStyle }}
            />
            Avatar
            <Controller
              name={`People[${peopleIndex}].Avatar`}
              control={control}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    onChange={(e) =>
                      field.onChange(
                        updateWithCustomAvatar(e.target.value, peopleIndex)
                      )
                    }
                  />
                );
              }}
            />
          </div>
          <DeleteButton
            onClick={() => {
              setLastDeleted(news);
              remove(peopleIndex);
              trigger();
            }}
          >
            Delete People
          </DeleteButton>
          <Divider
            light
            style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
          />
        </div>
      ))}
      <AddButton
        onClick={() => {
          appendFuncClickHandler(append, lastDeleted);
        }}
      >
        Add People
      </AddButton>
    </div>
  );
}

/** Nested form array */
function Phones({ nestIndex, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `People.${nestIndex}.Phone`,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className={formStyle}>
            Country Code:
            <input
              {...register(`People.${nestIndex}.Phone.${k}.CountryCode`, {
                // required: true
              })}
            />
            Phone:
            <input {...register(`People.${nestIndex}.Phone.${k}.Phone`)} />
            <DeleteButton onClick={() => remove(k)}>Delete Phone</DeleteButton>
          </div>
        );
      })}

      <AddButton onClick={() => append({})}>Add Phone</AddButton>
    </div>
  );
}

// let testData =
// {
//     "Title" : "Organization Chart",
//     "People": [
//       {
//         "FullName": "John Smith",
//         "Title": "VP of Sales",
//         "Email": "jsmith@acme.com",
//         "Phone": [
//           {
//             "CountryCode": "1",
//             "Phone": 8348766789
//           }
//         ],
//         "LinkedIn": "https://www.linkedin.com/in/john-smith",
//         "Avatar": "https://drive.google.com/file/d/1cvsvYYF02ZKZ34FW4g9G-DB70Bvj7q1L/view?usp=sharing",
//         "People": [
//           {
//             "FullName": "Mike Jones",
//             "Title": "director of Inside Sales",
//             "Email": "mjones@acme.com",
//             "Phone": [
//               {
//                 "CountryCode": "1",
//                 "Phone": 3108766789
//               }
//             ],
//             "LinkedIn": "https://www.linkedin.com/in/mike-jones",
//             "Avatar": "https://drive.google.com/file/d/1VHkN27uo2dom96YAAGSP0IthkCWNrjs5/view?usp=sharing"
//           },
//           {
//             "FullName": "Andrew Black",
//             "Title": "director of Field Sales",
//             "Email": "ablack@acme.com",
//             "Phone": [
//               {
//                 "CountryCode": "1",
//                 "Phone": 3108768945
//               }
//             ],
//             "LinkedIn": "https://www.linkedin.com/in/andy-black",
//             "Avatar": "https://drive.google.com/file/d/1Wpbn2BWxDQkcfyvtVFnmsYSC3tS_lQ5c/view?usp=sharing",
//             "People": [
//               {
//                 "FullName": "Eric Bolt",
//                 "Title": "Senior Account Executive",
//                 "Email": "ebolt@acme.com",
//                 "Phone": [
//                   {
//                     "CountryCode": "1",
//                     "Phone": 3108769823
//                   }
//                 ],
//                 "LinkedIn": "https://www.linkedin.com/in/eric-bolt",
//                 "Avatar": "https://drive.google.com/file/d/1SJGA9Ic5hkJklXOv-KSFTP-T940OGNnT/view?usp=sharing"
//               },
//               {
//                 "FullName": "Linda Brown",
//                 "Title": "Account Executive",
//                 "Email": "lbrown@acme.com",
//                 "Phone": [
//                   {
//                     "CountryCode": "1",
//                     "Phone": 3108765563
//                   }
//                 ],
//                 "LinkedIn": "https://www.linkedin.com/in/linda-brown",
//                 "Avatar": "https://drive.google.com/file/d/1I8F8TELb-8Kl7oZVdX3nGvn0e_si4q8u/view?usp=sharing"
//               }
//             ]
//           }
//         ]
//       }
//     ],
//     "Source": {
//       "Name": "LinkedIn",
//       "Logo": "https://drive.google.com/file/d/1IXlvw8c-KguehZ1LDjwXTyl-h8-dm_YJ/view?usp=sharing"
//     },
//     "NewUpdate": false
//   }
