import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, IconButton, Typography, Tooltip } from "@mui/material";
import axios from "axios";
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import PromptsTable from "./PromptsTable";
import { rule5properties } from "../../properties";
import { StyledTextfield } from "../../common/StyledComponents";
import CustomSnackbar from "../../common/CustomSnackbar";
import { useDialog } from "../../context/DialogContext";
import GenericConfirmation from "../../modal/GenericConfirmation";
import { isArray } from "lodash";
import {
  highlightSubstring,
  mergePromptSearchResponses,
} from "../../common/Utils";
import {
  getPromptBookSearchRequests,
  isSuccessStatus,
} from "../../common/RequestUtils";
import CreatePrompt from "../../modal/CreatePrompt";
import useSnack from "../../context/Snack";

const useStyles = makeStyles(() => ({
  // TODO this is reused all over the place, should be a styled data grid
  // Can now switch over to StyledDataGrid and then remove this whenever.
  root: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginLeft: "30px",
      marginRight: "30px",
    },
    minHeight: "40px",
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "hidden",
      lineHeight: "20px",
      whiteSpace: "normal",
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "100px",
    },
    "& .MuiFormControl-root": {
      // hacky solution to style the quick filter since customtoolbar doesn't work with server side filtering
      // this targets the quickfilter. ideally there'd be something like MuiDataGrid-toolbarQuickFilter but doesn't seem to work
      width: "250px",
      marginLeft: "70px",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      height: "56px",
      color: "#999CA0",
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
          "& .MuiDataGrid-menuIcon": {
            "& .MuiButtonBase-root": {
              color: "#999CA0",
            },
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
            "& .MuiDataGrid-iconButtonContainer": {
              "& .MuiButtonBase-root": {
                color: "#999CA0",
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "0px",
      "& .MuiTablePagination-root": {
        color: "rgba(0,0,0,0.7)",
        "& .MuiSelect-select": {
          color: "#000000",
          border: "1px solid #F0F0F0",
        },
        "& .MuiSelect-icon": {
          // color: theme.common.selectIconColor,
        },
        "& .MuiTablePagination-actions": {
          "& .MuiButtonBase-root": {
            "& .MuiSvgIcon-root": {
              color: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
      "&:focus": {
        outline: "none",
      },
      maxHeight: "fit-content !important",
      overflow: "hidden",
      whiteSpace: "initial !important",
      display: "flex !important",
      alignItems: "center !important",
      paddingTop: "5px !important",
      paddingBottom: "5px !important",
      fontSize: "1rem",
      borderBottom: "none",
      borderTop: "1px solid gainsboro",
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.01)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiGridPanelWrapper-root": {
      opacity: 0.1,
    },
    "& .MuiButton-root.Mui-disabled": {
      color: "rgba(0,0,0,0.5)",
    },
    "& .MuiDataGrid-editInputCell": {
      backgroundColor: "#FCFCFC",
      fontSize: "16px",
      padding: "10px 0px 6px 0px",
      borderRadius: "6px",
      border: "1px solid #DDD",
      margin: "0px 10px",
    },
  },
  toolbarButton: {
    padding: "10px 5px 10px 5px",
    margin: "7px",
    color: "rgba(0,0,0,0.5)",
    borderRadius: "8px",
    textTransform: "none",
  },
}));

function EditInputCell(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event, newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  return (
    <StyledTextfield
      multiline
      name="texfield"
      value={value}
      onChange={handleChange}
      style={{ width: "100%", margin: "0px 10px" }}
    />
  );
}

export const renderEditInputCell = (params) => {
  return <EditInputCell {...params} />;
};

function CustomDetailPanelToggle(props) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? "Close" : "Open"}
    >
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
}

function CustomToolbar() {
  const dgclasses = useStyles();
  return (
    <div style={{ padding: "0px 15px 10px 7px" }}>
      <GridToolbarContainer>
        <GridToolbarQuickFilter
          className={dgclasses.toolbarButton}
          sx={{ width: "250px" }}
          debounceMs={500}
        />
        <div style={{ flex: 1 }}> </div>
        {/* TODO fix export so that nested prompts are outputted as well */}
        <GridToolbarExport
          variant="filled"
          color="inherit"
          className={dgclasses.toolbarButton}
          csvOptions={{ allColumns: true }}
        />
      </GridToolbarContainer>
    </div>
  );
}

export const handleRowEditStop = (params, event) => {
  if (params.reason === GridRowEditStopReasons.rowFocusOut) {
    event.defaultMuiPrevented = true;
  } else if (params.reason === GridRowEditStopReasons.enterKeyDown) {
    if (!event.shiftKey) {
      event.preventDefault(); // prevent newline from looking like it's added during submission
    } else {
      event.defaultMuiPrevented = true;
    }
  }
};

export default function PromptBooksTable(props) {
  const { shouldReload } = props;

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [searchValue, setSearchValue] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] =
    React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const dialog = useDialog();
  const classes = useStyles();
  const snackBar = useSnack();

  const loadPromptBooks = () => {
    setLoading(true);
    const requests = getPromptBookSearchRequests(searchValue);
    Promise.all(requests)
      .then((body) => {
        if (isArray(body?.[0]?.data)) {
          let tempRows = body[0].data;
          if (isArray(body?.[1]?.data)) {
            mergePromptSearchResponses(tempRows, body[1].data);
          }

          setRows(tempRows);
          if (searchValue) {
            setDetailPanelExpandedRowIds(tempRows.map((row) => row.id));
          }
          setLoading(false);
        } else {
          setLoading(false);
          // API call failed
          setSnackbarMessage("There was a problem with loading prompt books");
          setOpenSnackbar(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          // setError(true);
          setSnackbarMessage("There was a problem with loading prompt books");
          setOpenSnackbar(true);
        }
      });
  };

  React.useEffect(() => {
    loadPromptBooks();
  }, [searchValue, shouldReload]);

  const handleBookDeleteClick = (promptBook) => (event) => {
    dialog.openModal(
      "Delete prompt book",
      GenericConfirmation,
      {
        children: (
          <Typography>
            The following prompt book will be deleted:<br></br>
            <br></br>
            {promptBook.name}
          </Typography>
        ),
        confirmFunction: (callback) => {
          axios
            .delete(`${rule5properties.promptBooks}/${promptBook.id}`)
            .then((response) => {
              if (!isSuccessStatus(response.status)) {
                snackBar.createSnack(
                  "Unable to delete prompt book: " + response.data.message
                );
              }
              loadPromptBooks();
              if (callback) callback(response);
            });
        },
      },
      "sm"
    );
  };

  // Below is from https://github.com/mui/material-ui/issues/36909
  // to silence benign resizeobserver loop limit errors that happen
  // due to nested data grids with auto calculating height
  React.useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (updatedRow, originalRow) => {
    const params = {
      id: updatedRow.id,
      updatePromptBookObj: {
        ...(updatedRow.name !== originalRow.name
          ? { name: updatedRow.name }
          : {}),
        ...(updatedRow.description !== originalRow.description
          ? { promptBookInfo: { description: updatedRow.description } }
          : {}),
      },
    };
    axios.patch(rule5properties.promptBooks, params).then((resp) => {
      if (!isSuccessStatus(resp.status)) {
        snackBar.createSnack(
          "Unable to update prompt book: " + resp.data.message
        );
      }
      loadPromptBooks();
    });
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const onFilterChange = React.useCallback((filterModel) => {
    const inputValue = filterModel?.quickFilterValues?.join(" ");
    if (inputValue?.length === 1) {
      return;
    }
    setSearchValue(inputValue);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params) => (
          <CustomDetailPanelToggle id={params.id} value={params.value} />
        ),
      },
      {
        field: "name",
        headerName: "Prompt book name",
        flex: 1,
        editable: true,
        renderEditCell: renderEditInputCell,
        renderCell: (cellValues) => {
          const numPrompts = cellValues.row.prompts.length;
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px 0px",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: highlightSubstring(cellValues.row.name, searchValue),
                }}
              ></div>
              <Typography variant="promptCount">{`${numPrompts} ${
                numPrompts !== 1 ? "prompts" : "prompt"
              }`}</Typography>
            </div>
          );
        },
      },
      {
        field: "description",
        headerName: "Description",
        editable: true,
        renderEditCell: renderEditInputCell,
        valueGetter: ({ row }) => {
          return row.promptBookInfo?.description;
        },
        renderCell: (cellValues) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: highlightSubstring(
                  cellValues.row.promptBookInfo?.description,
                  searchValue
                ),
              }}
            ></div>
          );
        },
        cellClassName: classes.description,
        flex: 2,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 130,
        getActions: ({ row, id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (!isInEditMode) {
            return [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Edit prompt book">
                    <EditIcon />
                  </Tooltip>
                }
                label="Edit"
                onClick={handleEditClick(id)}
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title="Delete prompt book">
                    <DeleteIcon />
                  </Tooltip>
                }
                label="Delete"
                onClick={handleBookDeleteClick(row)}
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title="Add prompt">
                    <AddIcon />
                  </Tooltip>
                }
                label="Add"
                onClick={() => {
                  dialog.openModal(
                    "Add prompt",
                    CreatePrompt,
                    {
                      loadPromptBooks: loadPromptBooks,
                      promptBook: row,
                      addExpandedId: addExpandedId,
                    },
                    "sm"
                  );
                }}
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={<DoneIcon />}
                label="Save"
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Add"
                onClick={handleCancelClick(id)}
              />,
            ];
          }
        },
      },
    ],
    [rowModesModel]
  );

  const getDetailPanelContent = React.useCallback(
    ({ row }) =>
      row?.prompts?.length > 0 ? (
        <PromptsTable
          rows={row.prompts}
          loadPromptBooks={loadPromptBooks}
          searchValue={searchValue}
        ></PromptsTable>
      ) : null,
    [searchValue]
  );

  const getDetailPanelHeight = React.useCallback(() => "auto", []);
  const getRowHeight = React.useCallback(() => "auto", []);

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds);
  }, []);

  function addOrRemoveExpandedId(id) {
    if (!detailPanelExpandedRowIds.includes(id)) {
      const newArray = detailPanelExpandedRowIds.concat(id);
      return newArray;
    } else {
      const newArray = detailPanelExpandedRowIds.filter((item) => item !== id);
      return newArray;
    }
  }

  function addExpandedId(id) {
    if (!detailPanelExpandedRowIds.includes(id)) {
      const newArray = detailPanelExpandedRowIds.concat(id);
      setDetailPanelExpandedRowIds(newArray);
    }
  }

  const allRowsExpanded = detailPanelExpandedRowIds?.length === rows.length;

  return (
    <Card
      id="card"
      variant="outlined"
      sx={{
        display: "flex",
        flexGrow: "1",
        pt: 1,
        borderRadius: "8px",
        minWidth: "600px",
        minHeight: "10px",
      }}
    >
      <Tooltip title={allRowsExpanded ? "Collapse all" : "Expand all"}>
        <IconButton
          sx={{
            marginLeft: "30px",
            position: "absolute",
            marginTop: "18px",
            zIndex: 10,
          }}
          onClick={() => {
            allRowsExpanded
              ? setDetailPanelExpandedRowIds([])
              : setDetailPanelExpandedRowIds(rows.map((row) => row.id));
          }}
        >
          {allRowsExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        </IconButton>
      </Tooltip>
      <DataGridPro
        className={classes.root}
        getRowHeight={getRowHeight}
        rows={rows}
        columns={columns}
        loading={loading}
        pagination
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        slots={{
          detailPanelExpandIcon: ExpandMoreIcon,
          detailPanelCollapseIcon: ExpandLessIcon,
          toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        disableMultipleRowSelection
        disableRowSelectionOnClick
        disableVirtualization
        disableColumnMenu
        filterMode="server"
        onFilterModelChange={onFilterChange}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={
          handleDetailPanelExpandedRowIdsChange
        }
        onRowClick={(params, event) => {
          if (params.row?.prompts?.length > 0) {
            setDetailPanelExpandedRowIds(addOrRemoveExpandedId(params.id));
          }
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Card>
  );
}
