import { Avatar, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";
import { rule5properties } from "../../properties";
import { EMPTY_CONVERSATION } from "../WSChat";
import { getColorFromString } from "../../common/Utils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  GrayCircularProgress,
  StyledTextfield,
} from "../../common/StyledComponents";
import { useDialog } from "../../context/DialogContext";
import DeleteConversation from "../../modal/DeleteConversation"; // TODO replace this with GenericConfirmation
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useGptConversationContext } from "../../context/GptConversationContext";
import useSnack from "../../context/Snack";
import { isSuccessStatus } from "../../common/RequestUtils";
import { useImageMapContext } from "../../context/ImageMapContext";
import { PLAN_TYPES, useUser } from "../../context/UserContext";

const useStyles = makeStyles(() => ({
  topActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "48px",
    padding: "0px 8px",
  },
  conversationItem: {
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    textOverflow: "ellipsis",
    borderRadius: "12px",
    marginBottom: "2px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  selectedConversationItem: {
    backgroundColor: "rgb(227, 242, 253)",
    "& span": {
      maxWidth: "160px",
    },
    "& $conversationActions": {
      visibility: "visible",
      backgroundColor: "rgba(227, 242, 253)",
    },
    "&:hover": {
      backgroundColor: "rgb(222, 240, 252)",
      "& $conversationActions": {
        backgroundColor: "rgb(222, 240, 252)",
      },
    },
  },
  conversationContainer: {
    display: "flex",
    minHeight: "48px",
    flexDirection: "column",
    padding: "0px 8px",
    overflowY: "auto",
    height: "100%",
    position: "relative",
  },
  companyIcon: {
    height: "25px",
    maxWidth: "25px",
    maxHeight: "25px",
    marginLeft: "15px",
    fontSize: "16px",
  },
  conversationActions: {
    visibility: "hidden",
    position: "absolute",
    justifySelf: "end",
    right: "15px",
    borderRadius: "12px",
  },
}));

export const MemoizedChatHistory = React.memo(ChatHistory);

const MemoizedConversationItem = React.memo(
  ConversationItem,
  (prevProps, nextProps) => {
    // This does work but,
    // tor some reason the ConversationItems all still render once each time one clicking betwen items,
    // not really sure why but maybe some upstream issue
    if (
      prevProps.initConversation?.name === nextProps.initConversation?.name &&
      prevProps.selectedConversationId !== prevProps.initConversation?.id &&
      nextProps.selectedConversationId !== nextProps.initConversation?.id
    ) {
      return true;
    }
    return false;
  }
);

function ChatHistory(props) {
  // If the URL has conversationId, automatically attempt to load a conversation with this ID.
  const { conversationId } = useParams();
  const history = useHistory();
  const gptConversationContext = useGptConversationContext();
  const classes = useStyles();
  const location = useLocation();
  const user = useUser();

  const {
    setHistoryOpen,
    selectedConversationId,
    setConversation,
    userOpportunityCompanies,
    setLoading,
  } = props;
  const [conversations, setConversations] = React.useState([]);
  const [loadingHistory, setLoadingHistory] = React.useState(false);

  /** If the history was initially loaded from Salesforce iframe with conversation filtering params in the URL, persist
   * the filter throughout conversation changes.*/
  const [conversationUrlParams, setConversationUrlParams] = React.useState(
    new URLSearchParams(window.location.search)
  );

  const isContentUser =
    user.userSubscription?.planType === PLAN_TYPES.premiumContent;

  function loadNewPresetConversation() {
    gptConversationContext.abortChat();
    // A default company exists, so don't allow the user to select a company/functional area.
    let foundCompany = userOpportunityCompanies.find(
      (uoc) =>
        uoc.companyId === parseInt(conversationUrlParams.get("companyId"))
    );
    history.push(`/main/chat`);
    setConversation({
      company: foundCompany,
      functionalArea: conversationUrlParams.get("functionalArea"),
      id: null,
      promptCompletion: [],
      suggestedQuestions: null,
    });
  }

  // Set the default conversation URL params so if the URL changes, company remains.
  React.useEffect(() => {
    let conversationUrlParams = new URLSearchParams(window.location.search);
    setConversationUrlParams(conversationUrlParams);
    if (
      conversationUrlParams.get("companyId") &&
      userOpportunityCompanies?.length > 0
    ) {
      loadNewPresetConversation();
    }
  }, [userOpportunityCompanies]);

  // Re-fetch the conversations as needed.
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (conversationId && !queryParams.has("new") && conversations.length > 0) {
      // Already have conversations list and nothing has updated.
      return;
    }
    let tempParams = conversationUrlParams;

    if (!tempParams.has("filterByUser")) {
      tempParams.append("filterByUser", true);
    }
    setLoadingHistory(true);
    axios
      .get(
        `${
          rule5properties.conversations
        }?excludePromptCompletion=${(!isContentUser).toString()}&${
          conversationUrlParams.get("filterToDefault")
            ? tempParams.toString()
            : "filterByUser=true"
        }`
      )
      .then((res) => {
        setLoadingHistory(false);
        if (res.data && Array.isArray(res.data)) {
          setConversations(res.data);
          if (!queryParams.has("new")) {
            gptConversationContext.resetSelection();
            gptConversationContext.abortChat();
            // Only trigger a new conversation load if there's no default company set.
            if (
              (!conversationId && isContentUser) ||
              (!conversationId && !conversationUrlParams.get("companyId")) ||
              !conversationUrlParams.get("filterToDefault")
            ) {
              setConversation(EMPTY_CONVERSATION);
              props.setOpenAutocomplete(true);
            }
          } else if (
            res.data.find((conv) => conv.id === parseInt(conversationId))
          ) {
            // Delete the new=true param so URL can be easily copied without it.
            // Copy/pasting the link with it will not give expected result.
            // It's no longer needed since setConversation logic above has already been successfully avoided.
            queryParams.delete("new");
            history.replace({
              search: queryParams.toString(),
            });
          }
        }
      });
  }, [conversationId]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (
      conversationId &&
      (userOpportunityCompanies || isContentUser) &&
      !queryParams.has("new")
    ) {
      setLoading(true);
      axios
        .get(`${rule5properties.conversations}/${conversationId}`)
        .then((res) => {
          setLoading(false);
          if (isSuccessStatus(res.status)) {
            setConversation(res.data);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [conversationId, userOpportunityCompanies]);

  const drawer = (
    <>
      <div className={classes.topActions}>
        <IconButton onClick={() => setHistoryOpen(false)}>
          <LastPageIcon />
        </IconButton>
        <Button
          variant="text"
          disableElevation
          sx={{
            textTransform: "none",
            width: "100%",
            margin: "15px",
            alignSelf: "center",
          }}
          onClick={
            conversationUrlParams.has("companyId") &&
            conversationUrlParams.has("filterToDefault")
              ? loadNewPresetConversation
              : () => {
                  if (history.location.pathname !== "/main/chat") {
                    history.push(`/main/chat`);
                  } else {
                    gptConversationContext.abortChat();
                  }
                  setConversation(EMPTY_CONVERSATION);
                  props.setOpenAutocomplete(true);
                }
          }
        >
          New conversation
        </Button>
      </div>
      <div style={{}} className={classes.conversationContainer}>
        {!loadingHistory ||
        (Array.isArray(conversations) && conversations?.length > 0) ? (
          conversations?.map((conversation, index) => (
            <MemoizedConversationItem
              initConversation={conversation}
              setChatConversation={setConversation}
              key={conversation.id}
              selectedConversationId={selectedConversationId}
              displayAvatar={!isContentUser}
            ></MemoizedConversationItem>
          ))
        ) : (
          <GrayCircularProgress sx={{ margin: "35vh auto 0 auto" }} />
        )}
      </div>
      <div
        style={{
          backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,0) 0%,#fff  100%)",
          backgroundColor: "transparent",
          position: "sticky",
          bottom: "0",
          width: "100%",
          height: "60px",
        }}
      ></div>
    </>
  );

  return drawer;
}

function ConversationItem(props) {
  const {
    setChatConversation,
    initConversation,
    selectedConversationId,
    displayAvatar,
  } = props;

  const classes = useStyles();
  const dialog = useDialog();
  const history = useHistory();
  const snackbar = useSnack();

  const [editMode, setEditMode] = React.useState(false);
  const [name, setName] = React.useState(initConversation.name);
  const [conversation, setConversation] = React.useState(initConversation);
  const [imageSrc, setImageSrc] = React.useState();

  const { getImageData } = useImageMapContext();

  React.useEffect(() => {
    if (conversation?.company?.companyInfo?.icon) {
      getImageData(conversation.company.companyInfo.icon, (data, error) => {
        if (data) {
          setImageSrc(data);
        }
      });
    }
  }, [conversation]);

  const updateNameSubmit = (event) => {
    event.preventDefault();
    const params = {
      id: conversation.id,
      updateConversationObj: {
        name: name,
      },
    };
    axios
      .patch(rule5properties.conversations, params)
      .then((resp) => {
        setEditMode(false);
        setConversation({ ...conversation, name: resp.data?.name });
      })
      .catch((error) =>
        snackbar.createSnack(
          "There was a problem updating the conversation name."
        )
      );
  };

  const secondaryText =
    (conversation.functionalArea !== "Global" && conversation.functionalArea) ||
    conversation?.promptCompletion?.[0]?.prompt?.contents?.[0]?.contentName;

  return (
    <div
      role="button"
      aria-label={"open conversation " + conversation?.name}
      className={
        classes.conversationItem +
        `${
          conversation.id === selectedConversationId
            ? " " + classes.selectedConversationItem
            : ""
        }`
      }
      onClick={(event) => {
        if (!editMode) {
          history.push(`/main/chat/${conversation.id}`);
        }
      }}
    >
      {!displayAvatar ? (
        <div style={{ width: "10px" }} />
      ) : imageSrc ? (
        <img
          src={imageSrc}
          className={classes.companyIcon}
          alt={conversation.company.companyInfo.icon}
        />
      ) : (
        <Tooltip title={conversation.company?.companyInfo?.name}>
          <Avatar
            variant="rounded"
            className={classes.companyIcon}
            sx={{
              bgcolor: getColorFromString(
                conversation.company?.companyInfo?.name
              ),
            }}
          >
            {conversation.company?.companyInfo?.name?.toUpperCase().charAt(0)}
          </Avatar>
        </Tooltip>
      )}

      {editMode ? (
        <form
          onSubmit={updateNameSubmit}
          style={{ display: "flex", alignItems: "center" }}
          onBlur={(event) => {
            // Exit edit mode if focus is no longer in one of the form inputs.
            // onBlur gets called even if focus remains within the form, hence this check.
            if (!event.currentTarget.contains(event.relatedTarget)) {
              setEditMode(false);
            }
          }}
        >
          <StyledTextfield
            style={{
              width: "150px",
              height: "36px",
              margin: "0px 10px",
              backgroundColor: "inherit",
              fontSize: "0.9rem",
            }}
            inputRef={(input) => input && input.focus()}
            defaultValue={name}
            onChange={(event) => setName(event.target.value)}
          />
          <div style={{}}>
            <Tooltip title="Confirm edit">
              <IconButton aria-label="Edit" size="small" type="submit">
                <DoneIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip>
              <IconButton
                title="Cancel edit"
                aria-label="Delete"
                size="small"
                onClick={() => setEditMode(false)}
              >
                <CancelIcon fontSize="small"></CancelIcon>
              </IconButton>
            </Tooltip>
          </div>
        </form>
      ) : (
        <>
          <div
            style={{ display: "flex", flexDirection: "column", width: "230px" }}
          >
            <Typography
              sx={{
                pl: "12px",
                fontSize: "0.9rem",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              component="span"
            >
              {conversation.name
                ? conversation.name
                : conversation.company?.companyInfo?.name?.substring(0, 20) +
                  "…"}
            </Typography>
            {secondaryText && (
              <Typography
                sx={{
                  pl: "12px",
                  fontSize: "0.9rem",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  opacity: 0.5,
                }}
                component="span"
              >
                {secondaryText}
              </Typography>
            )}
          </div>
          <div className={classes.conversationActions}>
            <Tooltip title="Edit conversation">
              <IconButton
                aria-label="Edit"
                size="small"
                onClick={() => setEditMode(true)}
              >
                <EditIcon fontSize="small"></EditIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete conversation">
              <IconButton
                aria-label="Delete"
                size="small"
                onClick={() =>
                  dialog.openModal(
                    "Delete conversation",
                    DeleteConversation,
                    {
                      name: conversation.name,
                      id: conversation.id,
                      setConversation: setChatConversation,
                    },
                    "sm"
                  )
                }
              >
                <DeleteIcon fontSize="small"></DeleteIcon>
              </IconButton>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
}
