import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Popover,
  createFilterOptions,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ColorizeIcon from "@mui/icons-material/Colorize";
import axios from "axios";
import { rule5properties } from "../../properties";
import {
  PopperComponent,
  StyledPopper,
  StyledTextfield,
} from "../../common/StyledComponents";
import { TwitterPicker } from "react-color";
import useSnack from "../../context/Snack";
import { isSuccessStatus } from "../../common/RequestUtils";
import { isEqual } from "lodash";

export default function LabelAutocomplete(props) {
  const {
    anchorEl,
    open,
    handleClose,
    labels,
    promptInfo,
    loadLabels,
    loadPromptBooks,
    values,
    pendingValues,
    setPendingValues,
    handleApplyLabel,
  } = props;

  const [colorizerAnchorEl, setColorizerAnchorEl] = React.useState(null);

  const snackBar = useSnack();

  const colorizerOpen = Boolean(colorizerAnchorEl);
  const colorizerId = open ? "colorizer-id" : undefined;
  const filter = createFilterOptions();
  const id = open ? `${promptInfo.promptId}-label` : undefined;

  const handleColorizerClose = () => {
    setColorizerAnchorEl(null);
  };

  function handleColorizerClick(event) {
    setColorizerAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  }

  function handleColorChange(color, event) {
    const updatedPendingValues = pendingValues.map((pendingValue) => {
      if (pendingValue.labelId?.toString() === colorizerAnchorEl.id) {
        return {
          ...pendingValue,
          labelInfo: { ...pendingValue?.labelInfo, color: color.hex },
        };
      } else {
        return pendingValue;
      }
    });
    setPendingValues(updatedPendingValues);
    handleColorizerClose();
    // Update the color if it's not a label added from user input which does not yet exist;
    if (colorizerAnchorEl?.id !== "-1") {
      const params = {
        id: colorizerAnchorEl?.id,
        updateLabelObj: { labelInfo: { color: color.hex } },
      };
      axios
        .patch(rule5properties.labels, params)
        .then((resp) => {
          if (!isSuccessStatus(resp.status)) {
            snackBar.createSnack(
              "Unable to update label color: " + resp.data.message
            );
          }
          loadPromptBooks();
          loadLabels();
        })
        .catch(() => snackBar.createSnack("Error updating label color."));
    }
  }

  if (!labels || !pendingValues || !promptInfo) {
    return null;
  }

  return (
    <StyledPopper
      anchorEl={anchorEl}
      open={open}
      id={id}
      placement="bottom-start"
      sx={{ width: "300px" }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Autocomplete
            defaultValue={pendingValues}
            value={pendingValues}
            onChange={(event, newValue, reason) => {
              if (
                event.type === "keydown" &&
                event.key === "Backspace" &&
                reason === "removeOption"
              ) {
                return;
              }
              // In case user presses enter key in the input. Don't do anything.
              if (reason === "createOption") {
                return;
              }
              // In case user presses enter key in the input to create a new option
              if (colorizerAnchorEl) {
                // Cause the colorizer popover inside automcomplete popup doesn't work very well,
                // so don't allow any options in the underneath autocomplete to look selected
                // while colorizer popover is open.
                return;
              }

              const newValue2 = newValue.map((value) => {
                // User selected to add a new option based on their inputValue
                if (value?.inputValue) {
                  return { name: value.inputValue, labelId: -1 };
                } else {
                  return value;
                }
              });
              setPendingValues(newValue2);
            }}
            isOptionEqualToValue={(option, value) =>
              option?.labelId === value?.labelId
            }
            open
            onClose={(event, reason) => {
              if (reason === "escape") {
                handleClose();
              }
            }}
            multiple
            renderTags={() => null}
            disableCloseOnSelect
            PopperComponent={PopperComponent}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.name
              );
              if (inputValue !== "" && !isExisting) {
                filtered.unshift({
                  inputValue,
                  name: `Add "${inputValue}"`,
                  labelId: -1,
                });
              }
              const pendingInputtedValue = pendingValues.find(
                (pendingValue) => pendingValue?.labelId === -1
              );
              if (pendingInputtedValue) {
                filtered.unshift(pendingInputtedValue);
              }
              return filtered;
            }}
            renderInput={(params) => {
              const { InputLabelProps, ...rest } = params;
              const InputProps = {
                ...params.InputProps,
              };
              return (
                <>
                  <StyledTextfield
                    autoFocus
                    placeholder={"Search labels or add new"}
                    {...rest}
                    {...InputProps}
                    {...params}
                  />
                </>
              );
            }}
            clearOnBlur={true}
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={[...labels].sort((a, b) => {
              // Display the selected labels first.
              // TODO not working
              let ai = values.indexOf(a);
              ai = ai === -1 ? values.length + labels.indexOf(a) : ai;
              let bi = values.indexOf(b);
              bi = bi === -1 ? values.length + labels.indexOf(b) : bi;
              return ai - bi;
            })}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <div style={{ display: "flex", gap: "5px", width: "100%" }}>
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
                    style={{
                      visibility: selected ? "visible" : "hidden",
                    }}
                  />
                  {!option.inputValue ? (
                    <CircleIcon
                      fontSize="small"
                      style={{
                        color: option.labelInfo?.color,
                      }}
                    ></CircleIcon>
                  ) : (
                    <AddCircleOutlineIcon
                      fontSize="small"
                      style={{ color: "rgba(0, 0, 0, 0.54)" }}
                    ></AddCircleOutlineIcon>
                  )}
                  {option.name}
                  {!option.inputValue && (
                    <IconButton
                      size="small"
                      sx={{
                        marginLeft: "auto",
                      }}
                      id={option.labelId}
                      onClick={handleColorizerClick}
                    >
                      <ColorizeIcon
                        sx={{ width: 18, height: 18 }}
                        fontSize="small"
                      />
                    </IconButton>
                  )}
                  <IconButton
                    size="small"
                    style={{
                      visibility: selected ? "visible" : "hidden",
                    }}
                  >
                    <CancelIcon
                      sx={{ width: 18, height: 18 }}
                      fontSize="small"
                    />
                  </IconButton>
                  <Popover
                    id={colorizerId}
                    open={colorizerOpen}
                    anchorEl={colorizerAnchorEl}
                    onClose={handleColorizerClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={(theme) => ({
                      boxShadow: theme.workspace.boxShadow,
                      "& .MuiPopover-paper": {
                        boxShadow:
                          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                      },
                    })}
                  >
                    <TwitterPicker
                      triangle="hide"
                      onChange={handleColorChange}
                    ></TwitterPicker>
                  </Popover>
                </div>
              </li>
            )}
            sx={{ width: 300 }}
            freeSolo
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderTop: "1px solid gainsboro",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              sx={{
                textTransform: "none",
                alignSelf: "center",
                justifySelf: "center",
                margin: "10px",
              }}
              onClick={handleApplyLabel}
              disabled={isEqual(values, pendingValues)}
            >
              Apply
            </Button>
          </div>
        </div>
      </ClickAwayListener>
    </StyledPopper>
  );
}
