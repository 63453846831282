import React from "react";
import {
  Card,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { InvertedIconButton } from "../../common/StyledComponents";
import { useDialog } from "../../context/DialogContext";
import CategoryItem from "./CategoryItem";
import CreateCategory from "../../modal/CreateCategory";

export default function CategoriesPanel(props) {
  const {
    loadContent,
    selectedContent,
    setSelectedContent,
    loading,
    viewingPdf,
  } = props;

  const dialog = useDialog();

  if (!selectedContent) {
    return null;
  }

  return (
    <Card
      id="card2"
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: "0",
        p: 2,
        borderRadius: "8px",
        width: "400px",
        minHeight: "10px",
        minWidth: "280px",
        height: "100%",
      }}
    >
      {loading ? (
        <CircularProgress></CircularProgress>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <div>
              <Typography fontSize={16}>{selectedContent.name}</Typography>
              <Typography
                fontSize={14}
                sx={{
                  opacity: 0.7,
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {selectedContent.description}
              </Typography>
            </div>
            {!viewingPdf && (
              <Tooltip title="Hide categories">
                <IconButton
                  size="small"
                  aria-label="Close content categories pane"
                  onClick={() => {
                    setSelectedContent(null);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "15px",
              marginBottom: "15px",
              height: "100%",
            }}
          >
            {selectedContent.categories?.length > 0 ? (
              selectedContent.categories?.map((dataRow) => {
                return (
                  <CategoryItem
                    key={dataRow.categoryId}
                    category={dataRow}
                    content={selectedContent} //probably more efficient to use a context or something here
                    loadContent={loadContent}
                  ></CategoryItem>
                );
              })
            ) : (
              <div
                style={{
                  margin: "auto",
                  color: "rgba(0,0,0,0.6)",
                  fontSize: 22,
                }}
              >
                Click "+" to add categories
              </div>
            )}
          </div>
          <Tooltip title="Add category">
            <InvertedIconButton
              color="primary"
              sx={{ marginTop: "auto", marginLeft: "auto" }}
              aria-label="Add category"
              onClick={() => {
                dialog.openModal(
                  `Content Categories`,
                  CreateCategory,
                  {
                    loadContent: loadContent,
                    content: selectedContent,
                  },
                  "sm"
                );
              }}
            >
              <AddIcon />
            </InvertedIconButton>
          </Tooltip>
        </>
      )}
    </Card>
  );
}
