import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import CheckBox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import produce from "immer";
import { rule5properties } from "../../../../properties";

const useStyles = makeStyles(() => ({
  cardstyle: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "0px",
    textAlign: "left",
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    padding: "10px 48px 32px 48px",
  },
  flexContent: {
    flexGrow: 1,
    position: "relative",
    margin: "10px",
    width: 320,
    height: 200,
    overflowX: "hidden",
    overflowY: "hidden",
    "&::after": {
      position: "absolute",
      bottom: 0,
      height: "15%",
      width: "100%",
      content: '""',
      background:
        "linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 0.7), rgba(255,255,255, 1));",
      pointerEvents: "none" /* so the text is still selectable */,
    },
  },
  newsImage: {
    width: "120px",
    float: "left",
    marginRight: "10px",
  },
  authorContent: {
    color: "green",
  },
  contentType: {
    textDecorationLine: "underline",
    fontWeight: "bold",
  },
  boldStyle: {
    fontWeight: "800",
  },
  root: {
    "& .MuiTableCell-root": {
      borderBottom: "0px solid",
      padding: "0px",
    },
    "& .MuiPaper-root": {
      boxShadow: "none",
    },
    "& .MuiToolbar-regular": {
      minHeight: "0px",
    },
    "& .hideAddIcon": {
      visibility: "hidden",
    },
    "&:hover .hideAddIcon": {
      visibility: "visible",
    },
    "& .MuiList-padding": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "&. MuiTableRow-root": {
      position: "relative",
    },
  },
}));

export default function RecommendedActions(props) {
  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [firstTime, setFirstTime] = React.useState(true);
  const [actionData, setActionData] = React.useState({ Stages: [] });
  const [currentStageData, setCurrentStageData] = React.useState({ items: [] });

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      let parameter = "/" + props.cardId; // cardId hardcoded for now
      axios
        .get(rule5properties.getCard + parameter)
        .then((response) => {
          let rawdata = response.data;
          if (!Object.keys(rawdata) !== 0) {
            // Validate content
            // Array reference will update the value
            setActionData(rawdata);

            rawdata.Stages.map((stage) => {
              if (props.opportunity.Stage.currentStage === stage.name) {
                setCurrentStageData(stage);
              }
            });
          } else {
            // API call failed
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        });
    }
  }, [firstTime, actionData, currentStageData]);

  const handleClick = (e, row, index) => {
    const newRow = { ...row, status: e.target.checked };

    const newCurrentStageData = produce(currentStageData, (draftState) => {
      draftState.items[index] = newRow;
    });

    const newActionData = produce(actionData, (draftState) => {
      const index = draftState.Stages.findIndex(
        (element) => element.name === newCurrentStageData.name
      );

      if (index !== -1) {
        draftState.Stages[index] = newCurrentStageData;
      }
    });

    setCurrentStageData(newCurrentStageData);
    saveCard(newActionData);
  };

  const saveCard = (newActionData) => {
    const params = {
      id: props.cardId,
      card_info: newActionData,
    };
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
    };

    axios
      .patch(rule5properties.getCard, params, options)
      .then((response) => {
        let rawdata = response.data.cardInfo;
        if (!Object.keys(rawdata) !== 0) {
          // Validate content
          // Array reference will update the value
          setActionData(rawdata);
        } else {
          // API call failed
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
      });
  };

  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={
        actionData.Title +
        " for " +
        props.opportunity.Stage.currentStage +
        " Stage"
      }
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <Card className={classes.cardstyle}>
        <CardContent className={classes.cardContent}>
          <TableContainer>
            <Table>
              <TableBody>
                {currentStageData.items.map((row, index) => {
                  return (
                    <TableRow key={row.name + "_"} className={classes.root}>
                      <TableCell
                        style={{
                          width: "10px",
                          maxwidth: "100px",
                          paddingRight: "5px",
                        }}
                        align="left"
                      >
                        <FormControlLabel
                          key={index}
                          control={
                            <CheckBox
                              key={row.name}
                              value={row.name + "_" + index}
                              checked={row.status}
                              onChange={(e) => handleClick(e, row, index)}
                            />
                          }
                          label=""
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body"
                          style={{ position: "relative", right: "10px" }}
                        >
                          {row.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Researchcard>
  );
}
