import React, { useEffect } from "react";
import AuthService from "./AuthService";
import { useLocation, useHistory } from "react-router-dom";
import { Card, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import JwtService from "./JwtService";
import { useUserUpdate } from "../context/UserContext";
import { LoadingButton } from "@mui/lab";
import { StyledTextfield } from "../common/StyledComponents";
import ClearIcon from "@mui/icons-material/Clear";
import { emailSchema } from "./Registration";
import { getLandingFromAllowedActions } from "../common/Utils";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100vh",
  },
  confirmationMessage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "30px",
  },
  formCard: {
    margin: "140px",
    borderRadius: "10px",
    width: 470,
    boxSizing: "border-box",
    padding: 42,
    boxShadow: "0 3px 3px -2px rgba(0, 0, 0, 0.1)",
  },
}));

/** Verification page to redirect users who click on invitation / verification links. Page expects a token ?token= to
 * call the verification API. */

export default function Verify() {
  const DEFAULT_STATUS = "Verifying...";
  const RESENT_STATUS = "Verification email sent!";
  const location = useLocation();
  const classes = useStyles();
  const [status, setStatus] = React.useState(DEFAULT_STATUS);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const history = useHistory();
  const updateUserContext = useUserUpdate();
  // Logout to clear previous refresh token prior to acquiring new one from verification. Rare case.

  useEffect(() => {
    AuthService.verifyEmail(location.search).then((data) => {
      if (data.accessToken) {
        JwtService.setToken(data.accessToken, data.expiresIn);
        updateUserContext(data);
        history.push(
          "/main/" +
            getLandingFromAllowedActions(data.userSubscription?.allowedActions)
        );
      } else {
        if (data?.code === "INVALID_ARGUMENT") {
          setStatus(data.message);
        } else {
          setStatus("Access token is no longer valid.");
        }
        AuthService.logout();
        updateUserContext(null);
      }
    });
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card className={classes.formCard} variant="outlined">
        <div className={classes.confirmationMessage}>
          <Typography variant="h5" textAlign="center">
            Email Verification
          </Typography>

          <Typography
            variant="body2"
            textAlign="center"
            color={
              status === DEFAULT_STATUS || status === RESENT_STATUS
                ? "black"
                : "red"
            }
          >
            {status}
          </Typography>
          {status !== DEFAULT_STATUS && (
            <>
              <StyledTextfield
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                size="small"
                fullWidth
                placeholder={"Email"}
                endAdornment={
                  <IconButton
                    size="small"
                    sx={{
                      visibility: email ? "visible" : "hidden",
                    }}
                    onClick={() => setEmail("")}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                }
              />
              <LoadingButton
                variant="outlined"
                disableElevation
                loading={loading}
                sx={{ textTransform: "none", width: "175x" }}
                onClick={() => {
                  if (emailSchema.validate(email).error) {
                    setStatus("Please enter a valid email address.");
                    return;
                  }

                  setLoading(true);
                  AuthService.resendEmailVerification(email).then((data) => {
                    setLoading(false);
                    if (data === "OK") {
                      setStatus(RESENT_STATUS);
                    } else {
                      if (data?.code === "INVALID_ARGUMENT") {
                        setStatus(data.message);
                      } else {
                        setStatus("Access token is no longer valid.");
                      }
                      AuthService.logout();
                      updateUserContext(null);
                    }
                  });
                }}
              >
                Resend Verification Email
              </LoadingButton>
            </>
          )}
        </div>
      </Card>
    </div>
  );
}
