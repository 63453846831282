import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { rule5properties } from "../../properties";
import { cloneDeep } from "lodash";
import { getUserlabel } from "../common/utils";

export default function AssigneeSelector(props) {
  const [detUsers, setDetUsers] = React.useState(null);
  const task = props.task;
  const taskType = props.taskType;
  const onUpdate = props.onUpdate;
  const value = props.value;
  const classes = props.classes;

  React.useEffect(() => {
    axios
      .get(rule5properties.detGetUsers)
      .then((response) => {
        if (response.status === 403) {
          console.log("You don't have permission to get detusers.");
          return;
        }
        setDetUsers(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.status);
          console.log("error resp: " + error.response.data);
        }
      });
  }, []);

  const onChange = (newValue) => {
    if (!task || !task.id) {
      // Just carry on with normal onChange if this is not a request for an existing task.
      onUpdate(newValue);
    } else {
      // Make an API PATCH request if this is for an existing research.
      let taskCopy = cloneDeep(task);
      taskCopy.actionInfo["assignee"] = newValue;
      const params = {
        id: parseInt(taskCopy.id),
        action_info: taskCopy.actionInfo,
      };
      let requestPromise = null;
      switch (taskType) {
        case "card":
          params.original_card_id = parseInt(taskCopy.originalCardId);
          requestPromise = axios.patch(rule5properties.detCards, params);
          break;
        case "research":
          requestPromise = axios.patch(rule5properties.detResearch, params);
          break;
        default:
          console.log("Missing type");
          return;
      }
      requestPromise
        .catch(function (error) {
          alert("ERROR: " + JSON.stringify(error));
          console.log(JSON.stringify(error));
        })
        .then((response) => {
          onUpdate(response.data);
        });
    }
  };

  if (!detUsers) {
    return "";
  }

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      value={value}
      classes={classes}
      disabled={
        task && ["Published", "Approved"].includes(task.status)
        // || (task && !task.actionInfo) // I think this was for preventing it being changed during initial create card flow
      }
      sx={{ width: "225px", mx: "2%" }}
      size="small"
      disableListWrap
      id="assignee-combo-box"
      options={detUsers.map((row, index) => {
        return getUserlabel(row);
      })}
      //   sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          style={{ textOverflow: "ellipsis" }}
          {...params}
          label="Assignee"
        />
      )}
    />
  );
}
