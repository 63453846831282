import { Box } from "@mui/material";
import { useCampaignResults } from "../../api/campaigns";
import { useParams } from "react-router-dom";
import CampaignResultsDataGrid from "./CampaignResultsDataGrid";

export default function CampaignResults() {
  const { campaignId } = useParams();
  const {
    data: campaignResults,
    isLoading,
    refetch,
  } = useCampaignResults(campaignId);

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
      <CampaignResultsDataGrid
        rows={campaignResults}
        isLoading={isLoading}
        idField="campaignResultId"
        refetchRows={refetch}
      />
    </Box>
  );
}
