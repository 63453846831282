import React, { useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Collapse,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import {
  CancelConfirmButtons,
  StyledTextfield,
} from "../common/StyledComponents";

export default function FeedbackForm(props) {
  const {
    defaultFeedbackInfo,
    selectedId,
    toggleFeedbackMode,
    setFeedbackById,
    refetchRows,
    setLocalFeedbackInfo,
    resultsUrl,
  } = props;

  const defaults = {
    rating: defaultFeedbackInfo?.rating || "",
    comment: defaultFeedbackInfo?.comment || "",
    tags: defaultFeedbackInfo?.tags || [],
    notTrue: defaultFeedbackInfo?.tags?.includes("notTrue") || false,
    notHelpful: defaultFeedbackInfo?.tags?.includes("notHelpful") || false,
  };
  const methods = useForm({ defaultValues: defaults });

  // 0 not selected, 1 thumbs up, 2 thumbs down
  const [thumbSelection, setThumbSelection] = React.useState(
    defaultFeedbackInfo
      ? defaultFeedbackInfo.rating === "thumbsUp"
        ? 1
        : 2
      : 0
  );
  const [feedbackStatus, setFeedbackStatus] = React.useState("");

  function sendFeedbackFunction(value) {
    const tags = [];
    if (value.notTrue) {
      tags.push("notTrue");
    }
    if (value.notHelpful) {
      tags.push("notHelpful");
    }
    const params = {
      rating: value.rating,
    };
    if (value.rating === "thumbsDown") {
      params.comment = value.comment;
      params.tags = tags;
    }
    axios.post(`${resultsUrl}/${selectedId}/feedback`, params).then((res) => {
      if (res.status === 200) {
        setFeedbackStatus("");
        setLocalFeedbackInfo(params);
        setFeedbackById && setFeedbackById(selectedId, params);
        refetchRows && refetchRows();
      } else if (res.data?.message) {
        console.log("[rule5] Failed to submit feedback.");
        setFeedbackStatus(res.data.message);
      }
    });
  }

  useEffect(() => {
    if (thumbSelection === 1) {
      methods.setValue("rating", "thumbsUp");
    } else if (thumbSelection === 2) {
      methods.setValue("rating", "thumbsDown");
    }
  }, [thumbSelection, methods]);

  return (
    <Box sx={{ width: "100%" }}>
      <form
        style={{ width: "100%" }}
        onSubmit={methods.handleSubmit(sendFeedbackFunction)}
      >
        <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <IconButton
              disabled={defaultFeedbackInfo}
              onClick={() => {
                setThumbSelection(1);
              }}
            >
              {thumbSelection === 1 ? (
                <ThumbUpAltIcon />
              ) : (
                <ThumbUpAltOutlinedIcon />
              )}
            </IconButton>
            <IconButton
              disabled={defaultFeedbackInfo}
              onClick={() => {
                setThumbSelection(2);
              }}
            >
              {thumbSelection === 2 ? (
                <ThumbDownAltIcon />
              ) : (
                <ThumbDownAltOutlinedIcon />
              )}
            </IconButton>
            {thumbSelection === 0 && (
              <Typography variant="caption" sx={{ ml: 1 }}>
                Select thumbs up or thumbs down.
              </Typography>
            )}
          </Stack>
          <Collapse
            in={thumbSelection === 2}
            orientation="horizontal"
            unmountOnExit
          >
            <Box sx={{ ml: 2, textWrap: "nowrap" }}>
              <FormControlLabel
                disabled={defaultFeedbackInfo}
                sx={{ mr: 2 }}
                control={
                  <Checkbox
                    {...methods.register("notTrue")}
                    checked={methods.watch("notTrue")}
                    size="small"
                    style={{ padding: "6px" }}
                  />
                }
                label="Not true"
              />
              <FormControlLabel
                disabled={defaultFeedbackInfo}
                control={
                  <Checkbox
                    {...methods.register("notHelpful")}
                    checked={methods.watch("notHelpful")}
                    size="small"
                    style={{ padding: "6px" }}
                  />
                }
                label="Not helpful / relevant"
              />
            </Box>
          </Collapse>
        </Stack>
        <StyledTextfield
          {...methods.register("comment", {
            minLength: 1,
          })}
          disabled={defaultFeedbackInfo}
          autoFocus
          placeholder="Share feedback on this signal..."
          sx={{
            display: "block",
            fontSize: "16px",
            minHeight: "100px",
            overflowY: "auto",
            flexGrow: 1,
          }}
          multiline
        />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 1 }}
        >
          <Collapse in={feedbackStatus.trim().length > 0}>
            <Typography sx={{ ml: 1, mt: -0.5 }} color="error">
              {feedbackStatus}
            </Typography>
          </Collapse>
          <CancelConfirmButtons
            sx={{ width: "360px" }}
            cancelText={defaultFeedbackInfo ? "Back" : "Cancel"}
            cancelFunction={toggleFeedbackMode}
            confirmText={
              defaultFeedbackInfo ? "Feedback received" : "Send feedback"
            }
            confirmDisabled={thumbSelection === 0 || defaultFeedbackInfo}
          />
        </Stack>
      </form>
    </Box>
  );
}
