import axios from "axios";
import { useQuery } from "react-query";
import { rule5properties } from "../properties";

export const usePromptBooks = ({ name, description, searchValue } = {}) => {
  return useQuery(["promptBooks", name, description, searchValue], async () => {
    try {
      const queryParams = new URLSearchParams({
        ...(name && { name }),
        ...(description && { description }),
        ...(searchValue && { searchValue }),
      });

      const url = `${rule5properties.promptBooks}${
        queryParams.toString() ? `?${queryParams.toString()}` : ""
      }`;
      const promptBooksResponse = await axios.get(url);
      if (promptBooksResponse.data) {
        return promptBooksResponse.data;
      }
      throw new Error(
        promptBooksResponse.data?.message || "No prompt books found"
      );
    } catch (error) {
      console.error("Error loading Prompt books:", error);
      throw error;
    }
  });
};
