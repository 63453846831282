import React from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { useDialog } from "../../../context/DialogContext";
import { isEmpty } from "lodash";
import { accountsAgentType, peopleAgentType } from "./AgentTypes";
import EditAgentList from "./EditAgentList";
import { COMPANIES_RUN_NOW_LIMIT } from "./RunAgentButton";

export default function CongratsMessage(props) {
  const { agentType, response, loadAgentRows } = props;
  const dialog = useDialog();

  const openModal = () => {
    dialog.openModal(
      `Edit or add ${accountsAgentType.displayName} - ${response.name}`,
      EditAgentList,
      {
        agentType: agentType,
        modAgent: response,
        loadAgentRows,
      },
      "lg"
    );
  };

  const EditLink = (
    <Link sx={{ cursor: "pointer" }} onClick={openModal}>
      here
    </Link>
  );

  let displayText = "";
  let activeCount = 0;
  let totalCount = 0;
  let errorCount = 0;
  if (agentType.type === peopleAgentType.type) {
    activeCount = +response.peopleActiveCount;
    totalCount = +response.peopleCount;
    errorCount = +response.peopleErrorCount;
    if (activeCount > 0 && activeCount === totalCount) {
      displayText =
        "Congratulations! Your agent is active for the specified people. You will receive notifications every morning based on the people you've selected. If you would like to run the agent immediately, click 'Run now'.";
    } else if (totalCount === 0) {
      displayText =
        "Looks like your agent isn't ready to roll yet. To get it going, please provide a valid list of people  and specify actions. Once you've done that, you will receive notifications every morning based on the signals you've selected. If you would like to run the agent immediately, click 'Run now'.";
    } else if (activeCount > 0 && errorCount > 0) {
      displayText = (
        <>
          Congratulations! Your agent is active for {response.peopleActiveCount}{" "}
          {activeCount === 1 ? "person" : "people"}. You will receive
          notifications every morning based on the people you've selected. If
          you would like to run the agent immediately, click 'Run now'. There{" "}
          {errorCount === 1 ? "was" : "were"} {response.peopleErrorCount}{" "}
          {errorCount === 1 ? "person" : "people"} that could not be processed.
          You can review the errors {EditLink}.
        </>
      );
    } else if (activeCount === 0 && errorCount > 0) {
      displayText = (
        <>
          Looks like your agent isn't ready to roll yet. There{" "}
          {errorCount === 1 ? "was" : "were"} {response.peopleErrorCount}{" "}
          {errorCount === 1 ? "person" : "people"} that could not be processed.
          You can review the errors {EditLink}. Once errors have been resolved,
          you will receive notifications every morning based on the people
          you've selected. If you would like to run the agent immediately, click
          'Run now'.
        </>
      );
    }
  } else if (agentType.type === accountsAgentType.type) {
    activeCount = +response.accountsActiveCount;
    totalCount = +response.accountsCount;
    errorCount = +response.accountsErrorCount;
    if (isEmpty(response.signals) || totalCount === 0) {
      displayText =
        "Looks like your agent isn't ready to roll yet. To get it going, please provide a valid list of companies, select signals and specify actions. Once you've done that, you will receive notifications every morning based on the signals you've selected. If you would like to run the agent immediately, click 'Run now'.";
    } else if (activeCount === totalCount) {
      displayText = (
        <>
          Congratulations! Your agent is active for the specified companies. You
          will receive notifications every morning based on the signals you've
          selected.
          {activeCount < COMPANIES_RUN_NOW_LIMIT &&
            " If you would like to run the agent immediately, click 'Run now'."}
        </>
      );
    } else if (activeCount > 0 && errorCount > 0) {
      displayText = (
        <>
          Congratulations! Your agent is active for{" "}
          {response.accountsActiveCount}{" "}
          {activeCount === 1 ? "company" : "companies"}. You will receive
          notifications every morning based on the signals you've selected.{" "}
          {activeCount < COMPANIES_RUN_NOW_LIMIT &&
            " If you would like to run the agent immediately, click 'Run now'."}{" "}
          There {errorCount === 1 ? "was" : "were"}{" "}
          {response.accountsErrorCount}{" "}
          {errorCount === 1 ? "company" : "companies"} that could not be
          processed. You can review the errors {EditLink}.
        </>
      );
    } else if (activeCount === 0 && errorCount > 0) {
      displayText = (
        <>
          Looks like your agent isn't ready to roll yet. There{" "}
          {errorCount === 1 ? "was" : "were"} {response.accountsErrorCount}{" "}
          {errorCount === 1 ? "company" : "companies"} that could not be
          processed. You can review the errors {EditLink}. Once errors have been
          resolved, you will receive notifications every morning based on the
          signals you've selected. If you would like to run the agent
          immediately, click 'Run now'.
        </>
      );
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "30px",
      }}
      key="final-text"
    >
      <Typography>{displayText}</Typography>
      <Box sx={{ display: "flex", gap: "10px", alignSelf: "end" }}>
        {errorCount > 0 && (
          <Button variant="outlined" onClick={openModal}>
            Review errors
          </Button>
        )}
        <Button variant="contained" onClick={() => dialog.closeModal()}>
          Continue
        </Button>
      </Box>
    </Box>
  );
}
