import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useSnack from "../../context/Snack";

export default function Dropzone({
  value,
  setFileValue,
  onChange,
  fileType = "pdf",
  displayText,
}) {
  const snackBar = useSnack();

  const onDrop = (acceptedFiles) => {
    // Do something with the files
    setFileValue(acceptedFiles[0]);
  };
  const fileValidator = (file) => {
    if (file.size > 20000000) {
      return {
        code: "size-too-large",
        message: `File must not be larger than 20MB`,
      };
    }
    return null;
  };

  let accept = { "application/pdf": [] };
  if (fileType === "csv") {
    accept = { "text/csv": [] };
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: accept,
      multiple: false,
      validator: fileValidator,
    });

  React.useEffect(() => {
    if (fileRejections.length > 0) {
      const message = fileRejections[0]?.errors?.[0]?.message;
      snackBar.createSnack(message ? message : "Error uploading file.");
    }
  }, [fileRejections]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px dashed #DDD",
        borderRadius: "6px",
        width: "100%",
        height: "200px",
        alignItems: "center",
        justifyContent: "center",
        gap: "15px",
        cursor: "pointer",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps({ onChange })} />
      <Typography
        variant="h5"
        color="#525252" // TODO add this color to theme
      >
        {isDragActive
          ? `Drop the ${fileType} here`
          : `Drag and drop a ${displayText || fileType} here or click`}
      </Typography>
      <CloudUploadIcon fontSize="large" sx={{ color: "primary.main" }} />
      <Chip
        sx={{ visibility: value ? "visible" : "hidden" }}
        variant="outlined"
        label={value?.name}
        onDelete={() => {
          setFileValue(null);
        }}
      ></Chip>
    </Box>
  );
}
