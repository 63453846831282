import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDialog } from "../../context/DialogContext";
import useSnack from "../../context/Snack";
import { isSuccessStatus } from "../../common/RequestUtils";
import CreateCategory from "../../modal/CreateCategory";
import axios from "axios";
import { rule5properties } from "../../properties";

export default function CategoryItem(props) {
  const { category, content, loadContent } = props;

  const dialog = useDialog();
  const snackBar = useSnack();

  function deleteContentCategory() {
    axios
      .delete(
        `${rule5properties.contents}/${content.contentId}/category-values/${category.categoryId}`
      )
      .then((resp) => {
        if (isSuccessStatus(resp.status)) {
          snackBar.createSnack("Category successfully removed.");
        } else {
          snackBar.createSnack("Error removing category.");
        }
        loadContent();
      });
  }

  return (
    <Box
      sx={{
        minHeight: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: "12px",
        width: "100%",
        backgroundColor: "rgb(230,241,252)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "15px",
          gap: "5px",
        }}
      >
        <Typography
          sx={{
            fontSize: "0.9rem",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            color: "rgb(107,149,185)",
          }}
        >
          {category.category}
        </Typography>
        <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
          {category.categoryValues?.map((value) => (
            <Chip
              key={value}
              label={value}
              variant="outlined"
              sx={{ borderColor: "#b0c7da", cursor: "inherit" }}
            ></Chip>
          ))}
        </Box>
      </Box>
      <Tooltip title="Edit category">
        <IconButton
          aria-label="Edit category"
          size="small"
          onClick={(event) => {
            dialog.openModal(
              `Content Categories`,
              CreateCategory,
              {
                loadContent: loadContent,
                content: content,
                defaultValues: {
                  category: category,
                  values: category.categoryValues,
                },
              },
              "sm"
            );
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Remove category">
        <IconButton
          aria-label="Remove category"
          size="small"
          style={{ marginRight: "15px" }}
          onClick={() => deleteContentCategory()}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
