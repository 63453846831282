import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import { Autocomplete, TextField } from "@mui/material";
import ImageInput from "../util/ImageInput";

export default function PeerGroupEditor(props) {
  const formStyle = props.formStyle;
  const { register, control, trigger, getValues, setValue } = useFormContext();

  return (
    <div>
      <TitleInput register={register} />
      <h5>Overview</h5>
      <TextareaAutosize
        {...register(`Overview.Description`, {})}
        aria-label=""
        minRows={3}
        style={{ width: "100%" }}
      />
      <h5>Source Name:</h5>
      <input {...register(`Source.Name`)} />
      <h5>Source Logo:</h5>
      <ImageInput
        name={`Source.Logo`}
        control={control}
        setValue={setValue}
      ></ImageInput>
      <h5> Metrics: </h5>
      <Metrics {...{ trigger, control, register, getValues, formStyle }} />
      <h5> Measures: </h5>
      <Measure {...{ trigger, control, register, getValues, formStyle }} />
      <br />
    </div>
  );
}

const metricNames = [
  { name: "Overall Culture" },
  { name: "CEO Rank" },
  { name: "Gender Rank" },
  { name: "Product Quality" },
  { name: "Pricing Rank" },
  { name: "eNPS Rank" },
  { name: "Diversity Rank" },
  { name: "NPS Rank" },
  { name: "Customer Service" },
];

/** Form array */
function Metrics({ trigger, control, register, getValues, formStyle }) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `Metrics`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <Paper>
      {fields.map((item, metricIndex) => {
        return (
          <div>
            <div key={item.id} className={formStyle}>
              Name
              {/* <input {...register(`Metrics[${metricIndex}].Name`)} /> */}
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={metricNames.map((row, index) => {
                      return row.name;
                    })}
                    onChange={(e, data) => {
                      return field.onChange(data);
                    }}
                    freeSolo
                    autoSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Metric"
                        // placeholder=""
                      />
                    )}
                  />
                )}
                name={`Metrics[${metricIndex}].Name`}
                type="select"
                control={control}
                // defaultValue=""
              />
              Rank
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                {...register(`Metrics[${metricIndex}].Rank`, {
                  valueAsNumber: true,
                })}
              />
              Score
              <input
                type="number"
                step="0.01"
                onWheel={(e) => e.target.blur()}
                {...register(`Metrics[${metricIndex}].Score`, {
                  valueAsNumber: true,
                })}
              />
              <MoveAutocomplete
                onChange={(moveToIndex) => {
                  move(metricIndex, moveToIndex);
                  trigger();
                }}
                index={metricIndex}
                options={[...Array(getValues("Metrics").length).keys()]}
              ></MoveAutocomplete>
            </div>
            <DeleteButton
              sx={{ marginLeft: "15px", marginBottom: "15px" }}
              onClick={() => {
                setLastDeleted(item);
                remove(metricIndex);
                trigger();
              }}
            >
              Delete Metric
            </DeleteButton>
            <Divider
              style={{
                marginBottom: "1%",
                marginTop: "1%",
                marginLeft: "1%",
                marginRight: "1%",
              }}
            />
          </div>
        );
      })}

      <AddButton
        sx={{ marginLeft: "15px", marginBottom: "15px" }}
        onClick={() => appendFuncClickHandler(append, lastDeleted)}
      >
        Add Metric
      </AddButton>
    </Paper>
  );
}

/** Form array */
function Measure({ trigger, control, register, getValues, formStyle }) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `Measure`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <Paper>
      {fields.map((item, measureIndex) => {
        return (
          <div key={item.id}>
            <div className={formStyle}>
              Measure Name:
              <input {...register(`Measure[${measureIndex}].MeasureName`)} />
              Values
              <NestedMeasure
                nestIndex={measureIndex}
                {...{ trigger, control, register, getValues, formStyle }}
              />
              <MoveAutocomplete
                onChange={(moveToIndex) => {
                  move(measureIndex, moveToIndex);
                  trigger();
                }}
                index={measureIndex}
                options={[...Array(getValues("Measure").length).keys()]}
              ></MoveAutocomplete>
            </div>
            <DeleteButton
              sx={{ marginLeft: "15px", marginBottom: "15px" }}
              onClick={() => {
                setLastDeleted(item);
                remove(measureIndex);
                trigger();
              }}
            >
              Delete Measure
            </DeleteButton>
            <Divider
              style={{
                marginBottom: "1%",
                marginTop: "1%",
                marginLeft: "1%",
                marginRight: "1%",
              }}
            />
          </div>
        );
      })}

      <AddButton
        sx={{ marginLeft: "15px", marginBottom: "15px" }}
        onClick={() => appendFuncClickHandler(append, lastDeleted)}
      >
        Add Measure
      </AddButton>
    </Paper>
  );
}

/** Nested form array */
function NestedMeasure({
  nestIndex,
  trigger,
  control,
  register,
  getValues,
  formStyle,
}) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `Measure.${nestIndex}.orgs`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className={formStyle}>
            Company Name:
            <input
              {...register(`Measure.${nestIndex}.orgs.${k}.CompanyName`, {
                // required: true
              })}
            />
            Rank:
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              {...register(`Measure.${nestIndex}.orgs.${k}.Rank`, {
                valueAsNumber: true,
              })}
            />
            Logo:
            <input
              {...register(`Measure.${nestIndex}.orgs.${k}.Logo`, {
                // required: true
              })}
            />
            Score:
            <input
              type="number"
              step="0.01"
              onWheel={(e) => e.target.blur()}
              {...register(`Measure.${nestIndex}.orgs.${k}.Score`, {
                valueAsNumber: true,
              })}
            />
            scale:
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              {...register(`Measure.${nestIndex}.orgs.${k}.scale`, {
                valueAsNumber: true,
              })}
            />
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(k, moveToIndex);
                trigger();
              }}
              index={k}
              options={[
                ...Array(getValues(`Measure.${nestIndex}.orgs`).length).keys(),
              ]}
            ></MoveAutocomplete>
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(k);
              }}
            >
              Delete company
            </DeleteButton>
          </div>
        );
      })}

      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add company
      </AddButton>
    </div>
  );
}

// let testData =
// {
//     "Title": "Peer Group",
//     "Overview": {
//         "Description": "Pfizer's competitors include AbbVie, Johnson & Johnson, Roche, Merck and Abbott. Pfizer ranks 2nd on Product Quality Score on Comparably vs it's competitors. See below how Pfizer compares to it's competitors on CEO Rankings, Product and Services, NPS, Pricing,   Customer Services, Overall Culture Score, eNPS, Gender and Diversity scores."
//     },
//     "Metrics": [
//         {
//             "Name": "CEO Rank",
//             "Rank": 2,
//             "Score": 80
//         },
//         {
//             "Name": "Gender Rank",
//             "Rank": 5,
//             "Score": 55
//         },
//         {
//             "Name": "Product Quality",
//             "Rank": 3,
//             "Score": 67
//         },
//         {
//             "Name": "eNPS Rank",
//             "Rank": 1,
//             "Score": 87
//         },
//         {
//             "Name": "Diversity Rank",
//             "Rank": 4,
//             "Score": 69
//         }
//     ],
//     "Measure": [
//         {
//             "MeasureName": "Culture",
//             "orgs": [
//                 {
//                     "CompanyName": "AbbVie",
//                     "Rank": "1",
//                     "Logo": "https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 80,
//                     "scale": 100
//                 },
//                 {
//                     "CompanyName": "Johnson & Johnson",
//                     "Rank": "2",
//                     "Logo": "Logo = https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 75,
//                     "scale": 100
//                 },
//                 {
//                     "CompanyName": "Roche",
//                     "Rank": "3",
//                     "Logo": "https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 75,
//                     "scale": 100
//                 },
//                 {
//                     "CompanyName": "Merck",
//                     "Rank": "4",
//                     "Logo": "Logo = https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 72,
//                     "scale": 100
//                 },
//                 {
//                     "CompanyName": "Abbott",
//                     "Rank": "5",
//                     "Logo": "https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 70,
//                     "scale": 100
//                 },
//                 {
//                     "CompanyName": "Pfizer",
//                     "Rank": "6",
//                     "Logo": "Logo = https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 70,
//                     "scale": 100
//                 }
//             ]
//         },
//         {
//             "MeasureName": "Diversity",
//             "orgs": [
//                 {
//                     "CompanyName": "AbbVie",
//                     "Rank": "1",
//                     "Logo": "https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 8,
//                     "scale": 10
//                 },
//                 {
//                     "CompanyName": "Johnson & Johnson",
//                     "Rank": "2",
//                     "Logo": "Logo = https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 7.5,
//                     "scale": 10
//                 },
//                 {
//                     "CompanyName": "Roche",
//                     "Rank": "3",
//                     "Logo": "https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 7.4,
//                     "scale": 10
//                 },
//                 {
//                     "CompanyName": "Merck",
//                     "Rank": "4",
//                     "Logo": "Logo = https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 7.2,
//                     "scale": 10
//                 },
//                 {
//                     "CompanyName": "Abbott",
//                     "Rank": "5",
//                     "Logo": "https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 7,
//                     "scale": 10
//                 },
//                 {
//                     "CompanyName": "Pfizer",
//                     "Rank": "6",
//                     "Logo": "Logo = https://drive.google.com/file/d/1ecy5k8jQNvyEA5KwVSm8fB34NSQKjlTi/view?usp=sharing",
//                     "Score": 6.8,
//                     "scale": 10
//                 }
//             ]
//         }
//     ],
//     "Source": {
//         "Name": "Comparably",
//         "Logo": "https://drive.google.com/file/d/1j0QpV_eNCcXOsTDIJtuS2kD-MXh2VfMn/view?usp=sharing"
//     },
//     "NewUpdate": false
// }
