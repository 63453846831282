import React from "react";
import {
  Typography,
  Button,
  Box,
  Autocomplete,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  CustomPopper,
  ErrLabel,
  StyledTableCell,
  StyledTableRow,
  StyledTextfield,
} from "../../../common/StyledComponents";
import { Controller, useFormContext } from "react-hook-form";
import Dropzone from "../../content/Dropzone";
import { concat, reduce, isEmpty, assign } from "lodash";
import { csvFormatRow, csvParseRows } from "d3-dsv";
import EditAgentList from "./EditAgentList";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import AddIcon from "@mui/icons-material/Add";
import { GridRowModes } from "@mui/x-data-grid";
import AgentType, {
  OPTIONAL_TEXT,
  accountsAgentType,
  peopleAgentType,
} from "./AgentTypes";
import { useSyncedResearchAccounts } from "../../../api/agents";

function UploadCsv(props) {
  const { agentType } = props;

  const {
    control,
    watch,
    setValue,
    resetField,
    formState: { errors },
    trigger,
  } = useFormContext();

  let initFlow = "none";
  if (watch("csvFile")) {
    initFlow = "upload";
  } else if (!isEmpty(watch("payload"))) {
    initFlow = "add";
  }

  const { data: hasSyncedResearchAccount } = useSyncedResearchAccounts();

  const [flow, setFlow] = React.useState(initFlow);

  const csvData = csvFormatRow(agentType.getCsvTemplate());
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const initRowId = 0;

  function csvParseDuplicate(
    text,
    { empty = "_default", dedup = (name, j) => `${name}~${j}` } = {}
  ) {
    const columns = [];
    return Object.assign(
      csvParseRows(text, (row, i) => {
        if (i === 0) {
          for (let name of row) {
            let n = name || (name = empty);
            let j = 0;
            while (columns.includes(n)) n = dedup(name, ++j);
            columns.push(n);
          }
          return;
        }
        return Object.fromEntries(columns.map((c, i) => [c, row[i]]));
      }),
      { columns }
    );
  }

  return (
    <Box>
      {flow === "none" && (
        <Typography variant="body2" sx={{ my: 4, opacity: 0.7 }}>
          <span style={{ fontSize: 16 }}>
            In this step, you can add {agentType.displayName} to the agent. Feel
            free to either:
          </span>
          <ul>
            <li>
              <strong>Add {agentType.singularText}</strong> names directly.
            </li>
            <li>
              <strong>Upload a CSV</strong> file containing your list of{" "}
              {agentType.displayName}.
            </li>
          </ul>
          {agentType.type === peopleAgentType.type && (
            <>
              <span style={{ fontSize: 16 }}>👉 Things to consider:</span>
              <ul>
                <li>
                  <strong>Input Linkedin URL</strong>: Simply enter the LinkedIn
                  URL of the individual you’re interested in tracking. Our
                  system will immediately start monitoring their career
                  movements for you.
                </li>
                <li>
                  <strong>Add previous company (optional)</strong>: If you’re
                  curious about their job change since leaving a particular role
                  or company, include the name of the previous employer in the
                  company field. We’ll tailor the tracking to show job changes
                  from that point onward.
                </li>
              </ul>
            </>
          )}
          <span style={{ fontSize: 16 }}>
            If you’re not ready to add {agentType.displayName} right now, no
            worries! You can easily skip this step and add them later after your
            agent is set up.
          </span>
        </Typography>
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {flow === "none" ? (
          <>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setFlow("add");
                setValue("payload", [
                  {
                    tempId: 0,
                    isNew: true,
                    ...assign(
                      ...agentType.columns.map((column) => {
                        return { [column.key]: "" };
                      })
                    ),
                  },
                ]);
              }}
              disabled={flow !== "none"}
            >
              Add {agentType.singularText}
            </Button>
            <Button
              variant="text"
              onClick={() => setFlow("upload")}
              startIcon={<CloudUploadOutlinedIcon />}
              disabled={flow !== "none"}
            >
              Upload csv
            </Button>
          </>
        ) : (
          <IconButton
            sx={{ mr: "auto" }}
            onClick={() => {
              setFlow("none");
              resetField("payload");
              resetField("csvFile");
              resetField("rawPayload");
            }}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
        )}
      </Box>
      {flow === "add" && (
        <>
          <EditAgentList
            rows={watch("payload")}
            // Put the first row in edit mode if it's invalid (ie a new row)
            rowModesModel={
              watch("payload")?.[0]?.isValid
                ? {}
                : {
                    [initRowId]: {
                      mode: GridRowModes.Edit,
                      fieldToFocus: agentType.columns[0].key,
                    },
                  }
            }
            agentType={agentType}
            setValue={setValue}
            lite={true}
          />
        </>
      )}
      {flow === "upload" && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {/* <IconButton
            sx={{ ml: "auto" }}
            onClick={() => {
              setFlow("");
            }}
          >
            <CancelIcon />
          </IconButton> */}
          <Controller
            render={({ field }) => (
              <Dropzone
                setFileValue={(file) => {
                  setValue("csvFile", file, { shouldValidate: true });
                  setValue("originalFileName", file?.name);

                  if (file) {
                    const reader = new FileReader();

                    reader.onload = function (e) {
                      const text = e.target.result;

                      // Parse the full payload for now since custom column mappings are unknown at this time.
                      // Alternatively could possibly split text on second newline to fetch minimum necessary
                      // for getting custom mappings, and then parse full file later with known mappings as that
                      // might be slightly more efficient. Possibly errors wouldn't be caught as soon if file is malformed or something idk
                      const data = csvParseDuplicate(text);
                      setValue("rawPayload", data);

                      const columnMappingDefaults = reduce(
                        agentType.columns,
                        (result, { key, displayName }) => {
                          const foundDefault = data?.columns?.find((column) =>
                            [displayName, displayName + OPTIONAL_TEXT].includes(
                              column
                            )
                          );
                          result[key] = foundDefault
                            ? foundDefault
                            : "Unmapped";
                          return result;
                        },
                        {}
                      );
                      setValue("columnMappings", columnMappingDefaults);
                    };
                    reader.readAsText(file);
                  } else {
                    setValue("rawPayload", null);
                  }
                }}
                value={watch("csvFile")}
                displayText={`csv with a list of ${agentType.displayName}`}
                fileType={"csv"}
              />
            )}
            rules={{
              validate: (value) => {
                return value || watch("payload");
              },
            }}
            name={"csvFile"}
            control={control}
          />
          {errors?.csvFile && (
            <ErrLabel sx={{ mb: 1 }}>
              Please select a csv file or skip this step.
            </ErrLabel>
          )}
          {errors?.payload && (
            <ErrLabel sx={{ mb: 1 }}>{errors.payload.message}</ErrLabel>
          )}
          {!watch("csvFile") && (
            <Button
              variant="text"
              sx={{
                fontWeight: 400,
                fontSize: 16,
                mt: 1,
                width: "fit-content",
                justifyContent: "start",
              }}
              download={`rule5-${agentType.displayName}-template.csv`}
              href={url}
            >
              Download template
            </Button>
          )}
          {watch("rawPayload") && (
            <>
              <Typography
                variant="caption"
                sx={{ mt: 1, opacity: 0.7 }}
              >{`Csv file includes ${watch("rawPayload").length} row${
                watch("rawPayload").length !== 1 ? "s" : ""
              }.`}</Typography>
              <AccountFieldMappings agentType={agentType} />
              <Typography variant="body2" sx={{ mt: 1, opacity: 0.7 }}>
                {agentType.helperText}
              </Typography>
            </>
          )}
        </Box>
      )}
      {agentType.type === accountsAgentType.type &&
        !hasSyncedResearchAccount && (
          <>
            <Typography sx={{ opacity: 0.7, my: 2 }}>
              To automatically keep this agent in sync with all your companies
              from Account IQ, please check the box below. Note: You can only
              have one agent with this sync option enabled.
            </Typography>
            <FormControlLabel
              sx={{ ml: "5px", flexBasis: "100%" }}
              control={
                <Controller
                  control={control}
                  name={"syncResearchAccounts"}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        size="small"
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          trigger("payload");
                        }}
                        checked={field.value}
                      />
                    );
                  }}
                />
              }
              label={
                <Typography
                  sx={{ fontSize: "14px", userSelect: "none", opacity: 0.7 }}
                >
                  Sync companies to this agent
                </Typography>
              }
            />
          </>
        )}
      {errors?.payload && (
        <ErrLabel sx={{ mb: 1 }}>{errors.payload.message}</ErrLabel>
      )}
    </Box>
  );
}

function AccountFieldMappings(props) {
  const { agentType } = props;
  const { watch } = useFormContext();

  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none", mt: 1 }}>
      <Table sx={{ minWidth: 650 }} aria-label="Field mappings table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell sx={{ width: 200 }}>Field</StyledTableCell>
            <StyledTableCell sx={{ width: 300 }}>
              Your data's column header
            </StyledTableCell>
            <StyledTableCell>Your data</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {agentType.columns.map((column, index) => {
            const userColumnValue = watch(`columnMappings.${column.key}`);
            return (
              <StyledTableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell>{column.displayName}</StyledTableCell>
                <StyledTableCell>
                  <FieldMappingAutocomplete signal5FieldName={column.key} />
                </StyledTableCell>
                <StyledTableCell>
                  {watch("rawPayload")?.[0]?.[userColumnValue]}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function FieldMappingAutocomplete(props) {
  const { signal5FieldName } = props;
  const {
    control,
    watch,
    resetField,
    formState: { errors },
  } = useFormContext();

  const rawPayload = watch("rawPayload");

  if (isEmpty(watch("columnMappings"))) {
    return null;
  }

  return (
    <>
      <Controller
        render={({ field }) => (
          <Autocomplete
            {...field}
            autoComplete
            autoHighlight
            openOnFocus
            blurOnSelect
            includeInputInList
            onChange={(e, data, reason) => {
              resetField("payload"); // clear out the payload if mappings get changed.
              if (reason === "clear") {
                field.onChange("Unmapped");
                return;
              } else {
                field.onChange(data);
              }
            }}
            getOptionDisabled={(option) =>
              option !== "Unmapped" &&
              Object.values(watch("columnMappings"))?.includes(option)
            }
            noOptionsText={"No options found."}
            renderOption={(props, option) => {
              return (
                <Box {...props}>
                  <Typography variant="body1">{option}</Typography>
                </Box>
              );
            }}
            ListboxProps={{ style: { maxHeight: 350 } }}
            PopperComponent={CustomPopper}
            options={concat("Unmapped", rawPayload?.columns)}
            renderInput={(params) => {
              const { InputLabelProps, InputProps, ...rest } = params;
              return <StyledTextfield {...params.InputProps} {...rest} />;
            }}
          />
        )}
        name={`columnMappings.${signal5FieldName}`}
        control={control}
      />
      {errors?.columnMappings?.[signal5FieldName] && (
        <ErrLabel>This column must be mapped in order to upload.</ErrLabel>
      )}
    </>
  );
}

export default UploadCsv;
