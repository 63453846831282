import React from "react";
import Workspace from "./Workspace";
import Toolbar from "../common/Toolbar";
import { Box, Button, Fade, Stack, useTheme } from "@mui/material";
import { useDialog } from "../context/DialogContext";
import ContentTable from "./content/ContentTable";
import UploadContent from "../modal/UploadContent";
import { useParams, useHistory } from "react-router-dom";
import CategoriesPanel from "./content/CategoriesPanel";
import useSnack from "../context/Snack";
import axios from "axios";
import { rule5properties } from "../properties";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { MemoizedViewContent } from "./content/ViewContent";

export default function WSContent() {
  const [shouldReload, setShouldReload] = React.useState(null);
  const [viewingPdf, setViewingPdf] = React.useState(false);
  const [selectedContent, setSelectedContent] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [navButtonHover, setNavButtonHover] = React.useState(false);

  const dialog = useDialog();
  const theme = useTheme();
  const snackBar = useSnack();
  const history = useHistory();

  const { contentIdParam } = useParams();

  React.useEffect(() => {
    if (viewingPdf && !contentIdParam) {
      setViewingPdf(false);
    } else if (contentIdParam) {
      setViewingPdf(true);
    }
  }, [history.location]);

  const loadContent = (searchValue) => {
    setLoading(true);
    axios
      .get(
        `${rule5properties.contents}${
          searchValue?.length > 1 ? "?searchValue=" + searchValue : ""
        }`
      )
      .then((response) => {
        setLoading(false);
        setRows(response.data);
        // Refresh selected content, if any.
        if (selectedContent || contentIdParam) {
          setSelectedContent(
            response.data?.find(
              (row) =>
                selectedContent?.contentId === row.contentId ||
                parseInt(contentIdParam) === row.contentId
            )
          );
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          snackBar.createSnack("There was a problem with loading content");
        }
      });
  };

  React.useEffect(() => {
    loadContent(searchValue);
  }, [searchValue, shouldReload]);

  return (
    <div>
      <Toolbar>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: viewingPdf ? "rgb(25, 118, 210)" : "inherit",
              },
            }}
            onClick={() => {
              setViewingPdf(false);
              history.push("/main/content/");
            }}
            onMouseEnter={() => {
              setNavButtonHover(true);
            }}
            onMouseLeave={() => {
              setNavButtonHover(false);
            }}
          >
            Content
          </Box>
          <Fade in={viewingPdf} timeout={300}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ChevronRight
                sx={{
                  m: 1,
                  transform: navButtonHover ? "scaleX(-1)" : "none",
                }}
              />
              <span
                style={{
                  transition: "0.3s",
                  opacity: navButtonHover ? 0 : 1,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "50vw",
                }}
              >
                {selectedContent?.name}
              </span>
            </Stack>
          </Fade>
          {!viewingPdf && (
            <Button
              color="primary"
              variant="contained"
              sx={{ textTransform: "none", height: "38px", ml: "auto" }}
              aria-label="add"
              aria-haspopup="true"
              disableRipple
              onClick={() => {
                dialog.openModal(
                  "Upload content",
                  UploadContent,
                  { setShouldReload: setShouldReload },
                  "sm"
                );
              }}
            >
              Upload content
            </Button>
          )}
        </div>
      </Toolbar>
      <Workspace>
        <div
          style={{
            minHeight: "500px",
            height: "100%",
            width: "100%",
            padding: `0px ${theme.workspace.commonPadding}px ${theme.workspace.commonPadding}px ${theme.workspace.commonPadding}px`,
            display: "flex",
            gap: "15px",
            justifyContent: "end",
          }}
        >
          {!viewingPdf ? (
            <ContentTable
              rows={rows}
              loading={loading}
              loadContent={loadContent}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              selectedContent={selectedContent}
              setSelectedContent={setSelectedContent}
            />
          ) : (
            <MemoizedViewContent content={selectedContent} />
          )}
          {!viewingPdf && (
            <CategoriesPanel
              loading={loading}
              viewingPdf={viewingPdf}
              selectedContent={selectedContent}
              setSelectedContent={setSelectedContent}
              loadContent={loadContent}
            ></CategoriesPanel>
          )}
        </div>
      </Workspace>
    </div>
  );
}
