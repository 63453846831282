import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  IconButton,
  Tooltip,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { useAccountAgents, useAccountAgentsByCompany } from "../api/agents";
import {
  CustomPopper,
  PopperComponent,
  StyledLabel,
  StyledPopper,
  StyledTextfield,
} from "./StyledComponents";
import useSnack from "../context/Snack";
import { useUser } from "../context/UserContext";
import signalsIcon from "./../res/icons/outline/16px_antenna.svg";
import { AgentName } from "../workspaces/monitor/common/monitorColumns";
import axios from "axios";
import { rule5properties } from "../properties";
import { isSuccessStatus } from "./RequestUtils";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export default function AddToAgentOuter(props) {
  const { variant } = props;

  if (variant === "button") {
    return <WithClickAway {...props} />;
  }
  return (
    <>
      <StyledLabel>Company agents</StyledLabel>
      <AgentsAutocomplete {...props} />
    </>
  );
}

const WithClickAway = React.memo(function AddToAgent(props) {
  const { company } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={`Add ${company?.name} to existing agent`}>
        <Button
          size="small"
          onClick={handleClick}
          sx={{
            gap: "12px",
            width: "150px",
            height: "42px",
            fontWeight: 400,
            textTransform: "none",
            float: "right",
            color: "#333333",
            fontSize: "14px",
            borderRadius: "8px",
            opacity: 0.5,
            transition: "0.2s",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.03)",
              opacity: 1,
            },
            "&:active": {
              backgroundColor: "rgba(0,0,0,0.1)",
            },
          }}
        >
          <img src={signalsIcon} alt="Available Cards" />
          Enable signals
        </Button>
      </Tooltip>
      <StyledPopper
        anchorEl={anchorEl}
        open={open}
        id={"agents select"}
        placement="top-start"
        sx={{ width: "350px" }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
              width: "100%",
            }}
          >
            <AgentsAutocomplete
              {...props}
              open={open}
              handleClose={handleClose}
              isInClickAway={true}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
});

function AgentsAutocomplete(props) {
  const {
    company,
    valueFromParent,
    onChangeFromParent,
    handleClose = () => {},
    isInClickAway = false,
  } = props;

  const snackBar = useSnack();
  const { rows, isFetching: isFetchingOptions } = useAccountAgents();

  const {
    data: existingAgents,
    refetch,
    isFetching,
  } = useAccountAgentsByCompany(company);

  const handleOptionChange = (newValues) => {
    const prevIds = new Set(existingAgents.map((option) => option.agentId));
    const newIds = new Set(newValues.map((option) => option.agentId));

    // Find added options
    newValues.forEach((option) => {
      if (!prevIds.has(option.agentId)) {
        // addMutation.mutate(option.agentId);
        axios
          .post(rule5properties.addAccounts, {
            agentId: option.agentId?.toString(),
            accountAgents: [
              {
                companyId: company?.companyId,
                companyName: company?.name,
              },
            ],
          })
          .then((resp) => {
            if (resp.status === 200) {
              snackBar.createSnack(
                `Successfully added ${company?.name} to ${option.name}.`
              );
              refetch();
            }
          });
      }
    });

    // Find removed options
    existingAgents.forEach((option) => {
      if (!newIds.has(option.agentId)) {
        // deleteMutation.mutate(option.agentId);
        axios
          .delete(`${rule5properties.agentAccounts}/${option.agentAccountId}`)
          .then((resp) => {
            if (isSuccessStatus(resp.status)) {
              snackBar.createSnack(
                `Successfully removed ${company?.name} from ${option.name}.`
              );
              refetch();
            } else {
              const message = resp.data?.message;
              snackBar.createSnack(
                message ? message : "Unexpected error deleting agent."
              );
            }
          });
      }
    });
  };

  const onChange = (event, newSelectedAgents) => {
    if (onChangeFromParent) {
      onChangeFromParent(newSelectedAgents);
    } else {
      handleOptionChange(newSelectedAgents);
    }

    handleClose();
  };

  return (
    <Autocomplete
      onChange={onChange}
      value={valueFromParent || existingAgents}
      loading={isFetching || isFetchingOptions}
      PopperComponent={isInClickAway ? PopperComponent : CustomPopper}
      selectOnFocus
      isOptionEqualToValue={(option, value) => {
        return option.agentId === value.agentId;
      }}
      multiple={!onChangeFromParent}
      renderTags={() => null}
      getOptionLabel={(option) => option.name}
      open={isInClickAway ? true : undefined}
      clearIcon={false}
      clearOnBlur
      handleHomeEndKeys
      id="add-to-agents-autocomplete"
      options={!isFetching ? rows : []}
      slotProps={{
        popupIndicator: {
          onClick: handleClose,
        },
      }}
      noOptionsText="No agents found."
      onClose={(event, reason) => {
        if (reason === "escape") {
          handleClose();
        }
      }}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            component={DoneIcon}
            sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
            style={{
              visibility: selected ? "visible" : "hidden",
            }}
          />
          <AgentName
            name={option.name}
            syncResearchAccounts={option.syncResearchAccounts}
          />
          <Tooltip title={`Remove ${company?.name} from ${option.name}`}>
            <IconButton
              size="small"
              style={{
                visibility: selected ? "visible" : "hidden",
                marginLeft: "auto",
              }}
            >
              <CancelIcon sx={{ width: 18, height: 18 }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </li>
      )}
      renderInput={(params) => {
        const { InputLabelProps, ...rest } = params;
        const InputProps = {
          ...params.InputProps,
        };
        return (
          <>
            <StyledTextfield
              autoFocus
              placeholder={"Search agents…"}
              {...rest}
              {...InputProps}
              {...params}
            />
          </>
        );
      }}
    />
  );
}
