import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { rule5properties } from "../../properties";
import RichTextEditor from "../util/RichTextEditor";
import {
  convertHtmlToRawContentState,
} from "../common/utils";

export default function QuarterlyEarningsEditor(props) {
  const formStyle = props.formStyle;

  const { register, control, trigger, getValues, setValue } = useFormContext();

  const [loading, setLoading] = useState(false);
  const [generatedValue, setGeneratedValue] = useState(null);

  return (
    <div>
      <TitleInput register={register} />
      <LoadingButton
        loading={loading}
        style={{ textTransform: "none", marginTop: "2%" }}
        color="primary"
        variant="outlined"
        onClick={() => {
          setLoading(true);
          const cardDataParams = new URLSearchParams({
            functionalArea: props.functionalArea,
            cardType: "Quarterly Earnings Calls",
            companyId: props.companyId,
          });

          axios
            .get(rule5properties.detCardData + "?" + cardDataParams)
            .then((res) => {
              setLoading(false);
              if (res.status === 200) {
                let rawContentState;
                if (res.data.earningCallSummary) {
                  rawContentState = convertHtmlToRawContentState(
                    res.data.earningCallSummary
                  );
                  setGeneratedValue(rawContentState);
                }

                // Generated RTE content cannot be directly assigned so only spread the non-RTE data.
                let { earningCallSummary, ...nonGeneratedData } = res.data;
                props.setForm((draft) => {
                  draft.cardInfo = {
                    ...nonGeneratedData,
                    earningsCallSummary: rawContentState,
                    Title: "Quarterly Earnings Calls",
                  };
                  draft.isDraftSaved = false;
                });
                props.setHasPreview(true);
              } else {
                window.alert(
                  `Card data response code: ${
                    res.status
                  }. Details: ${JSON.stringify(res.data)}`
                );
              }
            })
            .catch((err) => {
              window.alert("Error retrieving on card data: " + err);
              setLoading(false);
            });
        }}
      >
        Generate Data
      </LoadingButton>
      <div className={formStyle}>
        earningDate
        <input {...register(`earningDate`)} />
        earningsCallSummary
        <Controller
          name="earningsCallSummary"
          control={control}
          rules={{
            required: { value: true, message: "RTE text is required" },
          }}
          render={({ field }) => {
            return (
              <RichTextEditor
                generatedValue={generatedValue}
                clearGeneratedValue={() => {
                  setGeneratedValue(null);
                }}
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
        />
        Quarterly Earnings:
        <QuarterlyEarning
          {...{ trigger, control, register, getValues, formStyle }}
          name="earningValues"
        />
      </div>
    </div>
  );
}

/** Form array */
function QuarterlyEarning({
  trigger,
  control,
  register,
  getValues,
  formStyle,
  name,
}) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: name,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, itemIndex) => (
        <div key={itemIndex} className={formStyle}>
          announcementDate
          <input {...register(`${name}[${itemIndex}].announcementDate`)} />
          fiscalQuarterEndDate
          <input {...register(`${name}[${itemIndex}].fiscalQuarterEndDate`)} />
          eps
          <input {...register(`${name}[${itemIndex}].eps`)} />
          epsBeatOrMiss
          <input {...register(`${name}[${itemIndex}].epsBeatOrMiss`)} />
          epsEstimated
          <input {...register(`${name}[${itemIndex}].epsEstimated`)} />
          revenue
          <input {...register(`${name}[${itemIndex}].revenue`)} />
          revenueBeatOrMiss
          <input {...register(`${name}[${itemIndex}].revenueBeatOrMiss`)} />
          revenueEstimated
          <input {...register(`${name}[${itemIndex}].revenueEstimated`)} />
          <MoveAutocomplete
            onChange={(moveToIndex) => {
              move(itemIndex, moveToIndex);
              trigger();
            }}
            index={itemIndex}
            options={[...Array(getValues(name).length).keys()]}
          ></MoveAutocomplete>
          <DeleteButton
            onClick={() => {
              setLastDeleted(item);
              remove(itemIndex);
              trigger();
            }}
          >
            {`Delete ${name}`}
          </DeleteButton>
        </div>
      ))}
      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        {`Add ${name}`}
      </AddButton>
    </div>
  );
}
