import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import RichTextEditor from "../util/RichTextEditor";
import { Chip } from "@mui/material";
import { MultiImageInput } from "../util/ImageInput";
import { REGIONS } from "../../workspaces/opportunity/research/util/Map";

export default function OperationsEditor(props) {
  const formStyle = props.formStyle;
  const { register, control, trigger, getValues, setValue } = useFormContext();

  return (
    <div>
      <TitleInput register={register} />
      <br></br>
      At a glance header:
      <br></br>
      <input
        {...register(`AtAGlanceHeader`)}
        defaultValue={`${props.buyerName} at a glance:`}
      />
      <br></br>
      <br></br>
      At a glance body:
      <br></br>
      <Controller
        name={`AtAGlanceBody`}
        control={control}
        render={({ field }) => {
          return (
            <RichTextEditor value={field.value} onChange={field.onChange} />
          );
        }}
      />
      <br></br>
      <h5>Brands</h5>
      <Controller
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            options={[]}
            onChange={(e, data) => field.onChange(data)}
            freeSolo
            autoSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Brands"
                placeholder="Press enter to add entries"
              />
            )}
          />
        )}
        name="Brands"
        type="select"
        control={control}
      />
      <h5>Products</h5>
      <Controller
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            options={[]}
            onChange={(e, data) => field.onChange(data)}
            freeSolo
            autoSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Products"
                placeholder="Press enter to add entries"
              />
            )}
          />
        )}
        name="Products"
        type="select"
        control={control}
      />
      <h5>Logos</h5>
      <MultiImageInput
        name={"Logos"}
        control={control}
        setValue={setValue}
      ></MultiImageInput>
      <h5>Services</h5>
      <Controller
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            options={[]}
            onChange={(e, data) => field.onChange(data)}
            freeSolo
            autoSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Services"
                placeholder="Press enter to add entries"
              />
            )}
          />
        )}
        name="Services"
        type="select"
        control={control}
      />
      <h5>Sales Channels</h5>
      <Controller
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            options={[]}
            onChange={(e, data) => field.onChange(data)}
            freeSolo
            autoSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Channels"
                placeholder="Press enter to add entries"
              />
            )}
          />
        )}
        name="Channels"
        type="select"
        control={control}
      />
      <h5>Markets</h5>
      <Controller
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            options={[]}
            onChange={(e, data) => field.onChange(data)}
            freeSolo
            autoSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Markets"
                placeholder="Press enter to add entries"
              />
            )}
          />
        )}
        name="Markets"
        type="select"
        control={control}
      />
      <h5>Components</h5>
      <Controller
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            options={[]}
            onChange={(e, data) => field.onChange(data)}
            freeSolo
            autoSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Components"
                placeholder="Press enter to add entries"
              />
            )}
          />
        )}
        name="Components"
        type="select"
        control={control}
      />
      <h5>Countries</h5>
      <Controller
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            options={[]}
            onChange={(e, data) => field.onChange(data)}
            freeSolo
            autoSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Countries"
                placeholder="Press enter to add entries"
              />
            )}
          />
        )}
        name="Countries"
        type="select"
        control={control}
      />
      <div className={formStyle}>
        Office Locations:
        <input {...register(`OfficeLocations`)} />
        Stores:
        <input {...register(`Stores`)} />
        Production Facilities:
        <input {...register(`ProductionFacilities`)} />
        Warehouses:
        <input {...register(`Warehouses`)} />
        Distribution And Logistics Centers:
        <input {...register(`DistributionAndLogisticsCenters`)} />
        Suppliers:
        <input {...register(`Suppliers`)} />
        Customers:
        <input {...register(`Customers`)} />
        Partners:
        <input {...register(`Partners`)} />
        Resellers:
        <input {...register(`Resellers`)} />
        Products:
        <input {...register(`ProductsCount`)} />
        SKUs:
        <input {...register(`SKUs`)} />
      </div>
      <hr></hr>
      <RichTextFields
        {...{ trigger, control, register, getValues, formStyle }}
      />
      <hr></hr>
      <PieCharts {...{ trigger, control, register, getValues, formStyle }} />
      <hr></hr>
      <h6 style={{ textAlign: "left" }}>Map</h6>
      <MapEditor {...{ control, register, formStyle }}></MapEditor>
    </div>
  );
}

/** Form array */
function RichTextFields({ trigger, control, register, getValues, formStyle }) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `RteFields`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <>
      {fields.map((rteField, rteFieldIndex) => (
        <div key={rteField.id} className={formStyle}>
          Title:
          <input {...register(`RteFields[${rteFieldIndex}].Title`)} />
          RTE Block:
          <Controller
            name={`RteFields[${rteFieldIndex}].RichText`}
            control={control}
            render={({ field }) => {
              return (
                <RichTextEditor
                  fontSize={14}
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <MoveAutocomplete
            onChange={(moveToIndex) => {
              move(rteFieldIndex, moveToIndex);
              trigger();
            }}
            index={rteFieldIndex}
            options={[...Array(getValues("RteFields").length).keys()]}
          ></MoveAutocomplete>
          <DeleteButton
            onClick={() => {
              setLastDeleted(rteField);
              remove(rteFieldIndex);
              trigger();
            }}
          >
            Delete RTE Block
          </DeleteButton>
          <hr />
        </div>
      ))}
      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Rich Text Block
      </AddButton>
    </>
  );
}

/** Form array */
function PieCharts({ trigger, control, register, getValues, formStyle }) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `PieCharts`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <>
      {fields.map((pieChart, pieChartIndex) => (
        <div key={pieChart.id} className={formStyle}>
          Type:
          <input {...register(`PieCharts[${pieChartIndex}].Type`)} />
          Fiscal Year:
          <input {...register(`PieCharts[${pieChartIndex}].Year`)} />
          Currency
          <input {...register(`PieCharts[${pieChartIndex}].Currency`)} />
          Revenues
          <Revenue
            parentName={`PieCharts[${pieChartIndex}]`}
            {...{ control, register, formStyle }}
          />
          <MoveAutocomplete
            onChange={(moveToIndex) => {
              move(pieChartIndex, moveToIndex);
              trigger();
            }}
            index={pieChartIndex}
            options={[...Array(getValues("PieCharts").length).keys()]}
          ></MoveAutocomplete>
          <DeleteButton
            onClick={() => {
              setLastDeleted(pieChart);
              remove(pieChartIndex);
              trigger();
            }}
          >
            Delete Pie Chart
          </DeleteButton>
        </div>
      ))}
      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Pie Chart
      </AddButton>
    </>
  );
}

/** Nested form array */
function Revenue({ parentName, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `${parentName}.Revenue`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className={formStyle}>
            {parentName !== "Map" ? (
              <>
                Type:
                <input {...register(`${parentName}.Revenue.${k}.Type`)} />
              </>
            ) : (
              <>
                Region:
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={REGIONS.map((row, index) => {
                        return row.name;
                      })}
                      onChange={(e, data) => {
                        return field.onChange(data);
                      }}
                      freeSolo
                      autoSelect
                      renderInput={(params) => (
                        <TextField {...params} label="Region" />
                      )}
                    />
                  )}
                  name={`${parentName}.Revenue.${k}.Region`}
                  type="select"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: { value: true, message: "Region is required" },
                  }}
                />
              </>
            )}
            Amount:
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              {...register(`${parentName}.Revenue.${k}.Amount`, {
                valueAsNumber: true,
              })}
            />
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(k);
              }}
            >
              Delete Revenue
            </DeleteButton>
          </div>
        );
      })}
      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Revenue
      </AddButton>
    </div>
  );
}

/** Form array */
function MapEditor({ control, register, formStyle }) {
  return (
    <div className={formStyle}>
      Title:
      <input {...register(`Map.Title`)} defaultValue="Revenue by Region" />
      Fiscal Year:
      <input {...register(`Map.Year`)} />
      Currency
      <input {...register(`Map.Currency`)} />
      Revenues
      <Revenue parentName={"Map"} {...{ control, register, formStyle }} />
    </div>
  );
}
