import React, { useEffect, useRef } from "react";
import axios from "axios";
import { rule5properties } from "../../properties";
import CustomSnackbar from "../../common/CustomSnackbar";
import makeStyles from "@mui/styles/makeStyles";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { cardTypes } from "../../workspaces/opportunity/research/OPXResearch";
import { Typography, useTheme } from "@mui/material";
import { useUser } from "../../context/UserContext";
import { approverRoles } from "../DetMainPage";

function deepCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

function getCardsDisplay(ucards) {
  // Keep a local copy of cards
  var cards = deepCopy(ucards);
  let cardType = null;
  cards.map((row, index) => {
    cardType = cardTypes.find((ctRow) => {
      return ctRow.cardType === row.cardType;
    });
    if (cardType) {
      row.dom = cardType.dom;
      row.displayName = cardType.displayName;
    } else console.log(row.cardType + " NOT FOUND!");
    return row;
  });

  return cards;
  /*
      return cards.sort(function (a, b) {
        return a.index - b.index;
      });
    */
}

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FAFAFA",
  },
  title: {
    fontSize: "min(2.3vw, 40px)",
    color: "#000000",
    textAlign: "center",
  },
  subtitle: {
    fontSize: "min(1vw, 14px)",
    color: "#000000",
    textAlign: "center",
  },
  separator: {
    height: "30px",
    backgroundColor: "",
    textAlign: "center",
  },
  leftCardspace: {
    display: "inline-block",
    // width: "10%",
  },
  cardcontainer: {
    position: "relative",
    display: "inline-block",
    width: "100%",
    padding: "10px",
    textAlign: "center",
  },
  rightCardspace: {
    display: "inline-block",
    // width: "10%",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  flexCard: {
    alignSelf: "stretch",
  },
}));

function sortDisplayName(a, b) {
  if (a.displayName < b.displayName) return -1;
  if (a.displayName > b.displayName) return 1;
  return 0;
}

function CardSpace(props) {
  const elementRef = useRef();
  // const [isVisible, setVisible] = React.useState(false);
  // const [observer, setObserver] = React.useState(new IntersectionObserver(
  //     ([entry]) => {
  //         setVisible(entry.isIntersecting);
  //     }, { rootMargin: '0px' }));

  // useEffect(() => {
  //     let isMounted = true;               // note mutable flag
  //     setTimeout(function () {
  //         isMounted && elementRef.current && observer.observe(elementRef.current);
  //     }, 1000); // Delay observing for 1 second
  //     return () => { isMounted = false; elementRef.current && observer.unobserve(elementRef.current); }
  // }, [isVisible]);

  // if (isVisible) {
  //     let cardIndex = elementRef.current.getAttribute("cardindex");
  //     let parmIndex = elementRef.current.getAttribute("parmindex");
  //     if (!props.usercards[cardIndex].viewed) {
  //         console.log('in viewport: Card#' + parmIndex);
  //         setTimeout(function () {
  //             // Call API to set viewed status:
  //             props.usercards[cardIndex].viewed = true;
  //             props.updatesettings(props.usercards);

  //         }, 2000); // Delay updating state for 2 seconds
  //     }
  // }

  const handleCardSpaceDrop = (event) => {
    let source = props.draggedcard.parentElement;
    if (source !== elementRef.current) {
      source.style.zIndex = 99;
      source.style.transition =
        "transform 0.7s cubic-bezier(0.4, 0, 0.6, 1) 0ms, " +
        "margin 0.7s cubic-bezier(0.4, 0, 0.6, 1) 0ms";
      elementRef.current.style.transition =
        "margin 0.7s cubic-bezier(0.4, 0, 0.6, 1) 0ms";

      if (elementRef.current.offsetTop > source.offsetTop) {
        elementRef.current.style.marginBottom = source.offsetHeight + "px";
        source.style.marginTop = -source.offsetHeight + "px";
        source.style.transform =
          "translateY(" +
          (elementRef.current.offsetTop -
            source.offsetTop +
            elementRef.current.offsetHeight) +
          "px)";
      } else {
        elementRef.current.style.marginTop = source.offsetHeight + "px";
        source.style.marginBottom = -source.offsetHeight + "px";
        source.style.transform =
          "translateY(" +
          (elementRef.current.offsetTop -
            source.offsetTop -
            source.offsetHeight) +
          "px)";
      }

      setTimeout(function () {
        source.style.zIndex = "auto";
        source.style.transition = "none";
        source.style.transform = "none";

        source.style.marginTop = 0;
        source.style.marginBottom = 0;
        source.style.marginBottom = 0;

        elementRef.current.style.transition = "none";
        elementRef.current.style.transform = "none";
        elementRef.current.style.marginTop = 0;
        elementRef.current.style.marginBottom = 0;

        props.handledrop(event, props.parmindex);
      }, 700);
    }
  };

  let { handledrop, usercards, updatesettings, ...other } = props; // remove handledrop

  return (
    <div
      {...other}
      ref={elementRef}
      onDrop={(e) => {
        handleCardSpaceDrop(e);
      }}
    >
      {props.children}
    </div>
  );
}

const CardSpaceMemo = React.memo(CardSpace);

export default function ResearchPreview(props) {
  // console.log("opportunity: " + JSON.stringify(ops));
  // const researchCardInfo = props.research.cardInfo ? props.research.cardInfo.info : {};
  const research = props.research;
  const cardDetails = props.cardDetails;
  const classes = useStyles();
  const theme = useTheme();

  const [usercards, setUsercards] = React.useState(research.cardInfo.info);
  const [cards, setCards] = React.useState(
    getCardsDisplay(research.cardInfo.info)
  );
  const [cardMenu, setCardMenu] = React.useState(
    getCardsDisplay(deepCopy(research.cardInfo.info)).sort(sortDisplayName)
  );
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [isExpandedArray, setIsExpandedArray] = React.useState(
    new Array(cards?.length).fill(true)
  );
  const user = useUser();

  useEffect(() => {
    setUsercards(props.research.cardInfo.info);
    setCards(getCardsDisplay(props.research.cardInfo.info));
    setCardMenu(
      getCardsDisplay(deepCopy(props.research.cardInfo.info)).sort(
        sortDisplayName
      )
    );
  }, [props.research]);

  const updateUserPreference = (research, cardPrefs) => {
    if (!approverRoles.includes(user.role)) {
      alert("Only approvers can update research.card_info");
      return;
    }

    let updateData = { info: cardPrefs };
    axios
      .patch(rule5properties.detResearch, {
        id: research.id,
        card_info: updateData,
      })
      .then((response) => {})
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
        }
      });
  };

  const updateSettings = (ucards) => {
    if (!approverRoles.includes(user.role)) {
      alert("Only approvers can update research.card_info");
      return;
    }
    // Call API to update the usercard
    updateUserPreference(research, ucards);
    setCards(getCardsDisplay(ucards));
    setUsercards(ucards);
  };

  // Menu Start
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (event, row) => {
    var local = usercards;
    var card = local.find((ctRow) => {
      return ctRow.cardId === row.cardId;
    });
    if (card) {
      if (card.hide) card.hide = false;
      else card.hide = true;

      updateSettings(local);
      // Only for menu.  DOM is not needed.
      var localCards = deepCopy(getCardsDisplay(local));
      setCardMenu(localCards.sort(sortDisplayName));
    }
  };
  // Menu End

  // Drag Operations Start
  const [dragCard, setDragCard] = React.useState(0);
  const [dragCardObject, setDragCardObject] = React.useState(null);
  const startDrag = (event, card) => {
    setDragCard(card);
    setDragCardObject(event.target);
    event.stopPropagation();
  };

  const handleDrag = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const findIndex = (cards, index) => {
    return cards.findIndex((ctRow) => {
      return ctRow.index === index;
    });
  };

  const handleDrop = (event, targetCard) => {
    var dragCardIdx = dragCard;
    var targetCardIdx = targetCard;

    if (dragCardIdx !== targetCardIdx) {
      var localCards = usercards;
      var localCardsCopy = deepCopy(usercards);
      var i;
      var srcCard = findIndex(localCards, dragCardIdx);
      if (srcCard !== -1) {
        if (dragCardIdx > targetCardIdx) {
          // i.e. 5 dropped on 3
          localCards[srcCard].index = targetCardIdx;
          for (i = 0; i < localCards.length; i++) {
            //console.log('Array Index: ' + i);
            if (i >= targetCardIdx && i < dragCardIdx) {
              let tgtCard = findIndex(localCardsCopy, i);
              //console.log('Target card: ' + tgtCard + ' original index ' + localCards[tgtCard].index)
              localCards[tgtCard].index = localCards[tgtCard].index + 1;
            }
          }
        } else {
          localCards[srcCard].index = targetCardIdx;
          for (i = 0; i < localCards.length; i++) {
            //console.log('Array Index: ' + i);
            if (i > dragCardIdx && i <= targetCardIdx) {
              let tgtCard = findIndex(localCardsCopy, i);
              //console.log('Target card: ' + tgtCard + ' original index ' + localCards[tgtCard].index)
              localCards[tgtCard].index = localCards[tgtCard].index - 1;
            }
          }
        }
      }

      // Call API to update cards setup
      updateSettings(localCards);
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const getCardDetail = (row) => {
    //Preload the previewdata and then just array access here
    return cardDetails.find((preview) => {
      //TODO .data. shouldn't be here, remove from cardDetails prop
      return parseInt(preview.data.id) === row.cardId;
    })?.data;
  };

  const toggleExpanded = (cardIndex) => {
    let newArray = deepCopy(isExpandedArray);
    newArray.splice(cardIndex, 1, !isExpandedArray[cardIndex]);
    setIsExpandedArray(newArray);
  };

  const actions = [
    { icon: <FileCopyIcon onClick={handleClick} />, name: "Available Cards" },
  ];

  if (!cards || !cardDetails) {
    // if (!cards || !cardMenu) { // Loading...
    return <div className={classes.container}></div>;
  } else {
    return (
      <div className={classes.container}>
        <SpeedDial
          ariaLabel="Opportunity Actions"
          sx={{
            position: "absolute",
            top: theme.toolbar.height + 20,
            right: 30,
          }}
          direction="down"
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDial>
        <Menu
          id="card-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {cardMenu.map((row, index) => {
            return (
              <MenuItem
                key={index}
                onClick={(e) => {
                  handleMenuClick(e, row);
                }}
              >
                <Switch
                  checked={!row.hide}
                  label={row.displayName}
                  size="small"
                />
                {row.displayName}
              </MenuItem>
            );
          })}
        </Menu>

        <div className={classes.flexContainer}>
          {cards.map((row, cardIndex) => {
            let Component = row.dom;
            let cardDetail = getCardDetail(row);
            if (!row.hide) {
              return (
                <CardSpaceMemo
                  className={classes.flexCard}
                  style={{ order: usercards[cardIndex].index + 1 }}
                  key={cardIndex}
                  handledrop={handleDrop}
                  parmindex={usercards[cardIndex].index}
                  cardindex={cardIndex}
                  draggedcard={dragCardObject}
                  onDragEnter={handleDrag}
                  onDragOver={handleDrag}
                  usercards={usercards}
                  updatesettings={updateSettings}
                >
                  <div className={classes.leftCardspace}></div>
                  <div
                    className={classes.cardcontainer}
                    draggable="true"
                    onDragStart={(e) => {
                      startDrag(e, row.index);
                    }}
                  >
                    {cardDetail && (
                      <div>
                        <Typography variant="body1" sx={{ textAlign: "right" }}>
                          {cardDetail.status} / Version: {cardDetail.version}
                        </Typography>
                        <Component
                          cardId={row.cardId}
                          cardType={row.cardType}
                          opportunity={{ Account: cardDetail.buyerName }}
                          viewed={row.viewed}
                          previewData={cardDetail.cardInfo}
                          expanded={isExpandedArray[cardIndex]}
                          toggleExpanded={() => {
                            toggleExpanded(cardIndex);
                          }}
                          forResearchPreview={true}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.rightCardspace}></div>
                </CardSpaceMemo>
              );
            } else return null;
          })}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </div>
    );
  }
}
