import { StyledDataGrid } from "../../common/StyledComponents";
import { useDialog } from "../../context/DialogContext";
import ViewCampaignResult from "../../modal/ViewCampaignResult";
import ViewCampaignErrors from "../../modal/ViewCampaignErrors";
import { CustomToolbar } from "../campaigns/MassOutreach";

export default function CampaignResultsDataGrid({
  rows,
  isLoading,
  idField = "campaignResultId",
  isErrorView = false,
  refetchRows,
}) {
  const dialog = useDialog();

  const columns = [
    ...(isErrorView
      ? [
          {
            field: "errorMessage",
            headerName: "Error Message",
            flex: 200,
          },
        ]
      : []),
    { field: "salesPlatformProspectName", headerName: "Prospect", flex: 100 },
    { field: "salesPlatformProspectEmail", headerName: "Email", flex: 100 },
    { field: "companyName", headerName: "Company", flex: 100 },
    { field: "emailSubject", headerName: "Email subject", flex: 200 },
    {
      field: "personalizedMessage",
      headerName: "Personalized message",
      flex: 300,
      renderCell: (params) => (
        <div dangerouslySetInnerHTML={{ __html: params.value }} />
      ),
    },
  ];

  return (
    <StyledDataGrid
      disableColumnMenu
      pagination
      initialState={{
        pagination: { paginationModel: { pageSize: 25 } },
      }}
      localeText={{ noRowsLabel: "No results found." }}
      loading={isLoading}
      pageSizeOptions={[10, 25, 50]}
      columns={columns}
      rows={rows || []}
      getRowId={(row) => row[idField]}
      sx={{
        height: "100%",
        mx: "30px",
        "& .MuiDataGrid-cellContent, & .MuiDataGrid-cell > div": {
          display: "-webkit-box",
          WebkitLineClamp: 4,
          WebkitBoxOrient: "vertical",
          whiteSpace: "pre-wrap",
          alignSelf: "center",
        },
      }}
      onRowClick={({ row }) => {
        if (isErrorView) {
          dialog.openModal("", ViewCampaignErrors, { campaignError: row });
        } else {
          dialog.openModal("", ViewCampaignResult, {
            campaignResult: row,
            refetchRows,
          });
        }
      }}
      getRowHeight={() => "auto"}
      slots={{
        toolbar: CustomToolbar,
      }}
    />
  );
}
