import React from "react";
import axios from "axios";
import { Box } from "@mui/system";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { rule5properties } from "../../properties";
import { useFilterForm } from "../context/FilterFormContext";
import AssigneeSelector from "./AssigneeSelector";
import { cardEditorTypes } from "../DetCardEditor";

const ControlledAutocomplete = ({
  options = [],
  renderInput,
  getOptionLabel,
  onChange: ignored,
  control,
  defaultValue,
  name,
  renderOption,
  getOptionDisabled,
  rules,
  isOptionEqualToValue,
  disabled,
}) => {
  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          {...field}
          disabled={disabled}
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          getOptionDisabled={getOptionDisabled}
          onChange={(e, data) => field.onChange(data)}
          isOptionEqualToValue={isOptionEqualToValue}
          size="small"
          sx={{ width: "225px", mx: "2%" }}
        />
      )}
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
    />
  );
};

export const scopes = [
  { value: "Global", label: "Global" },
  { value: "Seller Company", label: "Seller Company" },
  { value: "Opportunity", label: "Opportunity" },
];

export const cardStatuses = {
  draft: "Draft",
  readyForReview: "Ready for Review",
  inReview: "In Review",
  approved: "Approved",
  published: "Published",
};

const FILTER_FORM_DEFAULT_VALUES = {
  scope: null,
  type: null,
  buyerCompany: null,
  sellerCompany: null,
  status: null,
  functionalArea: null,
  assignee: null,
  maxVersion: true,
};

export default function CardFilterForm(props) {
  const [buyerCompanies, setBuyerCompanies] = React.useState(null);
  const [sellerCompanies, setSellerCompanies] = React.useState(null);
  const [cardTypes, setCardTypes] = React.useState(null);
  const [functionalAreas, setFunctionalAreas] = React.useState(null);
  const [firstTime, setFirstTime] = React.useState(true);
  const { formFilters, setFormFilters } = useFilterForm();
  const isForCreate = props.requiresAllFields;

  const defaultValues =
    formFilters && !props.ignorePrepopulatedFilters
      ? formFilters
      : FILTER_FORM_DEFAULT_VALUES;
  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({ defaultValues: defaultValues });

  const onSubmit = (data) => {
    setFormFilters(data);
    props.onSubmit(data);
  };

  const onReset = () => {
    reset(FILTER_FORM_DEFAULT_VALUES);
    setFormFilters({ FILTER_FORM_DEFAULT_VALUES });
  };

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);

      const requests = [
        axios.get(rule5properties.detCompanies),
        axios.get(rule5properties.detOrgs),
        axios.get(rule5properties.detCardTypes),
        axios.get(`${rule5properties.detValues}?name=FunctionalArea`),
      ];

      Promise.all(requests)
        .then((body) => {
          setBuyerCompanies(body[0].data);
          setSellerCompanies(body[1].data);
          setCardTypes(body[2].data);
          setFunctionalAreas(body[3].data?.values);
          // Submit form if it's the initial load.
          if (formFilters && isForCreate !== true) {
            props.onSubmit(getValues());
          }
        })
        .catch((err) => console.log(err));
    }
  }, [firstTime, formFilters, isForCreate, getValues, props]);

  if (!buyerCompanies || !sellerCompanies || !cardTypes || !functionalAreas) {
    return <div style={{ padding: "20px" }}>"Loading..."</div>;
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        "& > :not(style)": { m: 0.75 },
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {!props.excludeType && (
        <>
          <div>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={cardTypes
                    .filter((cardType) => {
                      return cardEditorTypes.some((cardEditor) => {
                        return cardEditor.cardType === cardType.type;
                      });
                    })
                    .map((row, index) => {
                      return row.type;
                    })}
                  onChange={(e, data) => {
                    setValue(
                      "scope",
                      cardTypes.find((cardType) => cardType.type === data)
                        ?.defaultScope
                    );

                    return field.onChange(data);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Card Type" />
                  )}
                  size="small"
                  sx={{ width: "225px", mx: "2%" }}
                />
              )}
              control={control}
              name="type"
              defaultValue={""}
              rules={{
                required: {
                  value: isForCreate,
                  message: "Card Type is required",
                },
                validate: (value) =>
                  value !== "Seller Competition" || // TODO: use the allowedScopes from card-type api
                  getValues("scope") === "Seller Company" ||
                  "Seller Competition card type must be made in Seller Company scope",
              }}
            />
            {errors?.type?.type === "required" && <p>{errors.type.message}</p>}
            {errors?.type?.type === "validate" && <p>{errors.type.message}</p>}
          </div>
          {/* <div style={{ flexBasis: "100%", height: 0, margin: 0 }}></div> */}
        </>
      )}
      <div>
        <ControlledAutocomplete
          control={control}
          defaultValue={""}
          name="scope"
          options={scopes.map((row, index) => {
            return row.label;
          })}
          renderInput={(params) => <TextField {...params} label="Scope" />}
          rules={{
            required: { value: isForCreate, message: "Scope is required" },
          }}
        />
        {errors?.scope?.type === "required" && <p>{errors.scope.message}</p>}
      </div>
      <div>
        <ControlledAutocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          control={control}
          defaultValue={""}
          name="buyerCompany"
          options={buyerCompanies.map((row, index) => {
            return { label: row.name, id: row.id };
          })}
          renderInput={(params) => (
            <TextField {...params} label="Buyer Company" />
          )}
        />
        {errors?.buyerCompany && <p>{errors.buyerCompany.message}</p>}
      </div>
      <div>
        <ControlledAutocomplete
          control={control}
          name="functionalArea"
          defaultValue={""}
          getOptionLabel={(option) => (option === "NA" ? "<None>" : option)}
          options={[...functionalAreas].concat("NA")}
          renderInput={(params) => (
            <TextField {...params} label="Functional Area" />
          )}
        />
      </div>
      <div>
        <ControlledAutocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          control={control}
          defaultValue={""}
          name="sellerCompany"
          options={sellerCompanies.map((row, index) => {
            return { label: row.name, id: row.id };
          })}
          renderInput={(params) => (
            <TextField {...params} label="Seller Company" />
          )}
          rules={{
            validate: {
              requiredForSeller: (value) =>
                value != null ||
                !["Seller Company", "Opportunity"].includes(
                  getValues("scope")
                ) ||
                !isForCreate ||
                "Seller company is required for this scope",
              notAllowedForSeller: (value) => {
                return (
                  value == null ||
                  getValues("scope") !== "Global" ||
                  "Seller company is not allowed for global scope"
                );
              },
            },
          }}
        />
        {errors?.sellerCompany && <p>{errors.sellerCompany.message}</p>}
      </div>
      {!isForCreate && (
        <div>
          <ControlledAutocomplete
            defaultValue={""}
            control={control}
            name="status"
            options={Object.values(cardStatuses)}
            renderInput={(params) => <TextField {...params} label="Status" />}
          />
        </div>
      )}
      <div>
        <Controller
          render={({ field: { onChange, value } }) => (
            <AssigneeSelector
              onUpdate={onChange}
              value={value}
            ></AssigneeSelector>
          )}
          defaultValue={""}
          name="assignee"
          control={control}
          // rules={{
          //   required: { value: true, message: "Company is required" },
          // }}
        />
      </div>
      {!isForCreate && (
        <Controller
          name="maxVersion"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              }
              label="Latest version"
            />
          )}
        />
      )}
      <Button type="submit" variant="contained" style={{ marginLeft: "13px" }}>
        {props.buttonLabel}
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          onReset();
        }}
      >
        Clear
      </Button>
    </Box>
  );
}
