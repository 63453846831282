import axios from "axios";
import { useQuery } from "react-query";
import { rule5properties } from "../properties";

export const useFunctionalAreas = () => {
  return useQuery("functionalAreas", async () => {
    try {
      const orgResponse = await axios.get(rule5properties.orgFunctionalAreas);
      if (orgResponse.data) {
        return orgResponse.data;
      }

      const valuesResponse = await axios.get(
        `${rule5properties.values}?name=FunctionalArea`
      );
      if (valuesResponse.data.values) {
        return valuesResponse.data.values;
      }

      throw new Error("No functional areas found");
    } catch (error) {
      console.error("Error loading Functional Areas:", error);
      throw error;
    }
  });
};
