import React from "react";
import ManageCompanies from "../ManageCompanies";

export default function EditCompanyPopup(props) {
  const { company, companies, updateCompaniesCallback } = props;

  const params = company
    ? {
        id: { id: company.id, label: company.name },
        ...company.companyInfo,
      }
    : null;
  return (
    <ManageCompanies
      company={params}
      companies={companies}
      updateCompaniesCallback={updateCompaniesCallback}
    />
  );
}
