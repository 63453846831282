import { IconButton, Stack, Typography } from "@mui/material";
import { rule5properties } from "../../properties";
import { useUser } from "../../context/UserContext";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { WhiteBorderlessButton } from "../../common/StyledComponents";
import { useDialog } from "../../context/DialogContext";
import ConfigureSlackChannel from "../../modal/ConfigureSlackChannel";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import slackLogo from "../../res/slack_button.svg";
import { useSlackConfig } from "../../api/integrations";

export function SlackIntegration(props) {
  const { data: configuredSlackChannels, refetch } = useSlackConfig();

  const dialog = useDialog();

  function deleteChannelConfig(index) {
    axios
      .delete(
        rule5properties.userMessageChannels +
          "/" +
          configuredSlackChannels[index].id
      )
      .then((res) => {
        if (res.status === 204) {
          refetch();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Stack>
      <Typography variant="settingsHeader" sx={{ mb: 1 }}>
        Configured Channels
      </Typography>
      {configuredSlackChannels.map((channelConfig, index) => (
        <SlackChannelRow
          config={channelConfig}
          index={index}
          configClicked={() => {
            dialog.openModal(
              "Configure Slack Channel",
              ConfigureSlackChannel,
              {
                modIndex: index,
                modConfig: channelConfig,
              },
              "sm"
            );
          }}
          deleteChannelConfig={deleteChannelConfig}
        />
      ))}
      <WhiteBorderlessButton
        sx={{
          mt: 1,
          px: 2.25,
          py: 1.5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => {
          dialog.openModal(
            "Configure Slack Channel",
            ConfigureSlackChannel,
            {},
            "sm"
          );
        }}
      >
        <Typography sx={{ fontSize: "14px", opacity: 0.7 }}>
          Add new...
        </Typography>
        <PlaylistAddIcon fontSize="small" sx={{ opacity: 0.7 }} />
      </WhiteBorderlessButton>
    </Stack>
  );
}

function SlackChannelRow(props) {
  const { config, configClicked, index, deleteChannelConfig } = props;
  return (
    <WhiteBorderlessButton
      sx={{ py: 1, px: 2, direction: "row", alignItems: "center", flexGrow: 1 }}
      onClick={configClicked}
    >
      <ChatBubbleIcon fontSize="small" sx={{ opacity: 0.5 }} />
      <Typography
        sx={{ flexGrow: 1, mx: 2, mb: 0.25, opacity: 0.7 }}
        textAlign="left"
      >
        # {config.channelName}
      </Typography>
      <IconButton
        disableRipple
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          deleteChannelConfig(index);
        }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </WhiteBorderlessButton>
  );
}

export function AddToSlackButton(props) {
  const userContext = useUser();
  const email = userContext.email;

  return (
    <a
      href={
        rule5properties.slackInstall + "&state=" + encodeURIComponent(email)
      }
      style={{
        alignItems: "center",
        color: "#fff",
        backgroundColor: "#4A154B",
        border: "0",
        borderRadius: "4px",
        display: "inline-flex",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        fontWeight: "600",
        height: "48px",
        justifyContent: "center",
        textDecoration: "none",
        width: "236px",
      }}
    >
      <img
        src={slackLogo}
        alt="slack_logo"
        style={{ height: "20px", width: "20px", marginRight: "12px" }}
      />
      Add to Slack
    </a>
  );
}
