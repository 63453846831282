import React from "react";
import { MenuItem } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ShareIcon from "@mui/icons-material/Share";
import axios from "axios";
import { rule5properties } from "../../properties";
import { StyledMenu } from "../../common/StyledComponents";
import { useDialog } from "../../context/DialogContext";
import GenericConfirmation from "../../modal/GenericConfirmation";
import { useGptConversationContext } from "../../context/GptConversationContext";
import { isMobile } from "react-device-detect";

export default function ChatActionsMenu(props) {
  const { anchorEl, open, handleClose, opportunityId, conversationId } = props;

  const dialog = useDialog();
  const gptConversationContext = useGptConversationContext();

  const id = open ? `${conversationId}-chatActionsMenu` : undefined;

  return (
    <StyledMenu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "chat-menu-button",
      }}
    >
      <MenuItem
        key="generate email"
        disabled={!conversationId}
        onClick={() => {
          handleClose();
          gptConversationContext.setSelection((draft) => {
            draft.selectionMode = true;
          });
        }}
      >
        Generate Email
        <MailIcon fontSize="small" sx={{ color: "#525252" }} />
      </MenuItem>
      {opportunityId &&
        !isMobile && [
          <MenuItem
            key="view research"
            onClick={handleClose}
            target="_blank"
            component="a"
            href={`/main/opportunities/${opportunityId}/research`}
          >
            View research
            <OpenInNewIcon
              fontSize="small"
              sx={{ color: "#525252" }}
            ></OpenInNewIcon>
          </MenuItem>,
          <MenuItem
            key="generate pov"
            onClick={handleClose}
            target="_blank"
            component="a"
            href={`/main/opportunities/${opportunityId}/research?download=true`}
          >
            Generate POV
            <OpenInNewIcon fontSize="small" sx={{ color: "#525252" }} />
          </MenuItem>,
        ]}
      <MenuItem
        key="email conversation"
        disabled={!conversationId}
        onClick={() => {
          handleClose();
          dialog.openModal(
            "Email Conversation",
            GenericConfirmation,
            {
              text: "A transcript of this conversation will be forwarded to your email inbox.",
              confirmFunction: (callback) => {
                const params = {
                  id: conversationId,
                };
                const options = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                };
                axios
                  .post(rule5properties.emailConversation, params, options)
                  .then((res) => {
                    if (callback) callback(res);
                  });
              },
            },
            "sm"
          );
        }}
      >
        Email conversation
        <ShareIcon fontSize="small" sx={{ color: "#525252" }} />
      </MenuItem>
    </StyledMenu>
  );
}
