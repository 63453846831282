import React from "react";
import {
  Collapse,
  Typography,
  Button,
  Box,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import {
  StyledTextfield,
  StyledLabel,
  ErrLabel,
} from "../common/StyledComponents";
import axios from "axios";
import { rule5properties } from "../properties";
import { useDialog } from "../context/DialogContext";
import { useForm } from "react-hook-form";

export default function ChatNegativeFeedback(props) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { register, handleSubmit } = useForm();

  const { promptId, setRating } = props;

  const dialog = useDialog();

  const submitForm = (data) => {
    setLoading(true);
    const params = {
      promptCompletionId: promptId,
      feedback: {
        rating: "thumbsDown",
        ...(data.comment ? { comment: data.comment } : {}),
        ...(data.tags ? { tags: data.tags } : {}),
      },
    };
    axios
      .post(rule5properties.conversationFeedback, params)
      .then((resp) => {
        if (resp.status === 200) {
          setRating("thumbsDown");
          dialog.closeModal();
          setLoading(false);
        } else {
          setError("Unexpected error giving feedback.");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <Box sx={{}}>
        <form
          style={{ display: "flex", flexDirection: "column" }}
          onSubmit={handleSubmit(submitForm)}
        >
          <StyledTextfield
            {...register("comment")}
            sx={{
              display: "block",
              fontSize: "16px",
              minHeight: "160px",
              maxHeight: "480px",
              overflowY: "auto",
            }}
            multiline
            placeholder="What was the issue with this response?"
          />
          <StyledLabel sx={{ marginTop: "15px" }}>
            <Checkbox
              sx={{ padding: "0px" }}
              type="checkbox"
              value="false" // false is not a boolean here, but rather the name for a tag meaning untrue
              {...register("tags")}
            />{" "}
            Not true
          </StyledLabel>
          <StyledLabel>
            <Checkbox
              sx={{ padding: "0px" }}
              type="checkbox"
              value="notHelpful"
              {...register("tags")}
            />{" "}
            Not helpful
          </StyledLabel>
          <Collapse in={error !== ""}>
            <ErrLabel>{error}</ErrLabel>
          </Collapse>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              mt: 2,
              alignItems: "flex-end",
            }}
          >
            <Button
              type="submit"
              disabled={loading}
              sx={{ width: "140px", marginLeft: "auto" }}
              variant="outlined"
            >
              <Typography
                variant="button"
                sx={{ textTransform: "none", mt: "3px" }}
              >
                Submit
              </Typography>
              {loading ? (
                <CircularProgress color="inherit" size={16} sx={{ ml: 2 }} />
              ) : null}
            </Button>
          </Box>
        </form>
      </Box>
    </React.Fragment>
  );
}
