import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { rule5AppUrl } from "../properties";
import { useDialog } from "../context/DialogContext";
import ResearchCompany from "../modal/ResearchCompany";
import { CircularProgress, Tooltip } from "@mui/material";
import { ALLOWED_ACTIONS, useUser } from "../context/UserContext";
import researchIcon from "../res/icons/outline/16px_research.svg";
import researchIconWhite from "../res/icons/outline/16px_research_white.svg";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(1),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledButton = styled((props) => (
  <Button disableElevation variant="contained" {...props} />
))(({ theme }) => ({
  width: "fit-content",
  borderRadius: "8px",
  height: "100%",
}));

export default function OpenResearchButton(props) {
  const { agentResult, userOpportunities, isFetching } = props;
  const user = useUser();

  if (
    !user?.userSubscription?.allowedActions.includes(
      ALLOWED_ACTIONS.requestResearch
    )
  ) {
    return <NotAllowedButton />;
  }

  if (isFetching) {
    return (
      <div
        style={{
          width: "140px",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={20} />
      </div>
    );
  }

  if (userOpportunities?.length === 1) {
    return <OpenSingleResearchButton opportunity={userOpportunities?.[0]} />;
  } else if (userOpportunities?.length > 1) {
    return <FunctionalAreaMenuButton opportunities={userOpportunities} />;
  } else {
    return <CreateResearchButton agentResult={agentResult} />;
  }
}

function NotAllowedButton(props) {
  return (
    <Tooltip title="This feature is not available with your subscription. Contact sales@rule5.io to upgrade.">
      <span
        style={{ cursor: "not-allowed" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* span wrapper is hack for tooltip to work on disabled button: https://github.com/mui/material-ui/issues/8416*/}
        <StyledButton
          endIcon={
            <img src={researchIcon} alt="search" style={{ opacity: 0.5 }} />
          }
          disabled={true}
        >
          Research this company
        </StyledButton>
      </span>
    </Tooltip>
  );
}

function OpenSingleResearchButton(props) {
  const { opportunity } = props;

  return (
    <StyledButton
      endIcon={<OpenInNewIcon />}
      onClick={() => {
        window.open(
          `${rule5AppUrl}/main/opportunities/${opportunity?.id}/research`,
          "_blank"
        );
      }}
    >
      Open research
    </StyledButton>
  );
}

function FunctionalAreaMenuButton(props) {
  const { opportunities } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFunctionalAreaSelect = (oppty) => {
    window.open(
      `${rule5AppUrl}/main/opportunities/${oppty?.id}/research`,
      "_blank"
    );
    handleClose();
  };

  return (
    <>
      <StyledButton
        id="research-by-functional-area-menu-button"
        aria-controls={open ? "functional-area-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Open research
      </StyledButton>
      <StyledMenu
        id="functional-area-menu"
        MenuListProps={{
          "aria-labelledby": "research-by-functional-area-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {opportunities.map((oppty) => (
          <MenuItem
            onClick={() => handleFunctionalAreaSelect(oppty)}
            disableRipple
          >
            {oppty.functionalArea}
            <OpenInNewIcon />
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}

function CreateResearchButton(props) {
  const { agentResult } = props;
  const dialog = useDialog();

  return (
    <StyledButton
      endIcon={<img src={researchIconWhite} alt="search" />}
      onClick={() => {
        dialog.openModal(
          "Research company",
          ResearchCompany,
          {
            defaultCompany: {
              id: agentResult.companyId,
              name: agentResult.companyName,
            },
            navigateToNewResearch: false,
          },
          "sm",
          true
        );
      }}
    >
      Research this company
    </StyledButton>
  );
}
