import React, { useEffect } from "react";
import {
  Badge,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import r5Logo from "./res/rule5gpt_logo.svg";
import searchIcon from "./res/icons/outline/16px_zoom.svg";
import opportunitiesIcon from "./res/icons/outline/16px_bulb-61.svg";
import settingsIcon from "./res/icons/outline/16px_gear.svg";
import shareIcon from "./res/icons/outline/16px_favorite.svg";
import researchIcon from "./res/icons/outline/16px_research.svg";
import helpIcon from "./res/icons/outline/16px_c-question.svg";
import notificationIcon from "./res/icons/outline/16px_bell.svg";
import chatIcon from "./res/icons/outline/comments.svg";
import searchIconFilled from "./res/icons/fill/16px_zoom.svg";
import opportunitiesIconFilled from "./res/icons/fill/16px_bulb-61.svg";
import settingsIconFilled from "./res/icons/fill/16px_gear.svg";
import signalsIcon from "./res/icons/outline/16px_antenna.svg";
import signalsIconFilled from "./res/icons/fill/16px_antenna.svg";
import chatIconFilled from "./res/icons/fill/comments.svg";
import ClinicalNotes from "./res/icons/fill/googlefont_clinicalnotes.svg";
import EmailFilled from "./res/icons/fill/email-open.svg";
import EmailOutlined from "./res/icons/outline/email-open.svg";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import MapIcon from "@mui/icons-material/Map";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import { useDialog } from "./context/DialogContext";
import { useSidepanel } from "./context/SidepanelContext";
import ResearchCompany from "./modal/ResearchCompany";
import { useLocation } from "react-router-dom";
import InviteUsers from "./modal/InviteUsers";
import Feedback from "./modal/Feedback";
import { rule5properties } from "./properties";
import {
  ALLOWED_ACTIONS,
  COREAPP_USER_ROLES,
  useUser,
} from "./context/UserContext";
import Notifications from "./common/notifications/Notifications";
import axios from "axios";
import NotificationsService from "./common/notifications/NotificationsService";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ContactSupport from "./modal/ContactSupport";
import EmbeddedVideo from "./modal/EmbeddedVideo";
import {
  MenuListItem,
  MenuListHeading,
  MenuListSubItem,
} from "./common/MenuListItem";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BusinessIcon from "@mui/icons-material/Business";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import mailWithStar from "./res/icons/outline/mail-with-star.svg";
import engageIqIcon from "./res/icons/fill/engage-iq.svg";
import engageIqOutlineIcon from "./res/icons/outline/rule5_engage_iq.svg";
import listBuildingIcon from "./res/icons/outline/List_building_agents.svg";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles(() => ({
  menuText: {
    transition: "0.2s",
    color: "rgba(0,0,0,0.8)",
    fontSize: "14px",
    width: "90%",
    whiteSpace: "pre-wrap",
  },
  selectedMenuText: {
    transition: "0.2s",
    color: "rgb(25, 118, 210)",
    fontSize: "14px",
    width: "90%",
    whiteSpace: "pre-wrap",
    fontWeight: 500,
    paddingLeft: 6,
  },
  menuImage: {
    height: "16px",
    width: "16px",
  },
  menuImageSelected: {
    height: "16px",
    width: "16px",
    filter:
      "invert(38%) sepia(98%) saturate(744%) hue-rotate(179deg) brightness(85%) contrast(102%)",
  },
  menuIcon: {
    minWidth: "40px",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
    "& .MuiListItemSecondaryAction-root": {
      right: "3px",
      top: "15px",
    },
  },
  listItem: {
    transition: "0.2s",
    backgroundColor: "#FFFFFF",
    height: "48px",
    margin: "4px 0px 4px 0px",
    borderRadius: "12px",
    paddingLeft: 20,
    overflowX: "hidden",
    "&:active": {
      backgroundColor: "rgba(0,0,0,0.1)",
      "& $clearButton": {
        visibility: "visible",
      },
    },
    "&:hover": {
      "& $clearButton": {
        visibility: "visible",
      },
    },
  },
  mainList: {
    flexGrow: 1,
  },
  subList: {
    paddingBottom: "30%",
  },
  largeButton: {
    transition: "0.2s",
    backgroundColor: "#FFFFFF",
    height: "48px",
    margin: "4px 0px 4px 0px",
    borderRadius: "12px",
    paddingLeft: 20,
    overflowX: "hidden",
    "&:active": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    // border: '1px dashed rgba(0,0,0,0.1)'
  },
  buttonText: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: 500,
  },
  menuSubHeading: {
    opacity: "0.8",
    fontSize: "16px",
    fontWeight: 500,
    padding: "0px 15px",
  },
  bulletIcon: {
    fontSize: "12px",
    transform: "scale(0.8)",
    marginLeft: "6px",
    color: "#1976d2",
  },
  feedbackMenu: {
    "& span": {
      fontSize: "14px",
    },
  },
  clearButton: {
    visibility: "hidden",
  },
  installExtensionText: {
    lineHeight: "2",
  },
  collapsedIcon: {},
}));

const SHOW_MENU_ITEM_ROLES = [
  "Basic",
  "Basic - Monitor",
  "Monitor",
  "Standard",
];

// Create a styled version of the base icon components
const CollapsedMenuIcon = styled(({ component: Component, ...props }) => (
  <Component fontSize="24px" {...props} />
))({
  margin: "auto",
  opacity: 0.4,
});

export const MenuList = React.memo(function MenuList(props) {
  const dialog = useDialog();
  const classes = useStyles();
  const user = useUser();

  const allowedActions = user.userSubscription?.allowedActions || [];
  // This is currently a separate value from showAllMenuItems in case things diverge.
  const locked = SHOW_MENU_ITEM_ROLES.includes(user.userSubscription?.planType);
  const showAllMenuItems = SHOW_MENU_ITEM_ROLES.includes(
    user.userSubscription?.planType
  );

  // TODO fix all these lists, e.g. so li in ul not anchors. Also enter key handlers
  return (
    <React.Fragment>
      <List dense={false} className={classes.mainList}>
        {(allowedActions?.includes(ALLOWED_ACTIONS.requestResearch) || // special case for basic user where they can't request research but we still want them to see the dialog (which then gates them)
          showAllMenuItems) && (
          <MenuListItem
            locked={locked}
            onClickFunction={() =>
              dialog.openModal("Research company", ResearchCompany, null, "sm")
            }
            text="Research company"
            expanded={props.expanded}
            icons={{
              custom: (
                <img
                  src={researchIcon}
                  alt="search"
                  className={classes.menuImage}
                />
              ),
            }}
          />
        )}
        {allowedActions?.includes(ALLOWED_ACTIONS.viewResearch) && (
          <MenuListItem
            locked={locked}
            linkTo="/main/search"
            text="Search"
            expanded={props.expanded}
            icons={{ filled: searchIconFilled, default: searchIcon }}
          />
        )}
        {(allowedActions?.includes(ALLOWED_ACTIONS.viewResearch) ||
          showAllMenuItems) && (
          <MenuListHeading
            locked={locked}
            text="Account IQ"
            icons={{
              filled: opportunitiesIconFilled,
              default: opportunitiesIcon,
            }}
            subItems={[
              <MenuListSubItem
                expanded={props.expanded}
                collapsedIcon={
                  <CollapsedMenuIcon component={WorkOutlineOutlinedIcon} />
                }
                linkTo="/main/opportunities"
                text="Accounts"
                key="opportunities"
              />,
              ...(allowedActions?.includes(
                ALLOWED_ACTIONS.viewPeopleConnections
              )
                ? [
                    <MenuListSubItem
                      expanded={props.expanded}
                      collapsedIcon={
                        <CollapsedMenuIcon component={PeopleAltOutlinedIcon} />
                      }
                      linkTo="/main/connections"
                      text="Connections"
                      key="connections"
                    />,
                  ]
                : []),
            ]}
          />
        )}
        {(allowedActions?.includes(ALLOWED_ACTIONS.conversations) ||
          showAllMenuItems) && (
          <MenuListHeading
            locked={locked}
            text="Action IQ"
            expanded={props.expanded}
            icons={{
              filled: chatIconFilled,
              default: chatIcon,
            }}
            subItems={[
              <MenuListSubItem
                expanded={props.expanded}
                collapsedIcon={
                  <CollapsedMenuIcon
                    fontSize="24px"
                    component={ChatBubbleOutlineOutlinedIcon}
                  />
                }
                key="conversations"
                linkTo="/main/chat"
                text="Conversations"
              />,
              ...(allowedActions?.includes(ALLOWED_ACTIONS.promptBooks)
                ? [
                    <MenuListSubItem
                      expanded={props.expanded}
                      collapsedIcon={
                        <CollapsedMenuIcon component={MenuBookIcon} />
                      }
                      key="prompt-books"
                      linkTo="/main/promptbooks"
                      text="Prompt books"
                    />,
                  ]
                : []),
              ...(allowedActions?.includes(ALLOWED_ACTIONS.content)
                ? [
                    <MenuListSubItem
                      expanded={props.expanded}
                      collapsedIcon={
                        <CollapsedMenuIcon component={PictureAsPdfIcon} />
                      }
                      key="content"
                      linkTo="/main/content"
                      text="Content"
                    />,
                  ]
                : []),
            ]}
          />
        )}
        {allowedActions?.includes(ALLOWED_ACTIONS.monitor) && (
          <MenuListHeading
            text="Signal IQ"
            expanded={props.expanded}
            icons={{
              filled: signalsIconFilled,
              default: signalsIcon,
            }}
            subItems={[
              <MenuListSubItem
                expanded={props.expanded}
                collapsedIcon={<CollapsedMenuIcon component={BusinessIcon} />}
                linkTo="/main/monitor/accounts"
                text="Company agents"
                key="accounts"
              />,
              <MenuListSubItem
                collapsedIcon={
                  <CollapsedMenuIcon component={EventNoteOutlinedIcon} />
                }
                expanded={props.expanded}
                linkTo="/main/monitor/account-events"
                text="Company signals"
                key="events"
              />,
              <MenuListSubItem
                collapsedIcon={
                  <CollapsedMenuIcon component={RecordVoiceOverIcon} />
                }
                expanded={props.expanded}
                linkTo="/main/monitor/people"
                text="People agents"
                key="people"
              />,
              <MenuListSubItem
                collapsedIcon={
                  <img
                    src={ClinicalNotes}
                    alt="PeopleEvents"
                    style={{
                      width: "18px",
                      height: "18px",
                      margin: "auto",
                      opacity: 0.4,
                    }}
                  />
                }
                expanded={props.expanded}
                linkTo="/main/monitor/job-change-leads"
                text="People signals"
                key="leads"
              />,
            ]}
          />
        )}
        {allowedActions?.includes(ALLOWED_ACTIONS.personalizeCampaign) && (
          <MenuListHeading
            locked={locked}
            text="Engage IQ"
            icons={{ filled: engageIqIcon, default: engageIqOutlineIcon }}
            subItems={[
              <MenuListSubItem
                expanded={props.expanded}
                collapsedIcon={
                  <img
                    src={mailWithStar}
                    alt="search"
                    style={{
                      width: "16px",
                      height: "16px",
                      margin: "auto",
                      opacity: 0.4,
                    }}
                  />
                }
                linkTo="/main/personalize-campaigns"
                text="Personalization agents"
                key="campaigns"
              />,
              <MenuListSubItem
                expanded={props.expanded}
                tooltip="Coming Soon! List-building agents will be available in our next update. Stay tuned!"
                disabled
                collapsedIcon={
                  <img
                    src={listBuildingIcon}
                    alt="search"
                    style={{
                      width: "16px",
                      height: "16px",
                      margin: "auto",
                      opacity: 0.4,
                    }}
                  />
                }
                // linkTo="/main/personalize-campaigns"
                text="List-building agents"
                key="list-agents"
              />,
            ]}
          />
        )}
      </List>
    </React.Fragment>
  );
});

export const MenuSubList = React.memo(function MenuSubList(props) {
  const classes = useStyles();
  const dialog = useDialog();
  const sidepanel = useSidepanel();
  const user = useUser();

  const [isChromeExtensionInstalled, setIsChromeExtensionInstalled] =
    React.useState(user?.userSettingInfo?.isChromeExtensionInstalled);

  const [notifications, setNotifications] = React.useState({
    all: [],
    new: [],
    old: [],
  });

  const [feedbackAnchorEl, setFeedbackAnchorEl] = React.useState(null);
  const handleFeedbackClick = (event) => {
    setFeedbackAnchorEl(event.currentTarget);
  };

  const allowedActions = user.userSubscription?.allowedActions;

  // Call getNotifications on 60 second intervals.
  function getNotificationsOnInterval(callback) {
    if (allowedActions.includes(ALLOWED_ACTIONS.viewNotifications)) {
      NotificationsService.getNotifications(callback).then((res) => {
        setNotifications(res);
      });
      // If there is a running interval, clear it.
      if (NotificationsService.getIntervalId()) {
        clearInterval(NotificationsService.getIntervalId());
      }
      // Start a new interval to continue refreshing notifications.
      let newIntervalId = setInterval(() => {
        NotificationsService.getNotifications(callback).then((res) => {
          setNotifications(res);
        });
      }, 60000);
      NotificationsService.setIntervalId(newIntervalId);
    }
  }

  function setChromeExtensionInstalled() {
    axios
      .patch(rule5properties.usersApiUrl + "/update-profile", {
        isChromeExtensionInstalled: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setIsChromeExtensionInstalled(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (!allowedActions.includes(ALLOWED_ACTIONS.viewNotifications)) {
      return;
    }
    getNotificationsOnInterval();
    // Remove any ongoing notifications intervals.
    return () => {
      if (NotificationsService.getIntervalId())
        clearInterval(NotificationsService.getIntervalId());
    };
  }, []);

  return (
    <List style={{ paddingBottom: "30%" }}>
      {!isChromeExtensionInstalled &&
        allowedActions.includes(ALLOWED_ACTIONS.viewResearch) && (
          <div
            style={{ height: "70px", marginBottom: "40px" }}
            className={classes.link}
          >
            <ListItem
              disableRipple
              sx={{ overflow: "hidden" }}
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                height: "70px",
                border: "1px solid rgb(167,201,234)",
                borderRadius: "12px",
                backgroundColor: "rgb(247,252,255)",
              }}
              className={classes.listItem}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="remove download extension button"
                  onClick={setChromeExtensionInstalled}
                  size="small"
                  className={classes.clearButton}
                >
                  <ClearIcon
                    style={{ height: "15px", width: "15px" }}
                    fontSize="small"
                  />
                </IconButton>
              }
            >
              <ListItemButton
                disableRipple
                style={{
                  padding: "0px 0px 0px 20px",
                  backgroundColor: "inherit",
                }}
                onClick={() => {
                  window.open(
                    "https://chrome.google.com/webstore/detail/rule5/lggohfbfkbnpgofmflaeboonadahfnim/",
                    "_blank"
                  );
                  setChromeExtensionInstalled();
                }}
              >
                <ListItemIcon className={classes.menuIcon}>
                  <img
                    src={r5Logo}
                    alt="extension"
                    className={classes.menuImage}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: `${classes.menuText} ${classes.installExtensionText}`,
                  }}
                  primary="Unlock rule5 everywhere"
                  secondary="Install Chrome extension"
                />
              </ListItemButton>
            </ListItem>
          </div>
        )}
      {allowedActions?.includes(ALLOWED_ACTIONS.viewNotifications) && (
        <MenuListItem
          onClickFunction={() => {
            sidepanel.openSidepanel("Notifications", Notifications, {
              notifications: notifications,
              getNotifications: getNotificationsOnInterval,
            });
          }}
          text="Notifications"
          expanded={props.expanded}
          icons={{
            custom: (
              <Badge
                invisible={notifications.new.length === 0}
                badgeContent={notifications.new?.length}
                color="primary"
              >
                <img
                  src={notificationIcon}
                  alt="notifications"
                  className={classes.menuImage}
                />
              </Badge>
            ),
          }}
        />
      )}

      {allowedActions?.includes(ALLOWED_ACTIONS.inviteYourTeam) && (
        <MenuListItem
          onClickFunction={() =>
            dialog.openModal("Invite your team", InviteUsers, {}, "md")
          }
          text="Invite your team"
          sx={{ textWrap: "nowrap" }}
          expanded={props.expanded}
          icons={{
            custom: (
              <img src={shareIcon} alt="invite" className={classes.menuImage} />
            ),
          }}
        />
      )}
      {user.role === COREAPP_USER_ROLES.orgAdmin && (
        <MenuListItem
          linkTo="/main/users"
          text="Manage your team"
          sx={{ textWrap: "nowrap" }}
          expanded={props.expanded}
          icons={{
            custom: (
              <PeopleOutlineIcon alt="manage" className={classes.menuImage} />
            ),
          }}
        />
      )}
      {user.role === COREAPP_USER_ROLES.orgAdmin &&
        allowedActions.includes(ALLOWED_ACTIONS.viewResearch) && (
          <MenuListItem
            linkTo="/main/accounts"
            text="Manage accounts"
            sx={{ textWrap: "nowrap" }}
            expanded={props.expanded}
            icons={{
              custom: (
                <ManageAccountsIcon
                  alt="manage"
                  className={classes.menuImage}
                />
              ),
            }}
          />
        )}

      <MenuListItem
        onClickFunction={handleFeedbackClick}
        text="Help & feedback"
        sx={{ textWrap: "nowrap" }}
        expanded={props.expanded}
        icons={{
          custom: (
            <img src={helpIcon} alt="help" className={classes.menuImage} />
          ),
        }}
      />

      <FeedbackMenu
        feedbackAnchorEl={feedbackAnchorEl}
        setFeedbackAnchorEl={setFeedbackAnchorEl}
      ></FeedbackMenu>

      <MenuListItem
        linkTo="/main/settings"
        text="Settings"
        sx={{ textWrap: "nowrap" }}
        expanded={props.expanded}
        icons={{
          filled: settingsIconFilled,
          default: settingsIcon,
        }}
      />
    </List>
  );
});

const FeedbackMenu = React.memo(function FeedbackMenu(props) {
  const { feedbackAnchorEl, setFeedbackAnchorEl } = props;
  const classes = useStyles();
  const dialog = useDialog();
  const location = useLocation();
  const user = useUser();

  const handleClose = () => {
    setFeedbackAnchorEl(null);
  };

  const allowedActions = user.userSubscription?.allowedActions;

  return (
    <Menu
      id="feedback-menu"
      anchorEl={feedbackAnchorEl}
      open={Boolean(feedbackAnchorEl)}
      className={classes.feedbackMenu}
      onClose={handleClose}
      onClick={handleClose}
      MenuListProps={{
        "aria-labelledby": "feedback-button",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {(allowedActions.includes(ALLOWED_ACTIONS.viewResearch) ||
        allowedActions.includes(ALLOWED_ACTIONS.monitor) ||
        allowedActions.includes(ALLOWED_ACTIONS.viewPeopleConnections)) && (
        <Typography className={classes.menuSubHeading} key="heading-1">
          Learn More
        </Typography>
      )}
      {allowedActions.includes(ALLOWED_ACTIONS.monitor) && [
        <MenuItem
          key="signalIQ-tour-link"
          onClick={() => {
            window.Appcues?.show("0107d8d8-bfb1-412d-9ce7-6372351f59f7");
          }}
        >
          <ListItemIcon>
            <MapIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Take a 1 min interactive tour of Signal IQ
          </ListItemText>
        </MenuItem>,
        <MenuItem
          key="signal-iq-video-tour"
          onClick={(event) =>
            dialog.openModal(
              "Take a 3 min video tour of Signal IQ",
              EmbeddedVideo,
              {
                url: "https://www.loom.com/embed/aeda8e130eea4d92a6205a25dedb91fb",
              }
            )
          }
        >
          <ListItemIcon>
            <LocalMoviesIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Take a 3 min video tour of Signal IQ</ListItemText>
        </MenuItem>,
        <Divider key="signal-hr"></Divider>,
      ]}
      {allowedActions.includes(ALLOWED_ACTIONS.viewResearch) && [
        <MenuItem
          key="tour-link"
          onClick={() => {
            window.Appcues?.show("82d00533-eb9d-4619-b4aa-e5bff6150852");
          }}
        >
          <ListItemIcon>
            <MapIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Take a 3 min interactive tour of Account IQ and Action IQ
          </ListItemText>
        </MenuItem>,
        <MenuItem
          key="video-tour"
          onClick={(event) =>
            dialog.openModal(
              "Take a 5 min video tour of Account IQ and Action IQ",
              EmbeddedVideo,
              {
                url: "https://www.loom.com/embed/5154e1280990490f82f59778bb06e79f",
              }
            )
          }
        >
          <ListItemIcon>
            <LocalMoviesIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Take a 5 min video tour of Account IQ and Action IQ
          </ListItemText>
        </MenuItem>,
        <Divider key="research-hr"></Divider>,
      ]}
      {allowedActions.includes(ALLOWED_ACTIONS.viewPeopleConnections) && [
        <MenuItem
          key="team-connect"
          onClick={(event) =>
            dialog.openModal(
              "Take a 5 min tour of TeamConnect",
              EmbeddedVideo,
              {
                url: "https://www.loom.com/embed/373e1b8c1b36464baf2c7cefb86a4556",
              }
            )
          }
        >
          <ListItemIcon>
            <LocalMoviesIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Take a 5 min tour of TeamConnect</ListItemText>
        </MenuItem>,
        <Divider key="connect-hr"></Divider>,
      ]}
      <Typography className={classes.menuSubHeading}>Support</Typography>
      <MenuItem
        onClick={(event) =>
          dialog.openModal(
            "Contact Support",
            ContactSupport,
            { url: location.pathname },
            "sm"
          )
        }
      >
        <ListItemIcon>
          <SupportAgentIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Contact Support</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={(event) =>
          dialog.openModal(
            "Feedback for Rule5",
            Feedback,
            { url: location.pathname },
            "sm"
          )
        }
      >
        <ListItemIcon>
          <TipsAndUpdatesIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Send Feedback</ListItemText>
      </MenuItem>
    </Menu>
  );
});
