import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import { Box, Link, Tooltip } from "@mui/material";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { convertFromRaw, EditorState } from "draft-js";
import Image from "../../../../common/Image";
import RichTextReader from "../../../../common/RichTextReader";

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 3fr",
    justifyItems: "left",
    rowGap: "10px",
  },
  logo: {
    maxWidth: "50px",
    maxHeight: "50px",
  },
}));

function HeaderRow(props) {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          minHeight: "40px",
        }}
      >
        Company
      </Typography>
      <Typography variant="subtitle2">Product/Solution</Typography>
      <Typography variant="subtitle2" sx={{ justifySelf: "center" }}>
        Details
      </Typography>
      <Typography variant="subtitle2">Remarks</Typography>
    </>
  );
}

function CompanyItem(props) {
  const classes = useStyles();
  let row = props.row;
  let index = props.index;
  let alt = "Company#" + (index + 1);

  const editorState = EditorState.createWithContent(
    convertFromRaw(row.Remarks)
  );

  return (
    <>
      {row.Logo ? (
        <Tooltip title={row.Name?.label}>
          <Box height="fit-content">
            <Image src={row.Logo} alt={alt} className={classes.logo} />
          </Box>
        </Tooltip>
      ) : (
        <Typography>{row.Name?.label}</Typography>
      )}
      <Typography sx={{ justifySelf: "left", textAlign: "left" }}>
        {row.Product}
      </Typography>
      {row.Details?.trim().length > 0 ? (
        <Link sx={{ justifySelf: "center" }} href={row.Details} target="_blank">
          Details
        </Link>
      ) : (
        // TODO: Temporary placeholder.
        <div></div>
      )}
      <RichTextReader editorState={editorState} />
    </>
  );
}

export default function TechnologyLandscape(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [firstTime, setFirstTime] = React.useState(true);
  const [technologyLandscapeData, setTechnologyLandscapeData] =
    React.useState(null);
  const classes = useStyles();
  const previewData = props.previewData;

  useEffect(() => {
    let isMounted = true; // note mutable flag
    if (previewData) {
      // Call Stub
      setTechnologyLandscapeData(previewData);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId;
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            if (isMounted) {
              let rawdata = response.data;
              if (rawdata.Company) {
                // Validate content
                setTechnologyLandscapeData(rawdata);
              } else {
                // API call failed
                setSnackbarMessage(
                  "There was a problem with the " + props.cardType + " Card"
                );
                setOpenSnackbar(true);
              }
            }
          })
          .catch((error) => {
            if (error.response && isMounted) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
    return () => {
      isMounted = true;
    };
  }, [firstTime, previewData]);

  if (!technologyLandscapeData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={technologyLandscapeData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div className={classes.gridContainer}>
          <HeaderRow></HeaderRow>
          {technologyLandscapeData.Company.map((row, index) => {
            return <CompanyItem key={index} row={row} index={index} />;
          })}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
}
