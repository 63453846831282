import React, { useContext } from "react";
import Workspace from "./Workspace";
import Toolbar from "../common/Toolbar";
import { UserContext } from "../context/UserContext";
import { Stack, Typography } from "@mui/material";

export default function WSHome() {
  const user = useContext(UserContext);

  var today = new Date();
  var curHr = today.getHours();

  let greetText = "Good morning, ";
  if (curHr < 12) {
    greetText = "Good morning, ";
  } else if (curHr < 18) {
    greetText = "Good afternoon, ";
  } else {
    greetText = "Good evening, ";
  }

  return (
    <div>
      <Toolbar>Home</Toolbar>
      <Workspace>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ flexGrow: "1", m: 10 }}
        >
          <Typography variant="h5" sx={{ opacity: 0.5 }}>
            {greetText}
            {user.firstName}! Home page is currently under construction.
          </Typography>
        </Stack>
      </Workspace>
    </div>
  );
}
