import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { rule5properties } from "../properties";
import { ALLOWED_ACTIONS, useUser } from "../context/UserContext";

const fetchOpportunityCompanies = async (user) => {
  if (
    !user?.userSubscription?.allowedActions.includes(
      ALLOWED_ACTIONS.viewResearch
    )
  ) {
    throw new Error("You do not have permission to view research.");
  }

  const { data } = await axios.post(rule5properties.opptySearch, {
    skinnySearch: true,
  });
  return data;
};

export const useOpportunityCompanies = () => {
  const user = useUser();
  return useQuery(
    ["opportunityCompanies", user.email],
    () => fetchOpportunityCompanies(user),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      select: (data) => {
        const sup = Object.values(
          data?.reduce((oppty, obj) => {
            if (oppty[obj.companyInfo?.companyId]) {
              // add to existing entry
              oppty[obj.companyInfo?.companyId].oppties.push({
                id: obj.id,
                functionalArea: obj.functionalArea,
              });
            } else {
              // create new entry
              oppty[obj.companyInfo?.companyId] = {
                companyId: obj.companyInfo?.companyId,
                companyInfo: obj.companyInfo,
                oppties: [{ id: obj.id, functionalArea: obj.functionalArea }],
              };
            }
            return oppty;
          }, {})
        );
        return sup;
      },
    }
  );
};
