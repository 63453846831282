import { ThemeContext } from "@emotion/react";
import React, { useContext } from "react";

/* Provides context that allows any children to determine if menu drawer is open or closed. */

const MenuContext = React.createContext();

export function useMenuContext() {
  return useContext(MenuContext);
}

export function MenuContextProvider({ children }) {
  const [open, setOpen] = React.useState(false);

  return (
    <MenuContext.Provider value={{ setOpen: setOpen, openStatus: open }}>
      {children}
    </MenuContext.Provider>
  );
}
