import React, { useEffect } from "react";

import { useDialog } from "../context/DialogContext";
import { Button, Stack, Typography, Box, Collapse } from "@mui/material";
import sfLogo from "../res/icons/salesforce.svg";
import googleLogo from "../res/icons/google.svg";
import oktaLogo from "../res/icons/okta.svg";
import { rule5properties } from "../properties";
import {
  CancelConfirmButtons,
  StyledLabel,
  StyledTextfield,
  errorStyle,
} from "../common/StyledComponents";
import { validateEmailString } from "../common/Utils";

// Third party login button, SFDC/Google/etc.
export default function ThirdPartyAuthButton(props) {
  const { statusUpdateFunction, type, email } = props;

  const dialogContext = useDialog();

  let authUrl;
  let title;
  let logo;
  if (type === "google") {
    logo = googleLogo;
    authUrl = "/google-login";
    title = "Continue with Google";
  }
  if (type === "sfdc") {
    logo = sfLogo;
    authUrl = "/sfdc-login";
    title = "Continue with Salesforce";
  }
  // If no email is provided along with okta login, create modal to get email before popup.
  if (type === "okta") {
    logo = oktaLogo;
    authUrl = "/okta-login";
    title = "Continue with Okta";
  }
  return (
    <Button
      sx={{
        textTransform: "none",
        mt: 1,
        width: "100%",
        height: "44px",
        borderRadius: "8px",
      }}
      className="crmButton"
      size="small"
      variant="outlined"
      onClick={() => {
        if (type === "okta") {
          if (email) {
            // An email is provided, we can navigate to endpoint.
            window.open(
              rule5properties.authApiUrl +
                authUrl +
                `?email=${encodeURIComponent(email)}`,
              "window",
              "toolbar=no, menubar=no, resizable=no, width=400, height=700"
            );
          } else {
            dialogContext.openModal(
              "Continue with Okta",
              GetOktaEmail,
              {
                statusUpdateFunction: statusUpdateFunction,
              },
              "sm"
            );
          }
        } else {
          window.open(
            rule5properties.authApiUrl + authUrl,
            "window",
            "toolbar=no, menubar=no, resizable=no, width=400, height=700"
          );
          // Add error listener to update page with login message.
        }
        window.addEventListener("message", (event) => {
          statusUpdateFunction(event.data.message);
        });
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ px: 0.5, py: 0.8 }}>
        {type && (
          <img
            src={logo}
            alt="logo"
            style={{ height: "24px", marginRight: "12px" }}
          />
        )}
        <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
          {title}
        </Typography>
      </Stack>
    </Button>
  );
}

/** Modal to get user's email for Okta authentication. */
function GetOktaEmail(props) {
  const { statusUpdateFunction } = props;
  const [emailValue, setEmailValue] = React.useState("");
  const [validEmail, setValidEmail] = React.useState(false);
  const [status, setStatus] = React.useState("");

  const dialogContext = useDialog();

  useEffect(() => {
    if (validateEmailString(emailValue)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }, [emailValue]);

  function continueToOkta(e) {
    e.preventDefault();
    window.open(
      rule5properties.authApiUrl + "/okta-login?email=" + emailValue,
      "window",
      "toolbar=no, menubar=no, resizable=no, width=400, height=700"
    );
    // Add error listener to update page with SFDC login message.
    window.addEventListener("message", (event) => {
      statusUpdateFunction(event.data.message);
    });
    dialogContext.closeModal();
  }

  return (
    <Box>
      <form onSubmit={continueToOkta} autoComplete="on">
        <StyledLabel fullWidth>Email Address</StyledLabel>
        <StyledTextfield
          autoFocus
          fullWidth
          value={emailValue}
          onChange={(e) => {
            setEmailValue(e.target.value);
          }}
        />
        <Collapse in={status?.trim().length > 0} sx={{ mx: 1 }}>
          <Typography sx={{ ...errorStyle, mt: 1 }}>{status}</Typography>
        </Collapse>
        <CancelConfirmButtons
          sx={{ mt: 2, width: "140px", float: "right" }}
          confirmText="Continue"
          confirmDisabled={emailValue.trim().length === 0 || !validEmail}
          confirmFunction={continueToOkta}
        />
      </form>
    </Box>
  );
}
