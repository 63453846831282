import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

export default function ConfigureSignals(props) {
  const { control, register, getValues, setValue } = useFormContext();
  const { fields: signalFields } = useFieldArray({
    control,
    name: "signals", // unique name for your Field Array
  });
  const { fields: regionFields } = useFieldArray({
    control,
    name: "regions", // unique name for your Field Array
  });

  function setAllSignals(value) {
    for (let i = 0; i < getValues("signals").length; i++) {
      setValue(`signals[${i}].checked`, value, { shouldDirty: true });
    }
  }

  function setAllRegions(value) {
    for (let i = 0; i < getValues("regions").length; i++) {
      setValue(`regions[${i}].checked`, value, { shouldDirty: true });
    }
  }

  return (
    <Box>
      <Typography variant="settingsCaption" sx={{ mb: 1 }}>
        Select signals of interest to trigger actions.
      </Typography>
      <SelectAllClearAllLinks setAllFunction={setAllSignals} />
      <Stack
        direction="column"
        flexWrap="wrap"
        alignItems="flex-start"
        sx={{ maxHeight: 300, mt: 0.5 }}
      >
        {signalFields.map((field, index) => (
          <SignalCheckbox
            key={field.signalName}
            label={field.signalName}
            itemIndex={index}
            control={control}
          />
        ))}
      </Stack>
      <Divider flexItem sx={{ my: 1 }} />
      <Typography variant="settingsCaption" sx={{ mt: 2, mb: 1 }}>
        Select regions to monitor for signals.
      </Typography>
      <SelectAllClearAllLinks setAllFunction={setAllRegions} />
      <Stack direction="row" sx={{ gap: "32px" }}>
        {regionFields.map((field, index) => (
          <RegionCheckbox
            key={field.regionName}
            label={field.regionName}
            itemIndex={index}
            control={control}
          />
        ))}
      </Stack>
    </Box>
  );
}

function SignalCheckbox(props) {
  const { label, control, itemIndex } = props;
  return (
    <FormControlLabel
      key={itemIndex}
      sx={{ fontSize: "12px" }}
      control={
        <Controller
          control={control}
          name={`signals[${itemIndex}].checked`}
          render={(values) => {
            return (
              <Checkbox
                size="small"
                {...values.field}
                checked={values.field.value}
              />
            );
          }}
        />
      }
      label={
        <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
          {label}
        </Typography>
      }
    />
  );
}

function RegionCheckbox(props) {
  const { label, control, itemIndex } = props;
  return (
    <FormControlLabel
      key={itemIndex}
      sx={{ fontSize: "12px" }}
      control={
        <Controller
          control={control}
          name={`regions[${itemIndex}].checked`}
          render={(values) => {
            return (
              <Checkbox
                size="small"
                {...values.field}
                checked={values.field.value}
              />
            );
          }}
        />
      }
      label={
        <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
          {label}
        </Typography>
      }
    />
  );
}

function SelectAllClearAllLinks(props) {
  const { setAllFunction } = props;
  return (
    <>
      <Link
        variant="caption"
        onClick={() => {
          setAllFunction(true);
        }}
        sx={{ cursor: "pointer", mr: 1.5 }}
      >
        Select All
      </Link>
      <Link
        variant="caption"
        onClick={() => {
          setAllFunction(false);
        }}
        sx={{ cursor: "pointer" }}
      >
        Clear
      </Link>
    </>
  );
}
