import { Box, ButtonBase, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { GrayCircularProgress } from "./StyledComponents";
import React from "react";

const TileButtonBase = styled(ButtonBase)(({ theme, disabled }) => ({
  height: 200,
  width: 300,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: disabled ? "rgb(230, 230, 230)" : "rgb(227, 239, 248)",
  border: "none",
  borderRadius: "12px",
  outline: "none",
  padding: "15px",
  minHeight: "150px",
  cursor: "pointer",
  pointerEvents: disabled ? "none" : "auto",
  "&:hover": {
    backgroundColor: disabled ? "rgb(230, 230, 230)" : "#ddebf7",
  },
  "&:focus": {
    backgroundColor: disabled ? "rgb(230, 230, 230)" : "#ddebf7",
  },
  "& > *": {
    pointerEvents: "auto",
  },
}));

export default function TileButton(props) {
  const { isLoading, isConnectionEnabled, AddToButton, onClick, disabled } =
    props;
  const [displayAddTo, setDisplayAddTo] = React.useState(null);

  let clickHandler = onClick;
  if (AddToButton && !isConnectionEnabled) {
    clickHandler = () => setDisplayAddTo(true);
  }

  if (displayAddTo && isConnectionEnabled) {
    setDisplayAddTo(false);
  }

  const handleClick = (event) => {
    if (!disabled && !isLoading) {
      clickHandler(event);
    }
  };

  if (displayAddTo) {
    return (
      <Stack
        sx={{
          height: 200,
          width: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AddToButton />
      </Stack>
    );
  }

  return (
    <TileButtonBase
      disableRipple
      {...props}
      disabled={isLoading || disabled}
      onClick={handleClick}
    >
      <PrimaryDisplay {...props} />
    </TileButtonBase>
  );
}

function PrimaryDisplay(props) {
  const {
    image,
    title,
    description,
    isLoading,
    isConnectionEnabled,
    disabled,
  } = props;

  let label = "";

  if (isLoading) {
    label = "Loading..";
  } else if (disabled) {
    label = description;
  } else if (!isConnectionEnabled) {
    label = "Enable integration";
  } else {
    label = description;
  }

  return (
    <>
      {isLoading ? (
        <GrayCircularProgress />
      ) : (
        <Tooltip title={title}>
          <span>
            <Box
              component="img"
              src={image}
              alt={`${title} logo`}
              sx={{
                height: "40px",
                width: "40px",
                pointerEvents: disabled ? "none" : "auto",
              }}
            />
          </span>
        </Tooltip>
      )}
      <Typography fontWeight={300}>{label}</Typography>
    </>
  );
}
