/*App.js*/
import React from "react";
import SignIn from "./user/SignIn";
import { AuthenticatedMain, AuthenticatedDet } from "./Authenticated";
import Registration from "./user/Registration";
import Verify from "./user/Verify";
import { Route, Redirect, useLocation, Switch } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import UserContextProvider from "./context/UserContext.js";
import PasswordReset from "./user/PasswordReset";
import CookieConsent from "react-cookie-consent";
import Typography from "@mui/material/Typography";
import { DialogProvider } from "./context/DialogContext";
import RedirectPage from "./Redirect";
import SidepanelProvider from "./context/SidepanelContext";
import { DisplayContextProvider } from "./context/DisplayContext";
import { MenuContextProvider } from "./context/MenuContext";
import SearchFormProvider from "./context/SearchFormContext";
import { SnackProvider } from "./context/Snack";
import { ImageMapProvider } from "./context/ImageMapContext";
import Congratulations from "./user/Congratulations.js";
import { QueryClientProvider } from "react-query";
import queryClient from "./api/queryClient.js";

const themes = [];

export default function App(props) {
  let location = useLocation();

  const theme = createTheme(require("./themes/material.json"));

  const [themeIndex, setThemeIndex] = React.useState(0);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <DisplayContextProvider>
          <SnackProvider>
            <UserContextProvider>
              <SearchFormProvider>
                <ImageMapProvider>
                  <DialogProvider>
                    <SidepanelProvider>
                      <MenuContextProvider>
                        <Switch location={location}>
                          <Route exact path="/">
                            <Redirect to="/login" />
                          </Route>
                          <Route path="/signup" component={Registration} />
                          <Route path="/redirect" component={RedirectPage} />
                          <Route path="/login" component={SignIn} />
                          <Route path="/verify" component={Verify} />
                          <Route
                            path="/password_reset"
                            component={PasswordReset}
                          />
                          <Route
                            path="/congratulations"
                            component={Congratulations}
                          />
                          <Route
                            path="/main"
                            render={() => (
                              <AuthenticatedMain
                                switchTheme={setThemeIndex}
                                themeIndex={themeIndex}
                                redirectUrl={
                                  !["/main", "/main/"].includes(
                                    location.pathname
                                  )
                                    ? location.pathname + location.search
                                    : "/main"
                                }
                              />
                            )}
                          />
                          <Route
                            path="/det"
                            render={() => (
                              <AuthenticatedDet
                                redirectUrl={
                                  location.pathname !== "/main"
                                    ? location.pathname + location.search
                                    : null
                                }
                              />
                            )}
                          />
                        </Switch>
                        <CookieConsent
                          style={{
                            boxSizing: "border-box",
                            padding: "8px 40px 8px 40px",
                            backgroundColor: "#FFF",
                            color: "#333333",
                            border: "1px solid #EEEEEE",
                            alignItems: "center",
                            zIndex: "1201",
                          }}
                          location="bottom"
                          buttonText="Accept"
                          buttonStyle={{
                            backgroundColor: "#1976d2",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            color: "#FFF",
                            fontWeight: 500,
                            padding: "8px 22px 8px 22px",
                            borderRadius: "4px",
                            height: "100%",
                            margin: "10px 6px 10px 0px",
                          }}
                          sameSite="None"
                          cookieSecurity={true}
                        >
                          <Typography sx={{ fontSize: "12px" }}>
                            This website stores cookies on your computer. These
                            cookies are used to collect information about how
                            you interact with our website and allow us to
                            remember you. We use this information in order to
                            improve and customize your browsing experience and
                            for analytics and metrics about our visitors both on
                            this website and other media. By clicking "Accept",
                            you consent to the use of cookies on this
                            application.
                          </Typography>
                        </CookieConsent>
                      </MenuContextProvider>
                    </SidepanelProvider>
                  </DialogProvider>
                </ImageMapProvider>
              </SearchFormProvider>
            </UserContextProvider>
          </SnackProvider>
        </DisplayContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
