import React from "react";
import {
  Collapse,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { StyledTextfield, ErrLabel } from "../common/StyledComponents";
import axios from "axios";
import { rule5properties } from "../properties";
import { useDialog } from "../context/DialogContext";
import { useForm } from "react-hook-form";
import { onEnterPress } from "../common/Utils";
import NewPromptOptions from "../workspaces/prompts/NewPromptOptions";
import { getSelectedCardTypesFromConversationPrompt } from "../workspaces/chat/CardTypesChip";

export default function CreatePromptBook(props) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [promptParams, setPromptParams] = React.useState({});
  const { register, handleSubmit, getValues } = useForm();

  const { initialPrompt, setShouldReload } = props;

  const dialog = useDialog();

  const onSubmit = (data, event) => {
    event.preventDefault();
    event.stopPropagation(); // So prompt book autocomplete doesn't also close
    createPromptBook(data);
  };

  const createPromptBook = (newPromptBook) => {
    const params = {
      name: newPromptBook.newBookName,
      promptBookInfo: { description: newPromptBook.newBookDescription },
      ...(initialPrompt?.prompt
        ? {
            prompts: [
              {
                prompt: initialPrompt.prompt,
                ...promptParams,
              },
            ],
          }
        : {}),
    };
    axios
      .post(rule5properties.promptBooks, params)
      .then((resp) => {
        if (resp.status === 200) {
          if (setShouldReload) {
            setShouldReload(resp.data?.id);
          }
          dialog.closeModal();
          setLoading(false);
        } else {
          setError(
            resp?.data?.message || "Unexpected error creating prompt book."
          );
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <Box sx={{}}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          <StyledTextfield
            style={{
              width: "100%",
              height: "36px",
              backgroundColor: "inherit",
              fontSize: "0.9rem",
            }}
            inputRef={(input) => input && input.focus()}
            {...register("newBookName")}
            autoFocus
            placeholder="Name"
          ></StyledTextfield>
          <StyledTextfield
            {...register("newBookDescription")}
            style={{
              minHeight: "200px",
              backgroundColor: "inherit",
              fontSize: "0.9rem",
              flexBasis: "100%",
              display: "block",
            }}
            multiline
            onKeyDown={onEnterPress(() => createPromptBook(getValues()))}
            placeholder="Description"
          ></StyledTextfield>
          {initialPrompt?.prompt && (
            <>
              <Typography
                width="100%"
                marginRight="auto"
                variant="promptBookPrompt"
              >
                {initialPrompt.prompt}
              </Typography>
              <NewPromptOptions
                initContent={
                  initialPrompt?.contents ? initialPrompt.contents : []
                }
                initCardTypes={getSelectedCardTypesFromConversationPrompt(
                  initialPrompt
                )}
                setPromptParams={setPromptParams}
              />
            </>
          )}
          <Collapse in={error !== ""}>
            <ErrLabel>{error}</ErrLabel>
          </Collapse>
          <Button
            sx={{
              textTransform: "none",
            }}
            color="primary"
            variant="outlined"
            onClick={(e) => {
              dialog.closeModal();
              e.stopPropagation(); // So prompt book autocomplete doesn't also close
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            sx={{ width: "100px" }}
            variant="contained"
          >
            <Typography
              variant="button"
              sx={{ textTransform: "none", mt: "3px" }}
            >
              Create
            </Typography>
            {loading ? (
              <CircularProgress color="inherit" size={16} sx={{ ml: 2 }} />
            ) : null}
          </Button>
        </form>
      </Box>
    </React.Fragment>
  );
}
