import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { rule5properties } from "../../../../properties";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import Twitter from "@mui/icons-material/Twitter";
import Instagram from "@mui/icons-material/Instagram";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: "0px",
    textAlign: "left",
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  flexContainer: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  collapse: {
    "&::after": {
      position: "absolute",
      bottom: 0,
      height: "100%",
      width: "100%",
      content: '""',
      background:
        "linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 0), rgba(255,255,255, 1));",
      pointerEvents: "none" /* so the text is still selectable */,
    },
  },
  flexContent: {
    flexGrow: 1,
    position: "relative",
    margin: "10px",
    width: 320,
    height: "100%",
  },
  metricsContainer: {
    display: "flex",
  },
  metrics: {
    display: "flex",
    alignItems: "center",
    paddingRight: "5%",
  },
  header: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  headerInfo: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "start",
  },
}));

export default function SocialMedia(props) {
  const defaultSocialMedia = {
    Title: "",
    SocialMedia: [],
  };
  const classes = useStyles();
  const [socialMedia, setSocialMedia] = useState(defaultSocialMedia);

  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [hasError, setError] = React.useState(false);
  const previewData = props.previewData;

  useEffect(() => {
    if (previewData) {
      setSocialMedia(previewData);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    axios
      .get(`${rule5properties.getCard}/${props.cardId}`)
      .then((response) => {
        let rawdata = response.data;
        if (rawdata.SocialMedia) {
          // Validate content
          // Array reference will update the value
          setSocialMedia(rawdata);
        } else {
          // API call failed
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setError(true);
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
      });
  }, [previewData]);

  const renderSource = (source) => {
    let icon = null;
    switch (source.toLowerCase()) {
      case "twitter":
        icon = <Twitter sx={{ mr: 1 }} />;
        break;
      case "instagram":
        icon = <Instagram sx={{ mr: 1 }} />;
        break;
      default:
        break;
    }
    return (
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "20px" }}
      >
        {icon} {source}
      </div>
    );
  };

  if (isLoading) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <CircularProgress />
      </Researchcard>
    );
  } else if (hasError) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={socialMedia.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div className={classes.flexContainer}>
          {socialMedia.SocialMedia.map((data, index) => {
            return (
              <div key={`socialMedia${index}`} className={classes.flexContent}>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Grid item className={classes.header}>
                      <div className={classes.headerInfo}>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                        >
                          <strong>{data.author_name}</strong>
                        </Typography>

                        <Typography
                          variant="body2"
                          color="text.secondary"
                          gutterBottom
                          style={{ paddingLeft: "2px" }}
                        >
                          {data.author_handle}
                        </Typography>
                      </div>
                      <Typography
                        variant="caption"
                        color="primary"
                        gutterBottom
                        style={{ paddingLeft: "2px" }}
                      >
                        {data.Source && renderSource(data.Source)}
                      </Typography>
                    </Grid>
                    <Typography variant="body2">{data.content}</Typography>
                  </Grid>
                  <Grid item>
                    <div className={classes.metrics}>
                      {Object.keys(data.metrics).map((key) => {
                        if (key === "likes") {
                          return (
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              key={`socialMedia${index}-${key}`}
                              className={classes.metrics}
                            >
                              <FavoriteBorderOutlinedIcon
                                sx={{ fontSize: 15 }}
                              />{" "}
                              <div style={{ paddingLeft: "2px" }}>
                                {data.metrics[key]}
                              </div>
                            </Typography>
                          );
                        }
                        if (key === "comments") {
                          return (
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              key={`socialMedia${index}-${key}`}
                              className={classes.metrics}
                            >
                              <MessageOutlinedIcon sx={{ fontSize: 15 }} />{" "}
                              <div style={{ paddingLeft: "2px" }}>
                                {data.metrics[key]}
                              </div>
                            </Typography>
                          );
                        }
                        if (key === "shares") {
                          return (
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              key={`socialMedia${index}-${key}`}
                              className={classes.metrics}
                            >
                              <IosShareOutlinedIcon sx={{ fontSize: 15 }} />
                              <div style={{ paddingLeft: "2px" }}>
                                {data.metrics[key]}
                              </div>
                            </Typography>
                          );
                        }
                      })}
                    </div>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
}
