import React from "react";
import Workspace from "./Workspace";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "../common/Toolbar";
import { ALLOWED_ACTIONS, useUser } from "../context/UserContext";
import { useLocation, Switch, Route, Redirect, Link } from "react-router-dom";
import Profile from "../settings/Profile";
import PasswordChange from "../settings/PasswordChange";
import Notifications from "../settings/Notifications";
import { StyledTab } from "./opportunity/OpportunityWindow";
import { Tabs } from "@mui/material";
import Gpt from "../settings/Gpt";
import Integrations from "../settings/Integrations";
import ManagePlan from "../settings/ManagePlan";

import { filter, map } from "lodash";
import Api from "../settings/Api";

export default function WSSettings(props) {
  const userContext = useUser();
  const location = useLocation();

  return (
    <div>
      <Toolbar>Settings</Toolbar>
      <Switch location={location}>
        <Route
          exact
          path="/main/settings/:tab"
          render={(props) => <OpportunityNavTabs {...props} />}
        />
      </Switch>
      <Workspace height="calc(100vh - 120px)">
        <Box
          sx={{
            width: "100%",
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Switch>
            <Route exact path="/main/settings">
              <Redirect to="/main/settings/profile" />
            </Route>
            <Route path="/main/settings/profile" component={Profile} />
            {userContext.crmSystem === null && (
              <Route
                path="/main/settings/password"
                component={PasswordChange}
              />
            )}
            <Route path="/main/settings/manageplan" component={ManagePlan} />
            {userContext.userSubscription?.allowedActions?.includes(
              ALLOWED_ACTIONS.viewNotifications
            ) && (
              <Route
                path="/main/settings/notifications"
                component={Notifications}
              />
            )}
            <Route path="/main/settings/gpt" component={Gpt} />
            <Route
              path="/main/settings/integrations"
              component={Integrations}
            />
            <Route path="/main/settings/api" component={Api} />
          </Switch>
        </Box>
      </Workspace>
    </div>
  );
}

export function OpportunityNavTabs(props) {
  const userContext = useUser();
  const location = useLocation();

  const displayApiMenu = ["Premium"].includes(
    userContext.userSubscription.planType
  );

  const actionMenu = [
    { label: "My Profile", value: "profile" },
    // { label: "Plan & Billing", value: "manageplan" }, //TODO
    userContext.crmSystem === null && {
      label: "Change Password",
      value: "password",
    },
    { label: "Plan & Billing", value: "manageplan" },
    userContext.userSubscription?.allowedActions?.includes(
      ALLOWED_ACTIONS.viewNotifications
    ) && {
      label: "Notifications",
      value: "notifications",
    },
    { label: "GPT", value: "gpt" },
    { label: "Integrations", value: "integrations" },
    ...(displayApiMenu ? [{ label: "API", value: "api" }] : []),
  ].filter(Boolean);

  const [navSelected, setNavSelected] = React.useState(0); // Default to tab at index 0

  React.useEffect(() => {
    const foundIndex = actionMenu.findIndex((el) =>
      location.pathname.endsWith(el.value)
    );
    if (foundIndex >= 0) {
      setNavSelected(foundIndex);
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setNavSelected(newValue);
  };

  return (
    <Tabs
      centered
      value={navSelected}
      onChange={handleChange}
      aria-label="Opportunity Tabs"
      allowScrollButtonsMobile
      sx={{ mb: 1 }}
    >
      {map(filter(actionMenu, Boolean), (row, index) => {
        return (
          <StyledTab
            key={index}
            label={row.label}
            component={Link}
            to={row.value}
          />
        );
      })}
    </Tabs>
  );
}
