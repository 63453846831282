import React from "react";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import OPXResearch from "./research/OPXResearch";
import OPXHuddle from "./huddle/OPXHuddle";
import OPXCollaborate from "./collaborate/OPXCollaborate";
import OPXUpdate from "./update/OPXUpdate";
import OPXTrack from "./track/OPXTrack";
import OPXReview from "./review/OPXReview";
import {
  Link,
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom";
import { CircularProgress, Stack, Box } from "@mui/material";
import JwtService from "../../user/JwtService";
import { rule5BaseUrl, rule5properties } from "../../properties";
import axios from "axios";
import Image from "../../common/Image";

export const actionMenu = rule5BaseUrl.includes("dev")
  ? [
      { label: "Research", action: 0 },
      { label: "Huddle", action: 1 },
      { label: "Collaborate", action: 2 },
      { label: "Update", action: 3 },
      { label: "Track", action: 4 },
      { label: "Review", action: 5 },
    ]
  : [{ label: "Research", action: 0 }];

const useStyles = makeStyles(() => ({
  root: {
    height: 72,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "calc(100vh - 72px)",
    maxWidth: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "5px 0px 5px 0px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0,0,0,.15)",
    },
  },
  headerContainer: {
    margin: "4px 0px 24px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100px",
    width: "100%",
    justifyContent: "space-between",
  },
  backButton: {
    backgroundColor: "#1565c0",
    transform: "none",
    width: "56px",
    height: "56px",
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
    borderRadius: "50%",
    color: "#fff",
    position: "absolute",
    top: 72 + 20,
    right: 100,
  },
  loadingContainer: {
    flexGrow: 1,
  },
  companyLogo: {
    width: "120px",
    maxHeight: "120px",
    flexShrink: "1",
    "@media (max-width: 1200px)": {
      display: "none",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "auto",
    },
  },
  title: {
    // fontSize: theme.opportunity.banner.titleSize,
    fontSize: "34px",
    color: "#000000",
    whiteSpace: "nowrap",
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
  },
  subtitle: {
    // fontSize: theme.opportunity.banner.subtitleSize,
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "14px",
    color: "#000000",
    // whiteSpace: "nowrap",
    textAlign: "center",
  },
  topStack: {
    marginTop: "14px",
    marginBottom: "14px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "90%",
  },
  placeholderContainer: {
    width: "100%",
    flexGrow: "1",
    backgroundColor: "black",
    height: "20px",
  },
}));

const MemoizedResearch = React.memo(OPXResearch);

export default function OpportunityWindow(props) {
  const classes = useStyles();
  const location = useLocation();
  const [pageReady, setPageReady] = React.useState(false);
  const [opportunity, setOpportunity] = React.useState({});

  let params = useParams();

  useEffect(() => {
    getOpportunity();
    if (location.search.includes("requested=true")) {
      props.setRowsStale(true);
    }
    return function cleanup() {
      props.setToolbarDetails();
    };
  }, []);

  let prevScrollDist = 0;

  const handleScroll = (event) => {
    if ((prevScrollDist <= 80) & (event.target.scrollTop > 80)) {
      props.setShowNavBarDetails(true);
    }
    if ((prevScrollDist > 80) & (event.target.scrollTop <= 80)) {
      props.setShowNavBarDetails(false);
    }
    prevScrollDist = event.target.scrollTop;
  };

  const getOpportunity = () => {
    let url = rule5properties.getOpportunityList + `/ ${props.match.params.id}`;
    axios
      .get(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JwtService.getToken(),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        let data = res.data; // props.opportunity;
        setOpportunity(data);
        props.setToolbarDetails(data, params.workspace);
        setPageReady(true);
      });
  };

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <Stack
      id="workspaceStyled"
      alignItems="center"
      className={classes.container}
      onScroll={handleScroll}
    >
      {pageReady ? (
        <div
          style={{
            maxWidth: "1400px",
            width: "100%",
            flexGrow: "1",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack alignItems="center" className={classes.topStack}>
            <div className={classes.headerContainer}>
              <Tooltip title={opportunity.companyName}>
                <Box className={classes.companyLogo}>
                  {opportunity.companyLogo && (
                    <Image src={opportunity.companyLogo} />
                  )}
                </Box>
              </Tooltip>
              <div
                style={{
                  minWidth: "600px",
                  display: "flex",
                  flexGrow: "1",
                  justifyContent: "center",
                  alignSelf: "end",
                  flexDirection: "column",
                }}
              >
                <div className={classes.title} style={{ marginBottom: "10px" }}>
                  {opportunity.Name}
                </div>
              </div>
              <Tooltip title={opportunity.orgName}>
                <Box className={classes.companyLogo}>
                  {opportunity.orgLogo && <Image src={opportunity.orgLogo} />}
                </Box>
              </Tooltip>
            </div>
            <Switch location={location}>
              <Route
                exact
                path="/main/opportunities/*/:tab"
                render={(props) => <OpportunityNavTabs {...props} />}
              />
            </Switch>
          </Stack>
          <Switch>
            <Route
              exact
              path="/main/opportunities/:id"
              render={({ match }) => (
                <Redirect
                  to={`/ main / opportunities / ${match.params.id} / research`}
                />
              )}
            />
            <Route
              path="/main/opportunities/:id/research"
              render={() => <MemoizedResearch opportunity={opportunity} />}
            />
            <Route
              path="/main/opportunities/:id/huddle"
              render={() => <OPXHuddle opportunity={opportunity} />}
            />
            <Route
              path="/main/opportunities/:id/collaborate"
              render={() => <OPXCollaborate opportunity={opportunity} />}
            />
            <Route
              path="/main/opportunities/:id/update"
              render={() => <OPXUpdate opportunity={opportunity} />}
            />
            <Route
              path="/main/opportunities/:id/track"
              render={() => <OPXTrack opportunity={opportunity} />}
            />
            <Route
              path="/main/opportunities/:id/review"
              render={() => <OPXReview opportunity={opportunity} />}
            />
          </Switch>
        </div>
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          className={classes.loadingContainer}
        >
          <CircularProgress size={60} />
        </Stack>
      )}
    </Stack>
  );
}

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 400,
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.7)",
    transition: "0.2s",
    "&.Mui-selected": {
      color: "rgb(25, 118, 210)",
      fontWeight: "500",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.8)",
    },
    "&:hover": {
      color: "rgb(25, 118, 210)",
    },
  })
);

export function OpportunityNavTabs(props) {
  const actionMap = new Map();

  actionMenu.map((row, index) => {
    actionMap.set(row.label.toLowerCase(), row.action);
  });

  let params = useParams();

  const [navSelected, setNavSelected] = React.useState(
    actionMap.get(params.tab)
  );
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setNavSelected(newValue);
  };

  return (
    <Tabs
      sx={{ width: "100%" }}
      value={navSelected}
      onChange={handleChange}
      aria-label="Opportunity Tabs"
      classes={{ root: classes.customTabs }}
      variant="scrollable"
      allowScrollButtonsMobile
    >
      {actionMenu.map((row, index) => {
        return (
          <StyledTab
            key={index}
            label={row.label}
            component={Link}
            to={row.label.toLowerCase()}
          />
        );
      })}
    </Tabs>
  );
}
